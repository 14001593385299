import { PublicKey } from '@solana/web3.js';

export class Const {
  public static TOKEN_METADATA_PROGRAM_ID = new PublicKey(
    'metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s'
  );
  public static SOLANA_CHAIN_ID = 10;
  public static NFT_TYPE_SINGLE = 1;
  public static NFT_TYPE_MULTIPLE = 2;
  public static SOL_ADDR: string =
    'So11111111111111111111111111111111111111111';
}

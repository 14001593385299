import React, { useRef, RefObject, useContext } from 'react';
import styled from 'styled-components';
import ReactDOM from 'react-dom';
import { useOutsideClick } from 'src/hooks/useOutsideClick';
import { ModalContext } from 'src/contexts/ModalContext';
import { theme } from 'src/styles/theme';
import ic_close from 'src/assets/ic_close.svg';

const ModalWrapper: React.FC = () => {
  console.log('=================== ModalWrapper =====================');
  const {
    modalContent,
    handleModal,
    isModalOpened,
    setIsLoadingList,
    setCurPage,
    setMorailsCursor,
    setTitle,
    title,
    setSubtitle,
    subtitle,
    setList,
  } = useContext(ModalContext);
  const divRef = useRef<HTMLDivElement>();

  const initialize = () => {
    handleModal();
    setIsLoadingList(false);
    setCurPage(1);
    setMorailsCursor('');
    setTitle('');
    setSubtitle('');
    setList([]);
  };

  useOutsideClick(divRef, () => {
    if (isModalOpened) {
      initialize();
    }
  });

  return ReactDOM.createPortal(
    <>
      {isModalOpened && (
        <StyledModalWrapper>
          <div
            className="modal-container"
            ref={divRef as RefObject<HTMLDivElement>}
          >
            <div className="modal-title">
              <div className="txt-area">
                {title && (
                  <div className="upper">
                    <div className="title">{title}</div>
                    <div className="close-btn-area">
                      <div
                        className="close"
                        onClick={() => {
                          initialize();
                        }}
                      />
                    </div>
                  </div>
                )}
                {subtitle && (
                  <div className="bottom">
                    {subtitle.split('\n').map((el: string) => {
                      return (
                        <React.Fragment key={el}>
                          <span>{el}</span>
                          <br />
                        </React.Fragment>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
            {modalContent}
          </div>
        </StyledModalWrapper>
      )}
    </>,
    document.querySelector('#modal-wrapper')!
  );
};

const StyledModalWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0px;
  z-index: 10001;
  width: 100vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  & > div.modal-container {
    position: relative;
    background-color: ${theme.mono6};
    border-radius: 6px;
    border: 1px solid ${theme.mono5};
    & > div.modal-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 24px 24px 0px 24px;
      & > div.txt-area {
        display: flex;
        flex-direction: column;
        width: 100%;

        & > div.upper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          & > div.title {
            ${theme.title1};
            color: ${theme.mono1};
          }
          & > div.close-btn-area {
            & > div.close {
              width: 24px;
              height: 24px;
              background: url(${ic_close}) top 0px center no-repeat;
              background-size: 24px 24px;
              cursor: pointer;
            }
          }
        }
        & > div.bottom {
          ${theme.body1};
          color: ${theme.mono4};
          margin-top: 6px;
        }
      }
    }
  }
`;

export { ModalWrapper };

import { useState, ReactNode } from 'react';
import { bridgePageAction } from 'src/actions/bridgePageAction';
import {
  TokenItemResponseType,
  NFTItemResponseType,
} from 'src/constants/types';
import { stepAction } from 'src/actions/stepAction';

export const useModal = () => {
  const [isModalOpened, toggleModal] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<ReactNode>();
  const [curPage, setCurPage] = useState<number>(1);
  const [morailsCursor, setMorailsCursor] = useState<string>('');
  const [isLoadingList, setIsLoadingList] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);

  const [title, setTitle] = useState<string>('');
  const [subtitle, setSubtitle] = useState<string>('');
  const [list, setList] = useState<any[]>([]);

  const handleModal = (props?: any) => {
    toggleModal(!!props ? true : false);
    if (!!props) {
      setModalContent(props);
    }
  };
  /* In useModal hook, to set from token info || from NFT info **/
  const handleOnSelect = (
    type: 'nft' | 'token',
    e: TokenItemResponseType & NFTItemResponseType
  ) => {
    // if token :
    // chainNo
    // originChainNo
    // originTokenAddr
    // tokenAddr
    // tokenBalance
    // tokenDecimal
    // tokenIdNo
    // tokenName
    // tokenSymbol
    // tokenType

    if (type === 'nft') {
      const props = {
        tokenId: e.tokenId,
        tokenName: e.tokenName,
        tokenSymbol: e.tokenSymbol,
        tokenImage: e.tokenImage,
        tokenScAddr: e.tokenAddr,
        tokenType: e.tokenType,
        aptosCreatorAddr: e.aptosCreatorAddr,
        aptosCollectionName: e.aptosCollectionName,
        aptosTokenName: e.aptosTokenName,
        aptosTokenPropertyVersion: e.aptosTokenPropertyVersion,
        transferTokenAmount: e.transferTokenAmount,
      };

      bridgePageAction._inputBalance('1');
      bridgePageAction._changeFromToken(props);
      stepAction._changeStep(2);
      setCurPage(1);
      setMorailsCursor('');
      handleModal();
    } else if (type === 'token') {
      const tokenObj = {
        tokenId: e.tokenIdNo,
        tokenName: e.tokenName,
        tokenSymbol: e.tokenSymbol,
        tokenAddr: e.tokenAddr,
        tokenMaxBalance: e.tokenBalance,
        tokenDecimal: e.tokenDecimal,
        tokenType: e.tokenType,
      };
      bridgePageAction._changeFromToken(tokenObj);
      stepAction._changeStep(3);
      handleModal();
    }
    setTitle('');
    setSubtitle('');
  };

  return {
    isModalOpened,
    modalContent,
    handleModal,
    setCurPage,
    curPage,
    setIsLoadingList,
    isLoadingList,
    setMorailsCursor,
    morailsCursor,
    setTotal,
    total,
    setTitle,
    title,
    setSubtitle,
    subtitle,
    handleOnSelect,
    setList,
    list,
  };
};

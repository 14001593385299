import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { theme } from 'src/styles/theme';

interface IProps {
  value: string | number;
  setValue: Dispatch<SetStateAction<string | number>>;
  onEnter?: (el: any) => void;
  reset?: () => void;
  disabled?: boolean;
  icon?: string;
  clearIcon?: string;
  hasIcon: boolean;
  placeholder?: string;
  color?: string;
}

const Input: React.FC<IProps> = (props) => {
  const {
    value,
    setValue,
    onEnter,
    reset,
    disabled = false,
    icon = '',
    clearIcon,
    hasIcon = false,
    placeholder = '',
    color = 'white',
  } = props;

  return (
    <StyledInput
      hasIcon={hasIcon}
      icon={icon}
      placeholder={placeholder}
      value={value}
      color={color}
      onChange={(e: any) => {
        if (!!reset) {
          reset();
        }
        setValue(e.target.value);
      }}
      onKeyUp={(e) => {
        if (e.key === 'Enter') {
          if (!!onEnter) {
            onEnter(value);
          }
        }
      }}
      disabled={disabled}
    />
  );
};

const StyledInput = styled.input<{
  hasIcon: boolean;
  icon: string | null;
  color: string;
}>`
  width: 100%;
  height: 100%;
  background-color: transparent;
  padding: 0px;
  border: none;
  color: ${(props) => (props.color === 'white' ? theme.mono1 : theme.mono4)};
  ${(props) =>
    props.hasIcon
      ? `
    padding: 14px;
    background : url(${props.icon}) center right 14px no-repeat;
    background-size : 20px 20px; 
  `
      : ` 
  `}
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: ${theme.mono5};
  }
  &:disabled {
    color: ${theme.mono5};
  }
`;

export { Input };

import React from 'react';
import styled from 'styled-components';

import { ReactComponent as InfoIcon } from 'src/assets/ic_exclude.svg';
import { theme } from 'src/styles/theme';

interface IProps {
  txt?: string;
}

const Warning4: React.FC<IProps> = ({ txt }) => {
  return (
    <StyledView>
      <InfoIcon />
      <div className="txt-area">
        <span className="txt-red">
          The {txt} doesn't support a batch transfer of NFTs. Only a single
          transfer is allowed.
        </span>
      </div>
    </StyledView>
  );
};

const StyledView = styled.div`
  position: relative;
  padding-left: 26px;
  width: 100%;
  & > svg {
    position: absolute;
    left: 0px;
  }
  & > svg > path {
    fill: ${theme.error};
  }
  & > div.txt-area {
    width: 100%;
    & > span {
      ${theme.body2};
      color: ${theme.mono4};
      &.txt-red {
        color: ${theme.error};
      }
    }
  }
`;

export { Warning4 };

import React, {
  useRef,
  RefObject,
  useState,
  useCallback,
  useEffect,
} from 'react';
import styled from 'styled-components';
import { ListItemType } from 'src/constants/types';
import { classNameBinder } from 'src/utils/utils';
import { NETWORK_LOGO } from 'src/constants/index';
import { theme } from 'src/styles/theme';
import { useOutsideClick } from 'src/hooks/useOutsideClick';
import logo_default from 'src/assets/chain/chain_default_dark.png';

import ic_dropdown_default from 'src/assets/down_default.svg';
import ic_dropdown_up_default from 'src/assets/down_up_default.svg';
import ic_dropdown_disabled from 'src/assets/down_disabled.svg';

interface IProps {
  initialValue: string;
  value?: number;
  name?: string;
  list: ListItemType[];
  height: string;
  handleOnSelect: (e: string | number) => void;
  handleOnList: () => void;
  disabled?: boolean;
}

const ComboBox: React.FC<IProps> = (props) => {
  const {
    initialValue,
    value,
    name,
    list,
    height,
    handleOnSelect,
    handleOnList,
    disabled = false,
  } = props;

  const divRef = useRef<HTMLDivElement>();
  const scrollRef = useRef<HTMLUListElement>();
  const [toggle, setToggle] = useState<boolean>(false);
  // const [curIdx, setCurIdx] = useState<number>(-1);
  // const [img, setCurImg] = useState<string>('');

  const handleOnClick = (el: ListItemType, idx: number) => {
    const {
      // icon = "",
      value,
    } = el;
    handleOnSelect(value);
    // setCurIdx(idx);
  };

  useOutsideClick(divRef, () => {
    setToggle(false);
  });

  useEffect(() => {
    scrollRef.current!.scrollTo(0, 0);
    scrollRef.current!.scrollTop = 0;
  }, [toggle]);
  // const _filtered = list.filter((el) => el.value === value);

  const onClick = useCallback(() => {
    console.log(scrollRef);
    scrollRef.current!.scrollTop = 0;
  }, []);
  const _name = name;
  const _icon =
    !!value && !!NETWORK_LOGO[value] ? NETWORK_LOGO[value] : logo_default;
  const hasNoList = list.length === 0;

  return (
    <StyledComboBoxWrapper
      ref={divRef as RefObject<HTMLDivElement>}
      onClick={() => {
        if (disabled) return;
        setToggle(!toggle);
        onClick();
        handleOnList();
      }}
      className={
        classNameBinder({ prefix: '', suffix: 'disabled', cond: disabled }) +
        classNameBinder({ prefix: '', suffix: 'active', cond: toggle })
      }
    >
      <StyledComboBoxTitle>
        {!!_name && !!_icon && !disabled && <img src={_icon} alt='logo' />}
        <span>{!!_name ? _name : initialValue}</span>
      </StyledComboBoxTitle>
      <StyledComboBoxList
        show={toggle}
        height={height}
        ref={scrollRef as RefObject<HTMLUListElement>}
      >
        {hasNoList && (
          <div className='no-list'>
            <span>No List</span>
          </div>
        )}
        {!hasNoList &&
          list.map((el: ListItemType, idx: number) => {
            const prefix = '';
            const suffix = 'active';
            const cond = idx === value;
            const props = {
              prefix,
              suffix,
              cond,
            };
            const { icon, name } = el;
            return (
              <StyledComboBoxItem
                key={idx.toString()}
                className={classNameBinder(props)}
                onClick={() => handleOnClick(el, idx)}
              >
                {!!icon && <img src={icon} alt='logo' />}
                {name}
              </StyledComboBoxItem>
            );
          })}
      </StyledComboBoxList>
    </StyledComboBoxWrapper>
  );
};

const StyledComboBoxWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 14px 61px 14px 14px;
  border-radius: 5px;
  border: 1px solid ${theme.mono8};
  color: ${theme.mono1};
  background: url(${ic_dropdown_default}) center right 14px no-repeat;
  background-size: 20px 20px;
  background-color: ${theme.mono8};
  cursor: pointer;
  &:hover {
    height: 100%;
    border: 1px solid ${theme.primaryBG};
  }
  &.disabled {
    color: ${theme.mono5};
    background: url(${ic_dropdown_disabled}) center right 14px no-repeat;
    background-size: 20px 20px;
    background-color: ${theme.mono8};
    cursor: default;
    &:hover {
      border: 1px solid ${theme.mono8};
    }
  }
  &.active {
    background: url(${ic_dropdown_up_default}) center right 14px no-repeat;
    background-size: 20px 20px;
    border: 1px solid ${theme.primaryBG};
    background-color: ${theme.mono8};
  }
`;

const StyledComboBoxTitle = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  & > img {
    width: 34px;
    height: 34px;
    margin-right: 8px;
    border-radius: 6px;
  }
`;

const StyledComboBoxList = styled.ul<{ show: boolean; height: string }>`
  position: absolute;
  z-index: 1010;
  display: ${(props) => (props.show ? 'block' : 'none')};
  width: calc(100% + 2px);
  max-height: 340px;
  overflow-x: hidden;
  top: ${(props) => props.height};
  left: -1px;
  list-style: none;
  padding: 0px;
  margin: 0px;
  background-color: ${theme.mono6};
  border: 1px solid ${theme.mono5};
  border-radius: 6px;
  box-shadow: 0px 16px 18px 0px rgba(0, 0, 0, 0.32);
  & > div.no-list {
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    padding: 14px;
    & > span {
      ${theme.body1};
      color: ${theme.mono1};
    }
  }
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${theme.mono4};
    border-radius: 4px;
    background-clip: padding-box;
    border: 2px solid transparent;
  }
  ::-webkit-scrollbar-track {
    margin-top: 6px;
    margin-bottom: 6px;
  }
`;

const StyledComboBoxItem = styled.li`
  position: relative;
  display: flex;
  align-items: center;
  width: calc(100% + 2px);
  padding: 7px 104px 7px 14px;
  height: 50px;
  & > img {
    width: 34px;
    height: 34px;
    margin-right: 8px;
    border-radius: 6px;
  }
  &:hover {
    background-color: rgba(0, 222, 158, 0.08);
  }
`;

export { ComboBox };

import React from 'react';
import styled from 'styled-components';

import { ReactComponent as InfoIcon } from 'src/assets/ic_info.svg';
import { theme } from 'src/styles/theme';

const Warning3: React.FC = () => {
  return (
    <StyledView>
      <InfoIcon />
      <div className="txt-area">
        <span className="txt-green">
          Please make sure you have enough to pay the bridge fees on the Source
          chain.
        </span>
        <br />
        <span>
          If there isn't enough left to pay for the fee, the bridge transaction
          will not be completed.
        </span>
      </div>
    </StyledView>
  );
};

const StyledView = styled.div`
  position: relative;
  padding-left: 26px;
  width: 100%;
  & > svg {
    position: absolute;
    left: 0px;
  }
  & > svg > path {
    fill: ${theme.primaryBG};
  }
  & > div.txt-area {
    width: 100%;
    & > span {
      ${theme.body2};
      color: ${theme.mono4};
      &.txt-green {
        color: ${theme.primaryBG};
      }
    }
  }
`;

export { Warning3 };

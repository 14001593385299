import React, { useState } from 'react';
import styled from 'styled-components';
import { theme } from 'src/styles/theme';
import img_empty_nft from 'src/assets/img_empty_nft.svg';
import img_empty_token from 'src/assets/tokens/img_havah_default.png';

interface IProps {
  url: string;
  isNFT?: boolean;
  img?: string;
  outline?: boolean;
}

const ImageLoader: React.FC<IProps> = ({
  isNFT = true,
  url,
  img,
  outline = false,
}) => {
  const [isVideo, showVideo] = useState<boolean>(false);
  const [iteration, setIteration] = useState<number>(0);
  const failedToLoad = iteration > 2;
  const hasUrl = !!url;
  const convertUrl = url?.startsWith('data:')
    ? url
    : 'https://imageproxy.havah.io/160x160/' + url;

  return (
    <StyledElement outline={outline}>
      {!failedToLoad && hasUrl && !isVideo && (
        <img
          src={convertUrl}
          alt='img'
          onError={({ currentTarget }) => {
            showVideo(true);
            setIteration((prev) => prev + 1);
            currentTarget.src = img_empty_nft;
          }}
        />
      )}
      {!failedToLoad && hasUrl && isVideo && (
        <video
          width='100%'
          height='100%'
          autoPlay={false}
          src={url}
          onError={({ currentTarget }) => {
            showVideo(false);
            setIteration((prev) => prev + 1);
            currentTarget.src = img_empty_nft;
          }}
        />
      )}
      {(failedToLoad || !hasUrl) && isNFT && (
        <img src={!!img ? img : img_empty_nft} alt='img' />
      )}
      {(failedToLoad || !hasUrl) && !isNFT && (
        <img src={!!img ? img : img_empty_token} alt='img' />
      )}
    </StyledElement>
  );
};

const StyledElement = styled.div<{ outline: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  overflow: hidden;
  ${(props) =>
    props.outline &&
    `
    outline: 1px solid ${theme.mono3};
    outline-offset: -1px;
  `}
  & > img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
  & > video {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
`;

export { ImageLoader };

import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { PATH } from 'src/constants/index';
import { ModalContext } from 'src/contexts/ModalShadowContext';
import { theme } from 'src/styles/theme';
import ic_logo from 'src/assets/havah_mitter_logo_green.png';
import img_service_unavailable from 'src/assets/img_service_unavailable.png';

const ModalServiceUnavailablePage: React.FC = () => {
  const { handleModal } = useContext(ModalContext);
  return (
    <StyledModal>
      <Link
        className="logo"
        to={PATH.NFT}
        onClick={() => {
          handleModal();
        }}
      />
      <div className="page-content">
        <img src={img_service_unavailable} alt="service-unavailable" />
        <div className="page-txt">
          <span>OOOPS!</span>
          <span>
            HAVAH Mitter is temporarily unavailable, Please try again later!
          </span>
        </div>
      </div>
    </StyledModal>
  );
};

const StyledModal = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme.mono8};
  z-index: 21000;
  & > a.logo {
    position: fixed;
    top: 10px;
    left: 26px;
    width: 170px;
    height: 40px;
    background: url(${ic_logo}) center center no-repeat;
    background-size: 170px 40px;
    cursor: pointer;
  }
  & > div.page-content {
    display: flex;
    & > img {
      width: 265px;
      height: 260px;
    }
    & > div.page-txt {
      display: flex;
      flex-direction: column;
      padding-top: 100px;
      & > span:first-of-type {
        ${theme.headline1};
        line-height: 40px;
        font-family: LuckiestGuy;
        color: ${theme.mono1};
        margin-bottom: 4px;
      }
      & > span:last-of-type {
        ${theme.title1};
        font-weight: 400;
        color: ${theme.mono3};
      }
    }
  }
`;

export { ModalServiceUnavailablePage };

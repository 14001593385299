import { Buffer } from 'buffer';
import { Assignable } from './instructions/assignable';
import * as borsh from 'borsh';
import { PublicKey } from '@solana/web3.js';
import { deserialize, field, fixedArray, serialize } from '@dao-xyz/borsh';

export class CollectionByOriginInfo extends Assignable {
  wrappedCollection: PublicKey;
  nftType: number;
  static fromBuffer(buffer: Buffer) {
    return borsh.deserialize(
      CollectionByOriginInfoSchema,
      CollectionByOriginInfo,
      buffer
    );
  }
}

const CollectionByOriginInfoSchema = new Map([
  [
    CollectionByOriginInfo,
    {
      kind: 'struct',
      fields: [
        ['wrappedCollection', ['u8', 32]],
        ['nftType', 'u8'],
      ],
    },
  ],
]);

export class WrappedByOriginInfo extends Assignable {
  wrapped: PublicKey;
  static fromBuffer(buffer: Buffer) {
    return borsh.deserialize(
      WrappedByOriginInfoSchema,
      WrappedByOriginInfo,
      buffer
    );
  }
}

const WrappedByOriginInfoSchema = new Map([
  [
    WrappedByOriginInfo,
    {
      kind: 'struct',
      fields: [['wrapped', ['u8', 32]]],
    },
  ],
]);

export class SettingsState extends Assignable {
  @field({ type: fixedArray('u8', 32) })
  admin: PublicKey;
  @field({ type: fixedArray('u8', 32) })
  validator: PublicKey;
  @field({ type: fixedArray('u8', 32) })
  targetChainFeeTreasury: PublicKey;
  @field({ type: fixedArray('u8', 32) })
  commissionTreasury: PublicKey;
  @field({ type: fixedArray('u8', 32) })
  lookupTable: PublicKey;
  @field({ type: 'u8' })
  active: boolean;

  static fromBuffer(buffer: Buffer) {
    const retData = new SettingsState({});
    retData.admin = new PublicKey(buffer.slice(0, 32));
    retData.validator = new PublicKey(buffer.slice(32, 64));
    retData.targetChainFeeTreasury = new PublicKey(buffer.slice(64, 96));
    retData.commissionTreasury = new PublicKey(buffer.slice(96, 128));
    retData.lookupTable = new PublicKey(buffer.slice(128, 160));
    retData.active = buffer[160] === 1 ? true : false;
    return retData;
    // return borsh.deserialize(SettingsSchema, SettingsState, buffer);
  }
}

const SettingsSchema = new Map([
  [
    SettingsState,
    {
      kind: 'struct',
      fields: [
        ['admin', ['u8', 32]],
        ['validator', ['u8', 32]],
        ['targetChainFeeTreasury', ['u8', 32]],
        ['commissionTreasury', ['u8', 32]],
        ['lookupTable', ['u8', 32]],
        ['active', 'u8'],
      ],
    },
  ],
]);

import React, { useState, useEffect, useCallback, useContext } from 'react';
import {
  FromChainConnectView,
  ModalWalletList,
  ModalServiceUnavailablePage,
} from 'src/components/index';
import { requestNFTChainList } from 'src/apis/api';
import bridgePageStore from 'src/stores/BridgePageStore';
import { ModalContext } from 'src/contexts/ModalContext';
import { ModalContext as ModalShadowContext } from 'src/contexts/ModalShadowContext';
import { bridgePageAction } from 'src/actions/bridgePageAction';
import { stepAction } from 'src/actions/stepAction';
import { ChainConfig, ChainListType } from 'src/constants/types';
import { WALLET_LIST } from 'src/constants/index';
import { NETWORK_ID } from 'src/constants/enums';
import { errCodeParser } from 'src/utils/utils';

const FromChainConnectViewContainer: React.FC = () => {
  const { handleModal, setTitle } = useContext(ModalContext);
  const shadowModal = useContext(ModalShadowContext);
  const store = bridgePageStore;
  const config = store.getBridgeConfig();

  const fromChainNo = (config.fromChain as ChainConfig).chainNo;
  const fromChainName = (config.fromChain as ChainConfig).chainName;
  const fromWalletAddr = config.fromWalletAddr;

  const [chainList, setChainList] = useState<ChainListType[]>([]);
  const [walletAddr, setWalletAddr] = useState<string>(fromWalletAddr);
  const [chainNo, setChainNo] = useState<number>(fromChainNo);
  const [chainName, setChainName] = useState<string>(fromChainName);

  const _onChange = () => {
    const _config = bridgePageStore.getBridgeConfig();
    const fromChainNo = (_config.fromChain as ChainConfig).chainNo;
    const fromChainName = (_config.fromChain as ChainConfig).chainName;
    const fromWalletAddr = _config.fromWalletAddr;
    setWalletAddr(fromWalletAddr);
    setChainNo(fromChainNo);
    setChainName(fromChainName);
  };

  useEffect(() => {
    store.addChangeListener(_onChange);
    return () => store.addChangeListener(_onChange);
  }, [store]);

  const getFromChainList = useCallback(async () => {
    try {
      const rsp = await requestNFTChainList();
      //Fix This
      // setChainList(rsp.concat({
      //   bridgeNftScAddr: "0x7d37dde40f5fbbb08a6bd3efb7deeccb44e9449b07c14e15bfa111fdeebb1158",
      //   chainName: "BASE",
      //   chainNo: 9,
      //   chainSymbol: "ETH",
      //   endpoint: "https://goerli.base.org"
      // }, {
      //   bridgeNftScAddr: "0x7d37dde40f5fbbb08a6bd3efb7deeccb44e9449b07c14e15bfa111fdeebb1158",
      //   chainName: "OKX",
      //   chainNo: 104,
      //   chainSymbol: "OKB",
      //   endpoint: "https://okbtestrpc.okbchain.org"
      // }));
      setChainList(rsp);
      //Fix This
      // setChainList(
      //   rsp.concat({
      //     bridgeNftScAddr:
      //       '0x7d37dde40f5fbbb08a6bd3efb7deeccb44e9449b07c14e15bfa111fdeebb1158',
      //     chainName: 'SOLANA',
      //     chainNo: 10,
      //     chainSymbol: 'SOL',
      //     endpoint: 'https://api.devnet.solana.com',
      //   })
      // );
    } catch (e: any) {
      fullyReset();
      const { retCode } = errCodeParser(e);
      if (retCode === '1') {
        shadowModal.handleModal(<ModalServiceUnavailablePage />);
      }
    }
  }, [shadowModal]);

  useEffect(() => {
    getFromChainList();
  }, []);

  const chainIdHandler = useCallback(
    async (chainId: number | string) => {
      const chainListIdx = chainList.findIndex((el) => el.chainNo === chainId);
      bridgePageAction._changeFromChain(chainList[chainListIdx]);
      bridgePageAction._removeFromWallet();
      bridgePageAction._removeFromToken();
      bridgePageAction._removeToChain();
      bridgePageAction._removeWeb3WalletID();
      bridgePageAction._removeToWallet();
      stepAction._changeStep(0);
    },
    [chainList]
  );

  const modalHandler = useCallback(() => {
    // WALLET_LIST[]
    const _chainNo = fromChainNo as number;
    const chainName = NETWORK_ID[_chainNo];
    const chainListIdx = chainList.findIndex(
      (el) => el.chainNo === fromChainNo
    );
    const chainInfo = chainList[chainListIdx];
    const toLower = chainName.toLowerCase();
    const walletList = WALLET_LIST[toLower];

    setTitle('Connect Wallet');
    handleModal(<ModalWalletList list={walletList} chainInfo={chainInfo} />);
  }, [fromChainNo, chainList, setTitle, handleModal]);

  const fromChainBtnDisabled = !fromChainNo;

  const resetFromChainView = () => {
    bridgePageAction._removeFromWallet();
    bridgePageAction._removeFromToken();
    bridgePageAction._removeToChain();
    bridgePageAction._removeWeb3WalletID();
    bridgePageAction._removeToWallet();
    stepAction._changeStep(0);
  };

  const fullyReset = () => {
    bridgePageAction._removeFromChain();
    bridgePageAction._removeFromWallet();
    bridgePageAction._removeFromToken();
    bridgePageAction._removeToChain();
    bridgePageAction._inputBalance('0');
    bridgePageAction._removeToWallet();
    bridgePageAction._removeWeb3WalletID();
    stepAction._changeStep(0);
  };

  return (
    <FromChainConnectView
      modalHandler={modalHandler}
      btnDisabled={fromChainBtnDisabled}
      chainIdHandler={chainIdHandler}
      reset={resetFromChainView}
      handleOnList={getFromChainList}
      walletAddr={walletAddr}
      chainNo={chainNo}
      chainName={chainName}
      chainList={chainList}
    />
  );
};

export { FromChainConnectViewContainer };

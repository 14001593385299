import React from 'react';
import styled from 'styled-components';
import { classNameBinder } from 'src/utils/utils';
import { theme } from 'src/styles/theme';
import ic_arr_left_default from 'src/assets/left_default.svg';

interface IProps {
  coordX: number;
  isNFT: boolean;
  stepIdx: number;
}

const Stepper: React.FC<IProps> = ({ coordX, stepIdx, isNFT }) => {
  return (
    <StyledStepper coordX={coordX}>
      <StyledStepList>
        <StyledStep
          className={classNameBinder({
            prefix: '',
            suffix: 'active',
            cond: stepIdx === 0,
          })}
        >
          1. Source chain
        </StyledStep>
        <StyledStep
          className={classNameBinder({
            prefix: '',
            suffix: 'active',
            cond: stepIdx === 1 || stepIdx === 3,
          })}
        >
          2. {isNFT ? 'NFT' : 'Token'}s
        </StyledStep>
        <StyledStep
          className={classNameBinder({
            prefix: '',
            suffix: 'active',
            cond: stepIdx === 2 || stepIdx === 4,
          })}
        >
          3. Destination chain
        </StyledStep>
        <StyledStep
          className={classNameBinder({
            prefix: '',
            suffix: 'active',
            cond: stepIdx === 5,
          })}
        >
          4. Send
        </StyledStep>
        <StyledStep
          className={classNameBinder({
            prefix: '',
            suffix: 'active',
            cond: stepIdx === 6,
          })}
        >
          5. Complete
        </StyledStep>
      </StyledStepList>
    </StyledStepper>
  );
};

const StyledStepper = styled.div<{ coordX: number }>`
  position: fixed;
  z-index: 1000;
  top: 218px;
  left: ${(props) => props.coordX}px;
  pointer-events: none;
`;

const StyledStepList = styled.ul`
  list-style: none;
  padding: 0px;
  margin: 0px;
  width: 166px;
  height: 196px;
`;

const StyledStep = styled.li`
  ${theme.body1};
  color: ${theme.mono4};
  padding: 0px 0px 0px 24px;
  margin-bottom: 24px;
  &.active {
    ${theme.tab1};
    color: ${theme.mono1};
    background: url(${ic_arr_left_default}) center left 0px no-repeat;
    background-size: 20px 20px;
  }
  &:last-of-type {
    margin-bottom: 0px;
  }
`;

export { Stepper };

import { EventEmitter } from 'events';
import dispatcher from 'src/dispatcher/dispatcher';
import { TOKEN_TRANSFER_STEP } from 'src/constants/actionTypes';

const CHANGE_STEP_EVENT = 'CHANGE_STEP';
let _stepCount: number = 0;

class StepStore extends EventEmitter {
  constructor() {
    super();
    dispatcher.register((payload: any) => {
      const action = payload.action.actionType;
      const stepNum = payload.action.id;
      switch (action) {
        case TOKEN_TRANSFER_STEP.SET_STEP_NUMBER:
          this.setStep(stepNum);
          this.emitChange();
          break;
        default:
          break;
      }
    });
  }

  addChangeListener(callback: any) {
    this.addListener(CHANGE_STEP_EVENT, callback);
  }

  removeChangeListener(callback: any) {
    this.removeListener(CHANGE_STEP_EVENT, callback);
  }

  emitChange() {
    this.emit(CHANGE_STEP_EVENT);
  }

  setStep = (stepNum: number) => {
    _stepCount = stepNum;
  };

  getStepNumber() {
    return _stepCount;
  }
}

const stepStore = new StepStore();

export default stepStore;

import { useState, useEffect, useCallback, useContext } from 'react';
import MetamaskApi from 'src/sc/metamaskApi';
import { METAMASK_NATIVE_CURRENCY } from 'src/constants';
import { stringToHexConverter } from 'src/utils/utils';
import { useSyncProviders } from './useSyncProviders';
import { bridgePageAction } from 'src/actions/bridgePageAction';
import { stepAction } from 'src/actions/stepAction';
import { ModalContext } from 'src/contexts/ModalContext';
import bridgePageStore from 'src/stores/BridgePageStore';
import { ChainConfig } from 'src/constants/types';
import { NETWORK_ID } from 'src/constants/enums';

export const useMetamask = () => {
  const providers = useSyncProviders();
  const [metamaskApi, setMetamaskApi] = useState<MetamaskApi | null>(null);
  const { handleModal } = useContext(ModalContext);

  useEffect(() => {
    if (providers.length) {
      const _metamaskProvider: any = providers.find(
        (provider) => provider.info.rdns === 'io.metamask'
      );
      if (_metamaskProvider) {
        const _metamaskApi = new MetamaskApi(_metamaskProvider.provider);
        setMetamaskApi(_metamaskApi);
      }
    }
  }, [providers]);

  const getMetamaskAddress = useCallback(async () => {
    console.log('getMetamaskAddress');
    if (!metamaskApi) return '';
    const res = await metamaskApi.getMetamaskAddress();
    console.log('setCurrentAccount', res.address);

    return res.address;
  }, [metamaskApi]);

  const connectMetamask = useCallback(
    async (isNft: boolean) => {
      try {
        console.log('connectMetamask');
        const _metamaskProvider: any = providers.find(
          (provider) => provider.info.rdns === 'io.metamask'
        );
        if (!_metamaskProvider) return null;

        const _metamaskApi = new MetamaskApi(_metamaskProvider.provider);
        const ret = await _metamaskApi.getMetamaskAddress();
        console.log('setCurrentAccount', ret.address);
        // if (ret.address) setMetamaskState({ address: ret.address });

        setMetamaskApi(_metamaskApi);

        _metamaskApi?.provider.on('accountsChanged', async (e: any) => {
          if (metamaskApi == null) return;
          const store = bridgePageStore;
          const config = store.getBridgeConfig();

          const fromChain = config.fromChain as ChainConfig;
          const toChain = config.toChain as ChainConfig;
          const toAddr = config.toWalletAddr;

          if (e.length === 0) {
            bridgePageAction._removeFromWallet();
            bridgePageAction._removeFromToken();
            bridgePageAction._removeToChain();
            bridgePageAction._removeWeb3WalletID();
            bridgePageAction._removeToWallet();
            stepAction._changeStep(0);
            handleModal();
            return;
          }
          if (
            fromChain.chainNo === NETWORK_ID.BASE ||
            fromChain.chainNo === NETWORK_ID.BSC ||
            fromChain.chainNo === NETWORK_ID.ETHEREUM ||
            fromChain.chainNo === NETWORK_ID.FNCY ||
            fromChain.chainNo === NETWORK_ID.KLAYTN ||
            fromChain.chainNo === NETWORK_ID.MEVERSE ||
            fromChain.chainNo === NETWORK_ID.POLYGON ||
            fromChain.chainNo === NETWORK_ID.OKTC ||
            fromChain.chainNo === NETWORK_ID.WEMIX
          ) {
            bridgePageAction._removeFromWallet();
            bridgePageAction._removeFromToken();
            bridgePageAction._removeToChain();
            bridgePageAction._removeWeb3WalletID();
            bridgePageAction._removeToWallet();
            stepAction._changeStep(0);
            handleModal();
          } else if (
            (toChain.chainNo === NETWORK_ID.BASE ||
              toChain.chainNo === NETWORK_ID.BSC ||
              toChain.chainNo === NETWORK_ID.ETHEREUM ||
              toChain.chainNo === NETWORK_ID.FNCY ||
              toChain.chainNo === NETWORK_ID.KLAYTN ||
              toChain.chainNo === NETWORK_ID.MEVERSE ||
              toChain.chainNo === NETWORK_ID.POLYGON ||
              toChain.chainNo === NETWORK_ID.OKTC ||
              toChain.chainNo === NETWORK_ID.WEMIX) &&
            toAddr !== ''
          ) {
            bridgePageAction._removeToWallet();
            stepAction._changeStep(isNft ? 2 : 4);
            handleModal();
          }
        });

        return ret.address;
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [providers, metamaskApi, handleModal]
  );

  const switchEthereumChain = useCallback(
    async (chainID: number) => {
      if (!metamaskApi) return;

      await metamaskApi.provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x' + chainID.toString(16) }],
      });
    },
    [metamaskApi]
  );

  const requestAddChain = useCallback(
    async (chainID: number, chainName: string, rpcUrl: string) => {
      const symbol = METAMASK_NATIVE_CURRENCY[chainID];
      const toHexChainId = stringToHexConverter(chainID);
      if (!metamaskApi) return;
      try {
        await metamaskApi.provider.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: toHexChainId,
              chainName: chainName,
              rpcUrls: [rpcUrl],
              nativeCurrency: {
                symbol: symbol,
                decimals: 18,
              },
            },
          ],
        });
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [metamaskApi]
  );

  const getMetamaskInstalled = useCallback(async () => {
    if (!metamaskApi) return false;
    return await metamaskApi.checkMetamaskInstalled();
  }, [metamaskApi]);

  const sendTransaction = useCallback(
    async (data: any) => {
      if (!metamaskApi) return null;
      try {
        const txHash = await metamaskApi.provider.request({
          method: 'eth_sendTransaction',
          params: [data],
        });
        return { txHash };
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [metamaskApi]
  );

  const checkTransaction = useCallback(
    async (txHash: string) => {
      if (!metamaskApi) return null;
      const recursive = async (): Promise<any> => {
        const result = await metamaskApi.provider.request({
          method: 'eth_getTransactionReceipt',
          params: [txHash],
        });
        if (result) {
          return result;
        } else {
          await delay(1000);
          return await recursive();
        }
      };
      return recursive();
    },
    [metamaskApi]
  );

  const requestPermission = useCallback(async () => {
    if (!metamaskApi) return null;
    try {
      await metamaskApi.requestWalletPermission();
    } catch (e) {
      console.error(e);
      throw e;
    }
  }, [metamaskApi]);

  const delay = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  return {
    connectMetamask,
    switchEthereumChain,
    getMetamaskInstalled,
    getMetamaskAddress,
    requestAddChain,
    sendTransaction,
    checkTransaction,
    requestPermission,
  };
};

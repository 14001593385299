import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { Pagination } from 'src/components/index';
import { theme } from 'src/styles/theme';

interface IProps {
  curPage: number;
  setCurPage: Dispatch<SetStateAction<number>>;
  totalCount: number;
}

const TablePaginationView: React.FC<IProps> = (props) => {
  const { curPage, setCurPage, totalCount } = props;
  return (
    <StyledView>
      <div className="pagination-area">
        <Pagination
          curPage={curPage}
          totalCount={totalCount}
          setCurPage={setCurPage}
        />
      </div>
    </StyledView>
  );
};

const StyledView = styled.div`
  width: 100vw;
  background-color: ${theme.mono1};
  padding-top: 32px;
  & > div.pagination-area {
    margin: 0px auto;
  }
`;

export { TablePaginationView };

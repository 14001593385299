import React from 'react';
import styled from 'styled-components';
import { ComboBox, Button } from 'src/components/index';
import { NETWORK_LOGO } from 'src/constants/index';
import { theme } from 'src/styles/theme';
import logo_default from 'src/assets/chain/chain_default_dark.png';

interface IProps {
  chainList: any[];
  curStepIdx: number;
  chainNo?: number;
  chainName?: string;
  handleOnList: () => void;
  isActiveToChain: boolean;
  isActiveToChainWallet: boolean;
  toChainWalletAddr?: string;
  modalHandler: () => void;
  toChainIdHandler: (val: number | string) => void;
  reset: () => void;
}

const ToChainConnectView: React.FC<IProps> = (props) => {
  const {
    chainList = [],
    isActiveToChain,
    isActiveToChainWallet,
    chainNo,
    chainName,
    toChainWalletAddr,
    modalHandler,
    toChainIdHandler,
    reset,
    handleOnList,
  } = props;
  const hasList = chainList.length > 0;
  const top = chainList.length > 6 ? -345 : -307 + (6 - chainList.length) * 50;
  const chainListForComboBox = hasList
    ? chainList.map((el, index) => {
        const { chainName, chainNo } = el;
        const icon = !!NETWORK_LOGO[chainNo]
          ? NETWORK_LOGO[chainNo]
          : logo_default;

        return {
          icon,
          name: chainName,
          value: chainNo,
        };
      })
    : [];

  return (
    <StyledComp>
      <div className='title'>To</div>
      <div className='connect-area'>
        <div className='w-388'>
          <ComboBox
            disabled={!isActiveToChain}
            initialValue={'Select Destination chain'}
            value={chainNo}
            name={chainName}
            height={top + 'px'}
            list={chainListForComboBox}
            handleOnSelect={toChainIdHandler}
            handleOnList={handleOnList}
          />
        </div>
        <div className='mgr-16' />
        <div className='w-160'>
          <Button
            disabled={!isActiveToChainWallet}
            initialValue={'Connect Wallet'}
            name={toChainWalletAddr}
            type={!!toChainWalletAddr ? 'connected' : 'cta'}
            handleOnClick={() => {
              if (!!toChainWalletAddr) {
                reset();
                return;
              }
              modalHandler();
            }}
          />
        </div>
      </div>
    </StyledComp>
  );
};

const StyledComp = styled.div`
  width: 100%;
  height: auto;
  & > div {
    &.title {
      ${theme.title1};
      color: ${theme.mono1};
      margin-bottom: 16px;
    }
    &.sub-title {
      color: ${theme.mono3};
      margin-bottom: 6px;
    }
    &.connect-area {
      display: flex;
      align-items: center;
      width: auto;
      height: 48px;
    }
  }
  .mgr-16 {
    margin-right: 16;
  }
  .w-160 {
    width: 160px;
    height: 100%;
  }
  .w-388 {
    width: 388px;
    height: 100%;
  }
`;

export { ToChainConnectView };

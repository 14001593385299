import React from 'react';
import styled from 'styled-components';
import { theme } from 'src/styles/theme';

interface IProps {
  close: () => void;
}

const ModalWalletNotInstalled: React.FC<IProps> = ({ close }) => {
  return (
    <StyledModal>
      <div className="modal-info">
        <div className="title">Wallet installation error</div>
        <div className="desc">
          Please refresh this page <br />
          after wallet has been installed.
        </div>
      </div>
      <div className="btn-area" onClick={() => close()}>
        OK
      </div>
    </StyledModal>
  );
};

const StyledModal = styled.div`
  width: 342px;
  border: 1px solid ${theme.mono5};
  background-color: ${theme.mono6};
  border-radius: 6px;

  & > div.modal-info {
    padding: 24px;
    border-bottom: 1px solid ${theme.mono5};
    & > div.title {
      ${theme.title1};
      color: ${theme.mono1};
      margin-bottom: 10px;
    }
    & > div.desc {
      ${theme.body1};
      color: ${theme.mono3};
    }
  }
  & > div.btn-area {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 44px;
    color: ${theme.primaryTxt};
    cursor: pointer;
    &:hover {
      color: ${theme.primaryBG};
    }
  }
`;

export { ModalWalletNotInstalled };

import React, { useEffect, ChangeEvent, RefObject, useRef } from 'react';
import styled from 'styled-components';
import { Warning1 } from 'src/components/index';
import { TOKEN_LOGO } from 'src/constants/index';
import { bridgePageAction } from 'src/actions/bridgePageAction';
import { stepAction } from 'src/actions/stepAction';
import { theme } from 'src/styles/theme';
import { noExponential, commify } from 'src/utils/utils';
import ic_close from 'src/assets/ic_close.svg';
import default_token from 'src/assets/tokens/img_token_default_dark.png';

interface IProps {
  maxBalance?: string;
  tokenName?: string;
  tokenId: number;
  tokenSymbol?: string;
  balance?: string;
  amountToMax: () => void;
  changeFromChainTokenBalance: (e: ChangeEvent<HTMLInputElement>) => void;
  isOver: boolean;
  isZero: boolean;
}

const FromChainTokenSelectedView: React.FC<IProps> = (props) => {
  const {
    maxBalance = '',
    tokenName = '',
    tokenId,
    tokenSymbol = '',
    balance = '',
    amountToMax,
    changeFromChainTokenBalance,
    isOver,
    isZero,
  } = props;

  const inputEl = useRef<HTMLInputElement>();

  useEffect(() => {
    const { current } = inputEl;
    if (current) {
      current.focus();
    }
  }, [inputEl]);

  return (
    <>
      <div className='mgb-22' />
      <div className='divider' />
      <div className='mgb-22' />
      <StyledComp>
        <div className='token-area'>
          <span>Selected tokens</span>
          <div className='selected-token'>
            <div className='token-info'>
              <img
                src={
                  !!TOKEN_LOGO[tokenId] ? TOKEN_LOGO[tokenId] : default_token
                }
                alt='logo'
              />
              <span>{tokenName}</span>
            </div>
            <div className='balance-info'>
              <span className='bal'>
                {commify(noExponential(parseFloat(maxBalance)))}
              </span>
              <span className='symbol'>{tokenSymbol}</span>
              <div>
                <img
                  src={ic_close}
                  onClick={() => {
                    stepAction._changeStep(1);
                    bridgePageAction._removeFromToken();
                    bridgePageAction._removeToChain();
                    bridgePageAction._removeToWallet();
                    bridgePageAction._inputBalance('0');
                  }}
                  alt='close'
                />
              </div>
            </div>
          </div>
        </div>
        <div className='token-area'>
          <span>Amount</span>
          <div className='input-token'>
            <input
              ref={inputEl as RefObject<HTMLInputElement>}
              placeholder={'0.0'}
              value={balance}
              onChange={(e) => changeFromChainTokenBalance(e)}
            />
            <div className='sh-divider' />
            <span className='symbol'>{tokenSymbol}</span>
            <div onClick={() => amountToMax()} className='max-btn'>
              MAX
            </div>
          </div>
        </div>
      </StyledComp>
      {isOver && <Warning1 txt={'Amount exceeds the current balance.'} />}
      {isZero && <Warning1 txt={'Amount must be greater than zero.'} />}
    </>
  );
};

const StyledComp = styled.div`
  width: 100%;
  height: auto;
  & > div.token-area {
    color: ${theme.mono1};
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    & > div.selected-token {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 9px 19px 9px 14px;
      width: 420px;
      height: 100%;
      border-radius: 6px 6px 0px 0px;
      background-color: ${theme.mono8};
      border: 1px solid ${theme.mono8};
      & > div {
        display: flex;
        align-items: center;
        &.token-info {
          & > img {
            width: 34px;
            height: 34px;
            margin-right: 8px;
            border-radius: 6px;
          }
        }
        &.balance-info {
          & > span.bal {
            max-width: 140px;
            overflow-x: scroll;
            ${theme.tab1};
            margin-right: 10px;
            -ms-overflow-style: none; /* IE and Edge */
            scrollbar-width: none;
            &::-webkit-scrollbar {
              display: none; /* Chrome, Safari, Opera*/
            }
          }
          & > span.symbol {
            color: ${theme.mono3};
          }
          & > div {
            width: 47px;
            height: 100%;
            & > img {
              float: right;
              width: 20px;
              height: 20px;
              cursor: pointer;
            }
          }
        }
      }
    }
    & > div.input-token {
      display: flex;
      align-items: center;
      width: 420px;
      height: 100%;
      padding: 13px 14px 13px 0px;
      background-color: ${theme.mono8};
      border-radius: 0px 0px 6px 6px;
      border: 1px solid ${theme.mono8};
      &:focus-within {
        border-radius: 0px 0px 6px 6px;
        border: 1px solid ${theme.primaryBG};
      }
      & > span.symbol {
        color: ${theme.mono3};
        margin-right: 14px;
      }
      & > div.max-btn {
        ${theme.body2};
        line-height: 16px;
        font-weight: 600;
        color: ${theme.mono1};
        border-radius: 6px;
        background-color: ${theme.mono5};
        padding: 3px 6px;
        cursor: pointer;
      }
      & > input {
        ${theme.tab1};
        position: relative;
        z-index: 9999;
        color: ${theme.mono1};
        width: calc(100% - 92px);
        padding: 0px;
        margin: 0px;
        margin-right: 10px;
        height: 100%;
        border: none;
        border-radius: 0px 0px 6px 6px;
        text-align: right;
        background-color: transparent;
        &::placeholder {
          ${theme.tab1};
        }
        &:focus {
          border: none;
          outline: none;
        }
      }
    }
  }
  .sh-divider {
    width: 1px;
    height: 16px;
    border-left: 1px solid ${theme.primaryBG};
    margin-right: 10px;
  }
`;

export { FromChainTokenSelectedView };

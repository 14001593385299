import {
  EIP6963AnnounceProviderEvent,
  EIP6963ProviderDetail
} from '@metamask/providers';

let providers: EIP6963ProviderDetail[] = [];

export const epiStore = {
  value: () => providers,
  subscribe: (callback: () => void) => {
    function onAnnouncement(event: any) {
      const detail = (<CustomEvent>event).detail;
      if (providers.map((p) => p.info.uuid).includes(event.detail.info.uuid))
        return;
      providers = [...providers, event.detail];
      callback();
    }
    window.addEventListener('eip6963:announceProvider', onAnnouncement);
    window.dispatchEvent(new Event('eip6963:requestProvider'));

    return () =>
      window.removeEventListener('eip6963:announceProvider', onAnnouncement);
  }
};

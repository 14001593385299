import React, { useRef, RefObject } from 'react';
import styled from 'styled-components';
import { PollingModal } from 'src/components/Common/Modal/PollingModal';
import { useOutsideClick } from 'src/hooks/useOutsideClick';

interface IProps {
  stepIdx: number;
}

const PollingModalWrapper: React.FC<IProps> = ({ stepIdx }) => {
  const divRef = useRef<HTMLDivElement>();
  useOutsideClick(divRef, () => {});

  return (
    <StyledModalWrapper>
      <div
        className='modal-container'
        ref={divRef as RefObject<HTMLDivElement>}
      >
        <PollingModal stepIdx={stepIdx} />
      </div>
    </StyledModalWrapper>
  );
};

const StyledModalWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0px;
  z-index: 30001;
  width: 100vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
`;

export { PollingModalWrapper };

import React, { useState, useEffect, ChangeEvent, useCallback } from 'react';
import { FromChainTokenSelectedView } from 'src/components/index';
import bridgePageStore from 'src/stores/BridgePageStore';
import { bridgePageAction } from 'src/actions/bridgePageAction';
import { stepAction } from 'src/actions/stepAction';
import {
  numberSubstract,
  numberZeroCompare,
  numberGreaterThan,
  noExponential,
  commify,
} from 'src/utils/utils';
import { ChainConfig, FromFTConfig } from 'src/constants/types';

const FromChainTokenSelectedViewContainer: React.FC = () => {
  const store = bridgePageStore;
  const config = store.getBridgeConfig();
  const fromToken = config.fromToken as FromFTConfig;
  const fromChain = config.fromChain as ChainConfig;

  const tokenName = fromToken.tokenName;
  const tokenId = fromToken.tokenId;
  const tokenSymbol = fromToken.tokenSymbol;
  const tokenMaxBalance = fromToken.tokenMaxBalance;
  const tokenType = fromToken.tokenType;
  const tokenDecimal = fromToken.tokenDecimal;
  const chainNo = fromChain.chainNo;
  const _initBalance = config.balance;
  const [balance, setBalance] = useState<string>(
    _initBalance === '0' ? '' : _initBalance
  );
  // const [balance, setBalance] = useState<string>(config.balance);
  const [isOver, setIsOver] = useState<boolean>(false);
  const [isZero, setIsZero] = useState<boolean>(false);

  /* tokenType이 코인인 경우, 이더는 0.1을 빼고 그외 체인은 0.01을 뺀다. (수수료 때문) tokenType이 토큰이면 max Balance를 셋팅한다. **/
  const subtractDefaultChainFee = useCallback(
    (value: string) => {
      let _sub = '0';
      if (tokenType === 'C') {
        if (chainNo !== 5) {
          _sub = numberSubstract(value, 0.1);
        } else {
          _sub = numberSubstract(value, 0.01);
        }
      } else {
        _sub = value;
      }
      return _sub;
    },
    [tokenType]
  );

  const amountToMax = useCallback(() => {
    const _tokenMaxBalance = tokenMaxBalance as string;
    let _sub = subtractDefaultChainFee(_tokenMaxBalance);
    const compared = numberZeroCompare(_sub);
    if (compared === 'zero') {
      setIsZero(true);
    } else if (compared === 'lt') {
      _sub = '0';
    }

    if (compared === 'gt') {
      stepAction._changeStep(4);
      bridgePageAction._inputBalance(commify(_sub.toString()));
      bridgePageAction._removeToChain();
      bridgePageAction._removeToWallet();
    }
  }, [tokenMaxBalance, subtractDefaultChainFee]);

  const changeFromChangeBalance = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value.replaceAll(',', '');
      const compared = numberZeroCompare(value);

      // Decimal length & number input constraint
      const rgx = /^(\d+)?([.]?\d{0,6})?$/g;
      if (!value.match(rgx)) {
        return;
      }

      // if decimal longer than token decimal, constraint decimal length;
      const hasDecimal = !!value.split('.')[1];
      const _tokenDec = tokenDecimal as number;
      if (hasDecimal && value.split('.')[1].length > _tokenDec) {
        return;
      }

      if (compared === 'zero') {
        setIsZero(true);
      } else {
        setIsZero(false);
      }

      if (!value) {
        setIsOver(false);
      }

      if (numberGreaterThan(value, tokenMaxBalance)) {
        setIsOver(true);
      } else {
        setIsOver(false);
      }
      const convertValue =
        value.includes('.') && hasDecimal === false
          ? commify(value) + '.'
          : commify(value);

      bridgePageAction._inputBalance(convertValue);
      bridgePageAction._removeToChain();
      bridgePageAction._removeToWallet();

      if (
        !value ||
        compared === 'zero' ||
        numberGreaterThan(value, tokenMaxBalance)
      ) {
        stepAction._changeStep(3);
      } else {
        stepAction._changeStep(4);
      }
    },
    [tokenDecimal, tokenMaxBalance]
  );

  const _onChangeFromChangeBalance = () => {
    const config = store.getBridgeConfig();
    const { balance } = config;
    setBalance(balance);
    // setIsOver(false);
  };

  useEffect(() => {
    store.addChangeListener(_onChangeFromChangeBalance);
    return () => store.removeChangeListener(_onChangeFromChangeBalance);
  }, []);

  return (
    <FromChainTokenSelectedView
      maxBalance={tokenMaxBalance}
      tokenName={tokenName}
      tokenId={tokenId as number}
      tokenSymbol={tokenSymbol}
      balance={balance}
      amountToMax={amountToMax}
      changeFromChainTokenBalance={changeFromChangeBalance}
      isOver={isOver}
      isZero={isZero}
    />
  );
};

export { FromChainTokenSelectedViewContainer };

import React from 'react';
import styled from 'styled-components';
import { theme } from 'src/styles/theme';
import img_step from 'src/assets/img_step.svg';

const ModalStatusDescription: React.FC = () => {
  return (
    <StyledModal>
      <div className="divider" />
      <div className="modal-desc">
        <div className="desc-title">
          <span>|</span>
          <span>Processing</span>
        </div>
        <div className="desc-content">
          Depending on the processing status of the Destination chain TX, it
          will be displayed in three steps, as shown below:
        </div>
        <div className="scenario-box">
          <img src={img_step} alt="step" />
          <div className="scenario">
            <div>Preparing to send Token/NFT</div>
            <div>Source chain is sending Token/NFT</div>
            <div>Destination chain is receiving Token/NFT</div>
          </div>
        </div>
        <div className="desc-title">
          <span>|</span>
          <span>Completed</span>
        </div>
        <div className="desc-content">
          The Source chain TX and Destination chain TX have been successfully
          created and the Bridge has been completed.
        </div>
        <div className="desc-title">
          <span>|</span>
          <span>Failed</span>
        </div>
        <div className="desc-content v2">
          The final bridge failed due to an error during the generation of
          Source chain TX.
        </div>
        <div className="scenario-box v2">
          <div>
            Explorer displays Source chain TX only for successfully generated
            transactions; It does not display transactions that are being
            processed or failed.
          </div>
        </div>
      </div>
    </StyledModal>
  );
};

const StyledModal = styled.div`
  width: 624px;
  padding: 24px;
  padding-top: 14px;

  & > div.divider {
    border-bottom: 1px solid ${theme.mono5};
    margin-bottom: 20px;
  }
  & > div.modal-desc {
    & > div.desc-title {
      color: ${theme.mono1};
      margin-bottom: 2px;
      & > span:first-of-type {
        margin-right: 6px;
      }
      & > span:last-of-type {
        ${theme.tab1};
      }
    }
    & > div.desc-content {
      color: ${theme.mono4};
      margin-bottom: 20px;
      padding-left: 9px;
      &.v2 {
        margin-bottom: 26px;
      }
      &:last-of-type {
        margin-bottom: 0px;
      }
    }
    & > div.scenario-box {
      display: flex;
      flex-direction: column;
      ${theme.body2};
      color: ${theme.mono3};
      width: 100%;
      border-radius: 6px;
      background-color: ${theme.mono7};
      padding: 28px;
      margin-bottom: 20px;
      &.v2 {
        padding: 10px 14px !important;
        color: ${theme.mono4};
      }
      &:last-of-type {
        margin-bottom: 0px;
      }
      & > img {
        width: 420px;
        height: 20px;
        margin-bottom: 9px;
      }
      & > div.scenario {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 80px;
      }
    }
  }
`;

export { ModalStatusDescription };

import React, { useEffect, useState } from 'react';
import bridgePageStore from 'src/stores/BridgePageStore';
import { bridgePageAction } from 'src/actions/bridgePageAction';
import { stepAction } from 'src/actions/stepAction';
import { FromNFTSelectedView } from 'src/components/index';
import { FromNFTConfig } from 'src/constants/types';

const FromNFTSelectedViewContainer: React.FC = () => {
  const store = bridgePageStore;
  const config = store.getBridgeConfig();
  const { fromToken } = config;

  const {
    tokenImage,
    tokenName,
    tokenId,
    aptosCreatorAddr,
    transferTokenAmount,
  } = fromToken as FromNFTConfig;
  const nftInfo = {
    name: tokenName,
    image: tokenImage,
  };

  const [info, setInfo] = useState<{ name: string; image: string }>(nftInfo);
  const [id, setTokenId] = useState<string>(tokenId);
  const [aptosAddr, setAptosAddr] = useState<string | undefined>(
    aptosCreatorAddr
  );

  const _onChange = () => {
    const _config = store.getBridgeConfig();
    const { tokenImage, tokenName, tokenId, aptosCreatorAddr } =
      _config.fromToken as FromNFTConfig;
    const info = {
      name: tokenName,
      image: tokenImage,
    };
    setInfo(info);
    setAptosAddr(aptosCreatorAddr);
    if (!aptosCreatorAddr) setTokenId(tokenId);
  };

  useEffect(() => {
    store.addChangeListener(_onChange);
    return () => store.removeChangeListener(_onChange);
  }, []);

  const _handleOnClick = () => {
    bridgePageAction._removeFromToken();
    bridgePageAction._removeToChain();
    bridgePageAction._removeToWallet();
    stepAction._changeStep(1);
  };

  return (
    <FromNFTSelectedView
      nftInfo={info}
      tokenId={id as string}
      aptosCreatorAddr={aptosAddr as string}
      close={_handleOnClick}
      tokenCount={transferTokenAmount}
    />
  );
};

export { FromNFTSelectedViewContainer };

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Input } from 'src/components/index';
import { theme } from 'src/styles/theme';
import ic_search from 'src/assets/ic_search_black.svg';
import ic_remove from 'src/assets/ic_remove_gray.png';

interface IProps {
  userInput: string;
  handleOnSearch: (el: string) => void;
  onEnter: (el: string) => void;
  reset: () => void;
}

const TableSearchInputView: React.FC<IProps> = (props) => {
  const { handleOnSearch, onEnter, reset } = props;
  const _initValue = props.userInput;

  const [userInput, setUserInput] = useState<string | number>(_initValue);
  const _userInputStr = userInput as string;
  const hasInput = _userInputStr.length > 0;
  return (
    <StyledView>
      <div className="search-area">
        <div className="input-wrapper">
          {hasInput && (
            <div
              className="remove-btn"
              onClick={() => {
                reset();
                setUserInput('');
              }}
            />
          )}
          <Input
            color={'mono4'}
            placeholder="Account, Tx Hash"
            value={userInput}
            setValue={(e) => {
              if (!e) {
                reset();
              }
              setUserInput(e);
            }}
            onEnter={() => onEnter(userInput as string)}
            hasIcon={false}
          />
          <div className="img-area">
            <img
              onClick={() => {
                handleOnSearch(userInput as string);
                // setUserInput("");
              }}
              src={ic_search}
            />
          </div>
        </div>
      </div>
    </StyledView>
  );
};

const StyledView = styled.div`
  width: 100vw;
  height: auto;
  padding-top: 50px;
  padding-bottom: 14px;
  background-color: ${theme.mono1};
  & > div.search-area {
    display: flex;
    justify-content: flex-end;
    width: 1208px;
    height: auto;
    margin: 0px auto;
    & > div.input-wrapper {
      position: relative;
      width: 375px;
      border-radius: 6px;
      border: 1px solid ${theme.mono2};
      padding: 14px 62px 14px 14px;
      &:focus-within {
        border: 1px solid ${theme.primaryBG};
      }
      & > input {
        color: ${theme.mono7};
      }
      & > input::placeholder {
        color: ${theme.mono4};
      }
      & > div.img-area {
        position: absolute;
        right: 14px;
        top: 14px;
        & > img {
          width: 20px;
          height: 20px;
          cursor: pointer;
        }
      }
      & > div.remove-btn {
        width: 20px;
        height: 20px;
        position: absolute;
        right: 40px;
        background: url(${ic_remove}) center center no-repeat;
        background-size: 20px 20px;
        cursor: pointer;
      }
    }
  }
`;

export { TableSearchInputView };

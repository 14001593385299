import React from 'react';
import styled from 'styled-components';
import { ExplorerSummaryDataType } from 'src/constants/types';
import { commify } from 'src/utils/utils';
import { theme } from 'src/styles/theme';
type ExcludeTopMoved = Omit<ExplorerSummaryDataType, 'topMoved'>;

interface IProps {
  data: ExcludeTopMoved;
}

const TransferSummaryView: React.FC<IProps> = (props) => {
  const {
    totalQuantityFT24 = '0',
    totalQuantityNFT24 = '0',
    totalTransactions = '0',
  } = props.data;
  return (
    <StyledView>
      <div>
        <div className='sh-line' />
        <div className='card-title'>
          <span>Total Bridges</span>
        </div>
        <div className='card-val'>{commify(totalTransactions)}</div>
      </div>
      <div>
        <div className='sh-line' />
        <div className='card-title'>
          <span>Total NFTs</span>
          <span className='interval'>[24h]</span>
        </div>
        <div className='card-val'>{commify(totalQuantityNFT24)}</div>
      </div>
      <div>
        <div className='sh-line' />
        <div className='card-title'>
          <span>Total Quantity Token</span>
          <span className='interval'>[24h]</span>
        </div>
        <div className='card-val'>{commify(totalQuantityFT24)}</div>
      </div>
    </StyledView>
  );
};

const StyledView = styled.div`
  display: grid;
  width: 872px;
  height: 70px;
  margin: 0px auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 64px;
  & > div {
    position: relative;
    border-left: 1px solid ${theme.mono5};
    padding-left: 30px;
    & > div.sh-line {
      position: absolute;
      width: 2px;
      height: 26px;
      background-color: ${theme.subpink};
      top: 0px;
      left: -1px;
      border-radius: 5px;
    }
    & > div.card-title {
      ${theme.tab1};
      color: ${theme.mono4};
      margin-bottom: 16px;
      & > span.interval {
        ${theme.body1};
        margin-left: 4px;
      }
    }
    & > div.card-val {
      ${theme.headline2};
      line-height: 28px;
      color: ${theme.mono1};
      font-weight: 900;
    }
  }
`;

export { TransferSummaryView };

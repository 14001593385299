import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { theme } from 'src/styles/theme';
import { classNameBinder } from 'src/utils/utils';

import ic_prev_default from 'src/assets/ic_prev_default.svg';
import ic_prev_disabled from 'src/assets/ic_prev_disabled.svg';
import ic_next_default from 'src/assets/ic_next_default.svg';
import ic_next_disabled from 'src/assets/ic_next_disabled.svg';
import ic_first_default from 'src/assets/ic_first_default.svg';
import ic_first_disabled from 'src/assets/ic_first_disabled.svg';
import ic_last_default from 'src/assets/ic_last_default.svg';
import ic_last_disabled from 'src/assets/ic_last_disabled.svg';

interface IProps {
  curPage: number;
  setCurPage: Dispatch<SetStateAction<number>>;
  totalCount?: number;
}

const Pagination: React.FC<IProps> = (props) => {
  const { curPage, setCurPage, totalCount = 1 } = props;

  const perPage = 20;
  const isZero = totalCount === 0;
  const lastPage = Math.ceil(isZero ? 1 : totalCount / perPage);
  const _lastPage = lastPage > 2000 ? 2000 : lastPage;

  const goToFirst = () => {
    setCurPage(1);
  };

  const goToPrev = () => {
    if (curPage === 1) {
      return;
    }
    setCurPage(curPage - 1);
  };

  const goToNext = () => {
    if (_lastPage === curPage) {
      return;
    }
    setCurPage(curPage + 1);
  };

  const goToLast = () => {
    setCurPage(_lastPage);
  };

  const isFirst = {
    prefix: 'first',
    suffix: 'disabled',
    cond: curPage <= 1,
  };

  const isPrev = {
    prefix: 'prev',
    suffix: 'disabled',
    cond: curPage <= 1,
  };
  const isNext = {
    prefix: 'next',
    suffix: 'disabled',
    cond: curPage === _lastPage,
  };
  const isLast = {
    prefix: 'last',
    suffix: 'disabled',
    cond: curPage === _lastPage,
  };

  return (
    <StyledPagination>
      <div className={classNameBinder(isFirst)} onClick={() => goToFirst()} />
      <div className={classNameBinder(isPrev)} onClick={() => goToPrev()} />
      <input
        value={curPage}
        onChange={(e) => {
          if (parseInt(e.target.value) > _lastPage) {
            setCurPage(_lastPage);

            return;
          }

          if (parseInt(e.target.value) > 2000) {
            setCurPage(2000);
            return;
          }

          let page: number;
          if (!e.target.value) {
            setCurPage(0);
            return;
          }

          page = parseInt(e.target.value);
          setCurPage(page);
        }}
      />
      <span className="divider">/</span>
      <span className="last-page">{_lastPage}</span>
      <div className={classNameBinder(isNext)} onClick={() => goToNext()} />
      <div className={classNameBinder(isLast)} onClick={() => goToLast()} />
    </StyledPagination>
  );
};

const StyledPagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 30px;
  margin-top: 32px;
  & > div {
    width: 30px;
    height: 30px;
    background-size: 30px 30px;
    &.first {
      margin-right: 8px;
      background-image: url(${ic_first_default});
      cursor: pointer;
      &.disabled {
        background-image: url(${ic_first_disabled});
        cursor: initial;
      }
    }
    &.prev {
      background-image: url(${ic_prev_default});
      cursor: pointer;
      &.disabled {
        background-image: url(${ic_prev_disabled});
        cursor: initial;
      }
    }
    &.next {
      background-image: url(${ic_next_default});
      margin-right: 8px;
      cursor: pointer;
      &.disabled {
        background-image: url(${ic_next_disabled});
        cursor: initial;
      }
    }
    &.last {
      background-image: url(${ic_last_default});
      cursor: pointer;
      &.disabled {
        background-image: url(${ic_last_disabled});
        cursor: initial;
      }
    }
  }

  & > img {
    width: 30px;
  }
  & > span.divider {
    margin: 0px 8px;
    color: ${theme.mono4};
  }
  & > span.last-page {
    ${theme.body1};
    margin-right: 20px;
    color: ${theme.mono4};
  }
  & > input {
    width: 80px;
    height: 30px;
    border-radius: 4px;
    border: 1px solid ${theme.mono2};
    margin-left: 20px;
    text-align: center;
    color: ${theme.mono6};
    &:focus {
      outline: none;
      border: 1px solid ${theme.primaryBG};
    }
  }
`;

export { Pagination };

import { NftBridge } from 'src/solana/nft-bridge';
import {
  Connection,
  Keypair,
  PublicKey,
  sendAndConfirmTransaction,
  SystemProgram,
} from '@solana/web3.js';
import { Buffer } from 'buffer';
import bs58 from 'bs58';
import { sign } from 'noble-ed25519';
import { packTransferMsg } from 'src/solana/message';
import { sha512 } from 'hash.js';
import BigNumber from 'bignumber.js';

let provider: any = undefined;

// validator.json
// [134,148,193,94,71,65,133,187,155,204,246,6,233,225,145,109,213,127,62,230,215,43,75,204,55,87,227,208,204,23,190,193,131,103,16,244,237,166,136,145,106,232,118,248,82,107,226,39,3,192,7,9,225,2,104,18,169,138,39,212,90,197,248,175]

// admin : (EvcqpKwHDGc9MdcXtuJHYgZubs9FEV8tDY2E1VdyJYNF)
// validator: (9qwZXE9cRRXLsZaunAKw1VcWy3hh3RHFAwMs6ZQEULr2)
// target chain fee treasury(DHDNXNF8zj1W4J6JfzNGXEPchdawUPXyFxrqGVXKeYrP),
// commission(E5P3ZVsYiuJ89bas2YSGta1Qj6nhY3fKadZc4giTq4Uk)

const getSolanaProvider = () => {
  provider = undefined;

  if ('phantom' in window) {
    provider = window.phantom?.solana;
    if (provider?.isPhantom) {
      return provider;
    }
  }
  return provider;
};

const connectToSolana = async () => {
  if (provider === undefined) {
    provider = getSolanaProvider();
  }
  const rsp = await provider.connect();
  return rsp;
};

const getAddressFromSolana = async () => {
  if (provider === undefined) {
    provider = getSolanaProvider();
  }

  const rsp = await provider.connect();

  return rsp.publicKey.toString();
};

const transferNFTFromSolana = async ({
  senderAddr,
  bridgeAddr,
  messageId,
  blockHeight,
  fromToken,
  toChain,
  commission,
  targetChainFee,
  recipient,
  signature,
  endPoint,
  amount,
}: any) => {
  try {
    const provider = getSolanaProvider();
    const connection = new Connection(endPoint);
    const bridgeKey = new PublicKey(bridgeAddr);
    const mitterCl: NftBridge = new NftBridge({
      connection: connection,
      program: bridgeKey,
      commitment: 'finalized',
    });
    const senderKey = new PublicKey(senderAddr);
    const fromTokenKey = new PublicKey(fromToken);
    const expiresBigint: bigint = BigInt(blockHeight);
    const commissionBigint: bigint = BigInt(commission);
    const targetChainFeeBigint: bigint = BigInt(targetChainFee);
    const amountBigint: bigint = amount ? BigInt(amount) : BigInt(1);
    const isBatch = amount > 1;

    const fromHexString = (hexString: any): Uint8Array =>
      Uint8Array.from(
        hexString.match(/.{1,2}/g).map((byte: any) => parseInt(byte, 16))
      );

    let rawTx = await mitterCl.transfer(
      senderKey,
      messageId,
      expiresBigint,
      fromTokenKey,
      toChain,
      commissionBigint,
      targetChainFeeBigint,
      recipient,
      fromHexString(signature),
      isBatch,
      amountBigint
    );
    const result = await provider.signAndSendTransaction(rawTx);
    return result.signature;
  } catch (e) {
    throw e;
  }
};

const getTokenBalance = async (
  endPoint: string,
  tokenAddress: string,
  senderAddr: string
) => {
  const provider = getSolanaProvider();
  const connection = new Connection(endPoint);
  const tokenMintAddress = new PublicKey(tokenAddress);
  const senderKey = new PublicKey(senderAddr);

  // let tokenAccountAddress = await connection.getTokenAccountBalance(
  //   senderKey,
  //   'finalized'
  // );
  let ret = await connection.getTokenAccountsByOwner(senderKey, {
    mint: tokenMintAddress,
  });
  console.log(ret);
  // tokenAccountAddress = await connection.getTokenAccountBalance(
  //   tokenMintAddress,
  //   'finalized'
  // );
  // console.log(tokenAccountAddress);
};

export {
  getSolanaProvider,
  connectToSolana,
  getAddressFromSolana,
  transferNFTFromSolana,
  getTokenBalance,
};

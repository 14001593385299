import React, { useContext } from 'react';
import styled from 'styled-components';
import { ModalContext } from 'src/contexts/ModalContext';
import { TokenItemResponseType } from 'src/constants/types';
import { TOKEN_LOGO } from 'src/constants/index';
import { noExponential, classNameBinder, commify } from 'src/utils/utils';
import { theme } from 'src/styles/theme';
import ic_refresh from 'src/assets/ic_refresh.svg';
import default_token from 'src/assets/tokens/img_token_default_dark.png';

interface IProps {
  list?: any[];
  refresh: () => void;
}

const ModalTokenList: React.FC<IProps> = ({ refresh }) => {
  const { list, isLoadingList, handleOnSelect, setList } =
    useContext(ModalContext);

  const len = list.length;
  const hasNoList = list.length === 0;
  return (
    <StyledModal>
      <div className='list-view-wrapper'>
        {!isLoadingList && hasNoList && (
          <div key='no-list' className='list-view no-list'>
            <span>No Token found in the Source chain.</span>
            <div
              className='refresh-btn'
              onClick={() => {
                setList([]);
                refresh();
              }}
            >
              <img src={ic_refresh} />
            </div>
          </div>
        )}
        {isLoadingList && (
          <div key='loading' className='list-view no-list black'>
            <span>Loading...</span>
          </div>
        )}
        {!isLoadingList && !hasNoList && (
          <>
            <div key='list' className='list-view-handler'>
              <div className='list-counter'>
                <span>Total</span>
                <span className='txt-green'>[{len}]</span>
              </div>
              <div className='list-refresh'>
                <span>Refresh</span>
                <img
                  onClick={() => {
                    refresh();
                  }}
                  src={ic_refresh}
                  alt='refresh'
                />
              </div>
            </div>
            <div className='list-view with-list'>
              {list.map((el: TokenItemResponseType, idx: number) => {
                const {
                  tokenIdNo,
                  tokenImage = '',
                  tokenName,
                  tokenSymbol,
                  tokenBalance,
                } = el;

                const isZero = parseInt(tokenBalance) === 0;
                const props = {
                  prefix: 'list-item',
                  suffix: 'disabled',
                  cond: isZero,
                };
                return (
                  <div
                    key={idx.toString()}
                    className={classNameBinder(props)}
                    onClick={() => handleOnSelect('token', el)}
                  >
                    <div className='token-name'>
                      <img
                        src={
                          !!tokenImage
                            ? tokenImage
                            : !!TOKEN_LOGO[tokenIdNo]
                            ? TOKEN_LOGO[tokenIdNo]
                            : default_token
                        }
                        alt='token-logo'
                      />
                      <span>{tokenName}</span>
                    </div>
                    <div className='token-balance'>
                      <span className='bal'>
                        {commify(noExponential(parseFloat(tokenBalance)))}
                      </span>
                    </div>
                    <div className='token-symbol'>
                      <span className='sym'>{tokenSymbol}</span>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
    </StyledModal>
  );
};

const StyledModal = styled.div`
  width: 628px;
  background-color: ${theme.mono6};
  padding: 20px 24px 24px;
  border-radius: 6px;
  overflow: hidden;

  & > div.list-view-wrapper {
    border-radius: 6px;
    background-color: ${theme.mono7};
    & > div.list-view-handler {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid ${theme.mono6};
      padding: 8px 14px;
      & > div {
        color: ${theme.mono4};
      }
      & > div.list-counter {
        & > span.txt-green {
          margin-left: 2px;
          color: ${theme.primaryBG};
        }
      }
      & > div.list-refresh {
        display: flex;
        align-items: center;
        & > img {
          width: 24px;
          height: 24px;
          margin-left: 6px;
          background-color: ${theme.mono6};
          border-radius: 4px;
          cursor: pointer;
        }
      }
    }
    & > div.list-view {
      width: 100%;
      height: 364px;
      &.with-list {
        & > div.list-item {
          color: ${theme.mono1};
          display: grid;
          grid-template-columns: 300px 1fr auto;
          grid-template-rows: auto;
          padding: 12px 22px 12px 14px;
          border: 1px solid ${theme.mono7};
          cursor: pointer;
          & > div:first-of-type {
            display: flex;
            align-items: center;
            & > img {
              width: 34px;
              height: 34px;
              margin-right: 8px;
              border-radius: 6px;
              outline: 1px solid ${theme.mono4};
              outline-offset: -1px;
            }
          }
          & > div.token-balance {
            ${theme.tab1};
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-right: 10px;
          }
          & > div.token-symbol {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            color: ${theme.mono3};
          }
          &:hover {
            border: 1px solid ${theme.primaryBG};
            border-radius: 6px;
          }
        }
      }
      &.no-list {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        & > img {
          width: 105px;
          height: 56px;
          margin-bottom: 20px;
        }
        & > span {
          color: ${theme.mono4};
        }
        &.black {
          border-radius: 6px;
          background-color: ${theme.mono8};
        }
        & > div.refresh-btn {
          margin-top: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background-color: ${theme.mono6};
          cursor: pointer;
          & > img {
            width: 28px;
            height: 28px;
          }
        }
        & > img.no-list-refresh {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          padding: 6px;
          background-color: ${theme.mono6};
          cursor: pointer;
          margin-top: 14px;
        }
      }
    }
  }
`;

export { ModalTokenList };

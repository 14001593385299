// import {PROGRAM_ID as TOKEN_METADATA_PROGRAM_ID,} from '@metaplex-foundation/mpl-token-metadata';
import { PublicKey } from '@solana/web3.js';
import { sha256 } from 'js-sha256';
import { Buffer } from 'buffer';
import { Const } from './const';

export const SEED_COLLECTION_MAP = 'COLLECTION_MAP';
export const SEED_FT_WRAPPED_TOKEN = 'FT_WRAPPED_TOKEN';
export const SEED_WRAPPED_TOKEN = 'WRAPPED_TOKEN';
export const SEED_MITTER_TOKEN_TO_ORIGIN_MAP = 'TOKEN_TO_ORIGIN_MAP';
// pub const PDA_SEED_TOKEN_TO_ORIGIN_MAP: &'static str = "TOKEN_TO_ORIGIN_MAP";
export const SEED_SETTINGS = 'SETTINGS';
export const SEED_VAULT = 'VAULT';
export const SEED_MINTER = 'MINTER';
export const SEED_MESSAGE_ID = 'MESSAGE_ID';

export const SEED_TOKEN_TO_ORIGIN_MAP = 'TOKEN_TO_ORIGIN_MAP';
export const SEED_ORIGIN_TO_TOKEN_MAP = 'ORIGIN_TO_TOKEN_MAP';

export class Pda {
  constructor(private readonly program: PublicKey) {}

  public deriveMitterPda(seeds: string[]): [PublicKey, number] {
    let msg = seeds.join('|');
    let seed = sha256.update(Buffer.from(msg));
    return PublicKey.findProgramAddressSync(
      [Buffer.from(seed.array())],
      this.program
    );
  }

  public getVaultAddress(): [PublicKey, number] {
    return this.deriveMitterPda([SEED_VAULT]);
  }

  public getSettingsAddress(): [PublicKey, number] {
    return this.deriveMitterPda([SEED_SETTINGS]);
  }

  public getMinterAddress(): [PublicKey, number] {
    return this.deriveMitterPda([SEED_MINTER]);
  }

  public getOriginToToken(
    originChain: number,
    originToken: string
  ): [PublicKey, number] {
    const seedForMintAccount = [
      SEED_ORIGIN_TO_TOKEN_MAP,
      originChain.toString(),
      originToken,
    ];
    return this.deriveMitterPda(seedForMintAccount);
  }

  public getInfoToCollectionAddress(
    originChain: number,
    originToken: string
  ): [PublicKey, number] {
    const seedForMintAccount = [
      SEED_COLLECTION_MAP,
      originChain.toString(),
      originToken,
    ];
    return this.deriveMitterPda(seedForMintAccount);
  }

  public getCollectionToInfoAddress(
    collection: PublicKey
  ): [PublicKey, number] {
    const seedForMintAccount = [SEED_COLLECTION_MAP, collection.toString()];
    return this.deriveMitterPda(seedForMintAccount);
  }

  public getFTWrappedTokenAddress(
    originChain: number,
    originToken: string
  ): [PublicKey, number] {
    const seedForMintAccount = [
      SEED_FT_WRAPPED_TOKEN,
      originChain.toString(),
      originToken,
    ];
    return this.deriveMitterPda(seedForMintAccount);
  }

  public getWrappedTokenAddress(
    originChain: number,
    originToken: string,
    tokenId: string
  ): [PublicKey, number] {
    const seedForMintAccount = [
      SEED_WRAPPED_TOKEN,
      originChain.toString(),
      originToken,
      tokenId,
    ];
    return this.deriveMitterPda(seedForMintAccount);
  }

  public getMetadataAddress(addr: PublicKey): PublicKey {
    return PublicKey.findProgramAddressSync(
      [
        Buffer.from('metadata'),
        Const.TOKEN_METADATA_PROGRAM_ID.toBuffer(),
        addr.toBuffer(),
      ],
      Const.TOKEN_METADATA_PROGRAM_ID
    )[0];
  }

  // contains origin info
  public getWrappedToOriginAddress(
    mintAccount: PublicKey
  ): [PublicKey, number] {
    const seeds = [SEED_MITTER_TOKEN_TO_ORIGIN_MAP, mintAccount.toString()];
    return this.deriveMitterPda(seeds);
  }

  public getMasterEditionAddress(account: PublicKey) {
    return PublicKey.findProgramAddressSync(
      [
        Buffer.from('metadata'),
        Const.TOKEN_METADATA_PROGRAM_ID.toBuffer(),
        account.toBuffer(),
        Buffer.from('edition'),
      ],
      Const.TOKEN_METADATA_PROGRAM_ID
    );
  }

  public getMessageIdAddress(messageId: string): [PublicKey, number] {
    return this.deriveMitterPda([SEED_MESSAGE_ID, messageId]);
  }
}

import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import stepStore from 'src/stores/StepStore';

import { Stepper } from 'src/components/index';

const StepperContainer: React.FC = () => {
  const { pathname } = useLocation();

  const stepIdx = stepStore.getStepNumber();
  const [coordX, setCoordX] = useState<number>(
    (window.innerWidth - 640) / 2 + 640 + 17
  );

  const isNFT = pathname === '/bridge/nft';

  const getPositionX = () => {
    const positionX = (window.innerWidth - 640) / 2 + 640 + 17;
    setCoordX(positionX);
  };

  useEffect(() => {
    window.addEventListener('resize', getPositionX);
    return () => window.removeEventListener('resize', getPositionX);
  }, []);

  return <Stepper coordX={coordX} stepIdx={stepIdx} isNFT={isNFT} />;
};

export { StepperContainer };

import React from 'react';
import styled from 'styled-components';
import { theme } from 'src/styles/theme';
import service_unavailable from 'src/assets/img_service_unavailable.png';

interface IProps {
  close: () => void;
}

const ModalServiceUnavailable: React.FC<IProps> = ({ close }) => {
  return (
    <StyledModal>
      <div className="info-area">
        <img src={service_unavailable} alt="service-unavailable" />
        <span>
          HAVAH Mitter is temporarily unavailable. Please try again later!
        </span>
      </div>
      <div
        className="btn-area"
        onClick={() => {
          close();
        }}
      >
        Save
      </div>
    </StyledModal>
  );
};

const StyledModal = styled.div`
  width: 320px;
  ${theme.body1};
  background-color: ${theme.mono6};
  border: 1px solid ${theme.mono5};
  border-radius: 6px;
  & > div.info-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 30px 24px 46px;
    color: ${theme.mono1};
    border-bottom: 1px solid ${theme.mono5};
    & > img {
      width: 140px;
      height: 142px;
      margin-bottom: 6px;
    }
  }
  & > div.btn-area {
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${theme.primaryTxt};
    padding: 12px 0px;
    cursor: pointer;
    &:hover {
      color: ${theme.primaryBG};
    }
  }
`;

export { ModalServiceUnavailable };

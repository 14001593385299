const ERROR_MSG: any = {
  '502': '502 Bad Gateway \n Please check the address and try again.',

  M001: 'Metamask installation error. \n Metamask is not installed. If your Metamask installed, please refresh this page.',
  H001: 'HAVAH installation error \n HAVAH Wallet is not installed. If your HAVAH Wallet installed, please refresh this page.',
  H002: 'Invalid HAVAH chain ID \n Please check network in HAVAH wallet.',
  H003: 'Invalid XPLA chain ID \n Please check network in XPLA vault wallet.',
  H004: 'Site permission error. \n Please check the site permission in wallet.',
  H005: 'Insufficient balance. \n The connected wallet has no balance.',
  P200: 'Transaction declined. \n Requested transaction has been declined by the user.',
  P300: 'Encode parameter failure. \n Parameter is invalid',
  E400: 'NFT not found \n No matching NFT found in the wallet.',
  '1000': 'Network error \n Invalid Parameter.',
  '1001': 'Network error \n Please check the address and try again.',
  '1010': 'Fail to send. \n Not available.',
  '2004': 'Network error \n Unable to get current exchange rate info.',
  '3001': 'NFT not found \n No matching NFT found in the wallet.',
  '3002': 'NFT not found \n No matching NFT found in the wallet.',
  '4001':
    'Transaction declined. \n Requested transaction has been declined by the user.',
  '4902':
    'Metamask connection error. \n Unrecognized chain ID. Try adding the chain ID.',
  '-32002':
    'Wallet connection has already been requested. \n Please check the wallet extension.',
  DEFAULT_1: 'Fail to send. \n Please retry',
  DEFAULT_2: 'Fail to send. \n Not available.',
  '2': 'tx parse error',
  '3': 'invalid sequence',
  '4': 'unauthorized',
  '5': 'insufficient funds',
  '6': 'unknown request',
  '7': 'invalid address',
  '8': 'invalid pubkey',
  '9': 'unknown address',
  '10': 'invalid coins',
  '11': 'out of gas',
  '12': 'memo too large',
  '13': 'insufficient fee',
  '14': 'maximum number of signatures exceeded',
  '15': 'no signatures supplied',
  '16': 'failed to marshal JSON bytes',
  '17': 'failed to unmarshal JSON bytes',
  '18': 'invalid request',
  '19': 'tx already in mempool',
  '20': 'mempool is full',
  '21': 'tx too large',
  '22': 'key not found',
  '23': 'invalid account password',
  '24': 'tx intended signer does not match the given signer',
  '25': 'invalid gas adjustment',
  '26': 'invalid height',
  '27': 'invalid version',
  '28': 'invalid chain-id',
  '29': 'invalid type',
  '30': 'tx timeout height',
  '31': 'unknown extension options',
  '32': 'incorrect account sequence',
  '33': 'failed packing protobuf message to Any',
  '34': 'failed unpacking protobuf message from Any',
  '35': 'internal logic error',
  '36': 'conflict',
  '37': 'feature not supported',
  '38': 'not found',
  '39': 'Internal IO error',
  '40': 'error in app.toml',
  '41': 'invalid gas limit',
  'Request rejected':
    'Transaction declined. \n Requested transaction has been declined by the user.',

  WALLET_NOT_SUPPORTED:
    'This wallet is not supported. \n Please connect with the chosen wallet.',
};

export { ERROR_MSG };

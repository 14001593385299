import IconService from 'icon-sdk-js';
import { TransferType } from 'src/constants/types';
import { createRndHexString } from 'src/utils/utils';

const networkId = 257;
const rpc = process.env.REACT_APP_HAVAH_CTZ_ENDPOINT;
const provider = new IconService.HttpProvider(rpc!);
const iconService = new IconService(provider);
const { IconBuilder, IconConverter } = IconService;
const { IcxTransactionBuilder, CallBuilder } = IconBuilder;

type CreateCallTxType = {
  contract: string;
  params: any;
  method: string;
};

const createCallTransaction = ({
  contract,
  params,
  method,
}: CreateCallTxType) => {
  try {
    const transactionObj = new CallBuilder()
      .to(contract)
      .method(method)
      .params(!!params ? params : {})
      .build();

    return transactionObj;
  } catch (e) {
    throw e;
  }
};

const createSendTransaction = (sender: string, receiver: string) => {
  try {
    const dateValue = new Date().getTime() * 1000;
    const transactionObj = new IcxTransactionBuilder()
      .from(sender)
      .to(receiver)
      .stepLimit(IconConverter.toBigNumber(2000000))
      .nid(IconConverter.toBigNumber(networkId))
      .version(IconConverter.toBigNumber(3))
      .timestamp(dateValue)
      .build();
    const rawTx = IconConverter.toRawTransaction(transactionObj);
    return rawTx;
  } catch (e) {
    throw e;
  }
};

const createSendTransactionWithValue = (
  sender: string,
  receiver: string,
  value: any
) => {
  try {
    const dateValue = new Date().getTime() * 1000;
    const transactionObj = new IcxTransactionBuilder()
      .from(sender)
      .to(receiver)
      .value(IconConverter.toBigNumber(value))
      .stepLimit(IconConverter.toBigNumber(2000000))
      .nid(IconConverter.toBigNumber(networkId))
      .version(IconConverter.toBigNumber(3))
      .timestamp(dateValue)
      .build();
    const rawTx = IconConverter.toRawTransaction(transactionObj);
    return rawTx;
  } catch (e) {
    throw e;
  }
};

const executeMethod = async (transactionObj: any) => {
  try {
    const result = await iconService.call(transactionObj).execute();
    return result;
  } catch (e) {
    throw e;
  }
};

const getStepCosts = async () => {
  try {
    const txObj = createCallTransaction({
      contract: 'cx0000000000000000000000000000000000000000',
      params: {},
      method: 'getStepCosts',
    });
    const result = await executeMethod(txObj);
    return result;
  } catch (e) {
    throw e;
  }
};

const getBalance = async (tokenAddr: string, address: string) => {
  try {
    const txObj = createCallTransaction({
      contract: tokenAddr,
      params: {
        _owner: address,
      },
      method: 'balanceOf',
    });
    const result = await executeMethod(txObj);
    return result;
  } catch (e) {
    throw e;
  }
};

const connectToICONex = () => {
  try {
    const customEvent = new CustomEvent<any>('ICONEX_RELAY_REQUEST', {
      detail: {
        type: 'REQUEST_ADDRESS',
        load: 'a',
      },
    });
    window.dispatchEvent(customEvent);
  } catch (e) {
    throw e;
  }
};

const iconexGetApproved = async (tokenAddr: string, tokenId: string) => {
  try {
    const _tokenId = IconConverter.toHex(IconConverter.toBigNumber(tokenId));
    const txObj = createCallTransaction({
      contract: tokenAddr,
      params: {
        _tokenId: _tokenId,
      },
      method: 'getApproved',
    });
    const result = await executeMethod(txObj);
    return result;
  } catch (e) {
    throw e;
  }
};

const iconexIsApprovedForAll = async (
  tokenAddr: string,
  senderAddress: string,
  bridgeContract: string
) => {
  try {
    const txObj = createCallTransaction({
      contract: tokenAddr,
      params: {
        _owner: senderAddress,
        _operator: bridgeContract,
      },
      method: 'isApprovedForAll',
    });
    const result = await executeMethod(txObj);
    return result;
  } catch (e) {
    throw e;
  }
};

const havahCheckGetApproved = async (
  bridgeContract: string,
  tokenId: string,
  tokenAddr: string
) => {
  try {
    const approvedAddr = await iconexGetApproved(tokenAddr, tokenId);
    return bridgeContract === approvedAddr ? true : false;
  } catch (e) {
    throw e;
  }
};

const havahCheckIsApproved = async (
  senderAddress: string,
  bridgeContract: string,
  scAddr: string
) => {
  try {
    const approvedTx = await iconexIsApprovedForAll(
      scAddr,
      senderAddress,
      bridgeContract
    );
    return parseInt(approvedTx, 16) === 1 ? true : false;
  } catch (e) {
    throw e;
  }
};

const iconexApprove = async ({
  senderAddr = '',
  tokenAddr,
  bridgeAddr,
  amount,
}: TransferType) => {
  try {
    const rawTx = createSendTransaction(senderAddr, tokenAddr);
    const scTx = {
      ...rawTx,
      dataType: 'call',
      data: {
        method: 'approve',
        params: {
          _spender: bridgeAddr,
          _value: amount,
        },
      },
    };

    return scTx;
  } catch (e) {
    throw e;
  }
};

const iconexApproveNFT = async ({
  senderAddr = '',
  tokenAddr,
  bridgeAddr,
  amount,
}: TransferType) => {
  try {
    const rawTx = createSendTransaction(senderAddr, tokenAddr);
    const scTx = {
      ...rawTx,
      dataType: 'call',
      data: {
        method: 'approve',
        params: {
          _approved: bridgeAddr,
          _tokenId: amount,
        },
      },
    };

    return scTx;
  } catch (e) {
    throw e;
  }
};

const iconexApproveAllNFT = async ({
  senderAddr = '',
  tokenAddr,
  bridgeAddr,
}: TransferType) => {
  try {
    const rawTx = createSendTransaction(senderAddr, tokenAddr);
    const scTx = {
      ...rawTx,
      dataType: 'call',
      data: {
        method: 'setApprovalForAll',
        params: {
          _operator: bridgeAddr,
          _approved: '0x1',
        },
      },
    };

    return scTx;
  } catch (e) {
    throw e;
  }
};

const iconexTransferFT = async ({
  bridgeAddr,
  senderAddr,
  messageId,
  decimal,
  blockHeight,
  fromChain,
  fromToken,
  toChain,
  amount,
  commission,
  targetChainFee,
  recipient,
  signature,
}: any) => {
  try {
    const toHex = IconConverter.toHex;
    const _commission = IconConverter.toBigNumber(Math.pow(10, decimal)).times(
      commission
    );
    const _targetChainFee = IconConverter.toBigNumber(
      Math.pow(10, decimal)
    ).times(targetChainFee);
    const _amount = IconConverter.toBigNumber(Math.pow(10, decimal)).times(
      amount
    );
    // const _txFee = IconConverter.toHex(IconConverter.toBigNumber(Math.pow(10, decimal)).times((commission + targetChainFee)));
    const _hexAmount = toHex(_amount);
    const _hexCommission = toHex(_commission);
    const _hexTargetChainFee = toHex(_targetChainFee);

    const rawTx = createSendTransaction(senderAddr, bridgeAddr);
    const scTx = {
      ...rawTx,
      dataType: 'call',
      data: {
        method: 'transferFT',
        params: {
          messageId,
          blockHeight: '0x' + blockHeight.toString(16),
          fromChain: '0x' + fromChain.toString(16),
          fromToken,
          toChain: '0x' + toChain.toString(16),
          amount: _hexAmount,
          commission: _hexCommission,
          targetChainFee: _hexTargetChainFee,
          recipient,
          signature,
        },
      },
      value: amount,
    };

    return scTx;
  } catch (e) {
    throw e;
  }
};

const iconexTransferNFT = async ({
  decimal,
  bridgeAddr,
  senderAddr,
  messageId,
  blockHeight,
  fromChain,
  fromToken,
  toChain,
  tokenId,
  tokenType,
  commission,
  targetChainFee,
  recipient,
  signature,
}: any) => {
  try {
    const toHex = IconConverter.toHex;
    const _commission = IconConverter.toBigNumber(Math.pow(10, decimal)).times(
      commission
    );
    const _targetChainFee = IconConverter.toBigNumber(
      Math.pow(10, decimal)
    ).times(targetChainFee);
    const _tokenId = IconConverter.toHex(IconConverter.toBigNumber(tokenId));
    // const _txFee = IconConverter.toHex(IconConverter.toBigNumber(Math.pow(10, decimal)).times((commission + targetChainFee)));
    const _txFee = IconConverter.toHex(_commission.plus(_targetChainFee));

    const _hexCommission = toHex(_commission);
    const _hexTargetChainFee = toHex(_targetChainFee);

    const rawTx = createSendTransactionWithValue(
      senderAddr,
      bridgeAddr,
      _txFee
    );
    const scTx = {
      ...rawTx,
      dataType: 'call',
      data: {
        method: 'transferNFT',
        params: {
          messageId,
          blockHeight: '0x' + blockHeight.toString(16),
          fromChain: '0x' + fromChain.toString(16),
          fromToken,
          toChain: '0x' + toChain.toString(16),
          tokenId: _tokenId,
          type: '0x' + tokenType.toString(16),
          commission: _hexCommission,
          targetChainFee: _hexTargetChainFee,
          recipient,
          signature: '0x' + signature,
        },
      },
    };

    return scTx;
  } catch (e) {
    throw e;
  }
};

const iconexDispatchEvent = (tx: any) => {
  try {
    const customEvent = new CustomEvent('ICONEX_RELAY_REQUEST', {
      detail: {
        type: 'REQUEST_JSON-RPC',
        payload: {
          jsonrpc: '2.0',
          method: 'icx_sendTransaction',
          id: createRndHexString(),
          params: tx,
        },
      },
    });
    window.dispatchEvent(customEvent);
  } catch (e) {
    throw e;
  }
};

const iconexCheckTxConfirm = async (url: string, params: string) => {
  try {
    const data = {
      jsonrpc: '2.0',
      id: '1001',
      method: 'icx_getTransactionResult',
      params: {
        txHash: params,
      },
    };
    const headers: HeadersInit = {
      Accept: '*/*',
      'Content-Type': 'application/json',
    };

    const rsp = await fetch(`${url}`, {
      method: 'POST',
      headers: {
        ...headers,
      },
      body: JSON.stringify({
        ...data,
      }),
    });
    let response = await rsp.json();

    return response;
  } catch (e) {
    throw e;
  }
};

export {
  getBalance,
  // checkHasAccount,
  connectToICONex,
  iconexDispatchEvent,
  iconexIsApprovedForAll,
  havahCheckGetApproved,
  havahCheckIsApproved,
  iconexApprove,
  iconexApproveNFT,
  iconexApproveAllNFT,
  iconexTransferFT,
  iconexTransferNFT,
  iconexCheckTxConfirm,
  getStepCosts,
};

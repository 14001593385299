import React from 'react';
import styled from 'styled-components';
import { ComboBox, Button } from 'src/components/index';
import { NETWORK_LOGO } from 'src/constants/index';
import { theme } from 'src/styles/theme';
import { ChainListType } from 'src/constants/types';
import logo_default from 'src/assets/chain/chain_default_dark.png';

interface IProps {
  modalHandler: () => void;
  btnDisabled: boolean;
  chainIdHandler: (val: string | number) => void;
  handleOnList: () => void;
  reset: () => void;
  walletAddr?: string;
  chainNo?: number;
  chainName?: string;
  chainList: ChainListType[];
}

const FromChainConnectView: React.FC<IProps> = (props) => {
  const {
    modalHandler,
    btnDisabled,
    chainIdHandler,
    handleOnList,
    reset,
    walletAddr,
    chainNo,
    chainName,
    chainList,
  } = props;
  const chainListForComboBox = chainList.map((el) => {
    const { chainName, chainNo } = el;

    const icon = !!NETWORK_LOGO[chainNo] ? NETWORK_LOGO[chainNo] : logo_default;

    return {
      icon,
      name: chainName,
      value: chainNo,
    };
  });

  return (
    <StyledComp>
      <div className="title">From</div>
      <div className="connect-area">
        <div className="w-388">
          <ComboBox
            initialValue={'Select Source chain'}
            value={chainNo}
            name={chainName}
            height={'54px'}
            list={chainListForComboBox}
            handleOnSelect={chainIdHandler}
            handleOnList={handleOnList}
          />
        </div>
        <div className="mgr-16" />
        <div className="w-160">
          <Button
            disabled={btnDisabled}
            initialValue="Connect Wallet"
            name={walletAddr}
            type={!!walletAddr ? 'connected' : 'cta'}
            handleOnClick={() => {
              if (!!walletAddr) {
                reset();
                return;
              }
              modalHandler();
            }}
          />
        </div>
      </div>
    </StyledComp>
  );
};

const StyledComp = styled.div`
  width: 100%;
  height: auto;
  & > div {
    &.title {
      display: flex;
      height: 24px;
      ${theme.title1};
      color: ${theme.mono1};
      margin-bottom: 16px;
    }
    &.sub-title {
      color: ${theme.mono3};
      margin-bottom: 6px;
    }
    &.connect-area {
      display: flex;
      align-items: center;
      width: auto;
      height: 48px;
    }
  }
  .mgr-16 {
    margin-right: 16;
  }
  .w-160 {
    width: 160px;
    height: 100%;
  }
  .w-388 {
    width: 388px;
    height: 100%;
  }
`;

export { FromChainConnectView };

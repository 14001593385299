import { useState, ReactNode, useEffect } from 'react';

export const useToastModal = () => {
  const [isModalOpened, setIsModalOpen] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<ReactNode>();

  const handleModal = (props?: any) => {
    setIsModalOpen(!!props ? true : false);
    if (!!props) {
      setModalContent(props);
    }
  };

  useEffect(() => {
    if (isModalOpened) {
      let timerId = setTimeout(() => {
        handleModal();
      }, 5000);
      return () => clearTimeout(timerId);
    }
  }, [isModalOpened]);

  return {
    isModalOpened,
    modalContent,
    handleModal,
  };
};

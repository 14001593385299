import React from 'react';
import styled from 'styled-components';
import { theme } from 'src/styles/theme';
import havah_logo from 'src/assets/havah_mitter_logo.svg';
import img_mob from 'src/assets/block/illust_1@2x.png';
import img_bear from 'src/assets/block/img_added@2x.png';

const MobileBlockPage: React.FC = () => {
  return (
    <StyledPage>
      <div className="box">
        <div className="illust-bear" />
        <img src={img_mob} alt="illust1" />
        <span>Access to HAVAH Mitter is restricted on mobile devices.</span>
        <br />
        <span>Please try again using PC!</span>
      </div>
    </StyledPage>
  );
};

const StyledPage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  padding-bottom: 66px;
  background: url(${havah_logo}) center bottom 28px no-repeat;
  background-size: 154px 38px;
  background-color: ${theme.mono7};
  & > div.box {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 335px;
    height: 380px;
    margin: 0px auto;
    border: 1px solid ${theme.mono4};
    background-color: ${theme.mono5};
    border-radius: 6px;
    padding: 65px 23px;
    & > img {
      width: 105px;
      height: 100px;
      align-self: center;
      margin-bottom: 40px;
    }
    & > span {
      ${theme.title1};
      line-height: 24px;
      color: ${theme.mono1};
    }
    & > span:first-of-type {
      font-weight: 400;
    }
    & > span:last-of-type {
    }
    & > div.illust-bear {
      position: absolute;
      top: -90px;
      left: 0px;
      width: 335px;
      height: 99px;
      background: url(${img_bear}) center center no-repeat;
      background-size: 335px 99px;
    }
  }
`;

export { MobileBlockPage };

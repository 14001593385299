import * as ed25519 from '@noble/ed25519';
import { Keypair, PublicKey } from '@solana/web3.js';
import { sha256, Hasher } from 'js-sha256';
import BigNumber from 'bignumber.js';

export enum BRIDGE_TYPE {
  NFT_TRANSFER = 'NT',
  NFT_RECEIVE = 'NR',
  NFT_TRANSFER_BATCH = 'NTB',
  NFT_RECEIVE_BATCH = 'NRB',
  FT_TRANSFER = 'FT',
  FT_RECEIVE = 'FR',
}

function compactMessage(type: BRIDGE_TYPE, args: any[]): Hasher {
  let msg = type + '|' + args.filter((v) => v !== undefined).join('|');
  console.log(`msg(${msg})`);
  return sha256.update(msg);
}

export function packMessage(type: BRIDGE_TYPE, ...args: any): number[] {
  const hasher = compactMessage(type, args);
  // const array = Array.from(args)
  // let msg = type + '|' + array.join('|');
  // console.log(`msg(${msg})`);
  return hasher.array();
  // return sha256.update(hasher).array();
}

export async function packTransferMsg(
  messageId: string,
  expires: bigint,
  fromChain: number,
  fromToken: PublicKey,
  toChain: number,
  commission: BigNumber,
  targetChainFee: BigNumber,
  recipient: string,
  isBatch: boolean,
  amount?: number,
  toHex: boolean = true
): Promise<number[] | string> {
  let msgPrefix;
  let items: string[];
  if (isBatch) {
    msgPrefix = 'NTB';
    items = [
      msgPrefix,
      messageId,
      expires.toString(),
      fromChain.toString(),
      fromToken.toString(),
      toChain.toString(),
      amount!!.toString(),
      commission.toString(),
      targetChainFee.toString(),
      recipient,
    ];
  } else {
    msgPrefix = 'NT';
    items = [
      msgPrefix,
      messageId,
      expires.toString(),
      fromChain.toString(),
      fromToken.toString(),
      toChain.toString(),
      commission.toString(),
      targetChainFee.toString(),
      recipient,
    ];
  }
  let msg = items.join('|');
  console.log(`msg(${msg})`);
  let hasher = sha256.update(msg);

  if (!toHex) {
    return hasher.array();
  }
  return hasher.hex();
}

export async function signTransferNft(
  validator: Keypair,
  messageId: string,
  expires: bigint,
  fromChain: number,
  fromToken: PublicKey,
  toChain: number,
  commission: BigNumber,
  targetChainFee: BigNumber,
  recipient: string,
  isBatch: boolean,
  amount?: number
): Promise<Uint8Array> {
  let msg = (await packTransferMsg(
    messageId,
    expires,
    fromChain,
    fromToken,
    toChain,
    commission,
    targetChainFee,
    recipient,
    isBatch,
    amount
  )) as string;
  return ed25519.sign(msg, validator.secretKey.slice(0, 32));
}

export const packReceiveMsg = function (
  messageId: string,
  expires: bigint,
  originChain: number,
  originToken: string,
  fromChain: number,
  fromToken: string,
  toChain: number,
  sender: string,
  tokenId: string,
  recipient: PublicKey,
  isBatch: boolean,
  amount?: bigint,
  toHex: boolean = true
): any {
  let msgPrefix;
  let items: string[];
  if (isBatch) {
    msgPrefix = 'NRB';
    items = [
      msgPrefix,
      messageId,
      expires.toString(),
      originChain.toString(),
      originToken,
      fromChain.toString(),
      fromToken,
      toChain.toString(),
      sender,
      tokenId,
      amount!!.toString(),
      recipient.toString(),
    ];
  } else {
    msgPrefix = 'NR';
    items = [
      msgPrefix,
      messageId,
      expires.toString(),
      originChain.toString(),
      originToken,
      fromChain.toString(),
      fromToken,
      toChain.toString(),
      sender,
      tokenId,
      recipient.toString(),
    ];
  }
  let msg = items.join('|');
  console.log(`msg(${msg})`);
  let hasher = sha256.update(msg);
  if (!toHex) {
    return hasher.array();
  }
  return hasher.hex();
};

export async function signReceiveFt(
  validator: Keypair,
  messageId: string,
  expires: bigint,
  originChain: number,
  originToken: string,
  fromChain: number,
  fromToken: string,
  toChain: number,
  sender: string,
  tokenId: string,
  recipient: PublicKey,
  isBatch: boolean,
  amount?: bigint
): Promise<Uint8Array> {
  let msg = (await packReceiveMsg(
    messageId,
    expires,
    originChain,
    originToken,
    fromChain,
    fromToken,
    toChain,
    sender,
    tokenId,
    recipient,
    isBatch,
    amount
  )) as string;
  return await ed25519.sign(msg, validator.secretKey.slice(0, 32));
}

export async function signBridgeMessage(
  type: BRIDGE_TYPE,
  validator: Keypair,
  ...args: any
): Promise<Uint8Array> {
  const hashed = compactMessage(type, args).hex();
  return await ed25519.sign(hashed, validator.secretKey.slice(0, 32));
}

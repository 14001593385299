import Web3 from 'web3';
import IconService from 'icon-sdk-js';
import a from 'src/constants/abi.json';
import {
  getApproved,
  isApprovedForAll,
  allowance,
  approve,
  approveAll,
  transferFT,
  transferNFT,
  ownerOf,
  balanceOf,
  transferNFTBatch,
} from 'src/constants/abis';
import { METAMASK_NATIVE_CURRENCY } from 'src/constants/index';
import { roundDownAt, stringToHexConverter } from 'src/utils/utils';
// import BigNumber from "bignumber.js";
let ethereum: any = undefined;
const getMetaMaskProvider = () => {
  let provider = undefined;

  if (window.ethereum?.providers != null) {
    provider = window.ethereum?.providers
      ? window.ethereum.providers.find(
          (provider: any) => provider.isMetaMask && !provider.overrideIsMetaMask
        )
      : undefined;
  } else if (
    window.ethereum?.isMetaMask &&
    !(window.ethereum?.isPhantom || window.ethereum?.isRabby)
  ) {
    provider = window.ethereum;
  }
  ethereum = provider;
  return provider;
};

const minABI: any = [
  {
    inputs: [{ name: '_owner', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ name: 'balance', type: 'uint256' }],
    type: 'function',
  },
];
const { IconConverter } = IconService;

const web3 = new Web3();

const hexToString = (str: string) => {
  return web3.utils.hexToNumberString(str);
};

const estimateGasPrice = async (
  ep: string,
  encodingParams: any,
  bridgeContract: string
) => {
  try {
    const {
      messageId,
      blockHeight,
      decimal,
      fromChain,
      fromToken,
      toChain,
      commission,
      targetChainFee,
      senderAddress,
      recipient,
      signature,
      tokenId,
      tokenType,
    } = encodingParams;
    const _commission = IconConverter.toBigNumber(Math.pow(10, decimal)).times(
      commission
    );
    const _targetChainFee = IconConverter.toBigNumber(
      Math.pow(10, decimal)
    ).times(targetChainFee);
    const _txFee = IconConverter.toHex(_commission.plus(_targetChainFee));
    const _tokenId = IconConverter.toHex(IconConverter.toBigNumber(tokenId));
    const web3 = new Web3(ep);
    const _abi: any = a;
    const gasPrice = await web3.eth.getGasPrice();
    const contract = new web3.eth.Contract(_abi, bridgeContract);
    const data = contract.methods.transferNFT(
      messageId,
      blockHeight,
      fromChain,
      fromToken,
      toChain,
      _tokenId,
      tokenType,
      IconConverter.toHex(_commission),
      IconConverter.toHex(_targetChainFee),
      recipient,
      '0x' + signature
    );
    const gasLimit = await data.estimateGas({
      gas: gasPrice,
      from: senderAddress,
      value: _txFee,
    });
    const gasP = gasPrice;
    // const num = IconConverter.toBigNumber(gasPrice).times(Math.pow(10, -9)).times(gasLimit * 1.5).toNumber();
    // console.log(num);
    return {
      gas: gasLimit * 1.5,
      gasPrice: parseFloat(gasP),
    };
  } catch (e) {
    throw e;
  }
};

const checkChainId = async (chainId: number) => {
  try {
    await ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: '0x' + chainId.toString(16) }],
    });
  } catch (e) {
    throw e;
  }
};

const requestWalletPermission = async () => {
  await ethereum.request({
    method: 'wallet_requestPermissions',
    params: [{ eth_accounts: {} }],
  });
};

const getBalance = async (
  endpoint: string,
  address: string,
  decimal: number
) => {
  const web3 = new Web3(endpoint);
  const result = await web3.eth.getBalance(address);
  const _toNum = IconConverter.toBigNumber(Math.pow(10, -1 * decimal))
    .times(result)
    .toNumber();
  const _result = roundDownAt(_toNum, 6);
  return _result;
};

const getBalanceOfToken = async (
  endpoint: string,
  address: string,
  tokenAddr: string,
  decimal: number
) => {
  const Web3Client = new Web3(new Web3.providers.HttpProvider(endpoint));
  const contract = new Web3Client.eth.Contract(minABI, tokenAddr);
  const result = await contract.methods.balanceOf(address).call(); // 29803630997051883414242659
  const _toNum = IconConverter.toBigNumber(Math.pow(10, -1 * decimal))
    .times(result)
    .toNumber();
  const _result = roundDownAt(_toNum, 6);
  return _result;
};

const getAddress = async () => {
  const result = await ethereum.request({ method: 'eth_requestAccounts' });
  return result[0];
};

const memaEncodeGetApproved = (tokenId: string) => {
  const _tokenId = IconConverter.toHex(IconConverter.toBigNumber(tokenId));
  const encodeTx = web3.eth.abi.encodeFunctionCall(getApproved, [_tokenId]);
  return encodeTx;
};

const memaEncodeIsApprovedForAll = (account: string, operator: string) => {
  const web3 = new Web3();
  const encodeTx = web3.eth.abi.encodeFunctionCall(isApprovedForAll, [
    account,
    operator,
  ]);
  return encodeTx;
};

const memaEncodeApprove = (sender: string, amount: string) => {
  const web3 = new Web3();
  const _amount = stringToHexConverter(amount);
  const encodeTx = web3.eth.abi.encodeFunctionCall(approve, [sender, _amount]);
  return encodeTx;
};

const memaEncodeApproveErc20 = (
  sender: string,
  value = '0x1431e0fae6d7217caa0000000'
) => {
  // "0x1431e0fae6d7217caa0000000"
  const web3 = new Web3();
  const encodeTx = web3.eth.abi.encodeFunctionCall(approve, [sender, value]);
  return encodeTx;
};

const memaEncodeApproveAll = (operator: string) => {
  const web3 = new Web3();
  const encodeTx = web3.eth.abi.encodeFunctionCall(approveAll, [operator, '1']);
  return encodeTx;
};

const memaEncodeAllowance = (sender: string, spender: string) => {
  const web3 = new Web3();
  const encodeTx = web3.eth.abi.encodeFunctionCall(allowance, [
    sender,
    spender,
  ]);
  return encodeTx;
};

const memaEncodeTransferFT = async ({
  messageId,
  blockHeight,
  decimal,
  fromChain,
  fromToken,
  toChain,
  amount,
  commission,
  targetChainFee,
  recipient,
  signature,
}: any) => {
  try {
    const web3 = new Web3();
    const _commission = IconConverter.toHex(
      IconConverter.toNumber(
        IconConverter.toBigNumber(Math.pow(10, decimal)).times(commission)
      )
    );
    const _targetChainFee = IconConverter.toHex(
      IconConverter.toNumber(
        IconConverter.toBigNumber(Math.pow(10, decimal)).times(targetChainFee)
      )
    );
    const _amount = IconConverter.toHex(
      IconConverter.toNumber(
        IconConverter.toBigNumber(Math.pow(10, decimal)).times(amount)
      )
    );

    const encodeTx = web3.eth.abi.encodeFunctionCall(transferFT, [
      messageId,
      blockHeight,
      fromChain,
      fromToken,
      toChain,
      _amount,
      _commission,
      _targetChainFee,
      recipient,
      '0x' + signature,
    ]);
    return encodeTx;
  } catch (e) {
    throw new Error(JSON.stringify('P300'));
  }
};

const memaEncodeTransferNFT = ({
  decimal,
  messageId,
  blockHeight,
  fromChain,
  fromToken,
  toChain,
  tokenId,
  tokenType,
  commission,
  targetChainFee,
  recipient,
  signature,
}: any) => {
  console.log({
    decimal,
    messageId,
    blockHeight,
    fromChain,
    fromToken,
    toChain,
    tokenId,
    tokenType,
    commission,
    targetChainFee,
    recipient,
    signature,
  });

  const _commission = IconConverter.toHex(
    IconConverter.toNumber(
      IconConverter.toBigNumber(Math.pow(10, decimal)).times(commission)
    )
  );
  const _targetChainFee = IconConverter.toHex(
    IconConverter.toNumber(
      IconConverter.toBigNumber(Math.pow(10, decimal)).times(targetChainFee)
    )
  );
  const _tokenId = IconConverter.toHex(IconConverter.toBigNumber(tokenId));
  // const _commission = BN(commission * Math.pow(10, decimal)).toString();
  // const _targetChainFee = BN(targetChainFee * Math.pow(10, decimal)).toString();

  const encodeTx = web3.eth.abi.encodeFunctionCall(transferNFT, [
    messageId,
    blockHeight,
    fromChain,
    fromToken,
    toChain,
    _tokenId,
    tokenType,
    _commission,
    _targetChainFee,
    recipient,
    '0x' + signature,
  ]);
  return encodeTx;
};

const memaEncodeTransferNFTBatch = ({
  decimal,
  messageId,
  blockHeight,
  fromChain,
  fromToken,
  toChain,
  tokens,
  tokenType,
  commission,
  targetChainFee,
  recipient,
  signature,
}: any) => {
  console.log({
    decimal,
    messageId,
    blockHeight,
    fromChain,
    fromToken,
    toChain,
    tokens,
    tokenType,
    commission,
    targetChainFee,
    recipient,
    signature,
  });
  const toHex = IconConverter.toHex;
  const _commission = toHex(
    IconConverter.toBigNumber(Math.pow(10, decimal)).times(commission)
  );
  const _targetChainFee = toHex(
    IconConverter.toBigNumber(Math.pow(10, decimal)).times(targetChainFee)
  );
  let tokenArray: any[] = [];
  let innerArray: any[] = [];

  for (let i = 0; i < tokens[0].length; i++) {
    innerArray.push(toHex(IconConverter.toBigNumber(tokens[0][i])).toString());
  }
  tokenArray.push(innerArray);
  innerArray = [];
  for (let i = 0; i < tokens[1].length; i++) {
    innerArray.push(toHex(IconConverter.toBigNumber(tokens[1][i])).toString());
  }

  tokenArray.push(innerArray);

  // const _commission = BN(commission * Math.pow(10, decimal)).toString();
  // const _targetChainFee = BN(targetChainFee * Math.pow(10, decimal)).toString();
  const encodeTx = web3.eth.abi.encodeFunctionCall(transferNFTBatch, [
    messageId,
    blockHeight,
    fromChain,
    fromToken,
    toChain,
    tokenArray,
    tokenType,
    _commission,
    _targetChainFee,
    recipient,
    '0x' + signature,
  ]);
  return encodeTx;
};

const memaEncodeOwnerOf = async ({ tokenId }: any) => {
  const encodeTx = web3.eth.abi.encodeFunctionCall(ownerOf, [tokenId]);

  return encodeTx;
};

const memaEncodeBalanceOf = async ({ address, id }: any) => {
  const encodeTx = web3.eth.abi.encodeFunctionCall(balanceOf, [address, id]);

  return encodeTx;
};

const ethCheckOwnerOf = async (
  endpoint: string,
  tokenId: string,
  scAddr: string,
  fromWalletAddr: string
) => {
  try {
    const ownerOfTx = await memaEncodeOwnerOf({ tokenId });
    const { result, error } = await requestJsonRpc(endpoint, 'eth_call', [
      { data: ownerOfTx, to: scAddr },
      'latest',
    ]);
    if (!!error) {
      throw error;
    }

    if (!!result) {
      const _result: string = result;
      const _noHex = fromWalletAddr.substr(2).toLowerCase();
      return _result.includes(_noHex);
    }
    return false;
  } catch (e) {
    throw e;
  }
};

const ethCheckBalanceOf = async (
  endpoint: string,
  tokenId: string,
  scAddr: string,
  fromWalletAddr: string
) => {
  try {
    const ownerOfTx = await memaEncodeBalanceOf({
      address: fromWalletAddr,
      id: tokenId,
    });
    const { result, error } = await requestJsonRpc(endpoint, 'eth_call', [
      { data: ownerOfTx, to: scAddr },
      'latest',
    ]);
    if (!!error) {
      throw error;
    }

    if (!!result) {
      const toNum = parseInt(result);
      return toNum > 0;
    }
    return false;
  } catch (e) {
    throw e;
  }
};

const ethCheckGetApproved = async (
  endpoint: string,
  tokenId: string,
  bridgeContract: string,
  scAddr: string
) => {
  try {
    const approvedTx = memaEncodeGetApproved(tokenId);
    const { result, error } = await requestJsonRpc(endpoint, 'eth_call', [
      { data: approvedTx, to: scAddr },
      'latest',
    ]);
    if (!!error) {
      throw error;
    }

    if (!!result) {
      const _result: string = result;
      const _noHex = bridgeContract.substr(2).toLowerCase();
      return _result.includes(_noHex);
    }

    return false;
  } catch (e) {
    throw e;
  }
};

const ethCheckIsApproved = async (
  endpoint: string,
  senderAddress: string,
  bridgeContract: string,
  scAddr: string
) => {
  try {
    const approvedTx = memaEncodeIsApprovedForAll(
      senderAddress,
      bridgeContract
    );
    const { result, error } = await requestJsonRpc(endpoint, 'eth_call', [
      { data: approvedTx, to: scAddr },
      'latest',
    ]);
    if (!!error) {
      throw error;
    }

    if (!!result) {
      const _result: string = result;
      return parseInt(_result, 16) === 1;
    }

    return false;
  } catch (e) {
    throw e;
  }
};

const ethCheckAllowance = async (
  endpoint: string,
  senderAddress: string,
  bridgeContract: string,
  scAddr: string
) => {
  try {
    const allowanceTx = memaEncodeAllowance(senderAddress, bridgeContract);
    const { result, error } = await requestJsonRpc(endpoint, 'eth_call', [
      { data: allowanceTx, to: scAddr },
      'latest',
    ]);
    if (!!error) {
      throw error;
    }
    return result;
  } catch (e) {
    throw e;
  }
};

const requestApprove = async ({
  from, // sender
  to, // tokenSc
  data, // encoded
}: any) => {
  try {
    const rawTx = {
      from,
      to,
      data,
    };

    return rawTx;
  } catch (e) {
    throw e;
  }
};

const requestTransferFT = async ({
  decimal,
  from, // sender
  to, // bridge
  data, // encoded
  chainId, // ether
  value,
}: any) => {
  try {
    const _amount = IconConverter.toBigNumber(Math.pow(10, decimal)).times(
      value
    );
    const _txFee = IconConverter.toHex(_amount);

    const rawTx = {
      from,
      to,
      data,
      chainId: '0x' + chainId.toString(16),
      value: _txFee,
    };
    return rawTx;
  } catch (e) {
    throw e;
  }
};

const requestTransferNFT = async ({
  decimal,
  commission,
  targetChainFee,
  from, // sender
  to, // bridge
  data, // encoded
}: any) => {
  try {
    // const __commission = BigNumber(commission).times(Math.pow(10, decimal));
    // const __targetChainFee = BigNumber(targetChainFee).times(Math.pow(10, decimal));
    // const __txFee = IconConverter.toHex(BigNumber(__commission).plus(__targetChainFee).toString());
    const _commission = IconConverter.toBigNumber(Math.pow(10, decimal)).times(
      commission
    );
    const _targetChainFee = IconConverter.toBigNumber(
      Math.pow(10, decimal)
    ).times(targetChainFee);
    const _txFee = IconConverter.toHex(_commission.plus(_targetChainFee));
    const rawTx = {
      from,
      to,
      value: _txFee,
      data,
    };

    return rawTx;
  } catch (e) {
    throw e;
  }
};

const metamaskRequestAddChain = async (
  chainId: number,
  chainName: string,
  rpcUrls: string
) => {
  const symbol = METAMASK_NATIVE_CURRENCY[chainId];
  const toHexChainId = stringToHexConverter(chainId);
  try {
    await ethereum.request({
      method: 'wallet_addEthereumChain',
      params: [
        {
          chainId: toHexChainId,
          chainName: chainName,
          rpcUrls: [rpcUrls],
          nativeCurrency: {
            symbol: symbol,
            decimals: 18,
          },
        },
      ],
    });
  } catch (e) {
    throw e;
  }
};

const metamaskRequestJsonRpc = async (rawTx: any) => {
  try {
    const txResult = await ethereum.request({
      method: 'eth_sendTransaction',
      params: [rawTx],
      id: 1234,
    });
    return txResult;
  } catch (e) {
    throw e;
  }
};

const requestJsonRpc = async (url: string, method: string, params: any) => {
  try {
    const data = {
      jsonrpc: '2.0',
      id: '1001',
      method,
      params,
    };
    const headers: HeadersInit = {
      Accept: '*/*',
      'Content-Type': 'application/json',
    };

    const rsp = await fetch(`${url}`, {
      method: 'POST',
      headers: {
        ...headers,
      },
      body: JSON.stringify({
        ...data,
      }),
    });
    let response = await rsp.json();

    return response;
  } catch (e) {
    throw e;
  }
};

const memaDispatchEvent = (tx: any) => {
  try {
    const customEvent = new CustomEvent('ICONEX_RELAY_RESPONSE', {
      detail: {
        type: 'RESPONSE_JSON-RPC',
        payload: {
          result: tx,
        },
      },
    });
    window.dispatchEvent(customEvent);
  } catch (e) {
    throw e;
  }
};

export {
  hexToString,
  estimateGasPrice,
  getBalance,
  getBalanceOfToken,
  getAddress,
  requestWalletPermission,
  checkChainId,
  memaEncodeIsApprovedForAll,
  memaEncodeApprove,
  memaEncodeApproveErc20,
  memaEncodeApproveAll,
  memaEncodeTransferFT,
  memaEncodeTransferNFT,
  memaEncodeTransferNFTBatch,
  ethCheckOwnerOf,
  ethCheckBalanceOf,
  ethCheckGetApproved,
  ethCheckIsApproved,
  ethCheckAllowance,
  requestApprove,
  requestTransferFT,
  requestTransferNFT,
  memaDispatchEvent,
  metamaskRequestJsonRpc,
  metamaskRequestAddChain,
  requestJsonRpc,
  getMetaMaskProvider,

  // ethCall,
};

import { TxnBuilderTypes, BCS, AptosClient } from 'aptos';
import { Buffer } from 'buffer';

const connectPetra = async () => {
  try {
    const connected = await window.aptos.connect();
    return connected;
  } catch (e) {
    throw e;
  }
};

const getAddressFromAptos = async () => {
  try {
    const { address } = await window.aptos.account();
    return address;
  } catch (e) {
    throw e;
  }
};

const encodeTransferNFT = ({
  moduleAccount = '',
  _commission,
  _txFee,
  messageId,
  blockHeight,
  fromChain,
  aptosCreatorAddr,
  aptosCollectionName,
  aptosTokenName,
  aptosTokenPropertyVersion,
  toChain,
  commission,
  targetChainFee,
  recipient,
  signature,
}: any) => {
  console.log({
    moduleAccount,
    messageId,
    blockHeight,
    fromChain,
    aptosCreatorAddr,
    aptosCollectionName,
    aptosTokenName,
    aptosTokenPropertyVersion,
    toChain,
    commission,
    targetChainFee,
    recipient,
    signature,
  });
  const sig = Buffer.from(signature, 'hex');
  return new TxnBuilderTypes.TransactionPayloadEntryFunction(
    TxnBuilderTypes.EntryFunction.natural(
      `${moduleAccount}::mitter_nft_bridge`,
      'transfer_nft',
      [],
      [
        BCS.bcsSerializeStr(messageId),
        BCS.bcsSerializeUint64(blockHeight),
        BCS.bcsSerializeU16(fromChain),
        BCS.bcsToBytes(
          TxnBuilderTypes.AccountAddress.fromHex(aptosCreatorAddr)
        ),
        BCS.bcsSerializeStr(aptosCollectionName),
        BCS.bcsSerializeU16(toChain),
        BCS.bcsSerializeStr(aptosTokenName),
        BCS.bcsSerializeUint64(aptosTokenPropertyVersion),
        BCS.bcsSerializeUint64(_commission),
        BCS.bcsSerializeUint64(_txFee),
        BCS.bcsSerializeStr(recipient),
        BCS.bcsToBytes(new TxnBuilderTypes.Ed25519Signature(sig)),
        // BCS.bcsSerializeBytes(signature)
      ]
    )
  );
};

const encodeReceiveNFT = ({
  moduleAccount = '',
  messageId,
  expires,
  origin_chain,
  origin_token,
  from_chain,
  from_token,
  uri,
  toChain,
  sender,
  token_id,
  recipient,
  signature,
}: any) => {
  console.log({
    moduleAccount,
    messageId,
    expires,
    origin_chain,
    origin_token,
    from_chain,
    from_token,
    uri,
    toChain,
    sender,
    token_id,
    recipient,
    signature,
  });
  const sig = Buffer.from(signature, 'hex');
  return new TxnBuilderTypes.TransactionPayloadEntryFunction(
    TxnBuilderTypes.EntryFunction.natural(
      `${moduleAccount}::mitter_nft_bridge`,
      'receive_nft',
      [],
      [
        BCS.bcsSerializeStr(messageId),
        BCS.bcsSerializeUint64(expires),
        BCS.bcsSerializeU16(origin_chain),
        BCS.bcsSerializeStr(origin_token),
        BCS.bcsSerializeU16(from_chain),
        BCS.bcsSerializeStr(from_token),
        BCS.bcsSerializeStr(uri),
        BCS.bcsSerializeU16(toChain),
        BCS.bcsSerializeStr(sender),
        BCS.bcsSerializeStr(token_id),
        BCS.bcsToBytes(TxnBuilderTypes.AccountAddress.fromHex(recipient)),
        BCS.bcsToBytes(new TxnBuilderTypes.Ed25519Signature(sig)),
      ]
    )
  );
};

const aptosSendTransaction = async (txObj: any) => {
  try {
    const rsp = await window.aptos.signAndSubmitTransaction(txObj);
    return rsp;
  } catch (e) {
    throw e;
  }
};

const aptosCheckTxConfirm = async (ep: string, txn: string) => {
  const aptosClient = new AptosClient(ep);
  const txResult = await aptosClient.waitForTransactionWithResult(txn);
  return txResult;
};

const aptosGetTokenStore = async (ep: string, owner: string) => {
  const aptosClient = new AptosClient(ep);
  try {
    const result = await aptosClient.getAccountResource(
      owner!,
      '0x3::token::TokenStore'
    );
    return result;
  } catch (e) {
    console.log('aptosGetTokenStore error = ' + e);
    return null;
  }
};

export {
  connectPetra,
  getAddressFromAptos,
  encodeTransferNFT,
  encodeReceiveNFT,
  aptosSendTransaction,
  aptosCheckTxConfirm,
  aptosGetTokenStore,
};

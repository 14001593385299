const getApproved: any = {
  constant: false,
  inputs: [
    {
      name: 'tokenId',
      type: 'uint256',
    },
  ],
  name: 'getApproved',
  type: 'function',
};

const isApprovedForAll: any = {
  constant: false,
  inputs: [
    {
      name: 'account',
      type: 'address',
    },
    {
      name: 'operator',
      type: 'address',
    },
  ],
  name: 'isApprovedForAll',
  type: 'function',
};

const approve: any = {
  constant: false,
  inputs: [
    {
      name: '_spender',
      type: 'address',
    },
    {
      name: '_value',
      type: 'uint256',
    },
  ],
  name: 'approve',
  type: 'function',
};

const approveAll: any = {
  constant: false,
  inputs: [
    {
      name: 'operator',
      type: 'address',
    },
    {
      name: 'approved',
      type: 'bool',
    },
  ],
  name: 'setApprovalForAll',
  type: 'function',
};

const allowance: any = {
  constant: false,
  inputs: [
    {
      name: '_owner',
      type: 'address',
    },
    {
      name: '_spender',
      type: 'address',
    },
  ],
  name: 'allowance',
  type: 'function',
};

const transferFT: any = {
  constant: false,
  inputs: [
    {
      name: 'messageID',
      type: 'string',
    },
    {
      name: 'blockHeight',
      type: 'uint256',
    },
    {
      name: 'fromChain',
      type: 'uint16',
    },
    {
      name: 'fromToken',
      type: 'address',
    },
    {
      name: 'toChain',
      type: 'uint16',
    },
    {
      name: 'amount',
      type: 'uint256',
    },
    {
      name: 'commission',
      type: 'uint256',
    },
    {
      name: 'targetChainFee',
      type: 'uint256',
    },
    {
      name: 'recipient',
      type: 'string',
    },
    {
      name: 'signature',
      type: 'bytes',
    },
  ],
  name: 'transferFT',
  type: 'function',
};

const transferNFT: any = {
  constant: false,
  inputs: [
    {
      name: 'messageID',
      type: 'string',
    },
    {
      name: 'expires',
      type: 'uint256',
    },
    {
      name: 'fromChain',
      type: 'uint16',
    },
    {
      name: 'fromToken',
      type: 'address',
    },
    {
      name: 'toChain',
      type: 'uint16',
    },
    {
      name: 'tokenId',
      type: 'uint256',
    },
    {
      name: 'tokenType',
      type: 'uint16',
    },
    {
      name: 'commission',
      type: 'uint256',
    },
    {
      name: 'targetChainFee',
      type: 'uint256',
    },
    {
      name: 'recipient',
      type: 'string',
    },
    {
      name: 'signature',
      type: 'bytes',
    },
  ],
  name: 'transferNFT',
  type: 'function',
};

const transferNFTBatch: any = {
  constant: false,
  inputs: [
    {
      name: 'messageID',
      type: 'string',
    },
    {
      name: 'expires',
      type: 'uint256',
    },
    {
      name: 'fromChain',
      type: 'uint16',
    },
    {
      name: 'fromToken',
      type: 'address',
    },
    {
      name: 'toChain',
      type: 'uint16',
    },
    {
      name: 'tokens',
      type: 'uint256[][]',
    },
    {
      name: 'tokenType',
      type: 'uint16',
    },
    {
      name: 'commission',
      type: 'uint256',
    },
    {
      name: 'targetChainFee',
      type: 'uint256',
    },
    {
      name: 'recipient',
      type: 'string',
    },
    {
      name: 'signature',
      type: 'bytes',
    },
  ],
  name: 'transferNFTBatch',
  type: 'function',
};

const ownerOf: any = {
  constant: false,
  inputs: [
    {
      name: 'tokenId',
      type: 'uint256',
    },
  ],
  name: 'ownerOf',
  type: 'function',
};

const balanceOf: any = {
  constant: false,
  inputs: [
    {
      name: 'account',
      type: 'address',
    },
    {
      name: 'id',
      type: 'uint256',
    },
  ],
  name: 'balanceOf',
  type: 'function',
};

export {
  getApproved,
  isApprovedForAll,
  approve,
  approveAll,
  allowance,
  transferFT,
  transferNFT,
  transferNFTBatch,
  ownerOf,
  balanceOf,
};

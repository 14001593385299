import React, { useContext, useLayoutEffect } from 'react';
import {
  Routes,
  Route,
  Navigate,
  UNSAFE_NavigationContext,
  useLocation,
} from 'react-router-dom';
import { MobileView, BrowserView } from 'react-device-detect';
import RouterChangeTracker from './hooks/routeChangeTracker';

import {
  FTBridgePage,
  NFTBridgePage,
  BridgeExplorerPage,
  BridgeExplorerDetailPage,
  MobileBlockPage,
  AptosConnectPage,
  XplaTestPage,
  SolanaTestPage,
} from 'src/pages/index';
import { PATH } from 'src/constants/index';
import { PageLayout } from 'src/components/index';
import { theme } from 'src/styles/theme';

const isDev = process.env.REACT_APP_IS_DEV === 'dev';

const App = () => {
  const { pathname } = useLocation();
  const navigation = useContext(UNSAFE_NavigationContext).navigator;
  RouterChangeTracker();

  // Pop action 발생 시, Dialog 생성 필요.
  useLayoutEffect(() => {
    const _nav = navigation as any;
    console.log('action', _nav.action);
    console.log('Pathname', pathname);
  }, [pathname, navigation]);

  return (
    <>
      <MobileView>
        <MobileBlockPage />
      </MobileView>
      <BrowserView>
        <Routes>
          <Route
            path={PATH.FT}
            element={
              <PageLayout
                bannerTitle={'Transfer your TOKENS to Other Chains!'}
                bannerSubtitle={'Try sending Tokens through the HAVAH Mitter'}
              >
                <FTBridgePage />
              </PageLayout>
            }
          />
          <Route
            path={PATH.NFT}
            element={
              <PageLayout
                bannerTitle={'Transfer your NFT to Other Chains!'}
                bannerSubtitle={'Try sending NFTs through the HAVAH Mitter'}
              >
                <NFTBridgePage />
              </PageLayout>
            }
          />
          <Route
            path={PATH.EXPLORER}
            element={
              <PageLayout type='explorer' bannerTitle={'EXPLORER'}>
                <BridgeExplorerPage />
              </PageLayout>
            }
          />
          <Route
            path={`${PATH.EXPLORER_DETAIL}:id`}
            element={
              <PageLayout type='explorer' sectionColor={theme.mono1}>
                <BridgeExplorerDetailPage />
              </PageLayout>
            }
          />
          <Route path='*' element={<Navigate to={PATH.NFT} replace />} />
          {isDev && <Route path='/bridge/test' element={<SolanaTestPage />} />}
        </Routes>
      </BrowserView>
    </>
  );
};

export default App;

import { BridgeDispatcher } from 'src/dispatcher/bridgeDispatcher';
import { POLLING_STEP } from 'src/constants/actionTypes';

const bridgeDispatcher = BridgeDispatcher();

const pollingStepAction = {
  _changePollingStep: (stepNum: number) => {
    bridgeDispatcher.handleViewAction({
      actionType: POLLING_STEP.SET_POLLING_STEP,
      id: stepNum,
    });
  },
};

export { pollingStepAction };

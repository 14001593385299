import React, {
  useState,
  useContext,
  useCallback,
  useEffect,
  useRef,
  RefObject,
  Dispatch,
  SetStateAction,
} from 'react';
import styled from 'styled-components';
import { ModalContext } from 'src/contexts/ModalContext';
import bridgePageStore from 'src/stores/BridgePageStore';
import { Button, ImageLoader, Skeleton } from 'src/components/index';
import { theme } from 'src/styles/theme';
import { NETWORK_ID } from 'src/constants/enums';
import { ChainConfig } from 'src/constants/types';

import ic_refresh from 'src/assets/ic_refresh.svg';
import img_empty_nft from 'src/assets/tokens/img_nft_empty_dark.png';
import img_empty_nft_2 from 'src/assets/tokens/img_NFT_default_dark.png';
import img_tip from 'src/assets/address_bubble_tip.png';
import img_copy_btn from 'src/assets/copy_s.png';
import img_copy_focus from 'src/assets/copy_focus.png';
import { copyToClipboard } from 'src/utils/utils';

interface IProps {
  refresh: () => void;
  nextList: (num: number) => void;
}

interface TokenIdProps {
  hasCopyButton: boolean;
}

interface CardIProps {
  comp: any;
  idx: number;
  setSelectedIdx: Dispatch<SetStateAction<number>>;
  setSelectedTokenCount: Dispatch<SetStateAction<number>>;
}

const ModalNFTList: React.FC<IProps> = ({ refresh, nextList }) => {
  const divRef = useRef<HTMLDivElement>(null);
  const bStore = bridgePageStore;
  const _config = bStore.getBridgeConfig();
  const {
    list,
    isLoadingList,
    curPage,
    morailsCursor,
    handleOnSelect,
    total,
    setList,
  } = useContext(ModalContext);

  console.log('ModalNFTList', list);

  const size = total % 2;
  const emptyArr = new Array(2 + size).fill('');
  const [isReloaded, setIsReloaded] = useState<boolean>(false);
  const [selectedIdx, setSelectedIdx] = useState<number>(-1);
  const [selectedTokenCount, setSelectedTokenCount] = useState<number>(1);
  const [isShow, setIsShow] = useState<boolean>(false);
  const [isIDShow, setIDIsShow] = useState<boolean>(false);
  const isNotSelected = selectedIdx < 0;
  let selectedItem = list[selectedIdx];
  const len = list.length;
  const hasNoList = len === 0;
  const isSolanaChain =
    (_config.fromChain as ChainConfig).chainNo === NETWORK_ID.SOLANA;

  const scrollTo = useCallback(() => {
    const { current } = divRef;
    const fromChain = _config.fromChain as ChainConfig;

    if (current) {
      if (
        Math.ceil(current.scrollTop + current.clientHeight) >=
        current.scrollHeight
      ) {
        if (!isLoadingList) {
          if (
            fromChain.chainNo === 2 ||
            fromChain.chainNo === 3 ||
            fromChain.chainNo === 5 ||
            fromChain.chainNo === 6 ||
            fromChain.chainNo === 8
          ) {
            if (!!morailsCursor) {
              nextList(morailsCursor);
            }
          } else {
            if (len !== total) {
              nextList(curPage);
            }
          }
        }
      }
    }
  }, [
    divRef,
    len,
    total,
    _config,
    isLoadingList,
    curPage,
    morailsCursor,
    nextList,
  ]);

  useEffect(() => {
    const { current } = divRef;
    if (current) {
      current.addEventListener('scroll', scrollTo);
      return () => current.removeEventListener('scroll', scrollTo);
    }
  }, [divRef, hasNoList, isLoadingList, len, total, _config, scrollTo]);

  const nftCountInputChange = (e: any) => {
    const total = selectedItem.tokenCount ? selectedItem.tokenCount : 1; //selectedItem.aptosTokenAmount;
    let numbersOnly = e.target.value.replace(/[^0-9]/g, '');
    if (parseInt(numbersOnly) > total) {
      numbersOnly = total;
    } else if (parseInt(numbersOnly) < 1) {
      numbersOnly = 1;
    }
    e.target.value = numbersOnly;
    setSelectedTokenCount(numbersOnly);
  };

  const nftCountInputFocus = (e: any) => {
    setTimeout(() => {
      e.target.selectionStart = e.target.selectionEnd = e.target.value.length;
    });
  };
  return (
    <StyledModal>
      {isLoadingList && hasNoList && (
        <div className='list-view no-list'>
          <span>Loading...</span>
        </div>
      )}
      {!isLoadingList && hasNoList && (
        <div className='list-view no-list'>
          <span>No NFT found on the Source chain.</span>
          <div
            className='refresh-btn'
            onClick={() => {
              setList([]);
              refresh();
            }}
          >
            <img src={ic_refresh} alt='refresh' />
          </div>
        </div>
      )}
      {!hasNoList && (
        <div className='nft-view-wrapper'>
          <div className='nft-view'>
            <div className='nft-list-handler'>
              <div className='list-counter'>
                <span>Total</span>
                <span className='txt-green'>[{len}]</span>
              </div>
              <div className='list-refresh'>
                <span>Refresh</span>
                <img
                  onClick={() => {
                    if (!isLoadingList) {
                      setList([]);
                      setSelectedIdx(-1);
                      refresh();
                    }
                  }}
                  src={ic_refresh}
                  alt='refresh'
                />
              </div>
            </div>
            <div
              className='scrollable-area'
              ref={divRef as RefObject<HTMLDivElement>}
            >
              <div id='scroll-id' className='nft-list'>
                {list.map((el: any, idx: number) => {
                  return (
                    <Card
                      key={idx.toString()}
                      comp={el}
                      setSelectedIdx={setSelectedIdx}
                      setSelectedTokenCount={setSelectedTokenCount}
                      idx={idx}
                    />
                  );
                })}
                {isLoadingList &&
                  emptyArr.map(() => {
                    return <Skeleton />;
                  })}
              </div>
            </div>
          </div>
          {isNotSelected && (
            <div className='preview-item'>
              <div className='empty-outline'>
                <img src={img_empty_nft} alt='empty' />
              </div>
              <div className='empty-txt'>
                Click on an NFT icon to view its details.
              </div>
            </div>
          )}
          {!isNotSelected && (
            <div className='preview-item selected'>
              <div className='overflow-wrapper'>
                <div className='img-box'>
                  <ImageLoader
                    url={!isReloaded ? selectedItem.tokenImage : ''}
                    img={img_empty_nft}
                    outline={true}
                  />
                  <div
                    className='reload-img-btn'
                    onClick={() => {
                      setIsReloaded(true);
                      setTimeout(() => {
                        setIsReloaded(false);
                      }, 1000);
                    }}
                  />
                </div>
                <div className='nft-info-area'>
                  {selectedItem.tokenType === 1 && (
                    <div className='nft-collection'>
                      {selectedItem.contractName}
                    </div>
                  )}

                  {selectedItem.tokenType === 2 &&
                    !!selectedItem.aptosCreatorAddr && (
                      <div className='nft-collection'>
                        {selectedItem.contractName}
                      </div>
                    )}

                  {!!selectedItem.tokenName && (
                    <div className='nft-info'>
                      <div>Name</div>
                      <div className='token-name'>{selectedItem.tokenName}</div>
                    </div>
                  )}
                  {!selectedItem.aptosCreatorAddr && (
                    <TokenId hasCopyButton={isSolanaChain}>
                      <div>Token ID</div>
                      <div className='token-id'>{selectedItem.tokenId} </div>
                      {isSolanaChain && (
                        <img
                          className='copy-btn'
                          src={img_copy_btn}
                          onMouseLeave={(e: any) => {
                            e.target.src = img_copy_btn;
                          }}
                          onMouseOver={(e: any) => {
                            e.target.src = img_copy_focus;
                          }}
                          onClick={(e: any) => {
                            setIDIsShow(true);
                            copyToClipboard(selectedItem.tokenId);
                            setTimeout(() => {
                              const text = document.querySelector('.copy-text');
                              text?.classList.add('fade-out');
                              setTimeout(() => {
                                setIDIsShow(false);
                              }, 200);
                            }, 600);
                          }}
                          alt='cp-img'
                        ></img>
                      )}
                      {isIDShow && <span className='copy-text'>Copied</span>}
                    </TokenId>
                  )}
                  {!!selectedItem.tokenAddr && (
                    <div className='nft-info'>
                      <div>
                        {(_config.fromChain as ChainConfig).chainNo ===
                        NETWORK_ID.APTOS
                          ? 'Creator'
                          : 'Contract'}
                      </div>
                      <div className='token-address'>
                        {selectedItem.tokenAddr.slice(0, 5) +
                          '...' +
                          selectedItem.tokenAddr.slice(
                            selectedItem.tokenAddr.length - 5
                          )}
                        <div className='hover-bubble'>
                          {selectedItem.tokenAddr}
                        </div>
                      </div>
                      <img
                        className='copy-btn'
                        src={img_copy_btn}
                        onMouseLeave={(e: any) => {
                          e.target.src = img_copy_btn;
                        }}
                        onMouseOver={(e: any) => {
                          e.target.src = img_copy_focus;
                        }}
                        onClick={(e: any) => {
                          setIsShow(true);
                          copyToClipboard(selectedItem.tokenAddr);
                          setTimeout(() => {
                            const text = document.querySelector('.copy-text');
                            text?.classList.add('fade-out');
                            setTimeout(() => {
                              setIsShow(false);
                            }, 200);
                          }, 600);
                        }}
                        alt='img'
                      ></img>
                      {isShow && <span className='copy-text'>Copied</span>}
                    </div>
                  )}
                </div>
              </div>
              {selectedItem.tokenType === 2 &&
              (_config.fromChain as ChainConfig).chainNo !==
                NETWORK_ID.APTOS ? (
                <>
                  <div className='multi-btn-area'>
                    <div className='btn-line'>
                      <div className='nft-count'>
                        {selectedItem.tokenCount <= 99999 ? (
                          <div className='count-text'>x&nbsp;</div>
                        ) : null}
                        {selectedItem.tokenCount
                          ? selectedItem.tokenCount > 99999
                            ? '99999+'
                            : selectedItem.tokenCount
                          : selectedItem.aptosTokenAmount > 99999
                          ? '99999+'
                          : selectedItem.aptosTokenAmount}
                      </div>

                      <input
                        className='input-count'
                        type='text'
                        defaultValue={1}
                        maxLength={5}
                        value={selectedTokenCount}
                        onChange={nftCountInputChange}
                        onFocus={nftCountInputFocus}
                      />
                    </div>
                    <Button
                      type='nftSelect'
                      name='Select'
                      handleOnClick={() => {
                        const count = selectedTokenCount.toString();
                        count === ''
                          ? (selectedItem = {
                              ...selectedItem,
                              transferTokenAmount: '1',
                            })
                          : (selectedItem = {
                              ...selectedItem,
                              transferTokenAmount: selectedTokenCount,
                            });
                        handleOnSelect('nft', selectedItem);
                      }}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className='btn-area'>
                    <Button
                      name='Select'
                      handleOnClick={() => {
                        handleOnSelect('nft', selectedItem);
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      )}
    </StyledModal>
  );
};

const TokenId = styled.div<TokenIdProps>`
  display: flex;
  width: 200px;

  & > div:first-of-type {
    width: 48px;
    margin-right: 10px;
    color: ${theme.mono4};
  }
  & > div.token-id {
    width: ${(props) => (props.hasCopyButton ? '85px' : '142px')};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  & > img.copy-btn {
    width: 16px;
    height: 18px;
    cursor: pointer;
  }
  & > span.copy-text {
    ${theme.body2}
    color: ${theme.primaryTxt};
    transition: opacity 200ms;
  }
  & > span.fade-out {
    opacity: 0;
  }
`;

const Card: React.FC<CardIProps> = ({
  comp,
  setSelectedIdx,
  idx,
  setSelectedTokenCount,
}) => {
  const [isVideo, setIsVideo] = useState<boolean>(false);
  const [iteration, setIteration] = useState<number>(0);
  const failedToLoad = iteration > 2;
  let tokenCnt = comp.aptosTokenAmount
    ? comp.aptosTokenAmount
    : comp.tokenCount;
  tokenCnt = tokenCnt?.length > 5 ? '99999+' : tokenCnt;
  return (
    <StyledCard>
      <div className='nft-info'>
        <div className='nft-image-area'>
          {failedToLoad && <img src={img_empty_nft_2} alt='empty' />}
          {!failedToLoad && isVideo && (
            <video
              width='100%'
              height='100%'
              autoPlay={false}
              src={!!comp.tokenImage ? comp.tokenImage : img_empty_nft_2}
              onError={({ currentTarget }) => {
                setIsVideo(false); // prevents looping
                setIteration((prev) => prev + 1);
                currentTarget.src = img_empty_nft_2;
              }}
            />
          )}
          {!failedToLoad && !isVideo && (
            <img
              src={
                !!comp.tokenImage
                  ? 'https://imageproxy.havah.io/60x60/' + comp.tokenImage
                  : img_empty_nft_2
              }
              onError={({ currentTarget }) => {
                setIsVideo(true); // prevents looping
                setIteration((prev) => prev + 1);
                currentTarget.src = img_empty_nft_2;
              }}
              alt='img'
            />
          )}
          {comp.tokenType === 2 && (
            <div className='nft-count'>
              {tokenCnt <= 99999 ? (
                <div className='count-text'>x&nbsp;</div>
              ) : null}
              {tokenCnt}
            </div>
          )}
        </div>
        <span className='nft-name'>{comp.tokenName}</span>
        {!comp.aptosCreatorAddr && (
          <span className='token-id'>#{comp.tokenId}</span>
        )}
      </div>
      <button
        className='nft-select-btn'
        disabled={!comp.tokenId || comp.aptosTokenAmount === '0'}
        onClick={() => {
          if (!!comp.tokenId) {
            setSelectedTokenCount(1);
            setSelectedIdx(idx);
          }
        }}
      >
        Details
      </button>
    </StyledCard>
  );
};

const StyledModal = styled.div`
  width: 624px;
  height: 489px;
  padding: 20px 24px 24px;

  & > div.list-view {
    width: 100%;
    height: 445px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background-color: ${theme.mono7};
    & > img {
      margin-bottom: 20px;
    }
    & > span {
      color: ${theme.mono4};
    }
    &.no-list {
      background-color: ${theme.mono8};
      & > div.refresh-btn {
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: ${theme.mono6};
        cursor: pointer;
        & > img {
          width: 28px;
          height: 28px;
        }
      }
    }
  }
  & > div.modal-desc {
    ${theme.body1};
    color: ${theme.mono4};
    margin-bottom: 30px;
  }
  & > div.nft-view-wrapper {
    display: flex;
    width: 100%;
    height: 445px;
    border-radius: 6px;
    overflow: hidden;
    & > div.nft-view {
      ${theme.body2};
      color: ${theme.mono2};
      width: 336px;
      background-color: ${theme.mono8};
      & > div.nft-list-handler {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid ${theme.mono6};
        padding: 8px 14px;
        & > div {
          color: ${theme.mono4};
        }
        & > div.list-counter {
          & > span.txt-green {
            margin-left: 2px;
            color: ${theme.primaryBG};
          }
        }
        & > div.list-refresh {
          display: flex;
          align-items: center;
          & > img {
            width: 24px;
            height: 24px;
            margin-left: 6px;
            background-color: ${theme.mono7};
            border-radius: 4px;
            cursor: pointer;
          }
        }
      }
      & > div.scrollable-area {
        width: 336px;
        height: 404px;
        overflow-y: scroll;
        &::-webkit-scrollbar {
          width: 0px;
          background-color: transparent;
        }
        & > div.nft-list {
          display: grid;
          grid-template-columns: 148px 148px;
          grid-gap: 14px;
          padding: 14px;
        }
      }
    }
    & > div.preview-item {
      position: relative;
      padding-top: 123px;
      width: 240px;
      background-color: ${theme.mono7};
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      &.selected {
        padding: 23px 0px 54px;
        overflow: hidden;
        & > div.overflow-wrapper {
          width: 100%;
          height: 500px;
          overflow-y: overlay;
          padding-top: 45px;
          ::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 0px;
          }
          & > div.img-box {
            position: relative;
            color: ${theme.mono1};
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 160px;
            height: 160px;
            margin: 0px auto;
            margin-bottom: 30px;
            & > div.reload-img-btn {
              width: 32px;
              height: 32px;
              position: absolute;
              bottom: 6px;
              right: 6px;
              background: url(${ic_refresh}) center center no-repeat;
              background-size: 24px 24px;
              background-color: rgba(79, 83, 85, 0.7);
              border-radius: 6px;
              cursor: pointer;
            }
            & > div.empty-box {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              height: 100%;
              border: 1px dashed rgba(255, 255, 255, 0.4);
              border-radius: 6px;
              overflow: hidden;
              & > img {
                width: 102px;
                height: 60px;
              }
            }
          }
          & > div.nft-info-area {
            ${theme.body2};
            color: ${theme.mono1};
            justify-content: center;
            align-items: center;
            display: flex;
            flex-direction: column;
            & > div.nft-collection {
              ${theme.tab1};
              width: 200px;
              margin-bottom: 10px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            & > div.nft-info {
              display: flex;
              width: 200px;
              & > div:first-of-type {
                width: 48px;
                margin-right: 10px;
                color: ${theme.mono4};
              }
              & > div.token-name {
                width: 142px;
                text-overflow: ellipsis;
                overflow: hidden;
                word-break: break-word;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
              }
              & > div.token-id {
                width: 85px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
              & > div.token-address {
                ${theme.body2}
                width: auto;
                margin-right: 2px;
                word-wrap: break-word;
                position: relative;
                cursor: pointer;
                & > div.hover-bubble {
                  display: none;
                  ${theme.caption};
                  padding: 8px 8px 10px;
                  position: absolute;
                  bottom: -3px;
                  left: 50%;
                  top: 120%;
                  transform: translateX(-50%);
                  width: 200px;
                  height: 44px;
                  visibility: visible;
                  background-color: ${theme.mono4};
                  border-radius: 6px;
                  z-index: 20000;
                  &:after {
                    content: ' ';
                    position: absolute;
                    top: -5px; /* 화살표 위치 조정 */
                    left: 50%;
                    width: 6px;
                    height: 5px;
                    background: url(${img_tip}) center center no-repeat;
                    background-size: 6px 5px;
                    transform: translateX(-50%) rotate(0deg); /* 화살표 방향 조정 */
                  }
                }
                &:hover > div.hover-bubble {
                  display: block;
                }
              }
              & > img.copy-btn {
                width: 16px;
                height: 18px;
                cursor: pointer;
              }
              & > span.copy-text {
                ${theme.body2}
                color: ${theme.primaryTxt};
                transition: opacity 200ms;
              }
              & > span.fade-out {
                opacity: 0;
              }
            }
          }
        }
      }
      & > div.empty-outline {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 160px;
        height: 160px;
        margin: 0px auto 30px;
        border: 1px dashed ${theme.mono4};
        border-radius: 6px;
        & > img {
          width: 100%;
          height: 100%;
        }
      }
      & > div.empty-txt {
        width: 160px;
        margin: 0px auto;
        color: ${theme.mono4};
        text-align: center;
      }
      & > div.btn-area {
        width: 200px;
        height: 32px;
        position: absolute;
        bottom: 23px;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      & > div.multi-btn-area {
        display: flex;
        width: 200px;
        height: 32px;
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        bottom: 23px;
        & > .btn-line {
          width: 132px;
          display: flex;
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
          border: 1px solid ${theme.primaryTxt};
          border-right: none;
          background-color: #000000;
          align-items: center;
          & > .input-count {
            padding: 0px;
            width: 40px;
            height: 20px;
            margin-left: auto;
            margin-right: 15px;
            border: 0px;
            background-color: #000000;
            color: ${theme.mono1};
            outline: none;
            text-align: end;
            caret-color: ${theme.primaryBG};
            ${theme.attention};
          }
          & > div.nft-count {
            ${theme.tokenAmount}
            padding: 1px 4px;
            display: inline-flex;
            justify-content: center;
            height: 18px;
            margin-left: 10px;
            min-width: 20px;
            width: auto;
            background-color: rgba(22, 217, 146, 0.14);
            border-radius: 6px;
            color: ${theme.primaryTxt};
          }
        }
      }
    }
  }
`;

const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 165px;
  border-radius: 6px;
  background-color: ${theme.mono7};

  & > div.nft-info {
    ${theme.body2};
    color: ${theme.mono1};
    display: flex;

    flex-direction: column;
    padding: 12px 12px 0px 12px;
    & > div.nft-image-area {
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 60px;
      overflow: hidden;
      margin-bottom: 6px;
      & > img {
        width: auto;
        height: auto;
        max-width: 60px;
        max-height: 60px;
        border-radius: 6px;
      }
      & > video {
        width: auto;
        height: auto;
        max-width: 60px;
        max-height: 60px;
        border-radius: 6px;
      }
      & > div.nft-count {
        ${theme.tokenAmount}
        padding: 1px 4px;
        display: inline-flex;
        justify-content: center;
        height: 18px;
        min-width: 20px;
        width: auto;
        background-color: rgba(22, 217, 146, 0.14);
        border-radius: 6px;
        color: ${theme.primaryTxt};
      }
    }
    & > span {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    & > span.nft-name {
      width: 100%;
      ${theme.tab1};
      font-weight: 900;
    }
    & > span.token-id {
      color: ${theme.mono4};
      margin-top: 2px;
      margin-bottom: 12px;
    }
  }
  & > button.nft-select-btn {
    ${theme.body2};
    border: none;
    border-top: 1px solid ${theme.mono6};
    text-align: center;
    height: 32px;
    padding: 7px;
    color: ${theme.mono1};
    background-color: transparent;
    cursor: pointer;
    &:disabled {
      cursor: default;
      color: ${theme.mono4};
    }
  }
`;

export { ModalNFTList };

import React from 'react';
import styled from 'styled-components';
import { theme } from 'src/styles/theme';
import ic_exclude from 'src/assets/ic_exclude.svg';

const Warning2: React.FC = () => {
  return (
    <StyledComp className="warning-2">
      <div className="icon-area">
        <img src={ic_exclude} alt="exlude" />
      </div>
      <div className="warning-txt-area">
        <span>
          If you don’t have as much as Bridge fee in the Source chain, You can’t
          proceed to the end of the steps.
        </span>
        <span className="warning-color">
          Please hold enough to pay Bridge fee to the Source chain.
        </span>
      </div>
    </StyledComp>
  );
};

const StyledComp = styled.div`
  margin-top: 16px;
  display: flex;
  padding: 7px 14px 7px 14px;
  background-color: rgba(255, 89, 89, 0.1);
  border: 1px solid rgba(255, 89, 89, 0.6);
  border-radius: 6px;
  & > div.icon-area {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: 22px;
    & > img {
      width: 20px;
      height: 20px;
    }
  }
  & > div.warning-txt-area {
    ${theme.body2};
    color: ${theme.mono1};
    & > span.warning-color {
      color: ${theme.error};
      margin-left: 2px;
    }
  }
`;

export { Warning2 };

import { BridgeDispatcher } from 'src/dispatcher/bridgeDispatcher';
import { TOKEN_TRANSFER_ACTIONS } from 'src/constants/actionTypes';
import { ChainListType } from 'src/constants/types';
const bridgeDispatcher = BridgeDispatcher();

const bridgePageAction = {
  _changeFromChain: (obj: ChainListType) => {
    bridgeDispatcher.handleViewAction({
      actionType: TOKEN_TRANSFER_ACTIONS.FROM_CHAIN_SELECT,
      id: obj,
    });
  },
  _removeFromChain: () => {
    bridgeDispatcher.handleViewAction({
      actionType: TOKEN_TRANSFER_ACTIONS.FROM_CHAIN_REMOVE,
    });
  },
  _changeFromWallet: (walletAddr: string) => {
    bridgeDispatcher.handleViewAction({
      actionType: TOKEN_TRANSFER_ACTIONS.FROM_WALLET_SELECT,
      id: walletAddr,
    });
  },
  _removeFromWallet: () => {
    bridgeDispatcher.handleViewAction({
      actionType: TOKEN_TRANSFER_ACTIONS.FROM_WALLET_REMOVE,
    });
  },
  _changeWeb3WalletID: (id: number) => {
    bridgeDispatcher.handleViewAction({
      actionType: TOKEN_TRANSFER_ACTIONS.FROM_WALLET_ID_SELECT,
      id,
    });
  },
  _removeWeb3WalletID: () => {
    bridgeDispatcher.handleViewAction({
      actionType: TOKEN_TRANSFER_ACTIONS.FROM_WALLET_ID_REMOVE,
    });
  },
  _changeFromToken: (tokenInfo: any) => {
    bridgeDispatcher.handleViewAction({
      actionType: TOKEN_TRANSFER_ACTIONS.FROM_TOKEN_SELECT,
      id: tokenInfo,
    });
  },
  _removeFromToken: () => {
    bridgeDispatcher.handleViewAction({
      actionType: TOKEN_TRANSFER_ACTIONS.FROM_TOKEN_REMOVE,
    });
  },
  _inputBalance: (amount: string) => {
    bridgeDispatcher.handleViewAction({
      actionType: TOKEN_TRANSFER_ACTIONS.INPUT_TOKEN_BALANCE,
      id: amount,
    });
  },
  _changeToChain: (toChainInfo: any) => {
    bridgeDispatcher.handleViewAction({
      actionType: TOKEN_TRANSFER_ACTIONS.TO_CHAIN_SELECT,
      id: toChainInfo,
    });
  },
  _removeToChain: () => {
    bridgeDispatcher.handleViewAction({
      actionType: TOKEN_TRANSFER_ACTIONS.TO_CHAIN_REMOVE,
    });
  },
  _changeToWallet: (walletAddr: string) => {
    bridgeDispatcher.handleViewAction({
      actionType: TOKEN_TRANSFER_ACTIONS.TO_WALLET_SELECT,
      id: walletAddr,
    });
  },
  _removeToWallet: () => {
    bridgeDispatcher.handleViewAction({
      actionType: TOKEN_TRANSFER_ACTIONS.TO_WALLET_REMOVE,
    });
  },

  _setMessageStatus: (data: any) => {
    bridgeDispatcher.handleViewAction({
      actionType: TOKEN_TRANSFER_ACTIONS.MESSAGE_STATUS,
      id: data,
    });
  },

  _changeResult: (data: any) => {
    bridgeDispatcher.handleViewAction({
      actionType: TOKEN_TRANSFER_ACTIONS.CHANGE_RESULT,
      id: data,
    });
  },

  _reset: () => {
    bridgeDispatcher.handleViewAction({
      actionType: TOKEN_TRANSFER_ACTIONS.RESET,
    });
  },

  _setAptosDirectTransfer: (data: any) => {
    bridgeDispatcher.handleViewAction({
      actionType: TOKEN_TRANSFER_ACTIONS.APTOS_DIRECT_TRANSFER,
      id: data,
    });
  },
};

export { bridgePageAction };

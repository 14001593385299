import { createContext } from 'react';
import { ModalShadow } from 'src/components/index';
import { useShadowModal } from 'src/hooks/useShadowModal';

const ModalContext = createContext<any>({});
const { Provider } = ModalContext;

const ModalProvider = ({ children }: { children: React.ReactNode }) => {
  const { isModalOpened, handleModal, modalContent } = useShadowModal();
  return (
    <Provider
      value={{
        isModalOpened,
        modalContent,
        handleModal,
      }}
    >
      <ModalShadow />
      {children}
    </Provider>
  );
};

export { ModalContext, ModalProvider };

import {
  Ed25519Program,
  PublicKey,
  SystemProgram,
  SYSVAR_INSTRUCTIONS_PUBKEY,
  SYSVAR_RENT_PUBKEY,
  TransactionInstruction,
  VersionedTransaction,
} from '@solana/web3.js';
import { Base } from './base';
import { Env } from './env';
import { Const } from './const';
import {
  ASSOCIATED_TOKEN_PROGRAM_ID,
  getAssociatedTokenAddress,
  TOKEN_PROGRAM_ID,
} from '@solana/spl-token';
import {
  NftInstruction,
  ReceiveNFTArgs,
  ReceiveNFTBatchArgs,
  TransferNFTArgs,
  TransferNFTBatchArgs,
} from './instructions/nft-bridge';
import { Pda } from './pda';
import { Settings } from './settings';
import BigNumber from 'bignumber.js';
import bs58 from 'bs58';
import { CollectionByOriginInfo } from './state';
import { Buffer } from 'buffer';

export class NftBridge extends Base {
  private readonly pda: Pda;
  private readonly settings: Settings;

  constructor(env: Env) {
    super(env);
    this.pda = new Pda(env.program);
    this.settings = new Settings(env);
  }

  public async transfer(
    sender: PublicKey,
    messageId: string,
    expires: bigint,
    fromToken: PublicKey,
    toChain: number,
    commission: bigint,
    targetChainFee: bigint,
    recipient: string,
    signature: Uint8Array,
    isBatch: boolean,
    amount?: bigint
  ): Promise<VersionedTransaction> {
    const mapPda = this.pda.getWrappedToOriginAddress(fromToken)[0];
    let accountInfo = await this.env.connection.getAccountInfo(mapPda);
    const isNative = accountInfo == null;
    const settingsPubKey = this.pda.getSettingsAddress()[0];
    const senderAta = await getAssociatedTokenAddress(fromToken, sender);
    let msg;
    let instructionBuffer;
    const FROM_CHAIN = Const.SOLANA_CHAIN_ID;
    if (!isBatch) {
      const args = new TransferNFTArgs({});
      args.instruction = isNative
        ? NftInstruction.TransferNativeNFT
        : NftInstruction.TransferWrappedNFT;
      args.messageId = messageId;
      args.expires = expires;
      args.fromChain = FROM_CHAIN;
      args.fromToken = fromToken.toString();
      args.toChain = toChain;
      args.commission = commission;
      args.targetChainFee = targetChainFee;
      args.recipient = recipient;
      args.signature = signature;
      instructionBuffer = args.toBuffer();
      msg = args.getMessage();
    } else {
      const args = new TransferNFTBatchArgs({});
      args.instruction = isNative
        ? NftInstruction.TransferNativeNFTBatch
        : NftInstruction.TransferWrappedNFTBatch;
      args.messageId = messageId;
      args.expires = expires;
      args.fromChain = FROM_CHAIN;
      args.fromToken = fromToken.toString();
      args.toChain = toChain;
      args.amount = amount!!;
      args.commission = commission;
      args.targetChainFee = targetChainFee;
      args.recipient = recipient;
      args.signature = signature;
      instructionBuffer = args.toBuffer();
      msg = args.getMessage();
    }

    const [treasuryForTargetChain, treasuryForCommission] =
      await this.settings.getTreasury();
    const messageIdAccount = this.pda.getMessageIdAddress(messageId)[0];
    let accounts = [
      { pubkey: fromToken, isSigner: false, isWritable: true }, // nft token account
      { pubkey: senderAta, isSigner: false, isWritable: true }, // Owner Token account
      { pubkey: treasuryForTargetChain, isSigner: false, isWritable: true }, // commission Treasury
      { pubkey: treasuryForCommission, isSigner: false, isWritable: true }, // commission Treasury

      { pubkey: messageIdAccount, isSigner: false, isWritable: true },
      { pubkey: sender, isSigner: true, isWritable: false },
      { pubkey: settingsPubKey, isSigner: false, isWritable: false },
      {
        pubkey: SYSVAR_INSTRUCTIONS_PUBKEY,
        isSigner: false,
        isWritable: false,
      }, //SYSVAR_INSTRUCTIONS_PUBKEY
      { pubkey: TOKEN_PROGRAM_ID, isSigner: false, isWritable: false }, // Token program
      { pubkey: SystemProgram.programId, isSigner: false, isWritable: false }, // System program
    ];

    if (isNative) {
      const fromTokenMetadata = this.pda.getMetadataAddress(fromToken);
      const vaultAddress = this.pda.getVaultAddress()[0];
      const vaultAta = await getAssociatedTokenAddress(
        fromToken,
        vaultAddress,
        true
      );
      accounts.push(
        { pubkey: fromTokenMetadata, isSigner: false, isWritable: true }, // metadata
        { pubkey: vaultAddress, isSigner: false, isWritable: true }, // Recipient Vault
        { pubkey: vaultAta, isSigner: false, isWritable: true }, // Solana Mitter ATA Token account
        {
          pubkey: ASSOCIATED_TOKEN_PROGRAM_ID,
          isSigner: false,
          isWritable: false,
        } // Associated token program
      );
    } else {
      const metadataAddress = this.pda.getMetadataAddress(fromToken);
      const originInfoAccount =
        this.pda.getWrappedToOriginAddress(fromToken)[0];
      accounts.push(
        { pubkey: metadataAddress, isSigner: false, isWritable: false }, // nft token account
        { pubkey: originInfoAccount, isSigner: false, isWritable: false }
      );
    }

    const validator = await this.settings.getValidator();

    return this.createVersionedTransaction(
      sender,
      [
        Ed25519Program.createInstructionWithPublicKey({
          publicKey: validator.toBytes(),
          message: Uint8Array.from(msg),
          signature: signature,
        }),
        new TransactionInstruction({
          keys: accounts,
          programId: this.env.program,
          data: Buffer.from(instructionBuffer),
        }),
      ],
      await this.settings.getAddressLookupTable()
    );
  }

  public async wrappedNft(
    originChainId: number,
    originTokenAddress: string
  ): Promise<PublicKey> {
    const collectionAddress = await this.pda.getInfoToCollectionAddress(
      originChainId,
      originTokenAddress
    )[0];
    let accountInfo = await this.env.connection.getAccountInfo(
      collectionAddress
    );
    if (accountInfo == null) {
      throw new Error(`${collectionAddress} is not allocated`);
    }
    return new PublicKey(
      CollectionByOriginInfo.fromBuffer(accountInfo.data).wrappedCollection
    );
  }

  public async receive(
    payer: PublicKey,
    messageId: string,
    expires: bigint,
    originChain: number,
    originToken: string,
    fromChain: number,
    fromToken: string,
    sender: string,
    tokenId: string,
    recipient: PublicKey,
    metadataUri: string,
    signature: Uint8Array,
    isBatch: boolean,
    amount?: bigint
  ): Promise<VersionedTransaction> {
    const isNative = originChain == Const.SOLANA_CHAIN_ID;
    let tokenAddress;
    if (isNative) {
      const hexTokenId = new BigNumber(tokenId).toString(16);
      const tokenIdInBase58 = bs58.encode(fromHexString(hexTokenId));
      tokenAddress = new PublicKey(tokenIdInBase58);
    } else {
      tokenAddress = this.pda.getWrappedTokenAddress(
        originChain,
        originToken,
        tokenId
      )[0];
    }
    const metadataAddress = this.pda.getMetadataAddress(tokenAddress);
    const recipientAta = await getAssociatedTokenAddress(
      tokenAddress,
      recipient
    );

    // mapPda => store origin info
    const origin_map_info = this.pda.getWrappedToOriginAddress(tokenAddress)[0];
    const TO_CHAIN = Const.SOLANA_CHAIN_ID;
    let instructionBuffer;
    let msg;
    if (!isBatch) {
      let args = new ReceiveNFTArgs({
        instruction: isNative
          ? NftInstruction.ReceiveNativeNFT
          : NftInstruction.ReceiveWrappedNFT,
        messageId: messageId,
        expires: expires,
        originChain: originChain,
        originToken: originToken,
        fromChain: fromChain,
        fromToken: fromToken,
        toChain: TO_CHAIN,
        tokenId: tokenId,
        sender: sender,
        recipient: recipient.toString(),
        metadataUri: metadataUri,
        signature: signature,
      });
      instructionBuffer = args.toBuffer();
      msg = args.getMessage();
    } else {
      let args = new ReceiveNFTBatchArgs({
        instruction: isNative
          ? NftInstruction.ReceiveNativeNFTBatch
          : NftInstruction.ReceiveWrappedNFTBatch,
        messageId: messageId,
        expires: expires,
        originChain: originChain,
        originToken: originToken,
        fromChain: fromChain,
        fromToken: fromToken,
        toChain: TO_CHAIN,
        tokenId: tokenId,
        amount: amount,
        sender: sender,
        recipient: recipient.toString(),
        metadataUri: metadataUri,
        signature: signature,
      });
      instructionBuffer = args.toBuffer();
      msg = args.getMessage();
    }

    const minterAddress = this.pda.getMinterAddress()[0];
    const validator = await this.settings.getValidator();
    const settingsAccount = this.pda.getSettingsAddress()[0];
    const messageIdAccount = this.pda.getMessageIdAddress(messageId)[0];
    const wrappedCollectionMap = this.pda.getInfoToCollectionAddress(
      originChain,
      originToken
    )[0];

    const accounts = [
      { pubkey: tokenAddress, isSigner: false, isWritable: true }, // Mint account
      { pubkey: metadataAddress, isSigner: false, isWritable: true }, // Metadata Account
      { pubkey: recipient, isSigner: false, isWritable: false }, // Recipient
      { pubkey: recipientAta, isSigner: false, isWritable: true }, // holderATA

      { pubkey: messageIdAccount, isSigner: false, isWritable: true }, // MessageId account
      { pubkey: payer, isSigner: true, isWritable: true }, // Payer
      { pubkey: settingsAccount, isSigner: false, isWritable: false }, // Associated token program
      {
        pubkey: ASSOCIATED_TOKEN_PROGRAM_ID,
        isSigner: false,
        isWritable: false,
      }, // Associate Token Program
      {
        pubkey: SYSVAR_INSTRUCTIONS_PUBKEY,
        isSigner: false,
        isWritable: false,
      }, //SYSVAR_INSTRUCTIONS_PUBKEY
      { pubkey: TOKEN_PROGRAM_ID, isSigner: false, isWritable: false }, // Token program
      { pubkey: SystemProgram.programId, isSigner: false, isWritable: false }, // System program
    ];

    if (isNative) {
      const vaultAccount = this.pda.getVaultAddress()[0];
      const vaultAta = await getAssociatedTokenAddress(
        tokenAddress,
        vaultAccount,
        true
        // this.program.publicKey,
      );
      const vaultAddress = this.pda.getVaultAddress()[0];
      accounts.push(
        { pubkey: vaultAta, isSigner: false, isWritable: true }, // Owner Token account
        { pubkey: vaultAddress, isSigner: false, isWritable: false } // Owner
      );
    } else {
      const collectionAddress = await this.wrappedNft(originChain, originToken);
      const masterEditionAddress =
        this.pda.getMasterEditionAddress(collectionAddress)[0];
      const collectionMetadataAddress =
        this.pda.getMetadataAddress(collectionAddress);
      accounts.push(
        { pubkey: minterAddress, isSigner: false, isWritable: true }, //Mint Authority
        { pubkey: origin_map_info, isSigner: false, isWritable: true }, // Metadata Account
        { pubkey: collectionAddress, isSigner: false, isWritable: true }, // address for collectionNFT
        {
          pubkey: collectionMetadataAddress,
          isSigner: false,
          isWritable: true,
        }, // holderATA
        { pubkey: wrappedCollectionMap, isSigner: false, isWritable: false }, // holderATA
        { pubkey: masterEditionAddress, isSigner: false, isWritable: true }, // holderATA
        { pubkey: SYSVAR_RENT_PUBKEY, isSigner: false, isWritable: false }, // Rent
        {
          pubkey: Const.TOKEN_METADATA_PROGRAM_ID,
          isSigner: false,
          isWritable: false,
        } // Token metadata program
      );
    }

    return await this.createVersionedTransaction(
      payer,
      [
        Ed25519Program.createInstructionWithPublicKey({
          publicKey: validator.toBytes(),
          message: Uint8Array.from(msg),
          signature: signature,
        }),
        new TransactionInstruction({
          keys: accounts,
          programId: this.env.program,
          data: instructionBuffer,
        }),
      ],
      await this.settings.getAddressLookupTable()
    );
  }
}

const fromHexString = (hexString: any) =>
  Uint8Array.from(
    hexString.match(/.{1,2}/g).map((byte: any) => parseInt(byte, 16))
  );

export const TOKEN_TRANSFER_ACTIONS = {
  FROM_CHAIN_SELECT: 'FROM_CHAIN_SELECT',
  FROM_CHAIN_REMOVE: 'FROM_CHAIN_REMOVE',
  FROM_WALLET_SELECT: 'FROM_WALLET_SELECT',
  FROM_WALLET_REMOVE: 'FROM_WALLET_REMOVE',
  FROM_WALLET_ID_SELECT: 'FRoM_WALLET_ID_SELECT',
  FROM_WALLET_ID_REMOVE: 'FRoM_WALLET_ID_REMOVE',
  FROM_TOKEN_SELECT: 'FROM_TOKEN_SELECT',
  FROM_TOKEN_REMOVE: 'FROM_TOKEN_REMOVE',
  TO_CHAIN_SELECT: 'TO_CHAIN_SELECT',
  TO_CHAIN_REMOVE: 'TO_CHAIN_REMOVE',
  TO_WALLET_SELECT: 'TO_WALLET_SELECT',
  TO_WALLET_REMOVE: 'TO_WALLET_REMOVE',
  INPUT_TOKEN_BALANCE: 'INPUT_TOKEN_BALANCE',

  // SET txHash & Timestamp;
  FROM_CHAIN_TXHASH: 'FROM_CHAIN_TXHASH',
  FROM_CHAIN_TIMESTAMP: 'FROM_CHAIN_TIMESTAMP',
  TO_CHAIN_TIMESTAMP: 'FROM_CHAIN_TIMESTAMP',

  MESSAGE_STATUS: 'MESSAGE_STATUS',
  CHANGE_RESULT: 'CHANGE_RESULT',
  RESET: 'RESET',
  APTOS_DIRECT_TRANSFER: 'APTOS_DIRECT_TRANSFER',
};

export const NFTOKEN_TRANSFER_ACTIONS = {
  FROM_CHAIN_SELECT: 'FROM_NCHAIN_SELECT',
  FROM_CHAIN_REMOVE: 'FROM_NCHAIN_REMOVE',
  FROM_WALLET_SELECT: 'FROM_NWALLET_SELECT',
  FROM_WALLET_REMOVE: 'FROM_NWALLET_REMOVE',
  FROM_WALLET_ID_SELECT: 'FROM_NWALLET_ID_SELECT',
  FROM_WALLET_ID_REMOVE: 'FROM_NWALLET_ID_REMOVE',
  FROM_TOKEN_SELECT: 'FROM_NTOKEN_SELECT',
  FROM_TOKEN_REMOVE: 'FROM_NTOKEN_REMOVE',
  TO_CHAIN_SELECT: 'TO_NCHAIN_SELECT',
  TO_CHAIN_REMOVE: 'TO_NCHAIN_REMOVE',
  TO_WALLET_SELECT: 'TO_NWALLET_SELECT',
  TO_WALLET_REMOVE: 'TO_NWALLET_REMOVE',
  INPUT_TOKEN_BALANCE: 'INPUT_NTOKEN_BALANCE',
  RESET: 'NRESET',
};

export const TOKEN_TRANSFER_STEP = {
  SET_STEP_NUMBER: 'SET_STEP_NUMBER',
};

export const POLLING_STEP = {
  SET_POLLING_STEP: 'SET_POLLING_STEP',
};

export const NFT_LIST_ACTION = {
  SET_NFT_LIST: 'SET_NFT_LIST',
  ADD_NFT_LIST: 'ADD_NFT_LIST',
  ADD_ITEM: 'ADD_ITEM',
};

export const EXPLORER_SUM_ACTION = {
  SET_RANK_DATA: 'SET_RANK_DATA',
  SET_TRANSFER_DATA: 'SET_TRANSFER_DATA',
};

export const EXPLORER_CONFIG_ACTION = {
  SET_EXPLORER_DETAIL: 'SET_EXPLORER_DETAIL',
  REMOVE_EXPLORER_DETAIL: 'REMOVE_EXPLORER_DETAIL',
};
export const TOKEN_LIST_ACTION = {
  SET_TOKEN_LIST: 'SET_TOKEN_LIST',
};

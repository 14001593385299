enum NETWORK_ID {
  ICON = 1,
  BSC = 2,
  KLAYTN = 3,
  HAVAH = 4,
  ETHEREUM = 5,
  POLYGON = 6,
  APTOS = 8,
  BASE = 9,
  SOLANA = 10,
  MEVERSE = 101,
  WEMIX = 102,
  FNCY = 103,
  OKTC = 104,
  XPLA = 201,
}

enum WALLET_ID {
  METAMASK = 1,
  ICONEX = 2,
  HAVAH = 3,
  PETRA = 4,
  XPLA = 5,
  PHANTOM = 6,
}

enum NAVS {
  NFT = 0,
  FT = 1,
  EXPLORER = 2,
}

export { NETWORK_ID, WALLET_ID, NAVS };

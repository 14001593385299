import React, { useEffect, useState, useCallback, useContext } from 'react';
import { useParams } from 'react-router-dom';
import {
  requestTransferInfo,
  requestMetadata,
  requestNFTChainList,
  requestFTChainList,
  requestNFTInfo,
} from 'src/apis/api';
import { errCodeParser, protocolReplacer } from 'src/utils/utils';
import { ExplorerCompleteView, ToastModalContent } from 'src/components/index';
import { TransferDataType } from 'src/constants/types';
import { ERROR_MSG } from 'src/constants/codes';
import { ModalContext } from 'src/contexts/ToastModalContext';

const ExplorerDetailViewController: React.FC = () => {
  const { handleModal } = useContext(ModalContext);
  const { id = '' } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fromChainInfo, setFromChainInfo] = useState<any>({
    chainNo: 0,
    chianName: '',
    chainSymbol: '',
  });
  const [toChainInfo, setToChainInfo] = useState<any>({
    chainNo: 0,
    chianName: '',
    chainSymbol: '',
  });
  const [metadata, setMetadata] = useState<any>({
    image: null,
    name: null,
  });
  const [transferInfo, setTransferInfo] = useState<TransferDataType>({
    messageId: '',
    tokenType: '', // 1 : FT 2 : NFT
    fromChainNo: 0,
    originChainNo: 0,
    originChainScAddr: '',
    fromChainScAddr: '',
    nftTokenId: '',
    nftTokenIdString: '',
    fromTokenIdNo: 0,
    fromTxHash: '',
    fromTxDate: 0,
    senderAddress: '',
    toChainNo: 0,
    toChainScAddr: '',
    toTxHash: '',
    toTxDate: 0,
    recipientAddress: '',
    transferStatus: 0,
    commission: 0,
    fromChainTxFee: 0, // from 체인에서 실제로 발생한 TX Fee
    claimToChainTxFee: 0,
    tokenName: '',
    tokenSymbol: '',
    amount: 0,
  });

  const getMetadata = async (
    fromChainNo: number,
    contractAddr: string,
    tokenId: string | number
  ) => {
    try {
      const res = await requestMetadata(fromChainNo, contractAddr, tokenId);
      return res;
    } catch (e) {
      throw e;
    }
  };

  const getTransferInfo = useCallback(async () => {
    try {
      setIsLoading(true);
      let metadata;
      let contractInfo;
      const textValid = /^[a-f0-9]{16}$/;
      if (!textValid.test(id)) {
        throw Error;
      }
      const res: TransferDataType = await requestTransferInfo(id);
      setTransferInfo(res);
      // tokenType : "1" Token, "2" NFT
      const _tokenType = res.tokenType;

      const _fromChainNo = res.fromChainNo;
      const _originChainNo = res.originChainNo;
      const _originContract = res.originChainScAddr;
      const _nftTokenId = res?.nftTokenIdString ? res.nftTokenIdString : '';

      const _toChainNo = res?.toChainNo;

      if (_tokenType === '2') {
        const chainList: any[] = await requestNFTChainList();
        const fromChainInfos = chainList.filter(
          (el) => el.chainNo === _fromChainNo
        )[0];
        const toChainInfos = chainList.filter(
          (el) => el.chainNo === _toChainNo
        )[0];
        if (!!fromChainInfos) {
          setFromChainInfo({
            chainNo: fromChainInfos.chainNo,
            chainName: fromChainInfos.chainName,
            chainSymbol: fromChainInfos.chainSymbol,
          });
        }

        if (!!toChainInfos) {
          setToChainInfo({
            chainNo: toChainInfos.chainNo,
            chainName: toChainInfos.chainName,
            chainSymbol: toChainInfos.chainSymbol,
          });
        }

        const toLowerSc = _originContract.toLowerCase();
        contractInfo = await requestNFTInfo({
          chainNo: _originChainNo,
          address: toLowerSc,
          tokenId: _nftTokenId,
        });
        let name;
        let image;
        let _imgUrl;
        metadata = await getMetadata(_originChainNo, toLowerSc, _nftTokenId);
        if (!!metadata) {
          name = metadata?.name;
          image = metadata?.image;
          _imgUrl = protocolReplacer(!!image ? image : '');
        }
        setMetadata({
          name: !!name ? name : contractInfo.contractName,
          image: !!_imgUrl ? _imgUrl : '',
        });
      } else {
        const chainList: any[] = await requestFTChainList();
        const fromChainInfos = chainList.filter(
          (el) => el.chainNo === _fromChainNo
        )[0];
        const toChainInfos = chainList.filter(
          (el) => el.chainNo === _toChainNo
        )[0];
        if (!!fromChainInfos) {
          setFromChainInfo({
            chainNo: fromChainInfos.chainNo,
            chainName: fromChainInfos.chainName,
            chainSymbol: fromChainInfos.chainSymbol,
          });
        }

        if (!!toChainInfos) {
          setToChainInfo({
            chainNo: toChainInfos.chainNo,
            chainName: toChainInfos.chainName,
            chainSymbol: toChainInfos.chainSymbol,
          });
        }
      }
    } catch (e) {
      // const { retCode, retMsg } = errCodeParser(e);
      // const default_msg = !!retMsg
      //   ? retMsg
      //   : 'Unknown Error' + '\n Unknown error.';
      // const msg = !!ERROR_MSG[retCode] ? ERROR_MSG[retCode] : default_msg;
      // handleModal(<ToastModalContent type='warning' txt={msg} />);
      console.log(e);
      // throw e;
    } finally {
      setIsLoading(false);
    }
  }, [id]);

  useEffect(() => {
    if (!!id) {
      getTransferInfo();
    }
  }, [id, getTransferInfo]);

  return (
    <ExplorerCompleteView
      isLoading={isLoading}
      fromChainInfo={fromChainInfo}
      toChainInfo={toChainInfo}
      tokenMetadata={metadata}
      transferData={transferInfo}
    />
  );
};

export { ExplorerDetailViewController };

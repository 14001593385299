import React from 'react';
import styled from 'styled-components';
import { ImageLoader } from 'src/components/index';
import { theme } from 'src/styles/theme';
import { NETWORK_LOGO, SCAN_URL, TOKEN_LOGO } from 'src/constants/index';
import { commify, noExponential } from 'src/utils/utils';
import ic_exclude from 'src/assets/ic_exclude.svg';
import ic_info from 'src/assets/ic_yellow_info.svg';
import img_complete from 'src/assets/img_complete.svg';
import img_failed from 'src/assets/img_failed.svg';
import img_pending from 'src/assets/img_pending.gif';
import img_nft_default from 'src/assets/tokens/img_NFT_default_dark.png';
import img_token_default from 'src/assets/tokens/img_token_default_dark.png';
import logo_default from 'src/assets/chain/chain_default_dark.png';
import ani_pending from 'src/assets/img_pending_white.gif';
import { Approximate } from '../Common/Approximate';
import { NETWORK_ID } from 'src/constants/enums';

interface IProps {
  isLightMode?: boolean;
  config?: any;
  result?: any;
}

const BridgeCompleteView: React.FC<IProps> = (props) => {
  const { isLightMode, config, result } = props;

  const {
    fromChain,
    fromToken,
    toChain,
    fromWalletAddr,
    toWalletAddr,
    aptosDirectTransfer,
  } = config;

  const fromChainName = fromChain?.chainName;
  const fromChainSymbol = fromChain?.chainSymbol;
  const toChainName = toChain?.chainName;

  const fromChainNo = fromChain?.chainNo;
  const toChainNo = toChain?.chainNo;

  const tokenImg = fromToken?.tokenImage;
  const tokenName = fromToken?.tokenName;
  const tokenId = fromToken?.tokenId;
  const tokenSymbol = fromToken?.tokenSymbol;
  const tokenType = fromToken?.tokenType;
  const tokenCount = fromToken.transferTokenAmount;

  const isNFT = tokenType === 1 || tokenType === 2;

  const unit = isNFT ? fromChainSymbol : tokenSymbol;
  const fromTx = result?.fromChainTxHash;
  // const fromTxDate = !!result.fromTxDate ? result.fromTxDate : 0;
  // const fromDateStr = localeTimeSetter(new Date(fromTxDate * 1000).toISOString()).split("T");
  const toTx = result?.toChainTxHash;
  // const toTxDate = !!result.toTxDate ? result.toTxDate : 0;
  // const toDateStr = localeTimeSetter(new Date(toTxDate * 1000).toISOString()).split("T");

  const amount = result.amount;
  const commission = !!result.commission ? result.commission : 0;
  const txFee = !!result.txFee ? result.txFee : 0;
  const deployFee = !!result.deployFee ? result.deployFee : 0;
  const _calculatedTxFee = !!deployFee
    ? (
        (txFee * Math.pow(10, 7) - deployFee * Math.pow(10, 7)) *
        Math.pow(10, -7)
      ).toFixed(7)
    : txFee;
  // const fromTxFee = !!result.fromTxFee ? result.fromTxFee : 0;

  const _fromChainLogo = !!NETWORK_LOGO[fromChainNo]
    ? NETWORK_LOGO[fromChainNo]
    : logo_default;
  const _toChainLogo = !!NETWORK_LOGO[toChainNo]
    ? NETWORK_LOGO[toChainNo]
    : logo_default;
  const _tokenLogo = TOKEN_LOGO[tokenId];

  const status = result.messageStatus;

  const total_token = parseFloat(
    (
      (amount * Math.pow(10, 7) -
        (commission * Math.pow(10, 7) + txFee * Math.pow(10, 7))) *
      Math.pow(10, -1 * 7)
    ).toFixed(7)
  );
  const total_nft = parseFloat(
    (
      (commission * Math.pow(10, 7) + txFee * Math.pow(10, 7)) *
      Math.pow(10, -1 * 7)
    ).toFixed(7)
  );

  const noTxFee = txFee === 0;
  const noCommission = commission === 0;

  const isFinalizing =
    fromChainNo === NETWORK_ID.ETHEREUM ||
    fromChainNo === NETWORK_ID.POLYGON ||
    fromChainNo === NETWORK_ID.BASE;
  const isDev = process.env.REACT_APP_IS_DEV === 'dev';

  return (
    <StyledView className={isLightMode ? 'light' : 'dark'}>
      <div className='view-title'>
        {status === 4 && (
          <>
            <span className='pri-txt'>Transaction completed</span>
            <img src={img_complete} alt='complete' />
          </>
        )}
        {(status === 1 || status === 2 || status === 3) && (
          <>
            <span>Transaction requested</span>
            <img src={img_pending} alt='pending' />
          </>
        )}
        {(status === 0 || status >= 10) && (
          <>
            <span className='err-txt'>Transaction failed</span>
            <img src={img_failed} alt='failure' />
          </>
        )}
      </div>
      {isNFT ? (
        <div className='item-info'>
          <div className='img-box'>
            <ImageLoader url={tokenImg} img={img_nft_default} outline={true} />
          </div>
          <span className='txt-name'>{tokenName}</span>
          {fromChainNo !== 8 && (
            <>
              <div className='sh-divider'>|</div>
              <span className='txt-ellipsis'>#{tokenId}</span>
            </>
          )}
          {tokenCount != null && (
            <div className='nft-count'>
              {tokenCount <= 99999 ? (
                <div className='count-text'>x&nbsp;</div>
              ) : null}
              {tokenCount}
            </div>
          )}
        </div>
      ) : (
        <div className='item-info'>
          <div className='img-box'>
            <ImageLoader
              url={_tokenLogo}
              img={img_token_default}
              isNFT={false}
              outline={true}
            />
          </div>
          <span>{tokenName}</span>
          <div className='sh-divider'>|</div>
          <span className='bold txt-ellipsis'>
            {commify(amount.toString())}
          </span>
          <span>{tokenSymbol}</span>
        </div>
      )}

      <div className='divider' />
      <div className='transfer-info'>
        <div className='transfer-header'>
          <span>From</span>
        </div>
        <div className='transfer-content'>
          <img src={_fromChainLogo} alt='logo' />
          <span>{fromChainName}</span>
          <div className='address-box'>{fromWalletAddr}</div>
        </div>
        <div className='transfer-header'>
          <span>To</span>
        </div>
        <div className='transfer-content'>
          <img src={_toChainLogo} alt='logo' />
          <span>{toChainName}</span>
          <div className='address-box'>{toWalletAddr}</div>
        </div>
      </div>

      {!aptosDirectTransfer && toChainNo === 8 && (
        <div className='attention'>
          <img className='attention_ic' src={ic_info} alt='info' />
          <div className='info-text'>Attention</div>
          <div className='content'>
            <div className='top-text'>
              The receiving wallet’s ‘Receiving NFTs’ option is currently turned
              OFF. The option must be turned ON to receive the NFTs.
            </div>
            <div className='bottom-text'>
              In order to change the option, you can set this option in Settings
              &#62; Security &#38; Privacy &#62; Receiving NFTs in the wallets
              supporting Aptos. If you sent an NFT with the ‘Receive NFT’ option
              turned off, you can accept the NFT from the Pending List in the
              Library menu.
            </div>
          </div>
        </div>
      )}

      <div className='divider' />
      <div className='transaction-info'>
        <div className='info-header'>Source chain</div>
        <div className='tx-links'>
          <span>TX</span>
          <span
            className={!!fromTx ? '' : 'disabled'}
            onClick={() => {
              if (!!fromTx) {
                const addUrl =
                  isDev && fromChainNo === NETWORK_ID.APTOS
                    ? '?network=testnet'
                    : isDev && fromChainNo === NETWORK_ID.SOLANA
                    ? '?cluster=devnet'
                    : '';
                window.open(SCAN_URL[fromChainNo] + fromTx + addUrl);
              }
            }}
          >
            View TX
          </span>
        </div>
        <div className='tx-content'>
          <div className='tx-details'>
            <span>Status</span>
            {(status === 0 || !fromTx) && <span className='txt-r'>Fail</span>}
            {status === 1 && !!fromTx && !isFinalizing && (
              <>
                <span>Pending</span>
                <img src={ani_pending} alt='dots' />
              </>
            )}
            {status === 1 && !!fromTx && isFinalizing && (
              <>
                <span>Finalizing</span>
                <Approximate
                  timer={
                    fromChainNo === NETWORK_ID.BASE
                      ? 20
                      : fromChainNo === NETWORK_ID.ETHEREUM
                      ? 16
                      : 15
                  }
                />
              </>
            )}
            {status >= 2 && !!fromTx && <span>Success</span>}
          </div>
          <div className='tx-details'>
            {status === 0 && (
              <div className='failed-txt'>
                <img src={ic_exclude} alt='exclude' />
                <span>Transaction not found error.</span>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className='divider' />
      <div className='transaction-info'>
        <div className='info-header'>Destination chain</div>
        <div className='tx-links'>
          <span>TX</span>
          <span
            className={!!toTx ? '' : 'disabled'}
            onClick={() => {
              if (!!toTx) {
                const addUrl =
                  isDev && toChainNo === NETWORK_ID.APTOS
                    ? '?network=testnet'
                    : isDev && toChainNo === NETWORK_ID.SOLANA
                    ? '?cluster=devnet'
                    : '';
                window.open(SCAN_URL[toChainNo] + toTx + addUrl);
              }
            }}
          >
            View TX
          </span>
        </div>
        <div className='tx-content'>
          <div className='tx-details'>
            <span>Status</span>
            {(status >= 10 || status === 0) && (
              <span className='txt-r'>Fail</span>
            )}
            {(status === 1 || status === 2 || status === 3) && (
              <>
                <span>Pending</span>
                <img src={ani_pending} alt='dots' />
              </>
            )}
            {status === 4 && <span>Success</span>}
          </div>
          {/* status가 42이면 deploy 실패 케이스 */}
          <div className='tx-details'>
            {(status >= 10 || status === 0) && (
              <div className='failed-txt'>
                <img src={ic_exclude} alt='exclude' />

                {isNFT ? (
                  <span>
                    <span className='bold'>Temporary error!</span> <br />
                    The current target chain's tx fee budget is not enough.{' '}
                    <br />
                    However, your NFT will be sent to your wallet in 1-2 days.
                  </span>
                ) : (
                  <span>Transaction not found error.</span>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className='divider' />
      {isNFT ? (
        <>
          <div className='fee-info'>
            <div className='info-header'>Fee</div>
            <div className='fee-content'>
              <div className='fee-details'>
                <div className='fee-type'>
                  <span>Destination chain TX fee</span>
                </div>
                <div className='fee-amount'>
                  <span>{commify(noExponential(_calculatedTxFee))}</span>
                </div>
                <div className='fee-symbol'>
                  <span className='symbol'>{unit}</span>
                </div>
                {!!deployFee && (
                  <>
                    <div className='fee-type'>
                      <span>Deploy NFT Fee</span>
                    </div>
                    <div className='fee-amount'>
                      <span>{commify(noExponential(deployFee))}</span>
                    </div>
                    <div className='fee-symbol'>
                      <span className='symbol'>{unit}</span>
                    </div>
                  </>
                )}
                <div className='fee-type'>
                  <span>Bridge fee</span>
                </div>
                <div className='fee-amount'>
                  <span>{commify(noExponential(commission))}</span>
                </div>
                <div className='fee-symbol'>
                  <span className='symbol'>{unit}</span>
                </div>
              </div>
            </div>
          </div>
          <div className='divider' />
          <div className='fee-sum'>
            <div>Total fee</div>
            <div className='info'>
              <span className='amount'>{total_nft}</span>
              <span className='symbol'>{unit}</span>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className='fee-info'>
            <div className='info-header'>Amount{'&'}fee</div>
            <div className='fee-content'>
              <div className='fee-details'>
                <div className='fee-type'>
                  <span>Transfer Amount</span>
                </div>
                <div className='fee-amount'>
                  <span>{commify(noExponential(amount))}</span>
                </div>
                <div className='fee-symbol'>
                  <span className='symbol'>{unit}</span>
                </div>
                <div className='fee-type'>
                  <span>Destination chain TX fee</span>
                </div>
                <div className='fee-amount'>
                  <span>
                    {!noTxFee && '(-)'} {commify(noExponential(txFee))}
                  </span>
                </div>
                <div className='fee-symbol'>
                  <span className='symbol'>{unit}</span>
                </div>
                <div className='fee-type'>
                  <span>Bridge fee</span>
                </div>
                <div className='fee-amount'>
                  <span>
                    {!noCommission && '(-)'}{' '}
                    {commify(noExponential(commission))}
                  </span>
                </div>
                <div className='fee-symbol'>
                  <span className='symbol'>{unit}</span>
                </div>
              </div>
            </div>
          </div>
          <div className='divider' />
          <div className='fee-sum'>
            <div>Receivable Amount</div>
            <div className='info'>
              <span className='amount'>{commify(total_token.toString())}</span>
              <span className='symbol'>{unit}</span>
            </div>
          </div>
        </>
      )}
    </StyledView>
  );
};

const StyledView = styled.div`
  color: ${theme.mono1};
  & > div.view-title {
    ${theme.title1};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 30px;
    & > span {
      margin-bottom: 6px;
      line-height: 24px;
      &.pri-txt {
        color: ${theme.primaryTxt};
      }
      &.err-txt {
        color: ${theme.error};
      }
    }
    & > img {
      width: 68px;
      height: 68px;
    }
  }
  & > div.view-img {
    text-align: center;
    margin-bottom: 24px;
    & > img {
      vertical-align: middle;
    }
  }
  & > div.item-info {
    ${theme.body1};
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 14px 0px;
    & > div.img-box {
      width: 48px;
      height: 48px;
      margin-right: 14px;
    }
    & > div.sh-divider {
      width: 4px;
      height: 20px;
      color: ${theme.mono4};
      margin: 0px 10px;
    }
    & > span.bold {
      ${theme.tab1};
      margin-right: 4px;
    }
    & > span.txt-ellipsis {
      max-width: 160px;
      max-width: 208px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    & > span.txt-name {
      max-width: 208px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    & > div.nft-count {
      ${theme.tokenAmount}
      padding: 1px 4px;
      display: inline-flex;
      justify-content: center;
      height: 18px;
      margin-left: 10px;
      min-width: 20px;
      width: auto;
      background-color: rgba(22, 217, 146, 0.14);
      border-radius: 6px;
      color: ${theme.primaryTxt};
    }
  }
  & > div.attention {
    display: flex;
    margin: 24px 0px;
    border-radius: 6px;
    border: 1px solid rgba(255, 171, 67, 0.36);
    background: rgba(255, 171, 67, 0.06);
    & > img.attention_ic {
      margin: 14px 0px 0px 14px;
      width: 20px;
      height: 20px;
    }
    & > div.info-text {
      margin-left: 4px;
      margin-top: 14px;
      color: ${theme.attention1};
      ${theme.attention};
    }
    & > div.content {
      margin-left: 29px;
      margin-top: 14px;
      margin-bottom: 14px;
      width: 420px;
      & > div.top-text {
        ${theme.body1};
        color: ${theme.mono1};
      }
      & > div.bottom-text {
        ${theme.body2};
        color: ${theme.mono4};
      }
    }
  }
  & > div.transfer-info {
    ${theme.body1};
    margin: 24px 0px;
    & > div.transfer-header {
      color: ${theme.mono4};
      margin-bottom: 6px;
    }
    & > div.transfer-content {
      margin-bottom: 16px;
      & > span {
        vertical-align: middle;
      }
      & > img {
        width: 30px;
        height: 30px;
        border-radius: 6px;
        margin-right: 10px;
        vertical-align: middle;
      }
      & > div {
        width: 362px;
        padding: 7px 12px;
        border-radius: 6px;
        background-color: ${theme.mono8};
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        float: right;
        text-align: center;
      }
    }
  }
  & > div.transaction-info {
    ${theme.body1};
    margin: 0px 0px;
    & > div.info-header {
      margin-top: 40px;
      padding-bottom: 4px;
      border-bottom: 1px solid ${theme.mono1};
      margin-bottom: 12px;
    }
    & > div.tx-links {
      color: ${theme.mono4};
      margin-bottom: 10px;
      & > span:last-of-type {
        ${theme.body2}
        color : ${theme.primaryTxt};
        font-weight: 600;
        text-decoration: underline;
        float: right;
        cursor: pointer;
        &.disabled {
          cursor: default;
        }
      }
    }
    & > div.tx-content {
      & > div.tx-hash {
        width: 100%;
        height: auto;
        padding: 7px 12px;
        background-color: ${theme.mono8};
        border-radius: 6px;
        word-wrap: break-word;
        margin-bottom: 14px;
      }
      & > div.tx-details {
        margin-bottom: 10px;
        vertical-align: middle;
        & > span {
          color: ${theme.mono4};
        }
        &:last-of-type {
          margin-bottom: 0px;
        }
        & > img {
          width: 14px;
          height: 14px;
          float: right;
          margin-right: 8px;
          margin-top: 2px;
        }
        & > span:nth-child(2) {
          color: ${theme.mono1};
          font-weight: 900;
          float: right;
        }
        & > span:nth-child(3) {
          float: right;
        }
        & > span {
          ${theme.body1};

          &.txt-r {
            color: ${theme.error};
          }
          &.txt-g {
            color: ${theme.mono4};
          }
        }
        & > div.failed-txt {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: auto;
          min-height: 34px;
          background-color: rgba(255, 89, 89, 0.1);
          border-radius: 6px;
          margin-top: 14px;
          padding: 7px;
          & > img {
            width: 20px;
            height: 20px;
            margin-right: 10px;
          }
          & > span {
            text-align: center;
            color: ${theme.error};
            & > span.bold {
              font-weight: 900;
            }
          }
        }
      }
    }
  }
  & > div.fee-info {
    ${theme.body1};
    margin-top: 24px;
    & > div.info-header {
      margin-top: 40px;
      padding-bottom: 4px;
      border-bottom: 1px solid ${theme.mono1};
    }
    & > div.fee-header {
      color: ${theme.mono4};
      margin-bottom: 10px;
    }
    & > div.fee-content {
      display: flex;
      flex-direction: column;
      padding: 12px 0px;
      & > div.fee-details {
        display: grid;
        grid-template-columns: 210px 1fr auto;
        grid-template-rows: auto;
        row-gap: 8px;
        & > div {
          display: flex;
          align-items: center;
          color: ${theme.mono4};
        }
        & > div.fee-type {
          ${theme.body1};
          height: 20px;
        }
        & > div.fee-amount {
          font-weight: 900;
          justify-content: flex-end;
        }
        & > div.fee-symbol {
          margin-left: 6px;
          justify-content: flex-start;
        }
      }
    }
  }
  & > div.fee-sum {
    ${theme.tab1};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0px 0px;
    color: ${theme.mono1};
    & > div.title {
      font-weight: 900;
    }
    & > div.info {
      & > span:first-of-type {
        margin-right: 6px;
        ${theme.title1};
      }
      & > span.symbol {
        font-weight: 400;
      }
    }
  }
`;

export { BridgeCompleteView };

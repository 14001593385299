import React, {
  ReactNode,
  useRef,
  RefObject,
  useEffect,
  useState,
  useCallback,
} from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import PollingStepStore from 'src/stores/PollingStepStore';
import {
  Header,
  Footer,
  ServiceTitle,
  PollingModalWrapper,
} from 'src/components/index';
import bg from 'src/assets/bg_full_stardel_200.png';
import Banner from '../Banner/Banner';
import { requestConfig } from 'src/apis/api';
import { NETWORK_LOGO, TOKEN_LOGO } from 'src/constants/index';
import img_connect_to from 'src/assets/footer_link_icons/img_connect_discord.svg';

interface IProps {
  children: ReactNode;
  type?: 'bridge' | 'explorer';
  bannerTitle?: string;
  bannerSubtitle?: string;
  sectionColor?: string;
}
const isDev = process.env.REACT_APP_IS_DEV === 'dev';

const PageLayout: React.FC<IProps> = (props) => {
  const {
    children,
    type = 'bridge',
    bannerTitle,
    bannerSubtitle,
    sectionColor = '',
  } = props;

  const { pathname } = useLocation();
  const isDev = process.env.REACT_APP_IS_DEV === 'dev';

  const store = new PollingStepStore();
  const isBridgePage = type === 'bridge';
  const mainRef = useRef<HTMLElement>();
  const [isScrolled, setIsScrolled] = useState<boolean>(false);
  const [stepIdx, setStepIdx] = useState<number>(-1);
  const [isBannerOpen, setIsBannerOpen] = useState(false);

  const clickClose = () => {
    setIsBannerOpen(false);
  };

  useEffect(() => {
    const { current } = mainRef;
    if (current) {
      current.scrollTo(0, 0);
    }
  }, [pathname]);

  const startScroll = () => {
    const { current } = mainRef;
    if (current) {
      setIsScrolled(current?.scrollTop > 0);
    }
  };

  useEffect(() => {
    const { current } = mainRef;
    if (current) {
      current.addEventListener('scroll', startScroll);
      return () => current.removeEventListener('scroll', startScroll);
    }
  }, []);

  useEffect(() => {
    store.addChangeListener(_onChangeStep);
    return () => {
      store.removeChangeListener(_onChangeStep);
    };
  }, []);

  const getConfigData = useCallback(async () => {
    try {
      const result: any = await requestConfig();
      result.chains.map((chain: any) => {
        NETWORK_LOGO[chain.chainNo] = chain.chainImage;
      });

      result.tokens.map((token: any) => {
        TOKEN_LOGO[token.tokenIdNo] = token.tokenImage;
      });
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    getConfigData();
  }, [getConfigData]);

  const _onChangeStep = () => {
    setStepIdx(store.getPollingStep);
  };

  return (
    <Container isBannerOpen={isBannerOpen}>
      {isBannerOpen && <Banner clickClose={clickClose} />}
      <StyledPageLayout
        isBannerOpen={isBannerOpen}
        isBridgePage={isBridgePage}
        ref={mainRef as RefObject<HTMLDivElement>}
      >
        <Header isBridge={isBridgePage} isScrolled={isScrolled} />
        {!!bannerTitle && (
          <ServiceTitle
            isBridge={isBridgePage}
            bannerTitle={bannerTitle}
            bannerSubtitle={bannerSubtitle}
          />
        )}
        <StyledSection
          isBridge={isBridgePage}
          sectionColor={sectionColor}
          isBannerOpen={isBannerOpen}
        >
          <div>{children}</div>
          <ConnectDiscord
            className='connect-to-discord-btn'
            sectionColor={sectionColor}
          >
            <img
              onClick={() => {
                window.open('https://discord.gg/havahofficial');
              }}
              src={img_connect_to}
              alt='img-connect-discords'
            />
          </ConnectDiscord>
        </StyledSection>
        <Footer isBridge={isBridgePage} />
      </StyledPageLayout>
      {stepIdx >= 0 && <PollingModalWrapper stepIdx={stepIdx} />}
    </Container>
  );
};

const ConnectDiscord = styled.div<{ sectionColor: string }>`
  position: fixed;
  right: 50px;
  margin-left: auto;
  justify-content: center;
  display: flex;
  bottom: 10%;
  top: auto;
  width: 134px;
`;

const Container = styled.div<{ isBannerOpen: boolean }>`
  margin-top: ${({ isBannerOpen }) => (isBannerOpen ? '120px' : '')};
  ${({ isBannerOpen }) => (isBannerOpen ? 'transform: rotate(0)' : undefined)};
`;

const StyledPageLayout = styled.div<{
  isBridgePage: boolean;
  isBannerOpen: boolean;
}>`
  z-index: 9999;
  width: 100vw;
  height: ${({ isBannerOpen }) =>
    isBannerOpen ? `calc(100vh - 120px)` : '100vh'};
  overflow-y: scroll;
  padding-top: 60px;
  /* padding-bottom : 320px; */
  background: url(${bg}) center center no-repeat !important;
  background-size: cover;
  ::-webkit-scrollbar {
    width: 0px;
  }
  @media screen and (max-width: 1920px) {
    overflow-y: scroll;
    overflow-x: hidden;
  }
  @media screen and (min-height: 2000px) {
    background-size: 300% !important;
    background: url(${bg}) !important;
  }
  @media screen and (min-width: 2000px) {
    background-size: 300% !important;
    background: url(${bg}) center center !important;
  }
`;

const StyledSection = styled.section<{
  isBridge: boolean;
  sectionColor: string;
  isBannerOpen: boolean;
}>`
  position: relative;
  width: 100%;
  z-index: 1000;
  background-color: ${(props) => props.sectionColor};
  ${(props) =>
    props.isBridge
      ? props.isBannerOpen
        ? ` min-height : calc(100vh - 495px);`
        : `min-height : calc(100vh - 372px);`
      : ''}
`;

export { PageLayout };

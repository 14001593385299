import React from 'react';
import styled from 'styled-components';
import { Tooltip } from 'src/components/index';
import { ExplorerSummaryDataType } from 'src/constants/types';
import { NETWORK_LOGO, TOKEN_LOGO } from 'src/constants/index';
import { roundDownAt, commify } from 'src/utils/utils';
import { theme } from 'src/styles/theme';
import logo_default from 'src/assets/chain/chain_default.png';
import logo_token_default from 'src/assets/tokens/img_token_default.png';
import ic_arrow_r from 'src/assets/ic_arrow_right.svg';

type OnlyTopMoved = Pick<ExplorerSummaryDataType, 'topMoved'>;
type OnlyTopTokens = Pick<ExplorerSummaryDataType, 'topTokens'>;

interface IProps {
  isNFT?: boolean;
  txt: string;
  data: OnlyTopMoved & OnlyTopTokens;
}

const getTopMoved = (data: OnlyTopMoved & OnlyTopTokens, type: boolean) => {
  if (type && !!data.topMoved) {
    return data.topMoved;
  } else {
    return {
      amount: '0',
      from: 'Unknown',
      to: 'Unknown',
      fromChainNo: -1,
      toChainNo: -1,
    };
  }
};

const getTopTokens = (data: OnlyTopMoved & OnlyTopTokens, type: boolean) => {
  if (!type && !!data.topTokens) {
    return data.topTokens;
  } else {
    return {
      token: 'Unknown',
      amount: '0',
      usdt: '0',
      symbol: 'Unknown',
      tokenIdNo: -1,
    };
  }
};

const RankContentView: React.FC<IProps> = (props) => {
  const { isNFT = false, txt, data } = props;

  if (isNFT) {
    // const selectedData = ;
    const topMoved = getTopMoved(data, isNFT)!;
    const _fromLogo = NETWORK_LOGO[topMoved.fromChainNo] || logo_default;
    const _toLogo = NETWORK_LOGO[topMoved.toChainNo] || logo_default;
    return (
      <StyledView>
        <div className='rank-title'>
          <span>Top moved</span>
          <span>[{commify(topMoved.amount)} Bridges]</span>
          <Tooltip color={'#83898C'} txt={txt} font_weight={'400'} />
        </div>
        <div className='rank-desc'>
          <img src={_fromLogo} alt='logo' />
          <span>{topMoved.from}</span>
          <img className='arrow-r' src={ic_arrow_r} alt='arrow-r' />
          <img src={_toLogo} alt='logo' />
          <span>{topMoved.to}</span>
        </div>
      </StyledView>
    );
  } else {
    const topTokens = getTopTokens(data, isNFT)!;
    const tokenId = topTokens.tokenIdNo;
    const logo = TOKEN_LOGO[tokenId] || logo_token_default;
    return (
      <StyledView>
        <div className='rank-title'>
          <span>Top Tokens</span>
          <span>
            [{commify(roundDownAt(parseFloat(topTokens.usdt), 2))} USDT]
          </span>
          <Tooltip color={'#83898C'} txt={txt} font_weight={'400'} />
        </div>
        <div className='rank-desc'>
          <img src={logo} alt='logo' />
          <span>{commify(roundDownAt(parseFloat(topTokens.amount), 2))}</span>
          <span className='symbol'>{topTokens.symbol}</span>
        </div>
      </StyledView>
    );
  }
};

const StyledView = styled.div`
  border-radius: 6px;
  background-color: ${theme.mono8};
  padding: 24px 0px 28px 0px;
  & > div.rank-title {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 16px;
    & > span {
      ${theme.body1};
      color: ${theme.mono4};
      &:nth-child(1) {
        ${theme.title1};
        margin-right: 4px;
      }
      &:nth-child(2) {
        margin-right: 7px;
      }
    }
    & > img.bubble-info {
      vertical-align: middle;
    }
  }
  & > div.rank-desc {
    ${theme.headline3};
    color: ${theme.mono1};
    text-align: center;
    & > img {
      width: 30px;
      height: 30px;
      border-radius: 4px;
      vertical-align: middle;
      margin-right: 10px;
      &.arrow-r {
        margin: 0px 16px;
      }
    }
    & > span {
      vertical-align: middle;
      &.symbol {
        ${theme.tab1};
        color: ${theme.mono4};
        vertical-align: bottom;
        margin-left: 4px;
      }
    }
  }
`;

export { RankContentView };

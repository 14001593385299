import React, {
  SetStateAction,
  Dispatch,
  useContext,
  useState,
  useCallback,
  useEffect,
} from 'react';
import styled from 'styled-components';
import { ModalContext } from 'src/contexts/ModalContext';
import { ModalContext as ToastModalContext } from 'src/contexts/ToastModalContext';
import { ModalContext as ModalShadowContext } from 'src/contexts/ModalShadowContext';
// import BridgePageStore from "src/stores/BridgePageStore";
import { bridgePageAction } from 'src/actions/bridgePageAction';
import { stepAction } from 'src/actions/stepAction';
import { ListItemType } from 'src/constants/types';
import {
  getAddress,
  checkChainId,
  metamaskRequestAddChain,
  getMetaMaskProvider,
} from 'src/sc/metamask';
import { getAddrfromHAVAH, connectToHavah } from 'src/sc/havah';
import { connectPetra, getAddressFromAptos } from 'src/sc/aptos';
import { NETWORK_ID, WALLET_ID } from 'src/constants/enums';
import {
  METAMASK_CHAIN_ID,
  CHROME_EXTENSION_LINK,
  BRIDGE_CHAIN_NAME,
} from 'src/constants/index';
import { connectToSolana, getSolanaProvider } from 'src/sc/solana';
import { ERROR_MSG } from 'src/constants/codes';
import {
  Input,
  ToastModalContent,
  ModalWalletNotInstalled,
} from 'src/components/index';
import { theme } from 'src/styles/theme';
import { classNameBinder, errCodeParser } from 'src/utils/utils';

import ic_search from 'src/assets/ic_search.svg';
import ic_close from 'src/assets/ic_close.svg';
import ic_exclude from 'src/assets/ic_exclude.svg';
import logo_empty from 'src/assets/logo_empty.svg';
import ic_alarm from 'src/assets/img_alert.svg';
import { getAddressFromKeplr, getKeplr } from 'src/sc/keplr';
import { useMetamask } from 'src/hooks/useMetamask';
import stepStore from 'src/stores/StepStore';
import { useLocation } from 'react-router-dom';

interface IProps {
  chainId?: number;
  list: any[];
  chainInfo?: any;
  withInput?: boolean;
  saveBtn?: (val: string) => void;
}

interface IToggleProps {
  toggle: number;
  setToggle: Dispatch<SetStateAction<number>>;
}

const ModalWalletList: React.FC<IProps> = (props) => {
  const { list, chainInfo, withInput = false, saveBtn } = props;

  const [isWrong, setIsWrong] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useState<string | number>('');
  const [selectedIdx, setSelectedIdx] = useState<number>(0);
  const [selectedItemIdx, setSelectedItemIdx] = useState<number>(-1);
  const [inputValue, setInputValue] = useState<any>('');
  const [walletList, setWalletList] = useState<any[]>(list);

  const { handleModal } = useContext(ModalContext);
  const shadowModal = useContext(ModalShadowContext);
  const toastModal = useContext(ToastModalContext);
  const hasNoList = list.length === 0;
  const isDev = process.env.REACT_APP_IS_DEV === 'dev';
  const net = isDev ? 'testnet' : 'mainnet';
  const {
    connectMetamask,
    requestAddChain,
    switchEthereumChain,
    getMetamaskInstalled,
  } = useMetamask();
  const { pathname } = useLocation();
  const isNFT = pathname.includes('/bridge/nft');

  const directToExtensionLink = (key: string) => {
    window.open(CHROME_EXTENSION_LINK[key]);
  };

  const requestMetamask = useCallback(
    async (walletId?: number) => {
      let address;
      let _walletId = walletId || -1;

      try {
        const checkInstalled = await getMetamaskInstalled();
        if (checkInstalled === false) {
          directToExtensionLink('metamask');
          shadowModal.handleModal(
            <ModalWalletNotInstalled close={() => shadowModal.handleModal()} />
          );
          throw new Error(JSON.stringify({ code: 'M001' }));
        }
        const metamaskChainId = METAMASK_CHAIN_ID[chainInfo.chainNo];
        address = await connectMetamask(isNFT);
        if (address !== '') {
          const stepNumber = stepStore.getStepNumber();
          if (withInput) {
            if (selectedIdx === 0 && stepNumber === (isNFT ? 2 : 4)) {
              bridgePageAction._changeToWallet(address);
            }
          } else {
            if (stepNumber === 0) {
              await switchEthereumChain(metamaskChainId);
              bridgePageAction._changeWeb3WalletID(_walletId);
              bridgePageAction._changeFromWallet(address);
              stepAction._changeStep(1);
            }
          }
        } else {
          throw new Error(JSON.stringify({ code: 'nothing' }));
        }
      } catch (e) {
        console.log(e);
        const { retCode, retMsg } = errCodeParser(e);
        if (retCode === '4902') {
          try {
            const metamaskChainId = METAMASK_CHAIN_ID[chainInfo.chainNo];
            await requestAddChain(
              metamaskChainId,
              chainInfo.chainName,
              chainInfo.endpoint
            );
          } catch (e) {
            console.log(e);
          }
          await requestMetamask(_walletId);
          // address = await connectMetamask(metamaskChainId);
        } else {
          toastModal.handleModal(
            <ToastModalContent type='warning' txt={retMsg} />
          );
        }
        throw e;
      }
    },
    [
      chainInfo,
      selectedIdx,
      withInput,
      toastModal,
      isNFT,
      shadowModal,
      connectMetamask,
      requestAddChain,
      switchEthereumChain,
      getMetamaskInstalled,
    ]
  );

  const requestHavah = useCallback(
    async (walletId?: number) => {
      try {
        let returns = { address: '', nid: '' };
        let _walletId = walletId || -1;
        if (!window.havah) {
          directToExtensionLink('havah');
          shadowModal.handleModal(
            <ModalWalletNotInstalled close={() => shadowModal.handleModal()} />
          );
          throw new Error(JSON.stringify({ code: 'nothing' }));
        }

        if (withInput) {
          if (selectedIdx === 0) {
            await connectToHavah().then(async () => {
              returns = await getAddrfromHAVAH();
            });
            const _address = returns.address;
            bridgePageAction._changeToWallet(_address);
          }
        } else {
          const chainNo = chainInfo.chainNo;
          await connectToHavah().then(async () => {
            returns = await getAddrfromHAVAH();
          });
          const address = returns.address;
          const nid = returns.nid;
          const havahChainId = METAMASK_CHAIN_ID[chainNo];
          const hexChainId = '0x' + havahChainId.toString(16);

          if (!nid || hexChainId !== nid) {
            throw new Error(JSON.stringify({ code: 'H002' }));
          }
          if (!!address) {
            bridgePageAction._changeWeb3WalletID(_walletId);
            bridgePageAction._changeFromWallet(address);
            stepAction._changeStep(1);
          }
        }
      } catch (e) {
        const { retCode, retMsg } = errCodeParser(e);
        if (retCode !== 'nothing') {
          toastModal.handleModal(
            <ToastModalContent type='warning' txt={retMsg} />
          );
        }
        throw e;
      }
    },
    [chainInfo, selectedIdx, withInput, shadowModal, toastModal]
  );

  const requestPetra = useCallback(
    async (walletId?: number) => {
      try {
        let address;
        let _walletId = walletId || -1;
        if (!window.aptos) {
          directToExtensionLink('petra');
          shadowModal.handleModal(
            <ModalWalletNotInstalled close={() => shadowModal.handleModal()} />
          );
          throw new Error(JSON.stringify({ code: 'nothing' }));
        }
        if (withInput) {
          if (selectedIdx === 0) {
            await connectPetra();
            address = await getAddressFromAptos();
            bridgePageAction._changeToWallet(address);
          }
        } else {
          await connectPetra();
          address = await getAddressFromAptos();
          bridgePageAction._changeWeb3WalletID(_walletId);
          bridgePageAction._changeFromWallet(address);
          stepAction._changeStep(1);
        }
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
    [selectedIdx, shadowModal, withInput]
  );

  const requestXPLA = useCallback(
    async (walletId?: number) => {
      try {
        let address;
        let _walletId = walletId || -1;

        const keplr = await getKeplr(BRIDGE_CHAIN_NAME[NETWORK_ID.XPLA]);
        if (!keplr) {
          directToExtensionLink('keplr');
          shadowModal.handleModal(
            <ModalWalletNotInstalled close={() => shadowModal.handleModal()} />
          );
          throw new Error(JSON.stringify({ code: 'nothing' }));
        }
        if (withInput) {
          if (selectedIdx === 0) {
            address = await getAddressFromKeplr(
              BRIDGE_CHAIN_NAME[NETWORK_ID.XPLA]
            );
            bridgePageAction._changeToWallet(address);
          }
        } else {
          address = await getAddressFromKeplr(
            BRIDGE_CHAIN_NAME[NETWORK_ID.XPLA]
          );
          bridgePageAction._changeWeb3WalletID(_walletId);
          bridgePageAction._changeFromWallet(address);
          stepAction._changeStep(1);
        }
      } catch (e) {
        const { retCode, retMsg } = errCodeParser(e);
        if (retCode !== 'nothing') {
          if (retCode === 'P200') {
            throw e;
          }
          toastModal.handleModal(
            <ToastModalContent type='warning' txt={retMsg} />
          );
        }
        throw e;
      }
    },
    [selectedIdx, shadowModal, withInput, toastModal]
  );

  const requestPhantom = useCallback(
    async (walletId?: number) => {
      let address;
      let _walletId = walletId || -1;

      try {
        const provider = getSolanaProvider();
        if (provider === undefined) {
          directToExtensionLink('phantom');
          shadowModal.handleModal(
            <ModalWalletNotInstalled close={() => shadowModal.handleModal()} />
          );
          throw new Error(JSON.stringify({ code: 'nothing' }));
        }
        if (provider != null && !provider.isPhantom) {
          const msg = ERROR_MSG['WALLET_NOT_SUPPORTED'];
          toastModal.handleModal(
            <ToastModalContent type='warning' txt={msg} />
          );
          throw new Error(JSON.stringify({ code: 'nothing' }));
        }

        if (withInput) {
          if (selectedIdx === 0) {
            const rsp = await connectToSolana();
            bridgePageAction._changeToWallet(rsp.publicKey.toString());
          }
        } else {
          const rsp = await connectToSolana();
          bridgePageAction._changeWeb3WalletID(_walletId);
          bridgePageAction._changeFromWallet(rsp.publicKey.toString());
          stepAction._changeStep(1);
        }
      } catch (e) {
        const { retCode, retMsg } = errCodeParser(e);

        const msg = !!ERROR_MSG[retCode] ? ERROR_MSG[retCode] : retMsg;
        toastModal.handleModal(<ToastModalContent type='warning' txt={msg} />);
      }
    },
    [selectedIdx, withInput, shadowModal, toastModal]
  );

  const handleOnSelectWallet = useCallback(
    async (el: ListItemType) => {
      try {
        const { value } = el;
        const _valNum = value as number;
        // For Destination Chain
        if (value === WALLET_ID.METAMASK) {
          await requestMetamask(_valNum);
          toastModal.handleModal(
            <ToastModalContent
              type='connect'
              txt='Wallet connected successfully'
            />
          );
        }
        if (value === WALLET_ID.HAVAH) {
          await requestHavah(_valNum);
          toastModal.handleModal(
            <ToastModalContent
              type='connect'
              txt='Wallet connected successfully'
            />
          );
        }
        //
        if (value === WALLET_ID.PETRA) {
          await requestPetra(_valNum);
          toastModal.handleModal(
            <ToastModalContent
              type='connect'
              txt='Wallet connected successfully'
            />
          );
        }

        if (value === WALLET_ID.XPLA) {
          await requestXPLA(_valNum);
          toastModal.handleModal(
            <ToastModalContent
              type='connect'
              txt='Wallet connected successfully'
            />
          );
        }
        // For Source Chain
        if (value === WALLET_ID.PHANTOM) {
          await requestPhantom(_valNum);
        }
        // For Source Chain
        toastModal.handleModal(
          <ToastModalContent
            type='connect'
            txt='Wallet connected successfully'
          />
        );
      } catch (e: any) {
        console.log(e);
      } finally {
        handleModal();
      }
    },
    [
      handleModal,
      toastModal,
      requestHavah,
      requestMetamask,
      requestPetra,
      requestXPLA,
      requestPhantom,
    ]
  );

  useEffect(() => {
    const _searchParams = searchParams as string;
    const newList = list.filter((el) => {
      const { name } = el;
      if (name.toUpperCase().includes(_searchParams.toUpperCase())) {
        return el;
      }
    });
    setWalletList(newList);
  }, [searchParams, list]);

  const addressValidator = useCallback(
    (str: string) => {
      const chainNo = chainInfo.chainNo;

      if (
        chainNo === NETWORK_ID.HAVAH &&
        !str.startsWith('cx') &&
        !str.startsWith('hx')
      ) {
        return false;
      } else if (
        chainNo !== NETWORK_ID.XPLA &&
        chainNo !== NETWORK_ID.HAVAH &&
        chainNo !== NETWORK_ID.SOLANA &&
        !str.startsWith('0x')
      ) {
        return false;
      } else if (chainNo === NETWORK_ID.XPLA && str.length !== 43) {
        return false;
      } else if (chainNo === NETWORK_ID.SOLANA && str.length !== 44) {
        return false;
      } else if (chainNo === NETWORK_ID.APTOS && str.length !== 66) {
        return false;
      } else if (
        chainNo !== NETWORK_ID.XPLA &&
        chainNo !== NETWORK_ID.APTOS &&
        chainNo !== NETWORK_ID.SOLANA &&
        str.length !== 42
      ) {
        return false;
      }
      return true;
    },
    [chainInfo]
  );

  useEffect(() => {
    if (inputValue.length >= 0) {
      setIsWrong(false);
    }
  }, [inputValue]);

  return (
    <StyledModal>
      {withInput && (
        <ModalToggler toggle={selectedIdx} setToggle={setSelectedIdx} />
      )}
      {selectedIdx === 0 && (
        <div className='input-area'>
          <Input
            hasIcon={false}
            placeholder={'Search by Wallet'}
            value={searchParams}
            setValue={setSearchParams}
          />
          {!!searchParams ? (
            <img
              id='close-ic'
              src={ic_close}
              alt='close'
              onClick={() => setSearchParams('')}
            />
          ) : (
            <img src={ic_search} alt='search' />
          )}
        </div>
      )}
      {selectedIdx === 1 && (
        <>
          <div className='input-view'>
            <div className='input-area'>
              <Input
                hasIcon={false}
                placeholder={'Input your wallet address'}
                value={inputValue}
                setValue={setInputValue}
              />
            </div>
            {isWrong && (
              <div className='warning-txt'>
                Invalid Wallet Address. Please check the address and try again.
              </div>
            )}
            <div className='warning'>
              <div className='red'>CAUTION</div>
              <div>
                Please don't input a crypto exchange deposit address. Some
                exchanges don't allow a transfer via smart contract.
              </div>
              <img className='alarm' src={ic_alarm} alt='warning' />
            </div>
          </div>
          <div
            onClick={() => {
              if (inputValue) {
                const isError = addressValidator(inputValue);
                if (!isError) {
                  setIsWrong(true);
                  return;
                }
                if (!!saveBtn) {
                  saveBtn(inputValue);
                  toastModal.handleModal(
                    <ToastModalContent
                      type='connect'
                      txt='Wallet connected successfully'
                    />
                  );
                  handleModal();
                }
              }
            }}
            className={inputValue ? 'btn-view active' : 'btn-view'}
          >
            <span>Save</span>
          </div>
        </>
      )}
      {!hasNoList && selectedIdx === 0 && (
        <div className='list-view with-list'>
          {walletList.map((el: ListItemType, idx: number) => {
            const { icon = '', name } = el;
            const props = {
              prefix: '',
              suffix: 'active',
              cond: idx === selectedItemIdx,
            };
            return (
              <div
                key={idx.toString()}
                className={'list-item ' + classNameBinder(props)}
                onClick={() => {
                  setSelectedItemIdx(idx);
                  handleOnSelectWallet(el);
                }}
              >
                <img src={icon} alt='icon' />
                <span>{name}</span>
              </div>
            );
          })}
        </div>
      )}
      {hasNoList && selectedIdx === 0 && (
        <div className='list-view no-list'>
          <img src={logo_empty} alt='empty' />
          <span>No Wallet found in this chain</span>
        </div>
      )}
    </StyledModal>
  );
};

const ModalToggler: React.FC<IToggleProps> = ({ toggle, setToggle }) => {
  return (
    <StyledToggler>
      <div
        onClick={() => setToggle(0)}
        className={toggle === 0 ? 'active' : ''}
      >
        Selected Wallet
      </div>
      <div
        onClick={() => setToggle(1)}
        className={toggle === 1 ? 'active' : ''}
      >
        Input Address
      </div>
    </StyledToggler>
  );
};

const StyledToggler = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 20px;
  margin-bottom: 16px;
  padding: 0px 24px;
  border-bottom: 1px solid ${theme.mono5};
  & > div {
    position: relative;
    top: 1px;
    ${theme.body1};
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
    color: ${theme.mono1};
    cursor: pointer;
    &.active {
      ${theme.tab1};
      color: ${theme.primaryBG};
      border-bottom: 2px solid ${theme.primaryBG};
    }
  }
`;

const StyledModal = styled.div`
  width: 442px;
  height: auto;
  background-color: ${theme.mono6};
  border-radius: 6px;

  & > div.btn-view {
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${theme.mono4};
    border-top: 1px solid ${theme.mono5};
    width: 100%;
    height: 44px;
    cursor: pointer;
    &.active {
      background-color: ${theme.primaryTxt};
      color: ${theme.mono1};
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      &:hover {
        background-color: ${theme.primaryBG};
      }
    }
  }
  & > div.input-area {
    position: relative;
    background-color: ${theme.mono7};
    border-radius: 6px;
    margin: 16px 24px 8px;
    padding: 14px;
    & > img {
      position: absolute;
      top: 14px;
      right: 14px;
      &#close-ic {
        cursor: pointer;
      }
    }
  }
  & > div.input-view {
    position: relative;
    width: 100%;
    height: 286px;
    border-radius: 6px;
    padding: 0px 24px;

    & > div.input-area {
      background-color: ${theme.mono7};
      padding: 14px;
      border-radius: 6px;
      margin-bottom: 12px;
      &::placeholder {
        color: ${theme.mono4};
      }
      &:focus {
        outline: none;
      }
    }
    & > div.warning-txt {
      ${theme.body2};
      padding-left: 26px;
      background: url(${ic_exclude}) center left 0px no-repeat;
      background-size: 20px 20px;
      margin-bottom: 29px;
      color: ${theme.error};
    }
    & > div.warning {
      ${theme.body2};
      position: absolute;
      width: calc(100% - 48px);
      bottom: 24px;
      border-radius: 6px;
      border: 1px solid rgba(255, 89, 89, 0.5);
      background-color: rgba(255, 89, 89, 0.08);
      padding: 14px 14px 30px;
      color: ${theme.mono2};
      & > div.red {
        color: ${theme.error};
        font-weight: 700;
      }
      & > img {
        position: absolute;
        right: 20px;
        bottom: -6px;
      }
    }
  }
  & > div.list-view {
    height: 250px;
    border-radius: 6px;
    margin: 0px 24px 24px;
    background-color: ${theme.mono7};
    & > div.list-item {
      ${theme.body2};
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: ${theme.mono1};
      text-align: center;
      margin: 5px 11px;
      cursor: pointer;
      & > img {
        width: 40px;
        height: 40px;
        margin-bottom: 6px;
      }
    }
    &.with-list {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: repeat(auto-fill, 100px);
      overflow-y: scroll;
      &::-webkit-scrollbar {
        background-color: transparent;
        width: 0px;
      }
      & > div {
        width: auto;
        height: 90px;
        & > img {
          border-radius: 4px;
        }
        &:hover {
          border-radius: 6px;
          border: 1px solid ${theme.primaryBG};
        }
        &.active {
          border-radius: 6px;
          border: 1px solid ${theme.primaryBG};
        }
      }
    }
    &.no-list {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      & > img {
        width: 105px;
        height: 56px;
        margin-bottom: 20px;
      }
      & > span {
        color: ${theme.mono4};
      }
    }
  }
  & > div > input {
    border: none;
  }
`;

export { ModalWalletList };

import { pollingStepAction } from 'src/actions/pollingModalAction';
import { stepAction } from 'src/actions/stepAction';

type Timer = {
  txHash: string;
  func: (txHash: string) => Promise<number>;
  next?: (txHash?: string) => any;
  idx?: number;
};

const usePolling = () => {
  const handleAction = () => {
    pollingStepAction._changePollingStep(-1);
    stepAction._changeStep(6);
  };

  const setPolling = async ({ txHash, func, next, idx = 0 }: Timer) => {
    try {
      if (idx > 60) {
        handleAction();
        return;
      }

      const status = await func(txHash);
      if (status === 0 || status === 10) {
        handleAction();
        return;
      } else if (status === 1) {
        if (!!next) {
          next(txHash);
        } else {
          handleAction();
        }
        return;
      }
      const timerId = setTimeout(async () => {
        setPolling({
          txHash,
          func,
          idx: idx + 1,
          next,
        });
      }, 1000);
      return () => clearTimeout(timerId);
    } catch (e) {
      pollingStepAction._changePollingStep(-1);
      throw e;
    }
  };

  return {
    setPolling,
  };
};

export { usePolling };

import React from 'react';
import styled from 'styled-components';
import { ImageLoader } from 'src/components/index';
import bridgePageStore from 'src/stores/BridgePageStore';
import { NETWORK_LOGO } from 'src/constants';
import { commify, noExponential } from 'src/utils/utils';
import { theme } from 'src/styles/theme';
import ic_arrow_right from 'src/assets/ic_arrow_right.svg';
import img_nft_default from 'src/assets/tokens/img_NFT_default_dark.png';
import { ChainConfig, FromFTConfig, FromNFTConfig } from 'src/constants/types';
import logo_default from 'src/assets/chain/chain_default_dark.png';
import { classNameBinder } from 'src/utils/utils';
import { Tooltip } from 'src/components/index';
type TokenType = FromFTConfig | FromNFTConfig;

interface IProps {
  msg: any;
}

interface FeeProps {
  balance?: string;
  commission: number;
  txFee: number;
  deployFee?: number;
  symbol?: string;
  isNFT?: boolean;
}

interface DividerProps {
  mgb?: number;
  mgt?: number;
}

interface SelectedNFTProps {
  img: string;
  name: string;
  symbol?: string;
  tokenId: string;
  tokenCount: number;
}

interface TotalProps {
  isNFT: boolean;
  total: string;
  symbol?: string;
}

const ComponentTokenFee: React.FC<FeeProps> = ({
  balance = '0',
  commission,
  txFee,
  symbol,
  isNFT = false,
}) => {
  const isTxFeeErr = txFee < 0;
  const isBridgeFeeErr = commission < 0;
  const noTxFee = txFee <= 0;
  const noBridgeFee = commission <= 0;

  return (
    <StyledFeeArea isNFT={isNFT}>
      <div>
        <span className='title'>Amount {'&'} fee</span>
      </div>
      <div className='fee-content'>
        <div className='fee-header'>
          <span>Transfer amount</span>
        </div>
        <div className='fee-amount'>
          <span className='font-bold'>{balance}</span>
        </div>
        <div className='fee-symbol'>
          <span>{symbol}</span>
        </div>
        <div className='fee-header'>
          <span>Destination chain TX fee</span>
        </div>
        <div className='fee-amount'>
          <span className='font-bold'>
            {!noTxFee && '(-)'}{' '}
            {isTxFeeErr ? '-' : commify(noExponential(txFee))}
          </span>
        </div>
        <div className='fee-symbol'>
          <span>{symbol}</span>
        </div>
        <div className='fee-header'>
          <span>Bridge fee</span>
        </div>
        <div className='fee-amount'>
          <span className='font-bold'>
            {!noBridgeFee && '(-)'}{' '}
            {isBridgeFeeErr ? '-' : commify(noExponential(commission))}
          </span>
        </div>
        <div className='fee-symbol'>
          <span>{symbol}</span>
        </div>
      </div>
    </StyledFeeArea>
  );
};

const ComponentNFTFee: React.FC<FeeProps> = ({
  commission,
  txFee,
  deployFee = 0,
  symbol,
  isNFT = true,
}) => {
  const isTxFeeErr = txFee < 0;
  const isBridgeFeeErr = commission < 0;
  return (
    <StyledFeeArea isNFT={isNFT}>
      <div>
        <span className='title'>Fee</span>
      </div>
      <div className='fee-content'>
        <div className='fee-header'>
          <span>Destination chain TX fee</span>
        </div>
        <div className='fee-amount'>
          <span className='font-bold'>
            {isTxFeeErr ? '-' : commify(noExponential(txFee))}
          </span>
        </div>
        <div className='fee-symbol'>
          <span>{symbol}</span>
        </div>
        {!!deployFee && (
          <>
            <div className='fee-header'>
              <span>Deploy fee</span>
            </div>
            <div className='fee-amount'>
              <span className='font-bold'>
                {commify(noExponential(deployFee))}
              </span>
            </div>
            <div className='fee-symbol'>
              <span>{symbol}</span>
            </div>
          </>
        )}
        <div className='fee-header'>
          <span>Bridge fee</span>
        </div>
        <div className='fee-amount'>
          <span className='font-bold'>
            {isBridgeFeeErr ? '-' : commify(noExponential(commission))}
          </span>
        </div>
        <div className='fee-symbol'>
          <span>{symbol}</span>
        </div>
      </div>
    </StyledFeeArea>
  );
};

const Divider: React.FC<DividerProps> = ({ mgb = 0, mgt = 0 }) => {
  return <StyledDivider mgb={mgb} mgt={mgt} />;
};

const ComponentSelectedNFT: React.FC<SelectedNFTProps> = ({
  img,
  name,
  symbol,
  tokenId,
  tokenCount,
}) => {
  return (
    <StyledSelected>
      <div className='selected-title'>
        <span className='title'>Selected NFT</span>
      </div>
      <div className='selected-info'>
        <div className='img-box'>
          <ImageLoader
            url={img}
            isNFT={true}
            img={img_nft_default}
            outline={true}
          />
        </div>
        <span className='name'>{name}</span>
        {!!tokenId && (
          <>
            <div className='sh-divider'> | </div>
            <span>#</span>
            <span className='txt-ellipsis'>{tokenId}</span>
          </>
        )}
        {tokenCount != null && (
          <div className='nft-count'>
            {tokenCount <= 99999 ? (
              <div className='count-text'>x&nbsp;</div>
            ) : null}
            {tokenCount}
          </div>
        )}
      </div>
    </StyledSelected>
  );
};

const ComponentFeeTotal: React.FC<TotalProps> = ({ isNFT, total, symbol }) => {
  return (
    <StyledSum
      className={classNameBinder({
        prefix: '',
        suffix: 'red',
        cond: parseFloat(total) < 0,
      })}
    >
      <div className='title-area'>
        <div className='title'>{isNFT ? 'Total fee' : 'Receivable amount'}</div>
        {parseFloat(total) < 0 ? (
          <Tooltip
            color={theme.subpink}
            iconColor={theme.error}
            txt={'Total fee exceeds transfer amount'}
            font_weight={'700'}
          />
        ) : (
          ''
        )}
      </div>

      <div className='info'>
        <span className='amount'>
          {isNaN(parseFloat(total))
            ? commify(total)
            : commify(parseFloat(total).toString())}
        </span>
        <span className='symbol'>{symbol}</span>
      </div>
    </StyledSum>
  );
};

const feeCalculator = (commission: number, txFee: number, balance?: number) => {
  if (!!balance) {
    const _txFee = txFee < 0 ? 0 : txFee;
    const _commission = commission < 0 ? 0 : commission;
    return (
      (balance * Math.pow(10, 7) -
        _commission * Math.pow(10, 7) -
        _txFee * Math.pow(10, 7)) *
      Math.pow(10, -1 * 7)
    ).toFixed(7);
  } else {
    if (txFee < 0 || commission < 0) {
      return '-';
    }
    return (
      (commission * Math.pow(10, 7) + txFee * Math.pow(10, 7)) *
      Math.pow(10, -1 * 7)
    ).toFixed(7);
  }
};

const BridgeSummaryView: React.FC<IProps> = (props) => {
  const {
    msg,
    // sourceFee = 0
  } = props;
  const store = bridgePageStore;
  const config = store.getBridgeConfig();
  const { fromWalletAddr, toWalletAddr, balance } = config;

  const commission = msg.commission;
  const txFee = msg.txFee; // deploy + destinationTx fee
  const deployFee = msg.deployWrapped?.deployFee || 0;
  const _calculatedTxFee = !!deployFee
    ? (
        (txFee * Math.pow(10, 7) - Math.pow(10, 7) * deployFee) *
        Math.pow(10, -7)
      ).toFixed(7)
    : txFee;
  const _balance = parseFloat(balance.replaceAll(',', ''));
  const total_token = feeCalculator(commission, txFee, _balance);
  const total_nft = feeCalculator(commission, txFee);
  const fromToken = config.fromToken as TokenType;
  const isFT = typeof fromToken.tokenType === 'string';

  const fromChain = config.fromChain as ChainConfig;
  const fromChainName = fromChain.chainName;
  const fromChainNo = fromChain.chainNo;

  const toChain = config.toChain as ChainConfig;
  const toChainName = toChain.chainName;
  const toChainNo = toChain.chainNo;

  const fromTokenSymbol = fromToken?.tokenSymbol;
  const fromTokenId = fromToken?.tokenId;
  const fromTokenName = fromToken?.tokenName;
  const fromTokenImg = !isFT ? (fromToken as FromNFTConfig).tokenImage : '';
  const tokenCount = (fromToken as FromNFTConfig).transferTokenAmount;

  const chainSymbol = fromChain?.chainSymbol;

  const fromTxtEllipsis =
    fromWalletAddr.slice(0, 5) +
    '...' +
    fromWalletAddr.slice(fromWalletAddr.length - 5);
  const toTxtEllipsis =
    toWalletAddr.slice(0, 5) +
    '...' +
    toWalletAddr.slice(toWalletAddr.length - 5);

  const _fromLogo = !!NETWORK_LOGO[fromChainNo]
    ? NETWORK_LOGO[fromChainNo]
    : logo_default;
  const _toLogo = !!NETWORK_LOGO[toChainNo]
    ? NETWORK_LOGO[toChainNo]
    : logo_default;

  const componentNFTArr = [
    <Divider mgt={24} mgb={24} />,
    <ComponentSelectedNFT
      img={fromTokenImg}
      name={fromTokenName}
      symbol={fromTokenSymbol}
      tokenId={fromChainNo === 8 ? '' : (fromTokenId as string)}
      tokenCount={tokenCount}
    />,
    <Divider mgt={24} mgb={24} />,
    <ComponentNFTFee
      commission={commission}
      txFee={_calculatedTxFee}
      deployFee={deployFee}
      symbol={chainSymbol}
    />,
    <Divider mgt={12} mgb={12} />,
    <ComponentFeeTotal isNFT={true} total={total_nft} symbol={chainSymbol} />,
  ];
  const componentTokenArr = [
    <Divider mgt={24} mgb={26} />,
    <ComponentTokenFee
      balance={balance}
      commission={commission}
      txFee={txFee}
      symbol={fromTokenSymbol}
      isNFT={false}
    />,
    <Divider mgt={12} mgb={12} />,
    <ComponentFeeTotal
      isNFT={false}
      total={total_token}
      symbol={fromTokenSymbol}
    />,
  ];

  return (
    <StyledView>
      <div className='chain-area'>
        <StyledChain>
          <div className='chain-info'>
            <span>From </span>
            <span className='font-bold'>{fromChainName}</span>
          </div>
          <img src={_fromLogo} loading='lazy' alt='logo' />
          <div className='address-info'>{fromTxtEllipsis}</div>
        </StyledChain>
        <img src={ic_arrow_right} alt='arrow-right' />
        <StyledChain>
          <div className='chain-info'>
            <span>To </span>
            <span className='font-bold'>{toChainName}</span>
          </div>
          <img src={_toLogo} loading='lazy' alt='logo' />
          <div className='address-info'>{toTxtEllipsis}</div>
        </StyledChain>
      </div>
      {isFT && componentTokenArr.map((el) => el)}
      {!isFT && componentNFTArr.map((el) => el)}
    </StyledView>
  );
};

const StyledView = styled.div`
  padding: 0px 8px;
  & > div.chain-area {
    display: flex;
    align-items: center;
    justify-content: center;
    & > img {
      width: 30px;
      height: 30px;
      margin: 0px 56px;
    }
  }
  .font-bold {
    font-weight: 900;
  }
`;

const StyledDivider = styled.div<{ mgt: number; mgb: number }>`
  border-bottom: 1px solid ${theme.mono6};
  margin-top: ${(props) => props.mgt}px;
  margin-bottom: ${(props) => props.mgb}px;
`;

const StyledChain = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 170px;
  height: 136px;
  & > div.chain-info {
    color: ${theme.mono4};
    margin-bottom: 20px;
    max-width: 170px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    & > span.font-bold {
      ${theme.tab1};
      color: ${theme.mono1};
      font-weight: 900;
      margin-left: 2px;
    }
  }
  & > img {
    width: 50px;
    height: 50px;
    border-radius: 6px;
    margin-bottom: 12px;
  }
  & > div.address-info {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${theme.mono8};
    color: ${theme.mono1};
    border-radius: 6px;
    padding: 7px 19px;
  }
`;

const StyledSelected = styled.div`
  display: flex;
  align-items: center;
  & > div.selected-title {
    width: 85px;
    & > span.title {
      color: ${theme.mono1};
    }
  }
  & > div.selected-info {
    display: flex;
    align-items: center;
    margin-left: auto;
    color: ${theme.mono1};
    & > span.name {
      max-width: 186px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    & > div.img-box {
      width: 30px;
      height: 30px;
      margin-right: 10px;
      & > img {
        width: 100%;
        height: 100%;
      }
    }
    & > span.txt-ellipsis {
      max-width: 186px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    & > div.sh-divider {
      width: 4px;
      height: 20px;
      color: ${theme.mono4};
      margin: 0px 10px;
    }
    & > div.nft-count {
      ${theme.tokenAmount}
      padding: 1px 4px;
      display: inline-flex;
      justify-content: center;
      height: 18px;
      margin-left: 10px;
      min-width: 20px;
      width: auto;
      background-color: rgba(22, 217, 146, 0.14);
      border-radius: 6px;
      color: ${theme.primaryTxt};
    }
  }
`;

const StyledFeeArea = styled.div<{ isNFT: boolean }>`
  & > div {
    margin-bottom: 10px;
    color: ${theme.mono4};
    & > span.title {
      color: ${theme.mono1};
    }
    &:last-of-type {
      margin-bottom: 0px;
    }
  }
  & > div.fee-content {
    display: grid;
    grid-template-columns: 210px 1fr auto;
    grid-template-rows: auto;
    row-gap: 8px;
    & > div {
      display: flex;
      align-items: center;
    }
    & > div.fee-header {
      height: 20px;
    }
    & > div.fee-amount {
      justify-content: flex-end;
    }
    & > div.fee-symbol {
      margin-left: 6px;
      justify-content: flex-start;
    }
  }
`;

const StyledSum = styled.div`
  ${theme.tab1};
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ className }) =>
    className?.includes('red') ? theme.error : theme.mono1};
  & > div.title-area {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  & > div.title {
    font-weight: 700;
    margin-right: 2px;
  }
  & > div.info {
    & > span:first-of-type {
      margin-right: 6px;
      ${theme.title1};
    }
  }
`;

export { BridgeSummaryView };

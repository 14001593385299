import { BridgeDispatcher } from 'src/dispatcher/bridgeDispatcher';
import { TOKEN_TRANSFER_STEP } from 'src/constants/actionTypes';

const bridgeDispatcher = BridgeDispatcher();

const stepAction = {
  _changeStep: (stepNum: number) => {
    bridgeDispatcher.handleViewAction({
      actionType: TOKEN_TRANSFER_STEP.SET_STEP_NUMBER,
      id: stepNum,
    });
  },
};

export { stepAction };

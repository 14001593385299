import React from 'react';
import styled from 'styled-components';
import Banner3 from 'src/assets/banner/Banner3.png';
import RightImage from 'src/assets/banner/banner_img_right@2x.png';
import LeftImage from 'src/assets/banner/banner_img_left@2x.png';
import CloseIcon from 'src/assets/ic_Close_bold.svg';
import { theme } from 'src/styles/theme';

type Props = {
  clickClose: () => void;
};

const BannerContainer = styled.div`
  position: fixed;
  top: -120px;
  width: 100vw;
  height: 120px;
  background: linear-gradient(to left, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(to left, #141718, #141718);
`;

const BannerBg = styled.div`
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0) 40%,
    rgba(0, 185, 132, 0.36) 80%
  );
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  /* min-width: 1920px; */
`;

const BannerImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const RightImg = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const LeftImg = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const CloseImgWrapper = styled.div`
  position: absolute;
  cursor: pointer;
  top: 44px;
  right: 36px;
  z-index: 1000;
`;

const CloseImg = styled.img``;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  color: ${theme.primaryBG};
  text-align: center;
  line-height: 20px;
  overflow-x: hidden;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 20px;
  width: 813px;
`;

const Description = styled.div`
  width: 813px;
`;

const Banner = ({ clickClose }: Props) => {
  return (
    <BannerContainer>
      <BannerBg>
        <Container>
          {/* <BannerImg src={Banner3} /> */}
          <RightImg src={RightImage} />
          <LeftImg src={LeftImage} />
          <CloseImgWrapper onClick={clickClose}>
            <CloseImg src={CloseIcon} />
          </CloseImgWrapper>
          <TextContainer>
            <Title>
              Upcoming Ethereum Testnet Change: Transitioning from Goerli to
              Sepolia in September
            </Title>
            <Description>
              Due to the deprecation of Ethereum Goerli, HAVAH Mitter Vega will
              no longer support for bridging your assets on Goerli
            </Description>
          </TextContainer>
        </Container>
      </BannerBg>
    </BannerContainer>
  );
};

export default Banner;

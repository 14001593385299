import { Env } from './env';
import {
  AddressLookupTableAccount,
  PublicKey,
  TransactionInstruction,
  TransactionMessage,
  VersionedTransaction,
} from '@solana/web3.js';

export abstract class Base {
  protected constructor(protected readonly env: Env) {}

  protected async createVersionedTransaction(
    payer: PublicKey,
    instructions: Array<TransactionInstruction>,
    addressLookupTableAccount?: AddressLookupTableAccount
  ): Promise<VersionedTransaction> {
    const lastBlock = await this.env.connection.getLatestBlockhash(
      this.env.commitment
    );
    let _addressLookupTableAccount: AddressLookupTableAccount[] = [];
    if (addressLookupTableAccount != null) {
      _addressLookupTableAccount = [addressLookupTableAccount];
    }

    const tm = new TransactionMessage({
      payerKey: payer,
      recentBlockhash: lastBlock.blockhash,
      instructions: instructions,
    }).compileToV0Message(_addressLookupTableAccount);
    return new VersionedTransaction(tm);
  }
}

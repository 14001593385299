import React from 'react';
import styled from 'styled-components';
import { theme } from 'src/styles/theme';
import bg_bottom from 'src/assets/bg_bottom_snap.png';
import ic_havah from 'src/assets/footer_link_icons/Havah@2x.png';
import ic_havah_g from 'src/assets/footer_link_icons/Havah-g@2x.png';
import ic_twitter from 'src/assets/footer_link_icons/Twitter@2x.png';
import ic_twitter_g from 'src/assets/footer_link_icons/Twitter-g@2x.png';
import ic_discord from 'src/assets/footer_link_icons/Discord@2x.png';
import ic_discord_g from 'src/assets/footer_link_icons/Discord-g@2x.png';
import ic_medium from 'src/assets/footer_link_icons/Medium@2x.png';
import ic_medium_g from 'src/assets/footer_link_icons/Medium-g@2x.png';
import ic_gitbook from 'src/assets/footer_link_icons/ic_gitbook.svg';
import ic_gitbook_g from 'src/assets/footer_link_icons/ic_gitbook_g.svg';

interface IProps {
  isBridge: boolean;
}

// const isDev = process.env.REACT_APP_IS_DEV === "dev";

const Footer: React.FC<IProps> = (props) => {
  const { isBridge } = props;

  return (
    <StyledFooter isBridge={isBridge}>
      {isBridge && (
        <>
          <div className='bottom-img'>
            <img src={bg_bottom} alt='bg_bottom' />
          </div>
          <div className='cover'></div>
        </>
      )}
      <div className='footer-container'>
        <div className='footer-content'>
          <div className='terms-links'>
            <div className='terms'>
              {/* <span>FAQ</span>
              <div className="sh-divider">|</div> */}
              <span
                onClick={() => {
                  window.open(
                    'https://docs.google.com/document/d/1EDlagrh8L2UVsfA-zY_vs2VS-vL9kmR-RGmM9nFFvXM/edit',
                    '_blank'
                  );
                }}
              >
                Terms of Service
              </span>
            </div>
            <div className='rights'>
              <span>
                Copyright © 2022-2024 WEB3 SOLUTIONS PTE. LTD. All Rights
                Reserved
              </span>
              <span className='divider'>|</span>
              <span>{process.env.REACT_APP_VERSION}</span>
            </div>
          </div>
          <div className='sns-links'>
            <img
              onClick={() => {
                window.open('https://havah.io/');
              }}
              src={isBridge ? ic_havah : ic_havah_g}
              alt='havah'
            />
            <img
              onClick={() => {
                window.open('https://twitter.com/HAVAHofficial');
              }}
              src={isBridge ? ic_twitter : ic_twitter_g}
              alt='twitter'
            />
            <img
              onClick={() => {
                window.open('https://discord.gg/havahofficial');
              }}
              src={isBridge ? ic_discord : ic_discord_g}
              alt='discord'
            />
            <img
              onClick={() => {
                window.open('https://medium.com/havahofficial ');
              }}
              src={isBridge ? ic_medium : ic_medium_g}
              alt='medium'
            />
            <img
              onClick={() => {
                window.open(
                  'https://docs.havah.io/havah/v/english/havah-dapps/havah-mitter'
                );
              }}
              src={isBridge ? ic_gitbook : ic_gitbook_g}
              alt='gitbook'
            />
          </div>
        </div>
      </div>
    </StyledFooter>
  );
};

const StyledFooter = styled.footer<{ isBridge: boolean }>`
  position: relative;
  width: 100vw;
  padding-top: 50px;
  z-index: 0;
  bottom: 0;
  & > div.cover {
    position: absolute;
    z-index: 10;
    bottom: 0px;
    width: 100vw;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
    height: 170px;
    opacity: 0.5;
    pointer-events: none;
  }
  & > div.bottom-img {
    position: absolute;
    z-index: 0;
    bottom: 0px;
    width: 100vw;
    & > img {
      width: 100%;
      vertical-align: bottom;
    }
    @media screen and (max-width: 1920px) {
      width: 1920px;
      & > img {
        width: 100%;
        vertical-align: bottom;
      }
    }
  }
  ${(props) =>
    props.isBridge
      ? `
    background-color : transparent;
  `
      : `
    background-color : ${theme.mono1};
  `}
  & > div.footer-container {
    position: relative;
    width: 100%;
    min-width: 1200px;
    height: 170px;
    ${(props) =>
      props.isBridge
        ? `
    border-top : none;
    `
        : `
    border-top : 1px solid ${theme.mono2};
    `}
    & > div.footer-content {
      display: flex;
      justify-content: space-between;
      width: 700px;
      height: 100%;
      margin: 0px auto;
      padding: 40px 0px 0px;
      & > div.terms-links {
        color: ${(props) => (props.isBridge ? theme.mono2 : theme.mono6)};
        & > div.terms {
          ${theme.tab2};
          line-height: 20px;
          font-weight: 900;
          display: flex;
          margin-bottom: 10px;
          & > div.sh-divider {
            opacity: 0.3;
            color: ${(props) => (props.isBridge ? theme.mono1 : theme.mono6)};
            margin: 0px 10px;
          }
          & > span {
            cursor: pointer;
          }
        }
        & > div.rights {
          ${theme.body2};
          & > span.divider {
            margin: 0 10px;
          }
        }
      }
      & > div.sns-links {
        display: flex;

        & > img {
          width: 34px;
          height: 34px;
          margin-right: 10px;
          cursor: pointer;
          &:last-of-type {
            margin-right: 0px;
          }
        }
      }
    }
    & > div.version {
      position: absolute;
      bottom: 0px;
      right: 0px;
      width: 134px;
      height: 46px;
      color: #fff;
      font-weight: 900;
    }
  }
`;

export { Footer };

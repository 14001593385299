import { EventEmitter } from 'events';
import dispatcher from 'src/dispatcher/dispatcher';
import { TOKEN_TRANSFER_ACTIONS } from 'src/constants/actionTypes';
import { BridgeStoreConfig } from 'src/constants/types';

const CHANGE_EVENT = 'CHANGE';
let _config: BridgeStoreConfig = {
  fromChain: {},
  fromWalletAddr: '',
  walletId: 0,
  fromToken: {},
  balance: '',
  toChain: {},
  toWalletAddr: '',
  aptosDirectTransfer: false,
};

let _msgConfig: any;

let _txnHash: string = '';

class BridgePageStore extends EventEmitter {
  constructor() {
    super();
    dispatcher.register((payload: any) => {
      const action = payload.action.actionType;
      const id = payload.action.id as never;
      switch (action) {
        case TOKEN_TRANSFER_ACTIONS.FROM_CHAIN_SELECT:
          this.create('fromChain', id);
          this.emitChange();
          break;
        case TOKEN_TRANSFER_ACTIONS.FROM_CHAIN_REMOVE:
          this.destroy('fromChain');
          this.emitChange();
          break;
        case TOKEN_TRANSFER_ACTIONS.FROM_WALLET_SELECT:
          this.create('fromWalletAddr', id);
          this.emitChange();
          break;
        case TOKEN_TRANSFER_ACTIONS.FROM_WALLET_REMOVE:
          this.destroy('fromWalletAddr');
          this.emitChange();
          break;
        case TOKEN_TRANSFER_ACTIONS.FROM_WALLET_ID_SELECT:
          this.create('walletId', id);
          this.emitChange();
          break;
        case TOKEN_TRANSFER_ACTIONS.FROM_WALLET_ID_REMOVE:
          this.destroy('walletId');
          this.emitChange();
          break;
        case TOKEN_TRANSFER_ACTIONS.FROM_TOKEN_SELECT:
          this.create('fromToken', id);
          this.emitChange();
          break;
        case TOKEN_TRANSFER_ACTIONS.FROM_TOKEN_REMOVE:
          this.destroy('fromToken');
          this.emitChange();
          break;
        case TOKEN_TRANSFER_ACTIONS.TO_CHAIN_SELECT:
          this.create('toChain', id);
          this.emitChange();
          break;
        case TOKEN_TRANSFER_ACTIONS.TO_CHAIN_REMOVE:
          this.destroy('toChain');
          this.emitChange();
          break;
        case TOKEN_TRANSFER_ACTIONS.TO_WALLET_SELECT:
          this.create('toWalletAddr', id);
          this.emitChange();
          break;
        case TOKEN_TRANSFER_ACTIONS.TO_WALLET_REMOVE:
          this.destroy('toWalletAddr');
          this.emitChange();
          break;
        case TOKEN_TRANSFER_ACTIONS.INPUT_TOKEN_BALANCE:
          this.create('balance', id);
          this.emitChange();
          break;
        case TOKEN_TRANSFER_ACTIONS.CHANGE_RESULT:
          this.txResult(id);
          this.emitChange();
          break;
        case TOKEN_TRANSFER_ACTIONS.RESET:
          this.reset();
          this.emitChange();
          break;

        case TOKEN_TRANSFER_ACTIONS.MESSAGE_STATUS:
          this.setMessage(id);
          this.emitChange();
          break;

        case TOKEN_TRANSFER_ACTIONS.APTOS_DIRECT_TRANSFER:
          this.create('aptosDirectTransfer', id);
          this.emitChange();
          break;
        default:
          break;
      }
    });
  }

  addChangeListener(callback: any) {
    this.addListener(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback: any) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  emitChange() {
    this.emit(CHANGE_EVENT);
  }

  create = (key: keyof typeof _config, id: never) => {
    _config[key] = id;
  };

  destroy = (key: keyof typeof _config) => {
    if (key === 'fromChain' || key === 'fromToken' || key === 'toChain') {
      _config[key] = {};
    } else if (key === 'walletId') {
      _config[key] = 0;
    } else if (key === 'aptosDirectTransfer') {
      _config[key] = false;
    } else {
      _config[key] = '';
    }
  };

  setMessage = (id: any) => {
    _msgConfig = id;
  };

  txResult = (data: string) => {
    _txnHash = data;
  };

  reset = () => {
    _config = {
      fromChain: {},
      fromWalletAddr: '',
      fromToken: {},
      balance: '',
      toChain: {},
      toWalletAddr: '',
      walletId: 0,
      aptosDirectTransfer: false,
    };
  };

  getBridgeConfig() {
    return _config;
  }

  getBridgeResult() {
    return _txnHash;
  }

  getTxConfig() {
    return _msgConfig;
  }
}

const bridgePageStore = new BridgePageStore();

export default bridgePageStore;

import { MetaMaskInpageProvider } from '@metamask/providers';
import { CHROME_EXTENSION_LINK } from 'src/constants';

export default class MetamaskApi {
  provider: MetaMaskInpageProvider;

  constructor(_provider: MetaMaskInpageProvider) {
    this.provider = _provider;
  }

  minABI = [
    {
      inputs: [
        {
          internalType: 'address',
          name: 'owner',
          type: 'address',
        },
      ],
      name: 'balanceOf',
      outputs: [{ name: 'balance', type: 'uint256' }],
      type: 'function',
    },
  ];

  getMetaMaskProvider = () => {
    return this.provider;
  };

  moveToMetamaskStore = async () => {
    window.open(CHROME_EXTENSION_LINK['metamask'], '_blank');
  };

  checkMetamaskInstalled = async () => {
    if (!window.ethereum) return false;
    return true;
  };

  getMetamaskAddress = async () => {
    try {
      const res: any = await this.provider.request({
        method: 'eth_requestAccounts',
      });
      const address = res[0];
      if (address) return { address };
      return { address: '' };
    } catch (e) {
      throw e;
    }
  };

  requestWalletPermission = async () => {
    console.log('requestWalletPermission');
    try {
      this.provider.request({
        method: 'wallet_requestPermissions',
        params: [{ eth_accounts: {} }],
      });
      return true;
    } catch (e) {
      throw e;
    }
  };

  checkMetamaskConnected = async () => {
    try {
      const res = this.provider.isConnected();
      return res;
    } catch (e) {
      throw e;
    }
  };

  revokeMetamaskPermission = async () => {
    try {
      const res = this.provider.request({
        method: 'wallet_revokePermissions',
        params: [
          {
            eth_accounts: '',
          },
        ],
      });
    } catch (e) {
      throw e;
    }
  };

  checkChainId = async (chainId: number) => {
    try {
      await this.provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x' + chainId.toString(16) }],
      });
    } catch (e) {
      throw e;
    }
  };

  metamaskRequestJsonRpc = async (rawTx: any) => {
    try {
      const txResult = await this.provider.request({
        method: 'eth_sendTransaction',
        params: [rawTx],
      });
      return txResult;
    } catch (e) {
      throw e;
    }
  };
}

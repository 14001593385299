import React, { useContext, useCallback } from 'react';
import {
  requestNFTInfoForHavah,
  requestNFTInfoUsingMorails,
  requestNFTInfoForKlaytn,
  requestNFTInfoForAptos,
  requestNFTInfoForFNCY,
  requestNFTInfo,
  requestMetadata,
  requestMetadataAptos,
  requestTokenUri,
  requestNFTInfoForSolana,
  requestMetadataSolana,
} from 'src/apis/api';
import bridgePageStore from 'src/stores/BridgePageStore';
import stepStore from 'src/stores/StepStore';
import {
  FromChainTokenSelectView,
  ModalNFTInput,
  ModalNFTList,
  LoadingModal,
  ModalServiceUnavailable,
} from 'src/components/index';
import { hexToString } from 'src/sc/metamask';
import { ModalContext } from 'src/contexts/ModalContext';
import { ModalContext as ModalShadowContext } from 'src/contexts/ModalShadowContext';
import { protocolReplacer, errCodeParser } from 'src/utils/utils';
import { BRIDGE_CHAIN_NAME } from 'src/constants/index';
import { ChainConfig } from 'src/constants/types';
import { NETWORK_ID } from 'src/constants/enums';
// chainNo => 1 : ICON 2 : BNB 3 : Klaytn 4 : HAVAH 5 : ETH

const FromChainNFTSelectViewContainer: React.FC = () => {
  const {
    handleModal,
    setList,
    setTitle,
    setSubtitle,
    curPage,
    morailsCursor,
    setIsLoadingList,
    setCurPage,
    setMorailsCursor,
    setTotal,
  } = useContext(ModalContext);
  const modalShadow = useContext(ModalShadowContext);
  const store = bridgePageStore;
  const config = store.getBridgeConfig();

  const stepNumber = stepStore.getStepNumber();
  const { fromWalletAddr, fromChain } = config;
  const { chainNo } = fromChain as ChainConfig;

  const getNftForHavah = useCallback(
    async (fromWalletAddr: string, curPage: number) => {
      try {
        let newArr: any[] = [];
        const { data, totalSize } = await requestNFTInfoForHavah(
          fromWalletAddr,
          curPage
        );
        setTotal(totalSize);
        setCurPage((prev: number) => prev + 1);
        data.map((el: any) => {
          const _type = parseInt(el.nftType);
          const _imgUrl = el?.metadataImage;
          const _name = el?.metadataName;
          const _symbol = el?.contractSymbol;
          const _contractName = el?.contractName;
          const _imgReplacedProtocol = protocolReplacer(_imgUrl);
          const props = {
            tokenAddr: el.contractAddress,
            tokenName: !!_name ? _name : !!_contractName ? _contractName : '',
            tokenId: el.nftTokenIdString,
            tokenType: !!_type ? _type : '',
            tokenImage: !!_imgUrl ? _imgReplacedProtocol : '',
            tokenSymbol: !!_symbol ? _symbol : '',
            contractName:
              _type === 2 ? '' : !!_contractName ? _contractName : '',
            tokenCount: el.tokenCount,
          };
          newArr.push(props);
          // nftListAction._addItem(props);
        });
        return newArr;
      } catch (e) {
        throw e;
      }
    },
    [setTotal, setCurPage]
  );

  const getNftForEvm = useCallback(
    async (fromWalletAddr: string, chainNo: number, cursor: string) => {
      try {
        let newArr: any[] = [];
        // Hard-coding
        const chainName = BRIDGE_CHAIN_NAME[chainNo];
        const rsp = await requestNFTInfoUsingMorails(
          fromWalletAddr,
          chainName,
          cursor
        );
        const _morailsList: any[] = rsp.result;
        setTotal(_morailsList.length);

        _morailsList.map((el) => {
          const _metadataUri = el.metadata;
          const metadata = !!el.metadata && JSON.parse(_metadataUri);
          const _name = metadata?.name;
          const _imgUrl = metadata?.image;
          const _symbol = metadata?.symbol;
          const _contractName = !!el.name ? el.name : '';
          const _imgReplacedProtocol = protocolReplacer(_imgUrl);
          const _tokenType =
            el.contract_type === 'ERC721' || el.contract_type === 'HSP721'
              ? 1
              : 2;
          const props = {
            tokenAddr: el.token_address,
            tokenName: !!_name ? _name : !!_contractName ? _contractName : '',
            tokenId: el.token_id,
            tokenType: _tokenType,
            tokenImage: !!_imgUrl ? _imgReplacedProtocol : '',
            tokenSymbol: !!_symbol ? _symbol : el.symbol,
            contractName:
              _tokenType === 2 ? '' : !!_contractName ? _contractName : '',
            tokenCount: el.amount,
          };
          newArr.push(props);
        });

        setMorailsCursor(!!rsp.cursor ? rsp.cursor : '');
        return newArr;
      } catch (e) {
        throw e;
      }
    },
    [setTotal, setMorailsCursor]
  );

  const getNFT = async (
    contract: string,
    tokenId: string,
    tokenCount: string
  ) => {
    try {
      const { contractName, contractSymbol, type } = await requestNFTInfo({
        chainNo: 3,
        address: contract,
        tokenId: tokenId,
      });
      const metadata = await requestMetadata(3, contract, tokenId);
      const _tokenType = parseInt(type);
      const _name = metadata?.name;
      const _symbol = metadata?.symbol;
      const _image = metadata?.image;
      const _imgReplacedProtocol = protocolReplacer(_image);
      const props = {
        tokenAddr: contract,
        tokenName: !!_name ? _name : !!contractName ? contractName : '',
        tokenId: tokenId,
        tokenType: _tokenType,
        tokenImage: !!_image ? _imgReplacedProtocol : '',
        tokenSymbol: !!_symbol ? _symbol : contractSymbol,
        contractName:
          _tokenType === 2 ? '' : !!contractName ? contractName : '',
        tokenCount: tokenCount ? parseInt(tokenCount, 16).toString() : '',
      };
      return props;
    } finally {
    }
  };

  const getNftForKlaytn = useCallback(
    async (fromWalletAddr: string, cursor: string) => {
      try {
        let newArr: any[] = [];
        const rsp = await requestNFTInfoForKlaytn(
          fromWalletAddr,
          // '0xC12E91587890a421203d20DdA8dAdc353Dac87Bf',
          cursor
        );
        const total = rsp.items.length;
        setTotal(total);
        const result = rsp.items.map((el: any) => {
          const contract = el.contractAddress;
          const metadataUri = el.extras;
          const tokenCount = el.balance;
          const tokenId = hexToString(metadataUri.tokenId);
          return getNFT(contract, tokenId, tokenCount);
        });

        newArr = await Promise.all(
          result.map((el: any) =>
            el.catch(() => {
              return {
                tokenAddr: '',
                tokenName: '',
                tokenId: '',
                tokenType: '',
                tokenImage: '',
                tokenSymbol: '',
                contractName: '',
              };
            })
          )
        ).then((data) => {
          return data;
        });
        setMorailsCursor(!!rsp.cursor ? rsp.cursor : '');
        return newArr;
      } catch (e) {
        throw e;
      }
    },
    [setMorailsCursor, setTotal]
  );

  const getNftForAptos = useCallback(
    async (fromWalletAddr: string, cursor: string) => {
      try {
        let newArr: any[] = [];
        let _preList;

        while (true) {
          const rsp = await requestNFTInfoForAptos(fromWalletAddr, cursor);

          //amount가 0인 리스트 제거
          const _list = rsp.result.filter((el: any) => {
            return el.amount !== '0';
          });

          _preList = _preList ? _preList.concat(_list) : _list;
          cursor = rsp.cursor;
          if (_preList.length >= 10 || cursor == null) break;
        }
        // no more list
        if (_preList.length === 0) {
          setMorailsCursor('');
          return newArr;
        }
        const total = _preList.length;
        setTotal(total);
        const _morailsList = _preList;
        const ids = _morailsList.map((el: any) =>
          el.token_data_id_hash.replace('"', '')
        );

        const _list = await requestMetadataAptos(ids);

        const fetchData = _list.map(async (el: any, index: number) => {
          const {
            creator_address,
            collection_name,
            token_data_id_hash,
            name,
            largest_property_version,
          } = el;

          let _imgUrl = el.metadata_uri;
          try {
            const result = await requestTokenUri(el.metadata_uri);
            _imgUrl = protocolReplacer(result.image);
            console.log(_imgUrl);
          } catch (e) {
            console.error('Failed to fetch requestTokenUri :', e);
          }

          return {
            tokenAddr: creator_address,
            tokenName: name,
            tokenId: token_data_id_hash,
            tokenType: 2,
            tokenImage: _imgUrl,
            tokenSymbol: '',
            contractName: collection_name,
            aptosCreatorAddr: creator_address,
            aptosCollectionName: collection_name,
            aptosTokenName: name,
            aptosTokenPropertyVersion: largest_property_version,
            aptosTokenAmount: _morailsList[index].amount,
          };
        });

        const _newList = await Promise.all(fetchData).then((data) => {
          return data;
        });

        newArr = _newList;
        setMorailsCursor(!!cursor ? cursor : '');
        return newArr;
      } catch (e) {
        throw e;
      }
    },
    [setTotal, setMorailsCursor]
  );

  const getNftForSolana = useCallback(
    async (fromWalletAddr: string) => {
      let nftList: any = [];
      try {
        try {
          const rsp: any = await requestNFTInfoForSolana(fromWalletAddr);
          nftList = rsp['nfts']
            .filter(
              (el: any) =>
                !(el.name === '' && el.symbol === '') && el.decimals === '0'
            )
            .concat(
              rsp['tokens'].filter(
                (el: any) =>
                  !(el.name === '' && el.symbol === '') && el.decimals === '0'
              )
            );
        } catch (e) {
          console.log(e);
        }
        console.log(nftList);
        const total = nftList.length;

        setTotal(total);
        const fetchList = await nftList.map(async (el: any, index: number) => {
          const { mint, amount } = el;

          const metaData: any = await requestMetadataSolana(mint);
          console.log(metaData, amount);
          let _imgUrl = metaData.metaplex.metadataUri;
          let tokenType;
          let tokenAmount;
          if (amount == null) {
            tokenType = 1;
            tokenAmount = 1;
          } else {
            tokenType = 2;
            tokenAmount = amount;
          }
          try {
            const result = await requestTokenUri(metaData.metaplex.metadataUri);
            _imgUrl = protocolReplacer(result.image);
            console.log(_imgUrl);
          } catch (e) {
            console.log('Failed to fetch requestTokenUri :', e);
          }

          return {
            tokenAddr: '', //el.associatedTokenAddress,
            tokenName: metaData.name,
            tokenId: mint,
            tokenType: tokenType,
            tokenImage: _imgUrl,
            tokenSymbol: metaData.symbol,
            contractName: '',
            tokenCount: tokenAmount,
          };
        });
        const _newList = await Promise.all(fetchList).then((data) => {
          return data;
        });
        console.log(_newList);
        setTotal(total);
        return _newList;
      } catch (e) {
        throw e;
      }
    },
    [setTotal]
  );

  const getNftForFNCY = useCallback(
    async (fromWalletAddr: string, curPage: number) => {
      try {
        let newArr: any[] = [];
        const { data } = await requestNFTInfoForFNCY(fromWalletAddr, curPage);
        setTotal(data.paging.totalCount ? data.paging.totalCount : 0);
        setCurPage((prev: number) => prev + 1);
        const nftList = data.items ? data.items : [];

        nftList?.map((el: any) => {
          const _type =
            el.assetType === 'ERC721' || el.assetType === 'HSP721' ? 1 : 2;
          const _metadataUri = el.nftMetaUri;
          const metadata = !!el.nftMetaUri && JSON.parse(_metadataUri);
          const _name = metadata?.name;
          const _imgUrl = metadata?.image;
          const _symbol = metadata?.symbol;
          const _contractName = el?.nftNm;
          const _imgReplacedProtocol = protocolReplacer(_imgUrl);
          const props = {
            tokenAddr: el.contractAddress,
            tokenName: !!_name ? _name : !!_contractName ? _contractName : '',
            tokenId: el.tokenId,
            tokenType: !!_type ? _type : '',
            tokenImage: !!_imgUrl ? _imgReplacedProtocol : '',
            tokenSymbol: !!_symbol ? _symbol : '',
            contractName:
              _type === 2 ? '' : !!_contractName ? _contractName : '',
            tokenCount: el.tokenCount,
          };
          newArr.push(props);
          // nftListAction._addItem(props);
        });
        return newArr;
      } catch (e) {
        throw e;
      }
    },
    [setTotal, setCurPage]
  );

  const refresh = useCallback(async () => {
    try {
      let list: any[] = [];
      setIsLoadingList(true);
      setCurPage(1);
      setMorailsCursor('');
      if (chainNo === 4) {
        list = await getNftForHavah(fromWalletAddr, 1);
      } else if (
        chainNo === NETWORK_ID.BSC ||
        chainNo === NETWORK_ID.ETHEREUM ||
        chainNo === NETWORK_ID.POLYGON ||
        chainNo === NETWORK_ID.BASE
      ) {
        list = await getNftForEvm(fromWalletAddr, chainNo, '');
      } else if (chainNo === NETWORK_ID.KLAYTN) {
        list = await getNftForKlaytn(fromWalletAddr, '');
      } else if (chainNo === NETWORK_ID.APTOS) {
        list = await getNftForAptos(fromWalletAddr, '');
      } else if (chainNo === NETWORK_ID.SOLANA) {
        list = await getNftForSolana(fromWalletAddr);
      }
      // else if (chainNo === 103) {
      //   list = await getNftForFNCY(fromWalletAddr, 1);
      // }
      setList(list);
    } catch (e) {
      throw e;
    } finally {
      setIsLoadingList(false);
    }
  }, [
    chainNo,
    fromWalletAddr,
    setCurPage,
    setMorailsCursor,
    setIsLoadingList,
    setList,
    getNftForEvm,
    getNftForHavah,
    getNftForKlaytn,
    getNftForAptos,
    getNftForSolana,
  ]);

  const nextList = useCallback(
    async (curPage: any) => {
      try {
        setIsLoadingList(true);
        let list: any[] = [];
        if (chainNo === 4) {
          list = await getNftForHavah(fromWalletAddr, curPage);
        } else if (
          chainNo === NETWORK_ID.BSC ||
          chainNo === NETWORK_ID.ETHEREUM ||
          chainNo === NETWORK_ID.POLYGON ||
          chainNo === NETWORK_ID.BASE
        ) {
          list = await getNftForEvm(fromWalletAddr, chainNo, curPage);
        } else if (chainNo === 3) {
          list = await getNftForKlaytn(fromWalletAddr, curPage);
        } else if (chainNo === 8) {
          list = await getNftForAptos(fromWalletAddr, curPage);
        }
        // else if (chainNo === 103) {
        //   list = await getNftForFNCY(fromWalletAddr, curPage);
        // }
        setList((prev: any) => {
          return prev.concat(list);
        });
      } catch (e) {
        throw e;
      } finally {
        setIsLoadingList(false);
      }
    },
    [
      fromWalletAddr,
      chainNo,
      getNftForHavah,
      getNftForEvm,
      getNftForKlaytn,
      getNftForAptos,
      setList,
      setIsLoadingList,
    ]
  );

  // Fix ModalTokenList
  const modalHandler = useCallback(async () => {
    try {
      let list: any[] = [];
      modalShadow.handleModal(<LoadingModal />);
      if (chainNo === 4) {
        list = await getNftForHavah(fromWalletAddr, curPage);
      } else if (
        chainNo === NETWORK_ID.BSC ||
        chainNo === NETWORK_ID.ETHEREUM ||
        chainNo === NETWORK_ID.POLYGON ||
        chainNo === NETWORK_ID.BASE
      ) {
        list = await getNftForEvm(fromWalletAddr, chainNo, morailsCursor);
      } else if (chainNo === NETWORK_ID.KLAYTN) {
        list = await getNftForKlaytn(fromWalletAddr, morailsCursor);
      } else if (chainNo === NETWORK_ID.APTOS) {
        list = await getNftForAptos(fromWalletAddr, morailsCursor);
      } else if (chainNo === NETWORK_ID.SOLANA) {
        list = await getNftForSolana(fromWalletAddr);
      }
      // else if (chainNo === 103) {
      //   list = await getNftForFNCY(fromWalletAddr, curPage);
      // }
      // setIsLoadingList(true)

      setList(list);
    } catch (e: any) {
      const { retCode } = errCodeParser(e);
      if (retCode === '1') {
        modalShadow.handleModal(
          <ModalServiceUnavailable close={() => modalShadow.handleModal()} />
        );
      }
      throw e;
    } finally {
      // setIsLoading(false);
      modalShadow.handleModal();
      if (
        chainNo === NETWORK_ID.MEVERSE ||
        chainNo === NETWORK_ID.WEMIX ||
        chainNo === NETWORK_ID.FNCY ||
        chainNo === NETWORK_ID.OKTC ||
        chainNo === NETWORK_ID.XPLA
      ) {
        setTitle('Enter & Load NFT Info');
        setSubtitle(
          'Enter the Contract Address and NFT Token ID. \n Check the information before you select the NFT you want to transfer.'
        );
        handleModal(<ModalNFTInput />);
      } else {
        setTitle('My NFTs on chain');
        handleModal(<ModalNFTList refresh={refresh} nextList={nextList} />);
      }
    }
  }, [
    chainNo,
    fromWalletAddr,
    curPage,
    morailsCursor,
    handleModal,
    modalShadow,
    setList,
    setSubtitle,
    setTitle,
    getNftForAptos,
    getNftForEvm,
    getNftForHavah,
    getNftForKlaytn,
    getNftForSolana,
    nextList,
    refresh,
  ]);

  return (
    <FromChainTokenSelectView
      title={'NFTs'}
      modalHandler={modalHandler}
      btnDisabled={stepNumber < 1}
    />
  );
};

export { FromChainNFTSelectViewContainer };

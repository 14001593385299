import React, { useState, useCallback, useEffect, useContext } from 'react';
import bridgePageStore from 'src/stores/BridgePageStore';
import {
  ToastModalContent,
  ModalServiceUnavailable,
} from 'src/components/index';
import { useAccessBlocker } from 'src/hooks/useAccessBlocker';
import { ModalContext } from 'src/contexts/ToastModalContext';
import { ModalContext as ModalShadowContext } from 'src/contexts/ModalShadowContext';
import {
  ethCheckGetApproved,
  memaEncodeTransferNFTBatch,
} from 'src/sc/metamask';
import {
  requestNFTCreateMessage,
  requestNFTCheckMessage,
  requestNFTCreateMessageBatch,
} from 'src/apis/api';
import { usePolling } from 'src/hooks/usePolling';
import {
  havahSendTransaction,
  havahCheckGetApproved,
  havahCheckIsApproved,
  havahApproveAllNFT,
  havahApproveNFT,
  havahTransferNFT,
  havahTransferNFTBatch,
} from 'src/sc/havah';
import {
  ethCheckIsApproved,
  memaEncodeApprove,
  memaEncodeApproveAll,
  memaEncodeTransferNFT,
  requestJsonRpc,
  // estimateGasPrice
} from 'src/sc/metamask';
import {
  encodeTransferNFT,
  aptosSendTransaction,
  aptosCheckTxConfirm,
  aptosGetTokenStore,
} from 'src/sc/aptos';

import { iconexCheckTxConfirm } from 'src/sc/iconex';
import {
  BridgeSummaryView,
  Button,
  BridgeSummaryHeader,
  Warning3,
} from 'src/components/index';
import {
  StyledBtnWrapper,
  StyledWrapper,
  StyledTransferWrapper,
} from 'src/pages/StyledPage';
import { WALLET_ID, NETWORK_ID } from 'src/constants/enums';
import {
  ChainConfig,
  CreatedNFTMsgType,
  FromNFTConfig,
} from 'src/constants/types';
import { bridgePageAction } from 'src/actions/bridgePageAction';
import { pollingStepAction } from 'src/actions/pollingModalAction';
import { ERROR_MSG } from 'src/constants/codes';
import { errCodeParser, toHex } from 'src/utils/utils';
import BigNumber from 'bignumber.js';
import { BRIDGE_CHAIN_NAME, KEPLR_CHAIN_INFO } from 'src/constants';
import IconService from 'icon-sdk-js';
import { getXplaLCDClient, sendMsgsFromKeplr } from 'src/sc/keplr';
import { toUtf8 } from '@cosmjs/encoding';
import { MsgExecuteContract } from 'src/cosmos/cosmwasm/wasm/v1/tx';
import { transferNFTFromSolana } from 'src/sc/solana';
import { useMetamask } from 'src/hooks/useMetamask';

const { IconConverter } = IconService;

const initialValue: CreatedNFTMsgType = {
  messageId: '',
  blockHeight: 0,
  decimal: 0,
  fromChain: 0,
  fromToken: '',
  toChain: 0,
  tokenId: '',
  tokenType: 0,
  commission: -1,
  targetChainFee: -1,
  recipient: '',
  signature: '',
  txFee: -1,
  senderAddress: '',
  deployWrapped: {
    deployFee: 0,
  },
};

const SendViewContainer: React.FC = () => {
  useAccessBlocker();
  const { setPolling } = usePolling();
  const store = bridgePageStore;
  const config = store.getBridgeConfig();
  const { sendTransaction } = useMetamask();

  const { handleModal } = useContext(ModalContext);
  const modalShadow = useContext(ModalShadowContext);

  const [isFailed, setIsLoadFailed] = useState<boolean>(false);
  const [isDisabled, setBtnDisabled] = useState<boolean>(true);
  const [createdMsg, setCreatedMsg] = useState<CreatedNFTMsgType>(initialValue);
  const _messageId = createdMsg.messageId;
  const fromChainConfig = config.fromChain as ChainConfig;
  const {
    walletId,
    // fromToken,
  } = config;
  const chainNo = fromChainConfig.chainNo;
  const hasFinalization =
    chainNo === NETWORK_ID.ETHEREUM ||
    chainNo === NETWORK_ID.POLYGON ||
    chainNo === NETWORK_ID.BASE;
  const fromChainEp = fromChainConfig.endpoint;

  const requestCreateMsg = useCallback(async () => {
    try {
      const { fromWalletAddr, toWalletAddr } = config;
      const fromChain = config.fromChain as ChainConfig;
      const fromToken = config.fromToken as FromNFTConfig;
      const toChain = config.toChain as ChainConfig;

      const fromChainNo = fromChain.chainNo;
      const toChainNo = toChain.chainNo;
      const fromContract =
        fromToken.tokenScAddr === '' ? null : fromToken.tokenScAddr;
      const fromTokenId = fromToken.tokenId;
      const tokenType = fromToken.tokenType;
      let encodingParams: CreatedNFTMsgType;
      // const fromTokenName = fromToken.tokenName;

      if (tokenType === 2 && fromToken.transferTokenAmount > 1) {
        const props = {
          toTxSender: 'bridge', // 수신 chain에 tx를 보낼 주체 (bridge: 서버, wallet: 개인)
          fromChainNo, // Ethereum
          fromNftScAddr: fromContract,
          toChainNo, // HAVAH
          tokenIds: [fromTokenId], // USDT
          amounts: [fromToken.transferTokenAmount],
          senderAddress: fromWalletAddr, // 보내는 사람 Address
          recipientAddress: toWalletAddr,
          aptosCreatorAddr: fromToken.aptosCreatorAddr,
          aptosCollectionName: fromToken.aptosCollectionName,
          aptosTokenName: fromToken.aptosTokenName,
          aptosTokenPropertyVersion: fromToken.aptosTokenPropertyVersion,
        };
        const rsp = await requestNFTCreateMessageBatch(props);

        const deployFee = rsp.deployWrapped?.deployFee || 0;
        encodingParams = {
          messageId: rsp.messageId,
          blockHeight: rsp.blockHeight,
          decimal: rsp.decimal,
          fromChain: rsp.fromChainNo,
          fromToken: rsp.fromNftScAddr,
          toChain: rsp.toChainNo,
          tokenId: rsp.tokenIdString,
          tokenType: tokenType as number,
          commission: rsp.commission,
          targetChainFee: rsp.txFee,
          recipient: rsp.recipientAddress,
          signature: rsp.sig,
          senderAddress: rsp.senderAddress,
          aptosCreatorAddr: rsp.aptosCreatorAddr,
          aptosCollectionName: rsp.aptosCollectionName,
          aptosTokenName: rsp.aptosTokenName,
          aptosTokenPropertyVersion: rsp.aptosTokenPropertyVersion,
          deployWrapped: {
            deployFee: deployFee,
          },
          tokens: rsp.tokenIds,
          //
          txFee: rsp.txFee,
        };
      } else {
        const props = {
          toTxSender: 'bridge', // 수신 chain에 tx를 보낼 주체 (bridge: 서버, wallet: 개인)
          fromChainNo, // Ethereum
          fromNftScAddr: fromContract,
          toChainNo, // HAVAH
          tokenId: fromTokenId, // USDT
          senderAddress: fromWalletAddr, // 보내는 사람 Address
          recipientAddress: toWalletAddr,
          aptosCreatorAddr: fromToken.aptosCreatorAddr,
          aptosCollectionName: fromToken.aptosCollectionName,
          aptosTokenName: fromToken.aptosTokenName,
          aptosTokenPropertyVersion: fromToken.aptosTokenPropertyVersion,
        };
        const rsp = await requestNFTCreateMessage(props);

        const deployFee = rsp.deployWrapped?.deployFee || 0;
        encodingParams = {
          messageId: rsp.messageId,
          blockHeight: rsp.blockHeight,
          decimal: rsp.decimal,
          fromChain: rsp.fromChainNo,
          fromToken: rsp.fromNftScAddr,
          toChain: rsp.toChainNo,
          tokenId: rsp.tokenIdString,
          tokenType: tokenType as number,
          commission: rsp.commission,
          targetChainFee: rsp.txFee,
          recipient: rsp.recipientAddress,
          signature: rsp.sig,
          senderAddress: rsp.senderAddress,
          aptosCreatorAddr: rsp.aptosCreatorAddr,
          aptosCollectionName: rsp.aptosCollectionName,
          aptosTokenName: rsp.aptosTokenName,
          aptosTokenPropertyVersion: rsp.aptosTokenPropertyVersion,
          deployWrapped: {
            deployFee: deployFee,
          },
          //
          txFee: rsp.txFee,
        };
      }

      // calculateEstimateTxFee(encodingParams);
      if (toChainNo === NETWORK_ID.APTOS) {
        const tokenStore = await aptosGetTokenStore(
          toChain.endpoint,
          toWalletAddr
        );
        if (tokenStore != null) {
          bridgePageAction._setAptosDirectTransfer(
            (tokenStore.data as any).direct_transfer
          );
          console.log((tokenStore.data as any).direct_transfer);
        } else {
          bridgePageAction._setAptosDirectTransfer(false);
        }
      }
      setCreatedMsg(encodingParams);
      setIsLoadFailed(false);
      setBtnDisabled(false);
    } catch (e: any) {
      let { retCode, retMsg } = errCodeParser(e);
      console.log(retCode);
      const _codeToNum = parseInt(retCode);
      if (_codeToNum === 1) {
        setBtnDisabled(false);
        setIsLoadFailed(true);
        modalShadow.handleModal(
          <ModalServiceUnavailable close={() => modalShadow.handleModal()} />
        );
        return;
      } else if (_codeToNum === 2) {
        retMsg = ERROR_MSG['DEFAULT_2'];
        setBtnDisabled(false);
        setIsLoadFailed(true);
      } else if (_codeToNum === 1010) {
        setBtnDisabled(true);
        setIsLoadFailed(false);
      } else {
        setBtnDisabled(false);
        setIsLoadFailed(true);
      }
      handleModal(<ToastModalContent type='warning' txt={retMsg} />);
    }
  }, [config, handleModal, modalShadow]);

  // const calculateEstimateTxFee = useCallback(async (encodingParams: any) => {
  //   try {
  //     const bridgeContract = fromChainConfig.bridgeNftScAddr;
  //     const ep = fromChainConfig.endpoint;
  //     // const encoded = memaEncodeTransferNFT(encodingParams);
  //     const estimated = await estimateGasPrice(ep, encodingParams, bridgeContract);
  //     return estimated;
  //   } catch (e) {
  //     throw e;
  //   }
  // }, [fromChainConfig]);

  useEffect(() => {
    requestCreateMsg();
  }, []);

  const checkReceiveNFTMsg = useCallback(
    async (txHash: string) => {
      const returnValue = await requestNFTCheckMessage(txHash, {
        message_id: _messageId,
      });
      const status = returnValue.result?.messageStatus || -1;
      bridgePageAction._setMessageStatus(returnValue.result);
      bridgePageAction._changeResult(txHash);
      return status;
    },
    [_messageId]
  );

  const checkTransactionReceiptOnEvm = useCallback(
    async (txHash: string) => {
      console.log(txHash);
      const returnValue = await requestJsonRpc(
        fromChainEp,
        'eth_getTransactionReceipt',
        [txHash]
      );
      const stats = returnValue.result?.status || -1;
      const status = parseInt(stats);
      return status;
    },
    [fromChainEp]
  );

  const checkTransactionReceiptOnHvh = useCallback(
    async (txHash: string) => {
      const returnValue = await iconexCheckTxConfirm(fromChainEp, txHash);
      const stats = returnValue.result?.status || -1;
      const status = parseInt(stats);
      return status;
    },
    [fromChainEp]
  );

  const checkTransactionReceiptOnAptos = useCallback(
    async (txHash: string) => {
      const returnValue = await aptosCheckTxConfirm(fromChainEp, txHash);
      let status = 0;
      if ((returnValue as any).success) {
        status = 1;
      }
      // const stats = returnValue.result?.status || -1;
      return status;
    },
    [fromChainEp]
  );

  const checkTransactionReceiptOnXPLA = useCallback(async (txHash: string) => {
    let txInfo = undefined;
    try {
      txInfo = await getXplaLCDClient().tx.txInfo(txHash);
      console.log(txInfo);
    } catch (e) {
      return -1;
    }
    if (txInfo == null) {
      return -1;
    }

    return 1;
  }, []);

  const handleOnCheckMsg = useCallback(
    async (txHash?: string) => {
      if (!!txHash) {
        setPolling({
          txHash: txHash,
          func: checkReceiveNFTMsg,
        });
      }
    },
    [checkReceiveNFTMsg, setPolling]
  );

  const toastModalController = useCallback(
    (message: string) => {
      handleModal(<ToastModalContent type='warning' txt={message} />);
    },
    [handleModal]
  );

  /* Tx를 쏘기 전에 NFT가 Approved 되었는지 확인하고 Approved 여부에 따라 Approve || TransferNFT를 호출합니다.**/
  const checkIsApproved = async (
    createdMsg: CreatedNFTMsgType,
    fromChain: any
  ) => {
    let isApproved = false;
    const _tokenContract = createdMsg.fromToken;
    const _senderAddr = createdMsg.senderAddress;
    const tokenType = createdMsg.tokenType;
    const tokenId = createdMsg.tokenId;
    const networkId = createdMsg.fromChain;

    const bridgeContract = fromChain.bridgeNftScAddr;
    const fromChainEndpoint = fromChain.endpoint;
    if (networkId === NETWORK_ID.HAVAH) {
      if (tokenType === 1) {
        isApproved = await havahCheckGetApproved(
          bridgeContract,
          tokenId,
          _tokenContract
        );
      } else {
        isApproved = await havahCheckIsApproved(
          _senderAddr,
          bridgeContract,
          _tokenContract
        );
      }
    }
    // Fix this
    else if (
      networkId === NETWORK_ID.APTOS ||
      networkId === NETWORK_ID.SOLANA
    ) {
      isApproved = true;
    } else if (networkId === NETWORK_ID.XPLA) {
      if (tokenType === 1) {
        try {
          const ret: any = await getXplaLCDClient().wasm.contractQuery(
            _tokenContract,
            {
              approval: { token_id: tokenId, spender: bridgeContract },
            }
          );
          if (ret) isApproved = true;
        } catch {
          isApproved = false;
        }
      } else {
        const ret: any = await getXplaLCDClient().wasm.contractQuery(
          _tokenContract,
          {
            is_approved_for_all: {
              owner: _senderAddr,
              operator: bridgeContract,
            },
          }
        );
        isApproved = ret.approved;
      }
    } else {
      if (tokenType === 1) {
        isApproved = await ethCheckGetApproved(
          fromChainEndpoint,
          tokenId,
          bridgeContract,
          _tokenContract
        );
      } else {
        isApproved = await ethCheckIsApproved(
          fromChainEndpoint,
          _senderAddr,
          bridgeContract,
          _tokenContract
        );
      }
    }
    return isApproved;
  };

  const handleOnTransferNFT = useCallback(async () => {
    try {
      pollingStepAction._changePollingStep(1);
      const { decimal, commission, txFee, senderAddress, tokenType } =
        createdMsg;
      const bridgeContract = fromChainConfig?.bridgeNftScAddr;
      if (walletId === WALLET_ID.METAMASK) {
        let encodedTx;

        if (
          tokenType === 2 &&
          (config.fromToken as FromNFTConfig).transferTokenAmount > 1
        ) {
          encodedTx = memaEncodeTransferNFTBatch({
            ...createdMsg,
          });
        } else {
          encodedTx = memaEncodeTransferNFT({
            ...createdMsg,
          });
        }
        // const { gas, gasPrice } = await calculateEstimateTxFee(createdMsg);

        const _commission = BigNumber(commission).times(Math.pow(10, decimal));
        const _txFee = BigNumber(txFee).times(Math.pow(10, decimal));
        const _total = toHex(BigNumber(_commission).plus(_txFee).toNumber());

        const txObj =
          fromChainConfig.chainNo === 102
            ? {
                from: senderAddress,
                to: bridgeContract,
                value: _total,
                data: encodedTx,
                gas: '600000',
                gasPrice: '3000000000',
              }
            : fromChainConfig.chainNo === 103
            ? {
                from: senderAddress,
                to: bridgeContract,
                value: _total,
                data: encodedTx,
                gas: '100000',
                gasPrice: '1000000000',
              }
            : {
                from: senderAddress,
                to: bridgeContract,
                value: _total,
                data: encodedTx,
              };
        console.log(txObj);
        // calculateEstimateTxFee(txObj);

        const { txHash }: any = await sendTransaction(txObj);
        setPolling({
          txHash,
          func: checkReceiveNFTMsg,
        });
      } else if (walletId === WALLET_ID.HAVAH) {
        let params: any;
        if (
          tokenType === 2 &&
          (config.fromToken as FromNFTConfig).transferTokenAmount > 1
        ) {
          params = await havahTransferNFTBatch({
            ...createdMsg,
          });
        } else {
          params = await havahTransferNFT({
            ...createdMsg,
          });
        }

        const txObj = {
          from: senderAddress,
          to: bridgeContract,
          value: BigNumber(commission).plus(txFee).toNumber(),
          ...params,
        };

        const txResult = await havahSendTransaction(txObj);
        if (txResult.type === 'cancel' || txResult.type === 'close') {
          throw new Error(JSON.stringify('P200'));
        }
        setPolling({
          txHash: txResult.txHash,
          func: checkReceiveNFTMsg,
        });
      } else if (walletId === WALLET_ID.PETRA) {
        const _commission = new BigNumber(commission).times(
          new BigNumber(10).pow(8)
        );
        const _txFee = new BigNumber(txFee).times(new BigNumber(10).pow(8));

        const txObj = await encodeTransferNFT({
          moduleAccount: bridgeContract,
          _commission: _commission,
          _txFee: _txFee,
          ...createdMsg,
        });
        try {
          const txResult = await aptosSendTransaction(txObj);
          console.log(txResult);
          setPolling({
            txHash: txResult.hash,
            func: checkReceiveNFTMsg,
          });
        } catch (e: any) {
          throw new Error(JSON.stringify('P200'));
        }
      } else if (walletId === WALLET_ID.XPLA) {
        let transferMsg;
        let protoMsgs;
        if (
          tokenType === 2 &&
          (config.fromToken as FromNFTConfig).transferTokenAmount > 1
        ) {
          const signature = Buffer.from(createdMsg.signature, 'hex');
          let hexArray = Array.from(signature);
          let tokenArray: any[] = [];
          let innerArray: any[] = [];

          for (let i = 0; i < createdMsg.tokens[0].length; i++) {
            innerArray.push(
              IconConverter.toBigNumber(createdMsg.tokens[0][i]).toString()
            );
          }
          tokenArray.push(innerArray);
          innerArray = [];
          for (let i = 0; i < createdMsg.tokens[1].length; i++) {
            innerArray.push(
              IconConverter.toBigNumber(createdMsg.tokens[1][i]).toString()
            );
          }
          tokenArray.push(innerArray);

          const _commission = IconConverter.toBigNumber(Math.pow(10, decimal))
            .times(createdMsg.commission)
            .toString();
          const _targetChainFee = IconConverter.toBigNumber(
            Math.pow(10, decimal)
          )
            .times(createdMsg.targetChainFee ? createdMsg.targetChainFee : 0)
            .toString();
          transferMsg = {
            transfer_nft_batch: {
              message_id: createdMsg.messageId,
              expire: createdMsg.blockHeight,
              from_chain: createdMsg.fromChain,
              from_token: createdMsg.fromToken,
              to_chain: createdMsg.toChain,
              token_ids: tokenArray,
              token_type: createdMsg.tokenType,
              commission: _commission,
              target_chain_fee: _targetChainFee,
              recipient: createdMsg.recipient,
              signature1: hexArray.slice(0, 32),
              signature2: hexArray.slice(32, 64),
            },
          };
        } else {
          const signature = Buffer.from(createdMsg.signature, 'hex');
          let hexArray = Array.from(signature);
          const _commission = IconConverter.toBigNumber(Math.pow(10, decimal))
            .times(createdMsg.commission)
            .toString();
          const _targetChainFee = IconConverter.toBigNumber(
            Math.pow(10, decimal)
          )
            .times(createdMsg.targetChainFee ? createdMsg.targetChainFee : 0)
            .toString();
          transferMsg = {
            transfer_nft: {
              message_id: createdMsg.messageId,
              expire: createdMsg.blockHeight,
              from_chain: createdMsg.fromChain,
              from_token: createdMsg.fromToken,
              to_chain: createdMsg.toChain,
              token_id: createdMsg.tokenId.toString(),
              token_type: createdMsg.tokenType,
              commission: _commission,
              target_chain_fee: _targetChainFee,
              recipient: createdMsg.recipient,
              signature1: hexArray.slice(0, 32),
              signature2: hexArray.slice(32, 64),
            },
          };
        }
        const amount = IconConverter.toBigNumber(Math.pow(10, decimal))
          .times(BigNumber(commission).plus(txFee).toNumber())
          .toString();
        if (amount === '0') {
          protoMsgs = {
            typeUrl: '/cosmwasm.wasm.v1.MsgExecuteContract',
            value: MsgExecuteContract.encode({
              sender: senderAddress,
              contract: bridgeContract,
              msg: toUtf8(JSON.stringify(transferMsg)),
              funds: [],
            }).finish(),
          };
        } else {
          protoMsgs = {
            typeUrl: '/cosmwasm.wasm.v1.MsgExecuteContract',
            value: MsgExecuteContract.encode({
              sender: senderAddress,
              contract: bridgeContract,
              msg: toUtf8(JSON.stringify(transferMsg)),
              funds: [{ denom: 'axpla', amount: amount }],
            }).finish(),
          };
        }

        const txhash = await sendMsgsFromKeplr(
          KEPLR_CHAIN_INFO[BRIDGE_CHAIN_NAME[chainNo]],
          senderAddress,
          [protoMsgs],
          {
            amount: [{ denom: 'axpla', amount: '400000' }],
            gas: Math.floor(400000).toString(),
          }
        );

        if (txhash) {
          setPolling({
            txHash: txhash.toUpperCase(),
            func: checkReceiveNFTMsg,
          });
        } else {
          throw new Error();
        }
      } else if (walletId === WALLET_ID.PHANTOM) {
        try {
          const _commission = IconConverter.toBigNumber(Math.pow(10, decimal))
            .times(createdMsg.commission)
            .toString();
          const _targetChainFee = IconConverter.toBigNumber(
            Math.pow(10, decimal)
          )
            .times(createdMsg.targetChainFee ? createdMsg.targetChainFee : 0)
            .toString();
          if (
            tokenType === 2 &&
            (config.fromToken as FromNFTConfig).transferTokenAmount > 1
          ) {
            const txhash = await transferNFTFromSolana({
              senderAddr: createdMsg.senderAddress,
              bridgeAddr: bridgeContract,
              messageId: createdMsg.messageId,
              blockHeight: createdMsg.blockHeight,
              fromToken: createdMsg.tokens[0][0],
              toChain: createdMsg.toChain,
              commission: _commission,
              targetChainFee: _targetChainFee,
              recipient: createdMsg.recipient,
              signature: createdMsg.signature,
              endPoint: fromChainEp,
              amount: (config.fromToken as FromNFTConfig).transferTokenAmount,
            });
            if (txhash) {
              setPolling({
                txHash: txhash,
                func: checkReceiveNFTMsg,
              });
            } else {
              throw new Error();
            }
          } else {
            const txhash = await transferNFTFromSolana({
              senderAddr: createdMsg.senderAddress,
              bridgeAddr: bridgeContract,
              messageId: createdMsg.messageId,
              blockHeight: createdMsg.blockHeight,
              fromToken: createdMsg.tokenId,
              toChain: createdMsg.toChain,
              commission: _commission,
              targetChainFee: _targetChainFee,
              recipient: createdMsg.recipient,
              signature: createdMsg.signature,
              endPoint: fromChainEp,
            });
            if (txhash) {
              setPolling({
                txHash: txhash,
                func: checkReceiveNFTMsg,
              });
            } else {
              throw new Error();
            }
          }
        } catch (e: any) {
          throw new Error(e.code);
        }
      }
    } catch (e: any) {
      const { retMsg, bCosmosChianError } = errCodeParser(e);
      if (bCosmosChianError) {
        const msg = fromChainConfig.chainName + ' Error \n' + retMsg;
        toastModalController(msg);
      } else {
        toastModalController(retMsg);
      }
      pollingStepAction._changePollingStep(-1);
    }
  }, [
    createdMsg,
    walletId,
    fromChainEp,
    fromChainConfig,
    chainNo,
    config.fromToken,
    checkReceiveNFTMsg,
    setPolling,
    toastModalController,
    sendTransaction,
  ]);

  const handleOnApprove = useCallback(async () => {
    try {
      const { senderAddress, tokenId } = createdMsg;

      const tokenContract = createdMsg.fromToken;
      const tokenType = createdMsg.tokenType;

      const bridgeContract = fromChainConfig.bridgeNftScAddr;
      let encodedTx: any;

      if (walletId === WALLET_ID.METAMASK) {
        if (tokenType === 1) {
          encodedTx = memaEncodeApprove(bridgeContract, tokenId);
        } else {
          encodedTx = memaEncodeApproveAll(bridgeContract);
        }
        const rawTxObj =
          fromChainConfig.chainNo === 102
            ? {
                from: senderAddress,
                to: tokenContract,
                data: encodedTx,
                gasLimit: '600000',
                gasPrice: '2000000000',
              }
            : fromChainConfig.chainNo === 103
            ? {
                from: senderAddress,
                to: tokenContract,
                data: encodedTx,
                gas: '100000',
                gasPrice: '1000000000',
              }
            : {
                from: senderAddress,
                to: tokenContract,
                data: encodedTx,
              };
        console.log(rawTxObj);

        const { txHash }: any = await sendTransaction(rawTxObj);
        setPolling({
          txHash,
          func: checkTransactionReceiptOnEvm,
          next: handleOnTransferNFT,
        });
      } else if (walletId === WALLET_ID.HAVAH) {
        if (tokenType === 1) {
          encodedTx = await havahApproveNFT({
            senderAddr: senderAddress,
            tokenAddr: tokenContract,
            bridgeAddr: bridgeContract,
            amount: tokenId,
          });
        } else {
          encodedTx = await havahApproveAllNFT({
            senderAddr: senderAddress,
            tokenAddr: tokenContract,
            bridgeAddr: bridgeContract,
          });
        }
        const txResult = await havahSendTransaction(encodedTx);
        if (txResult.type === 'cancel' || txResult.type === 'close') {
          throw new Error(JSON.stringify('P200'));
        } else if (txResult.message === 'not-connected') {
          throw new Error(JSON.stringify('H004'));
        }
        setPolling({
          txHash: txResult.txHash,
          func: checkTransactionReceiptOnHvh,
          next: handleOnTransferNFT,
        });
      } else if (walletId === WALLET_ID.PETRA) {
        encodedTx = await encodeTransferNFT({
          moduleAccount: bridgeContract,
          ...createdMsg,
        });
        const txResult = await aptosSendTransaction(encodedTx);
        if (txResult.type === 'cancel' || txResult.type === 'close') {
          throw new Error(JSON.stringify('P200'));
        }
        setPolling({
          txHash: txResult.txHash,
          func: checkTransactionReceiptOnAptos,
          next: handleOnTransferNFT,
        });
      } else if (walletId === WALLET_ID.XPLA) {
        // connect(ConnectType.EXTENSION);

        let approveMsg;
        if (tokenType === 1) {
          approveMsg = {
            approve: {
              spender: bridgeContract,
              token_id: tokenId,
            },
          };
        } else {
          approveMsg = {
            approve_all: {
              operator: bridgeContract,
            },
          };
        }

        const protoMsgs = {
          typeUrl: '/cosmwasm.wasm.v1.MsgExecuteContract',
          value: MsgExecuteContract.encode({
            sender: senderAddress,
            contract: tokenContract,
            msg: toUtf8(JSON.stringify(approveMsg)),
            funds: [],
          }).finish(),
        };

        const txhash = await sendMsgsFromKeplr(
          KEPLR_CHAIN_INFO[BRIDGE_CHAIN_NAME[chainNo]],
          senderAddress,
          [protoMsgs],
          {
            amount: [{ denom: 'axpla', amount: '250000' }],
            gas: Math.floor(250000).toString(),
          }
        );
        if (txhash)
          setPolling({
            txHash: txhash,
            func: checkTransactionReceiptOnXPLA,
            next: handleOnTransferNFT,
          });
        else {
          throw new Error();
        }
      }
    } catch (e: any) {
      console.log(e);
      const { retMsg, bCosmosChianError } = errCodeParser(e);
      if (bCosmosChianError) {
        const msg = fromChainConfig.chainName + ' Error \n' + retMsg;
        toastModalController(msg);
      } else {
        toastModalController(retMsg);
      }
      pollingStepAction._changePollingStep(-1);
    }
  }, [
    createdMsg,
    walletId,
    chainNo,
    fromChainConfig,
    checkTransactionReceiptOnAptos,
    checkTransactionReceiptOnHvh,
    checkTransactionReceiptOnEvm,
    checkTransactionReceiptOnXPLA,
    setPolling,
    handleOnTransferNFT,
    toastModalController,
    sendTransaction,
  ]);

  const send = useCallback(async () => {
    try {
      pollingStepAction._changePollingStep(0);
      const isApproved = await checkIsApproved(createdMsg, fromChainConfig);
      if (isApproved) {
        handleOnTransferNFT();
      } else {
        handleOnApprove();
      }
    } catch (e) {
      const { retMsg, bCosmosChianError } = errCodeParser(e);
      if (bCosmosChianError) {
        const msg = fromChainConfig.chainName + ' Error \n' + retMsg;
        toastModalController(msg);
      } else {
        toastModalController(retMsg);
      }
      pollingStepAction._changePollingStep(-1);
    }
  }, [
    createdMsg,
    fromChainConfig,
    handleOnApprove,
    handleOnTransferNFT,
    toastModalController,
  ]);

  return (
    <>
      <StyledWrapper step={4} className='summary'>
        <BridgeSummaryHeader />
        <BridgeSummaryView msg={createdMsg} />
      </StyledWrapper>
      <StyledTransferWrapper>
        <Warning3 />
        <StyledBtnWrapper width={580} marginTop={18}>
          <Button
            disabled={isDisabled}
            name={isFailed ? 'Retry' : 'Send'}
            handleOnClick={isFailed ? requestCreateMsg : send}
            timer={
              hasFinalization
                ? chainNo === NETWORK_ID.BASE
                  ? '(20min)'
                  : chainNo === NETWORK_ID.ETHEREUM
                  ? '(16min)'
                  : '(15min)'
                : ''
            }
            fontWeight={700}
          />
        </StyledBtnWrapper>
      </StyledTransferWrapper>
    </>
  );
};

export { SendViewContainer };

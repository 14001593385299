import { EventEmitter } from 'events';
import dispatcher from 'src/dispatcher/dispatcher';
import { POLLING_STEP } from 'src/constants/actionTypes';

const CHANGE_STEP_EVENT = 'CHANGE_POLLING_STEP';
let _stepCount: number = -1;

class PollingStepStore extends EventEmitter {
  constructor() {
    super();
    dispatcher.register(this._registerToAction.bind(this));
  }

  _registerToAction = (payload: any) => {
    const action = payload.action.actionType;
    const stepNum = payload.action.id;
    switch (action) {
      case POLLING_STEP.SET_POLLING_STEP:
        this.setStep(stepNum);
        this.emitChange();
        break;
      default:
        break;
    }
  };

  addChangeListener(callback: any) {
    this.addListener(CHANGE_STEP_EVENT, callback);
  }

  removeChangeListener(callback: any) {
    this.removeListener(CHANGE_STEP_EVENT, callback);
  }

  emitChange() {
    this.emit(CHANGE_STEP_EVENT);
  }

  setStep = (stepNum: number) => {
    _stepCount = stepNum;
  };

  getPollingStep() {
    return _stepCount;
  }
}

export default PollingStepStore;

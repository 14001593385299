import React, { useState, useCallback, useEffect, useContext } from 'react';
import bridgePageStore from 'src/stores/BridgePageStore';
import PollingStepStore from 'src/stores/PollingStepStore';
import { bridgePageAction } from 'src/actions/bridgePageAction';
import { pollingStepAction } from 'src/actions/pollingModalAction';
import {
  ToastModalContent,
  ModalServiceUnavailable,
  BridgeSummaryView,
  Button,
  BridgeSummaryHeader,
  Warning3,
} from 'src/components/index';
import { ModalContext } from 'src/contexts/ToastModalContext';
import { ModalContext as ModalShadowContext } from 'src/contexts/ModalShadowContext';
import { requestFTCreateMessage, requestFTCheckMessage } from 'src/apis/api';
import { usePolling } from 'src/hooks/usePolling';
import { useBackListener } from 'src/hooks/useBackListener';
import { ethCheckAllowance } from 'src/sc/metamask';
import {
  havahCheckAllowance,
  havahSendTransaction,
  havahApproveFT,
  havahTransferFT,
  havahCheckTxConfirm,
} from 'src/sc/havah';
import {
  requestApprove,
  memaEncodeApproveErc20,
  memaEncodeTransferFT,
  requestTransferFT,
  requestJsonRpc,
} from 'src/sc/metamask';
import {
  StyledBtnWrapper,
  StyledWrapper,
  StyledTransferWrapper,
} from 'src/pages/StyledPage';
import { WALLET_ID, NETWORK_ID } from 'src/constants/enums';
import {
  ChainConfig,
  CreatedFTMsgType,
  FromFTConfig,
} from 'src/constants/types';
import { ERROR_MSG } from 'src/constants/codes';
import {
  errCodeParser,
  numberGreaterThan,
  numberEqualTo,
  bigNumberDivideDecimal,
} from 'src/utils/utils';
import IconService from 'icon-sdk-js';
import BigNumber from 'bignumber.js';
import { getXplaLCDClient, sendMsgsFromKeplr } from 'src/sc/keplr';
import { BRIDGE_CHAIN_NAME, KEPLR_CHAIN_INFO } from 'src/constants';
import { toUtf8 } from '@cosmjs/encoding';
import { MsgExecuteContract } from 'src/cosmos/cosmwasm/wasm/v1/tx';
import { useMetamask } from 'src/hooks/useMetamask';
const { IconConverter } = IconService;

const usdtAddr = process.env.REACT_APP_USDT_TOKEN_ADDR;

const preventGoback = () => {
  window.history.forward();
};

const convertMsg = (obj: any) => {
  const params: CreatedFTMsgType = {
    messageId: obj.messageId,
    blockHeight: obj.blockHeight,
    decimal: obj.decimal,
    fromChain: obj.fromChainNo,
    fromToken: obj.fromTokenScAddr,
    toChain: obj.toChainNo,
    tokenId: obj.fromTokenId,
    tokenType: obj.tokenType,
    commission: obj.commission,
    targetChainFee: obj.txFee,
    recipient: obj.recipientAddress,
    signature: obj.sig,
    txFee: obj.txFee,
    senderAddress: obj.senderAddress,
    amount: obj.amount,
  };
  return params;
};

const SendViewContainer: React.FC = () => {
  // useBackListener(preventGoback);
  const { setPolling } = usePolling();
  const store = bridgePageStore;
  const pollingStore = new PollingStepStore();
  const config = store.getBridgeConfig();
  const { sendTransaction } = useMetamask();
  const { handleModal } = useContext(ModalContext);
  const modalShadow = useContext(ModalShadowContext);
  const [isFailed, setIsLoadFailed] = useState<boolean>(false);
  const [isDisabled, setBtnDisabled] = useState<boolean>(true);
  const [createdMsg, setCreatedMsg] = useState<CreatedFTMsgType>({
    messageId: '',
    blockHeight: 0,
    decimal: 0,
    fromChain: 0,
    fromToken: '',
    toChain: 0,
    tokenId: 0,
    tokenType: '',
    commission: -1,
    targetChainFee: 0,
    recipient: '',
    signature: '',
    txFee: -1,
    senderAddress: '',
    amount: -1,
  });

  const [pollingStep, setPollingStep] = useState<number>(-1);

  const { walletId } = config;
  const fromChain = config.fromChain as ChainConfig;
  const fromToken = config.fromToken as FromFTConfig;
  const chainNo = fromChain.chainNo;
  const hasFinalization =
    chainNo === NETWORK_ID.ETHEREUM ||
    chainNo === NETWORK_ID.POLYGON ||
    chainNo === NETWORK_ID.BASE;

  const fromChainEp = fromChain.endpoint;
  const _messageId = createdMsg.messageId;

  const _pollingStep = () => {
    const store = new PollingStepStore();
    const pollingStep = store.getPollingStep();
    setPollingStep(pollingStep);
  };

  useEffect(() => {
    pollingStore.addChangeListener(_pollingStep);
    return () => pollingStore.removeChangeListener(_pollingStep);
  }, []);

  useEffect(() => {
    getMessage();
  }, []);

  const feeCalculator = (
    commission: number,
    txFee: number,
    balance?: number
  ) => {
    if (!!balance) {
      const _txFee = txFee < 0 ? 0 : txFee;
      const _commission = commission < 0 ? 0 : commission;
      return (
        (balance * Math.pow(10, 7) -
          _commission * Math.pow(10, 7) -
          _txFee * Math.pow(10, 7)) *
        Math.pow(10, -1 * 7)
      ).toFixed(7);
    } else {
      if (txFee < 0 || commission < 0) {
        return '-';
      }
      return (
        (commission * Math.pow(10, 7) + txFee * Math.pow(10, 7)) *
        Math.pow(10, -1 * 7)
      ).toFixed(7);
    }
  };

  const toastModalController = useCallback(
    (message: string) => {
      handleModal(<ToastModalContent type='warning' txt={message} />);
    },
    [handleModal]
  );

  const checkReceiveNFTMsg = useCallback(
    async (txHash: string) => {
      const returnValue = await requestFTCheckMessage(txHash, {
        message_id: _messageId,
      });
      const status = returnValue.result?.messageStatus || -1;
      bridgePageAction._setMessageStatus(returnValue.result);
      bridgePageAction._changeResult(txHash);
      return status;
    },
    [_messageId]
  );

  const checkTransactionReceiptOnEvm = useCallback(
    async (txHash: string) => {
      const returnValue = await requestJsonRpc(
        fromChainEp,
        'eth_getTransactionReceipt',
        [txHash]
      );
      const stats = returnValue.result?.status || -1;
      const status = parseInt(stats);
      return status;
    },
    [fromChainEp]
  );

  const checkTransactionReceiptOnHvh = useCallback(
    async (txHash: string) => {
      const returnValue = await havahCheckTxConfirm(fromChainEp, txHash);
      const stats = returnValue.result?.status || -1;
      const status = parseInt(stats);
      return status;
    },
    [fromChainEp]
  );

  const checkTransactionReceiptOnXPLA = useCallback(async (txHash: string) => {
    let txInfo = undefined;
    try {
      // fix XPLA
      txInfo = await getXplaLCDClient().tx.txInfo(txHash);
      console.log(txInfo);
    } catch (e) {
      return -1;
    }
    if (txInfo == null) {
      return -1;
    }

    return 1;
  }, []);

  const createMetamaskTransferFT = useCallback(
    async ({
      decimal,
      senderAddress,
      bridgeContract,
      fromChainNo,
      tokenType,
      amount,
    }: any) => {
      try {
        let rawTx;
        const encodedTx = await memaEncodeTransferFT({
          ...createdMsg,
        });
        rawTx = await requestTransferFT({
          decimal: decimal,
          from: senderAddress,
          to: bridgeContract,
          data: encodedTx,
          chainId: fromChainNo,
          value: tokenType === 'C' ? amount : 0,
        });

        if (fromChainNo === NETWORK_ID.WEMIX) {
          rawTx = {
            ...rawTx,
            gas: '600000',
            gasPrice: '3000000000',
          };
        }
        const { txHash }: any = await sendTransaction(rawTx);

        return txHash;
      } catch (e) {
        throw e;
      }
    },
    [createdMsg]
  );

  const createHAVAHTransferFT = useCallback(
    async ({ senderAddress, bridgeContract, tokenType, amount }: any) => {
      try {
        const encodedTx = await havahTransferFT({
          ...createdMsg,
          senderAddr: senderAddress,
          bridgeAddr: bridgeContract,
          value: tokenType === 'C' ? amount : 0,
        });

        const txResult = await havahSendTransaction(encodedTx);
        if (txResult.type === 'cancel' || txResult.type === 'close') {
          throw new Error(JSON.stringify('P200'));
        }
        return txResult.txHash;
      } catch (e) {
        throw e;
      }
    },
    [createdMsg]
  );

  const handleOnCheckMsg = useCallback(
    async (txHash?: string) => {
      if (!!txHash) {
        setPolling({
          txHash: txHash,
          func: checkReceiveNFTMsg,
        });
      }
    },
    [checkReceiveNFTMsg, setPolling]
  );

  const getMessage = useCallback(async () => {
    try {
      const { fromWalletAddr, toWalletAddr, balance } = config;

      const fromChain = config.fromChain as ChainConfig;
      const toChain = config.toChain as ChainConfig;
      const fromToken = config.fromToken as FromFTConfig;

      const fromChainNo = fromChain.chainNo;
      const fromTokenId = fromToken.tokenId;
      const tokenType = fromToken.tokenType;
      const toChainNo = toChain.chainNo;

      const _parsedNum = parseFloat(balance.replaceAll(',', ''));

      const props = {
        toTxSender: 'bridge', // 수신 chain에 tx를 보낼 주체 (bridge: 서버, wallet: 개인)
        fromChainNo,
        fromTokenId, // USDT
        toChainNo, // HAVAH
        amount: _parsedNum,
        senderAddress: fromWalletAddr, // 보내는 사람 Address
        recipientAddress: toWalletAddr,
      };

      const rsp = await requestFTCreateMessage(props);
      const result = {
        ...rsp,
        tokenType,
        amount: _parsedNum,
      };
      const encodingParams = convertMsg(result);
      setCreatedMsg(encodingParams);
      const _balance = parseFloat(balance.replaceAll(',', ''));
      const total_token = feeCalculator(
        result.commission,
        result.txFee,
        _balance
      );
      parseFloat(total_token) < 0
        ? setBtnDisabled(true)
        : setBtnDisabled(false);
      setIsLoadFailed(false);
    } catch (e: any) {
      setIsLoadFailed(true);
      setBtnDisabled(false);
      const { retCode } = errCodeParser(e);
      const _codeToNum = parseInt(retCode);
      if (_codeToNum === 1) {
        modalShadow.handleModal(
          <ModalServiceUnavailable close={() => modalShadow.handleModal()} />
        );
        return;
      } else {
        const default_msg = ERROR_MSG['DEFAULT_1'];
        const msg = !!ERROR_MSG[_codeToNum]
          ? ERROR_MSG[_codeToNum]
          : default_msg;
        handleModal(<ToastModalContent type='warning' txt={msg} />);
      }
      throw e;
    }
  }, [config, fromToken]);

  const createMetamaskApprove = useCallback(
    async ({
      bridgeContract,
      senderAddress,
      tokenContract,
      fromChainNo,
    }: any) => {
      let resultApprove;
      try {
        const encodedTx = memaEncodeApproveErc20(bridgeContract);
        resultApprove = await requestApprove({
          from: senderAddress,
          to: tokenContract,
          chainId: fromChainNo,
          data: encodedTx,
        });
        if (fromChainNo === NETWORK_ID.WEMIX) {
          resultApprove = {
            ...resultApprove,
            gas: '600000',
            gasPrice: '3000000000',
          };
        }
        const { txHash }: any = await sendTransaction(resultApprove);
        return txHash;
      } catch (e) {
        throw e;
      }
    },
    [sendTransaction]
  );

  const callTransferFT = useCallback(async () => {
    try {
      pollingStepAction._changePollingStep(1);
      const { senderAddress, amount, tokenType, decimal } = createdMsg;
      const fromChainNo = createdMsg.fromChain;
      const bridgeContract = fromChain.bridgeFtScAddr;
      if (walletId === WALLET_ID.METAMASK) {
        const txHash = await createMetamaskTransferFT({
          senderAddress,
          amount,
          tokenType,
          decimal,
          fromChainNo,
          bridgeContract,
        });
        setPolling({
          txHash,
          func: checkTransactionReceiptOnEvm,
          next: handleOnCheckMsg,
        });
      }

      if (walletId === WALLET_ID.HAVAH) {
        const txHash = await createHAVAHTransferFT({
          senderAddress,
          bridgeContract,
          tokenType,
          amount,
        });
        setPolling({
          txHash,
          func: checkTransactionReceiptOnHvh,
          next: handleOnCheckMsg,
        });
      }

      if (walletId === WALLET_ID.XPLA) {
        let protoMsgs;
        const signature = Buffer.from(createdMsg.signature, 'hex');
        let hexArray = Array.from(signature);
        const _commission = IconConverter.toBigNumber(Math.pow(10, decimal))
          .times(createdMsg.commission)
          .toFixed();
        const _targetChainFee = IconConverter.toBigNumber(Math.pow(10, decimal))
          .times(createdMsg.targetChainFee ? createdMsg.targetChainFee : 0)
          .toFixed();
        const _amount = IconConverter.toBigNumber(Math.pow(10, decimal))
          .times(createdMsg.amount)
          .toFixed();
        console.log(_amount);
        const transferMsg = {
          transfer_ft: {
            amount: _amount,
            message_id: createdMsg.messageId,
            expire: createdMsg.blockHeight,
            from_chain: createdMsg.fromChain,
            from_token: createdMsg.fromToken,
            to_chain: createdMsg.toChain,
            token_id: createdMsg.tokenId.toString(),
            token_type: createdMsg.tokenType,
            commission: _commission,
            target_chain_fee: _targetChainFee,
            recipient: createdMsg.recipient,
            signature1: hexArray.slice(0, 32),
            signature2: hexArray.slice(32, 64),
          },
        };

        if (!!createdMsg.tokenType && createdMsg.tokenType === 'C') {
          const amount = IconConverter.toBigNumber(Math.pow(10, decimal))
            .times(BigNumber(createdMsg.amount).toNumber())
            .toFixed();

          protoMsgs = {
            typeUrl: '/cosmwasm.wasm.v1.MsgExecuteContract',
            value: MsgExecuteContract.encode({
              sender: senderAddress,
              contract: bridgeContract,
              msg: toUtf8(JSON.stringify(transferMsg)),
              funds: [{ denom: 'axpla', amount: amount }],
            }).finish(),
          };
        } else {
          protoMsgs = {
            typeUrl: '/cosmwasm.wasm.v1.MsgExecuteContract',
            value: MsgExecuteContract.encode({
              sender: senderAddress,
              contract: bridgeContract,
              msg: toUtf8(JSON.stringify(transferMsg)),
              funds: [],
            }).finish(),
          };
        }

        const txhash = await sendMsgsFromKeplr(
          KEPLR_CHAIN_INFO[BRIDGE_CHAIN_NAME[chainNo]],
          senderAddress,
          [protoMsgs],
          {
            amount: [{ denom: 'axpla', amount: '500000' }],
            gas: Math.floor(500000).toString(),
          }
        );

        if (txhash)
          setPolling({
            txHash: txhash.toUpperCase(),
            func: checkTransactionReceiptOnXPLA,
            next: handleOnCheckMsg,
          });
        else {
          throw new Error();
        }
      }
    } catch (e: any) {
      console.log(e);
      const { retMsg, bCosmosChianError } = errCodeParser(e);
      if (bCosmosChianError) {
        const msg = fromChain.chainName + ' Error \n' + retMsg;
        toastModalController(msg);
      } else {
        toastModalController(retMsg);
      }
      pollingStepAction._changePollingStep(-1);
    }
  }, [
    createdMsg,
    walletId,
    fromChain,
    chainNo,
    checkTransactionReceiptOnEvm,
    checkTransactionReceiptOnHvh,
    checkTransactionReceiptOnXPLA,
    createMetamaskTransferFT,
    createHAVAHTransferFT,
    setPolling,
    handleOnCheckMsg,
    toastModalController,
  ]);

  const createHAVAHApprove = async ({
    bridgeContract,
    senderAddress,
    tokenContract,
  }: any) => {
    try {
      const encodedTx = await havahApproveFT({
        senderAddr: senderAddress,
        tokenAddr: tokenContract,
        bridgeAddr: bridgeContract,
        amount: '0x33b2e3c9fd0803ce8000000',
      });

      const txResult = await havahSendTransaction(encodedTx);
      if (txResult.type === 'cancel' || txResult.type === 'close') {
        throw new Error(JSON.stringify('P200'));
      }
      return txResult.txHash;
    } catch (e) {
      throw e;
    }
  };

  const approve = useCallback(async () => {
    try {
      // Message Props;
      const tokenContract = createdMsg.fromToken;
      const fromChainNo = createdMsg.fromChain;
      const senderAddress = createdMsg.senderAddress;

      // From Chain Props;
      const bridgeContract = fromChain.bridgeFtScAddr;

      if (walletId === WALLET_ID.METAMASK) {
        const txHash = await createMetamaskApprove({
          bridgeContract,
          senderAddress,
          tokenContract,
          fromChainNo,
        });

        setPolling({
          txHash,
          func: checkTransactionReceiptOnEvm,
          next: callTransferFT,
        });
      }

      if (walletId === WALLET_ID.HAVAH) {
        const txHash = await createHAVAHApprove({
          bridgeContract,
          senderAddress,
          tokenContract,
        });
        setPolling({
          txHash,
          func: checkTransactionReceiptOnHvh,
          next: callTransferFT,
        });
      }

      if (walletId === WALLET_ID.XPLA) {
        const _amount = IconConverter.toBigNumber(createdMsg.amount)
          .times(Math.pow(10, createdMsg.decimal))
          .toFixed();
        const allowanceMsg = {
          increase_allowance: {
            amount: _amount,
            spender: bridgeContract,
          },
        };
        const protoMsgs = {
          typeUrl: '/cosmwasm.wasm.v1.MsgExecuteContract',
          value: MsgExecuteContract.encode({
            sender: senderAddress,
            contract: tokenContract,
            msg: toUtf8(JSON.stringify(allowanceMsg)),
            funds: [],
          }).finish(),
        };

        const txhash = await sendMsgsFromKeplr(
          KEPLR_CHAIN_INFO[BRIDGE_CHAIN_NAME[chainNo]],
          senderAddress,
          [protoMsgs],
          {
            amount: [{ denom: 'axpla', amount: '250000' }],
            gas: Math.floor(250000).toString(),
          }
        );

        if (txhash)
          setPolling({
            txHash: txhash,
            func: checkTransactionReceiptOnXPLA,
            next: callTransferFT,
          });
        else {
          throw new Error();
        }
      }
    } catch (e: any) {
      const { retMsg, bCosmosChianError } = errCodeParser(e);
      if (bCosmosChianError) {
        const msg = fromChain.chainName + ' Error \n' + retMsg;
        toastModalController(msg);
      } else {
        toastModalController(retMsg);
      }
      pollingStepAction._changePollingStep(-1);
      throw e;
    }
  }, [
    createdMsg,
    walletId,
    fromChain,
    chainNo,
    callTransferFT,
    checkTransactionReceiptOnEvm,
    checkTransactionReceiptOnHvh,
    checkTransactionReceiptOnXPLA,
    setPolling,
    toastModalController,
    createMetamaskApprove,
  ]);

  /**
   * @description Token SmartContract에 출금 허용 잔액 확인 및 설정,
   * USDT는 Allowance를 덮어쓰는 것이 불가능하기에 0으로 변경 후 재설정 해야합니다.
   * 그 외의 ERC20 Token은 Allowance가 부족한 경우 Approve를 요청하고
   * Allowance가 부족하지 않은 경우 transferFT를 호출합니다.
   * @returns nothing | transferFT | approve;
   */
  const checkAllowance = useCallback(async () => {
    const _tokenContract = createdMsg.fromToken;
    const _senderAddr = createdMsg.senderAddress;
    const networkId = createdMsg.fromChain;
    const tokenDecimal = createdMsg.decimal;
    const bridgeContract = fromChain.bridgeFtScAddr;
    const balance = createdMsg.amount;
    const fromChainEndpoint = fromChain.endpoint;

    // USDT의 경우 Allowance 초기화 과정이 필요해서 별도의 요청 수행;
    // Network 마다 조정 필요
    const isUSDT = (fromToken as FromFTConfig).tokenAddr === usdtAddr;
    let isGreaterThan;
    if (networkId === NETWORK_ID.HAVAH) {
      const approvedAllowance = await havahCheckAllowance(
        _senderAddr,
        bridgeContract,
        _tokenContract
      );
      // > 0x33b2e3c9fd0803ce20a1f00
      const allowance = bigNumberDivideDecimal(approvedAllowance, tokenDecimal);
      isGreaterThan =
        numberGreaterThan(allowance, balance) ||
        numberEqualTo(allowance, balance);
    } else if (networkId === NETWORK_ID.XPLA) {
      // fix XPLA
      const ret: any = await getXplaLCDClient().wasm.contractQuery(
        _tokenContract,
        {
          allowance: { owner: _senderAddr, spender: bridgeContract },
        }
      );
      const allowance = bigNumberDivideDecimal(ret.allowance, tokenDecimal);
      isGreaterThan =
        numberGreaterThan(allowance, balance) ||
        numberEqualTo(allowance, balance);
    } else {
      // 출금이 허용된 잔액을 호출합니다.
      const approvedAllowance = await ethCheckAllowance(
        fromChainEndpoint,
        _senderAddr,
        bridgeContract,
        _tokenContract
      );
      // > 0x0000000000000000000000000000000000000000000000056bc75e2d63100000
      const allowance = bigNumberDivideDecimal(approvedAllowance, tokenDecimal);
      if (numberEqualTo(allowance, 0)) {
        return 'approve';
      }
      isGreaterThan =
        numberGreaterThan(allowance, balance) ||
        numberEqualTo(allowance, balance);

      // 토큰 Contract가 USDT이고 출금 허용 잔액이 부족하면 0으로 초기화 합니다.
      if (isUSDT && !isGreaterThan) {
        pollingStepAction._changePollingStep(999);

        const tokenContract = createdMsg.fromToken;
        const senderAddress = createdMsg.senderAddress;
        const encodedTx = memaEncodeApproveErc20(bridgeContract, '0x0');
        const resultApprove = await requestApprove({
          from: senderAddress,
          to: tokenContract,
          data: encodedTx,
        });

        const { txHash }: any = await sendTransaction(resultApprove);
        setPolling({
          txHash,
          func: checkTransactionReceiptOnEvm,
          next: approve,
        });
        return 'nothing';
      }
    }

    return isGreaterThan ? 'transferFT' : 'approve';
  }, [
    createdMsg,
    fromToken,
    fromChain,
    approve,
    checkTransactionReceiptOnEvm,
    sendTransaction,
    setPolling,
  ]);

  /**
   * @params TokenType C | W | T (C : 코인, W : Wrapped, T : Token)
   * @description Tx를 쏘기 전에 NFT가 Approved 되었는지 확인하고 Approved 여부에 따라 Approve || TransferNFT를 호출합니다.
   * Token Type이 코인인 경우 TransferFT를 호출합니다. WAS에서 transferFrom을 호출하여 가져갑니다.
   * Token Type이 코인이 아닌 경우, Approve가 필요합니다. checkAllowance를 호출합니다.
   */
  const startTo = useCallback(async () => {
    try {
      const _tokenType = createdMsg.tokenType;
      if (!!_tokenType && _tokenType === 'C') {
        pollingStepAction._changePollingStep(0);
        callTransferFT();
      } else {
        const nextStep = await checkAllowance();
        if (nextStep === 'transferFT') {
          pollingStepAction._changePollingStep(0);
          callTransferFT();
        } else if (nextStep === 'approve') {
          pollingStepAction._changePollingStep(0);
          approve();
        }
      }
    } catch (e) {
      pollingStepAction._changePollingStep(-1);
      throw e;
    }
  }, [createdMsg, approve, callTransferFT, checkAllowance]);

  useEffect(() => {
    const eventHandler = async (event: any) => {
      const { type, payload } = event.detail;
      if (type === 'RESPONSE_JSON-RPC') {
        const { code } = payload;
        if (code < 0) {
          toastModalController('RESPONSE_JSON-RPC error code :' + code);
          pollingStepAction._changePollingStep(-1);
          return;
        }
        // runner(result);
      } else if (type === 'CANCEL_JSON-RPC') {
        toastModalController(ERROR_MSG['P200']);
        pollingStepAction._changePollingStep(-1);
      }
    };
    window.addEventListener('ICONEX_RELAY_RESPONSE', eventHandler);
    return () =>
      window.removeEventListener('ICONEX_RELAY_RESPONSE', eventHandler);
  }, [pollingStep, fromChain]);

  return (
    <>
      <StyledWrapper step={4} className='summary'>
        <BridgeSummaryHeader />
        <BridgeSummaryView msg={createdMsg} />
      </StyledWrapper>
      <StyledTransferWrapper>
        <Warning3 />
        <StyledBtnWrapper width={580} marginTop={18}>
          <Button
            disabled={isDisabled}
            name={isFailed ? 'Retry' : 'Send'}
            handleOnClick={isFailed ? getMessage : startTo}
            timer={
              hasFinalization
                ? chainNo === NETWORK_ID.BASE
                  ? '(20min)'
                  : chainNo === NETWORK_ID.ETHEREUM
                  ? '(16min)'
                  : '(15min)'
                : ''
            }
            fontWeight={700}
          />
        </StyledBtnWrapper>
      </StyledTransferWrapper>
    </>
  );
};

export { SendViewContainer };

import { get, post } from 'src/apis/rest';
import { NFTInfoType } from 'src/constants/types';
import Wallet from 'icon-sdk-js/build/Wallet';
import { getAddressFromSolana } from 'src/sc/solana';
const domain = process.env.REACT_APP_DOMAIN;
const _v = process.env.REACT_APP_API_VERSION;
const pathname = {
  ft: {
    chainList: 'chain/list',
    tokenList: 'token/list',
    supportList: 'chain/support',
    createMessage: 'message/create/',
    checkMessage: 'message/status',
  },
  nft: {
    chainList: 'chain/list',
    nftInfo: 'info',
    nftInfoMorails: 'info/morails',
    nftInfoKlaytn: 'info/kas',
    nftInfoHavah: 'info/havah',
    nftInfoAptos: 'info/aptos',
    nftInfoFNCY: 'info/fncy',
    nftInfoBalance: 'info/balance',
    metadataAptos: 'info/aptos/nft/metadata',
    createMessage: 'message/create/',
    createMessageBatch: 'message/create_batch/',
    nftInfoSolana: 'info/solana/token_balance',
    metadataSolana: 'info/solana/metadata',
    createSignature: 'signature/create/',
    checkMessage: 'message/status',
    metadata: 'info/metadata',
    metadata_uri: 'info/metadata_uri',
    solanaPortfolio: 'info/solana/portfolio',
  },
  explorer: {
    info: 'scan/info',
    transferList: 'scan/transfer',
    transferInfo: 'scan/transfer/',
  },
  common: {
    config: 'common/config',
  },
};

const requestMetadata = async (
  chainNo: number,
  contractAddr: string,
  tokenId: number | string
) => {
  try {
    const rsp = await get({
      domain: `${domain}/${_v}/nft/${pathname.nft.metadata}`,
      params: {
        chainId: chainNo,
        scAddr: contractAddr,
        tokenId,
      },
    });
    return rsp.result;
  } catch (e) {
    throw new Error('3005');
  }
};

const requestFTChainList = async () => {
  try {
    const rsp = await get({
      domain: `${domain}/${_v}/ft/${pathname.ft.chainList}`,
    });
    return rsp.result;
  } catch (e) {
    throw e;
  }
};

const requestFTList = async (chainNo: number) => {
  try {
    const rsp = await get({
      domain: `${domain}/${_v}/ft/${pathname.ft.tokenList}?chainNo=${chainNo}`,
    });
    return rsp.result;
  } catch (e) {
    throw e;
  }
};

const requestFTToChainList = async (chainNo: number, tokenId: number) => {
  try {
    const rsp = await get({
      domain: `${domain}/${_v}/ft/${pathname.ft.supportList}?chainNo=${chainNo}&tokenId=${tokenId}`,
    });
    return rsp.result;
  } catch (e) {
    throw e;
  }
};

const requestFTCreateMessage = async (params: any) => {
  try {
    const rsp = await post({
      domain: `${domain}/${_v}/ft/${pathname.ft.createMessage}`,
      params,
    });
    return rsp.result;
  } catch (e) {
    throw e;
  }
};

const requestFTCheckMessage = async (txResult: string, params: any) => {
  try {
    const rsp = await get({
      domain: `${domain}/${_v}/ft/${pathname.ft.checkMessage}/${txResult}`,
      params,
    });
    return rsp;
  } catch (e) {
    throw e;
  }
};

const requestNFTChainList = async () => {
  try {
    const rsp = await get({
      domain: `${domain}/${_v}/nft/${pathname.nft.chainList}`,
    });
    return rsp.result;
  } catch (e) {
    throw e;
  }
};

const requestNFTToChainList = async (chainNo: number) => {
  try {
    const rsp = await get({
      domain: `${domain}/${_v}/nft/${pathname.nft.chainList}?chainNo=${chainNo}`,
    });
    return rsp.result;
  } catch (e) {
    throw e;
  }
};

const requestNFTInfo = async (params: NFTInfoType) => {
  try {
    const rsp = await post({
      domain: `${domain}/${_v}/nft/${pathname.nft.nftInfo}`,
      params,
    });
    return rsp.result;
  } catch (e) {
    throw e;
  }
};

const requestNFTInfoUsingMorails = async (
  walletAddr: string,
  chainName: string,
  cursor: string
) => {
  try {
    const rsp = await get({
      domain: `${domain}/${_v}/nft/${pathname.nft.nftInfoMorails}/${walletAddr}/nft?chain=${chainName}&format=decimal&limit=10&cursor=${cursor}`,
    });
    return rsp;
  } catch (e) {
    throw e;
  }
};

const requestNFTInfoForKlaytn = async (walletAddr: string, cursor: string) => {
  try {
    const rsp = await get({
      domain: `${domain}/${_v}/nft/${pathname.nft.nftInfoKlaytn}/${walletAddr}/token?kind=nft,mt&size=10&cursor=${cursor}`,
    });
    return rsp;
  } catch (e) {
    throw e;
  }
};

const requestNFTInfoForAptos = async (walletAddr: string, cursor: string) => {
  try {
    const rsp = await get({
      domain: `${domain}/${_v}/nft/${pathname.nft.nftInfoAptos}/nft?owner_addresses=${walletAddr}&limit=10&cursor=${cursor}`,
    });
    return rsp;
  } catch (e) {
    throw e;
  }
};

const requestNFTInfoForSolana = async (walletAddr: string) => {
  try {
    const rsp = await get({
      domain: `${domain}/${_v}/nft/${pathname.nft.solanaPortfolio}/${walletAddr}`,
    });
    return rsp;
  } catch (e) {
    throw e;
  }
};

const requestNFTInfoForFNCY = async (walletAddr: string, page?: number) => {
  try {
    const rsp = await get({
      domain: `${domain}/${_v}/nft/${pathname.nft.nftInfoFNCY}/${walletAddr}/nfts/?count=10&page=${page}`,
    });

    return rsp;
  } catch (e) {
    throw e;
  }
};

const createIds = (ids: string[]) => {
  let params = '';
  for (let i = 0; i < ids.length; i++) {
    params = params + 'token_ids=' + ids[i] + '&';
  }
  return params;
};

const requestMetadataAptos = async (ids: string[]) => {
  try {
    const queryParams = createIds(ids);
    const rsp = await get({
      domain: `${domain}/${_v}/nft/${pathname.nft.metadataAptos}?${queryParams}`,
    });
    return rsp;
  } catch (e) {
    throw e;
  }
};

const requestMetadataSolana = async (mint: string[]) => {
  try {
    const rsp = await get({
      domain: `${domain}/${_v}/nft/${pathname.nft.metadataSolana}/${mint}`,
    });
    return rsp;
  } catch (e) {
    throw e;
  }
};

const requestTokenUri = async (tokenUri: string) => {
  try {
    const rsp = await get({
      domain: `${domain}/${_v}/nft/${pathname.nft.metadata_uri}`,
      params: {
        token_uri: tokenUri,
      },
    });
    return rsp.result;
  } catch (e) {
    throw e;
  }
};

const requestNFTInfoForHavah = async (walletAddr: string, page?: number) => {
  try {
    const rsp = await get({
      domain: `${domain}/${_v}/nft/${pathname.nft.nftInfoHavah}?addr=${walletAddr}&count=10&page=${page}`,
    });

    return rsp;
  } catch (e) {
    throw e;
  }
};

const requestNFTCreateMessage = async (params: any) => {
  try {
    const rsp = await post({
      domain: `${domain}/${_v}/nft/${pathname.nft.createMessage}`,
      params,
    });
    return rsp.result;
  } catch (e) {
    throw e;
  }
};

const requestNFTCreateMessageBatch = async (params: any) => {
  try {
    const rsp = await post({
      domain: `${domain}/${_v}/nft/${pathname.nft.createMessageBatch}`,
      params,
    });
    return rsp.result;
  } catch (e) {
    throw e;
  }
};

// Fix this
const requestNFTCreateSignature = async (params: any) => {
  try {
    const rsp = await post({
      domain: `${domain}/${_v}/nft/${pathname.nft.createSignature}`,
      params,
    });
    return rsp.result;
  } catch (e) {
    throw e;
  }
};

const requestNFTCheckMessage = async (txResult: string, params: any) => {
  try {
    const rsp = await get({
      domain: `${domain}/${_v}/nft/${pathname.nft.checkMessage}/${txResult}`,
      params,
    });
    return rsp;
  } catch (e) {
    throw e;
  }
};

const requestNFTInfoBalance = async (
  chainId: number,
  scAddr: string,
  holderAddr: string,
  tokenId: string | number
) => {
  try {
    const rsp = await get({
      domain: `${domain}/${_v}/nft/${pathname.nft.nftInfoBalance}?chainId=${chainId}&scAddr=${scAddr}&holderAddr=${holderAddr}&tokenId=${tokenId}`,
    });
    return rsp;
  } catch (e) {
    throw e;
  }
};

const requestScanInfo = async () => {
  try {
    const rsp = await get({
      domain: `${domain}/${_v}/${pathname.explorer.info}`,
    });
    return rsp.result;
  } catch (e) {
    throw e;
  }
};

const requestTransferList = async (params: any) => {
  try {
    const rsp = await get({
      domain: `${domain}/${_v}/${pathname.explorer.transferList}`,
      params,
    });

    return rsp;
  } catch (e) {
    throw e;
  }
};

const requestTransferInfo = async (transferNo: string) => {
  try {
    const rsp = await get({
      domain: `${domain}/${_v}/${pathname.explorer.transferInfo}${transferNo}`,
    });

    return rsp.result;
  } catch (e) {
    throw e;
  }
};

const requestConfig = async () => {
  try {
    const rsp = await get({
      domain: `${domain}/${_v}/${pathname.common.config}`,
    });
    return rsp.result;
  } catch (e) {
    throw e;
  }
};

export {
  requestMetadata,
  requestMetadataAptos,
  requestMetadataSolana,
  requestTokenUri,
  requestFTChainList,
  requestFTList,
  requestFTToChainList,
  requestFTCreateMessage,
  requestNFTCreateSignature,
  requestFTCheckMessage,
  requestNFTChainList,
  requestNFTToChainList,
  requestNFTInfo,
  requestNFTInfoUsingMorails,
  requestNFTInfoForKlaytn,
  requestNFTInfoForHavah,
  requestNFTInfoForAptos,
  requestNFTInfoForSolana,
  requestNFTInfoForFNCY,
  requestNFTCreateMessage,
  requestNFTCreateMessageBatch,
  requestNFTCheckMessage,
  requestNFTInfoBalance,
  requestScanInfo,
  requestTransferList,
  requestTransferInfo,
  requestConfig,
};

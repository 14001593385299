import React from 'react';
import styled from 'styled-components';
import { theme } from 'src/styles/theme';

const Skeleton: React.FC = () => {
  return (
    <StyledSkeleton>
      <div className="flash-1" />
      <div className="flash-2" />
      <div className="flash-3" />
      <div className="btn-1">Detail</div>
    </StyledSkeleton>
  );
};

const StyledSkeleton = styled.div`
  height: 165px;
  border-radius: 6px;
  width: 148px;
  background-color: ${theme.mono7};
  & > div.btn-1 {
    ${theme.body2};
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 32px;
    color: ${theme.mono1};
    border-top: 1px solid ${theme.mono6};
  }
  & > div.flash-1 {
    position: relative;
    width: 60px;
    height: 60px;
    border-radius: 6px;
    overflow: hidden;
    margin: 12px auto 10px 12px;
    background-color: ${theme.mono5};
  }
  & > div.flash-2 {
    position: relative;
    width: 100px;
    height: 12px;
    border-radius: 6px;
    overflow: hidden;
    margin: 0px auto 8px 12px;
    background-color: ${theme.mono5};
  }
  & > div.flash-3 {
    position: relative;
    width: 56px;
    height: 10px;
    border-radius: 6px;
    overflow: hidden;
    margin-bottom: 16px;
    margin: 2px auto 20px 12px;
    background-color: ${theme.mono5};
  }
  & > div.flash-1:before {
    position: absolute;
    content: '';
    width: 200px;
    height: 200px;
    background-image: linear-gradient(
      to right,
      rgba(79, 83, 85, 0.4) 0%,
      rgba(189, 194, 196, 0.4) 20%,
      rgba(79, 83, 85, 0.4) 50%,
      rgba(189, 194, 196, 0.4) 70%,
      rgba(79, 83, 85, 0.4) 100%
    );
    transform: translateX(-27px) translateY(-27px) rotate(45deg);
    transform-origin: 50% 50%;
    background-size: 200px 200px;
    background-repeat: no-repeat;
    animation: flashing 1.6s 1s linear infinite;
  }
  & > div.flash-2:before {
    position: absolute;
    content: '';
    width: 200px;
    height: 200px;
    background-image: linear-gradient(
      to right,
      rgba(79, 83, 85, 0.4) 0%,
      rgba(189, 194, 196, 0.4) 20%,
      rgba(79, 83, 85, 0.4) 50%,
      rgba(189, 194, 196, 0.4) 70%,
      rgba(79, 83, 85, 0.4) 100%
    );
    transform: translateX(-27px) translateY(-97px) rotate(45deg);
    transform-origin: 50% 50%;
    background-size: 200px 200px;
    background-repeat: no-repeat;
    animation: flashing 1.6s 1s linear infinite;
  }
  & > div.flash-3:before {
    position: absolute;
    content: '';
    width: 200px;
    height: 200px;
    background-image: linear-gradient(
      to right,
      rgba(79, 83, 85, 0.4) 0%,
      rgba(189, 194, 196, 0.4) 20%,
      rgba(79, 83, 85, 0.4) 50%,
      rgba(189, 194, 196, 0.4) 70%,
      rgba(79, 83, 85, 0.4) 100%
    );
    transform: translateX(-27px) translateY(-117px) rotate(45deg);
    transform-origin: 50% 50%;
    background-size: 200px 200px;
    background-repeat: no-repeat;
    animation: flashing 1.6s 1s linear infinite;
  }
  @keyframes flashing {
    0% {
      background-position: 0px 0px;
    }
    100% {
      background-position: 200px 0px;
    }
  }
`;

export { Skeleton };

import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { theme } from 'src/styles/theme';
import img_polling_1 from 'src/assets/img_polling_1.png';
import img_polling_2 from 'src/assets/img_polling_2.png';
import img_polling_3 from 'src/assets/img_polling_3.png';
import img_alert from 'src/assets/img_alert.svg';
interface IProps {
  stepIdx: number;
}
const PollingModal: React.FC<IProps> = ({ stepIdx }) => {
  const { pathname } = useLocation();
  const [statement, setStatement] = useState<string>(
    'Requesting transfer from Source chain.'
  );

  useEffect(() => {
    const isNFT = pathname === '/bridge/nft';
    switch (stepIdx) {
      case 0:
        setStatement(`Preparing to send the ${isNFT ? 'NFT' : 'Token'}.`);
        break;
      case 1:
        setStatement(`Source chain is sending the ${isNFT ? 'NFT' : 'Token'}.`);
        break;
      case 2:
        setStatement(
          `Destination is receiving the ${isNFT ? 'NFT' : 'Token'}.`
        );
        break;

      case 999:
        setStatement(`Preparing to send the ${isNFT ? 'NFT' : 'Token'}.`);
        break;
    }
  }, [stepIdx, pathname]);

  const flow_1 = stepIdx > 0 && stepIdx !== 999 ? 'completed' : 'inprogress';
  const flow_2 =
    stepIdx === 1
      ? 'inprogress'
      : stepIdx > 1 && stepIdx !== 999
      ? 'completed'
      : 'normal';
  const flow_3 =
    stepIdx === 2
      ? 'inprogress'
      : stepIdx > 2 && stepIdx !== 999
      ? 'completed'
      : 'normal';

  return (
    <StyledModal>
      <div className="title-area">
        <span>Transaction In Progress</span>
      </div>
      <div className="img-area">
        {(stepIdx === 0 || stepIdx === 999) && (
          <img src={img_polling_1} alt="polling-img" />
        )}
        {stepIdx === 1 && <img src={img_polling_2} alt="polling-img" />}
        {stepIdx === 2 && <img src={img_polling_3} alt="polling-img" />}
        <div className="cover" />
      </div>
      <StyledProgress>
        <StyledProgressItem status={flow_1}>
          <div className="ball" />
          <div className="line">
            <svg className="over-line" viewBox="0 0 67 3">
              <path
                fill={theme.primaryBG}
                stroke-width="4"
                stroke={theme.primaryBG}
                d="M0 0 L67 0"
              />
            </svg>
          </div>
        </StyledProgressItem>
        <StyledProgressItem status={flow_2}>
          <div className="ball" />
          <div className="line">
            <svg className="over-line" viewBox="0 0 67 3">
              <path
                fill={theme.primaryBG}
                stroke-width="4"
                stroke={theme.primaryBG}
                d="M0 0 L67 0"
              />
            </svg>
          </div>
        </StyledProgressItem>
        <StyledProgressItem status={flow_3}>
          <div className="ball" />
          <div className="line">
            <svg className="over-line" viewBox="0 0 67 3">
              <path
                fill={theme.primaryBG}
                stroke-width="4"
                stroke={theme.primaryBG}
                d="M0 0 L67 0"
              />
            </svg>
          </div>
          <div className="ball" />
        </StyledProgressItem>
      </StyledProgress>
      <div className="operation-txt">
        <span>{statement}</span>
      </div>
      <div className="warning-area">
        <div className="warning-txt">DO NOT CLOSE</div>
        <div className="desc-txt">
          Do not close this page until bridging is complete. If you leave, it
          may result in your {pathname === '/bridge/nft' ? 'NFT' : 'Token'}{' '}
          being locked in the source chain.
        </div>
        <img src={img_alert} alt="alert" />
      </div>
    </StyledModal>
  );
};

const StyledModal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 346px;
  background-color: ${theme.mono6};
  border-radius: 6px;
  padding: 40px 26px 26px 26px;
  border: 1px solid ${theme.mono5};

  & > div.title-area {
    ${theme.title1};
    color: ${theme.mono1};
    margin-bottom: 10px;
  }
  & > div.img-area {
    position: relative;
    overflow: hidden;
    width: 196px;
    height: 114px;
    margin-bottom: 30px;
    & > img {
      width: 100%;
      height: 100%;
    }
    & > div.cover {
      position: absolute;
      top: 0px;
      width: 118px;
      height: 114px;
    }
  }
  & > div.operation-txt {
    width: 100%;
    height: 40px;
    text-align: center;
    color: ${theme.mono2};
    margin-bottom: 30px;
  }
  & > div.warning-area {
    position: relative;
    ${theme.body2};
    color: ${theme.mono2};
    padding: 13px 14px 29px 14px;
    background-color: rgba(255, 89, 89, 0.15);
    border-radius: 6px;
    border: 1px solid ${theme.error};
    & > div.warning-txt {
      color: ${theme.error};
      font-weight: 700;
      margin-bottom: 2px;
    }
    & > img {
      position: absolute;
      width: 42px;
      height: 49px;
      bottom: -6px;
      right: 20px;
    }
  }
`;

const StyledProgress = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 12px;
  margin-bottom: 16px;
`;

const StyledProgressItem = styled.div<{
  status: 'normal' | 'inprogress' | 'completed';
}>`
  display: flex;
  align-items: center;
  & > div.ball {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 1px solid ${theme.mono1};
    ${(props) => {
      const isNormal = props.status === 'normal';
      if (isNormal) {
        return `
            border : 1px solid ${theme.mono1};
          `;
      } else {
        return `
            border : 1px solid ${theme.primaryBG};
            background-color :  ${theme.primaryBG};
          `;
      }
    }}
    &:last-of-type {
      border: 1px solid ${theme.mono1};
      background-color: transparent;
    }
  }
  & > div.line {
    position: relative;
    width: 67px;
    height: 1px;
    background-color: ${theme.mono1};
    & > svg {
      position: absolute;
      top: -0.5px;
      & > path {
        stroke-dasharray: 10;
        ${(props) => {
          const isCompleted = props.status === 'completed';
          const isInprogress = props.status === 'inprogress';
          if (isCompleted) {
            return `stroke-dasharray : 0;`;
          } else if (isInprogress) {
            return `animation: dash-1 2s linear infinite; `;
          } else {
            return `display : none;`;
          }
        }}
      }
    }
  }
  @keyframes dash-1 {
    to {
      stroke-dashoffset: -100;
    }
  }
`;

export { PollingModal };

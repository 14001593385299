const theme = {
  primaryBG: '#16D992',
  primaryTxt: '#00B984',
  subpink: '#FF88B8',
  error: '#FF5959',

  mono1: '#FFF',
  mono2: '#E3E4E4',
  mono31: '#D9D9D9',
  mono3: '#BDC2C5',
  mono4: '#83898C',
  mono5: '#4F5355',
  mono6: '#393C3F',
  mono7: '#25292C',
  mono8: '#141718',
  attention1: '#FFAB43',

  headline1: {
    fontSize: '30px',
    letterSpacing: '0px',
    fontWeight: 400,
  },
  headline2: {
    fontSize: '26px',
    lineHeight: '40px',
    letterSpacing: '0px',
    fontWeight: 400,
  },
  headline3: {
    fontSize: '22px',
    lineHeight: '24px',
    letterSpacing: '0px',
    fontWeight: 900,
  },

  title1: {
    fontSize: '18px',
    letterSpacing: '0px',
    fontWeight: 900,
  },
  tab1: {
    fontSize: '14px',
    letterSpacing: '0px',
    fontWeight: 700,
    lineHeight: '20px',
  },
  tab2: {
    fontSize: '14px',
    letterSpacing: '0px',
    fontWeight: 600,
  },
  body1: {
    fontSize: '14px',
    letterSpacing: '0px',
    lineHeight: '20px',
    fontWeight: 400,
  },
  body2: {
    fontSize: '12px',
    letterSpacing: '0px',
    lineHeight: '18px',
    fontWeight: 400,
  },
  caption: {
    fontSize: '10px',
    letterSpacing: '0px',
    lineHeight: '14px',
  },
  attention: {
    fontSize: '14px',
    letterSpacing: '0px',
    fontWeight: 700,
    lineHeight: '20px',
  },
  tokenAmount: {
    fontSize: '12px',
    letterSpacing: '0px',
    lineHeight: '16px',
    fontWeight: 700,
  },
  tokenAmountEx: {
    fontSize: '13px',
    letterSpacing: '0px',
    lineHeight: '18px',
    fontWeight: 700,
  },
};

export { theme };

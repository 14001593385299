import React, { useEffect, useState, useCallback } from 'react';
import { RankNTransferView } from 'src/components/index';
import { requestScanInfo } from 'src/apis/api';
import { ExplorerSummaryDataType } from 'src/constants/types';
import { useLocation, useNavigate } from 'react-router-dom';
import { requestTransferList } from 'src/apis/api';
import { queryParser, queryUpdater, errCodeParser } from 'src/utils/utils';
import {
  TableView,
  TablePaginationView,
  TableSearchInputView,
} from 'src/components/index';
import { PATH } from 'src/constants/index';
import { TransferDataType } from 'src/constants/types';
import styled from 'styled-components';

type SearchInputType = {
  optional: string;
  type: number;
  curPage: number;
};

const ExplorerViewController: React.FC = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const parsedPageNum = queryParser(search)?.p;
  const parsedPageType = queryParser(search)?.t;
  const parsedPageAddr = queryParser(search)?.addr;

  const _p = !!parsedPageNum ? parseInt(parsedPageNum) : 1;
  const _t = !!parsedPageType ? parseInt(parsedPageType) : 0;
  const _addr = !!parsedPageAddr ? parsedPageAddr : '';

  // const { handleModal } = useContext(ModalContext)
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [totalCount, setTotalCount] = useState<number>(1);
  const [transferData, setTransferData] = useState<TransferDataType[]>([]);
  const [summaryData, setSummaryData] = useState<ExplorerSummaryDataType>({
    topMoved: {
      amount: '',
      fromChainNo: -1,
      from: '',
      toChainNo: -1,
      to: '',
    },
    topTokens: {
      token: '',
      amount: '0',
      usdt: '0',
      symbol: '',
      tokenIdNo: -1,
    },
    totalQuantityFT24: '',
    totalQuantityNFT24: '',
    totalTransactions: '',
  });
  const getTransferList = async ({
    optional,
    type,
    curPage,
  }: SearchInputType) => {
    try {
      if (curPage === 0) return;
      const timeoutId = setTimeout(() => {
        setIsLoading(true);
      }, 1000);
      let params: any = {
        page: !!curPage ? curPage : 1,
        count: 20,
        type: !!type ? type : 0,
      };

      if (optional) {
        params = {
          ...params,
          search: optional.toLowerCase(),
        };
      }

      // 0: All, 1: ft, 2: nft
      const rsp = await requestTransferList(params);
      const status = rsp.retCode;
      if (status === 0) {
        const { bridge, total } = rsp.result;
        setTotalCount(total);
        setTransferData(bridge);
      } else {
        setTotalCount(0);
        setTransferData([]);
      }
      clearTimeout(timeoutId);
    } catch (e) {
      setTotalCount(0);
      setTransferData([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOnSearch = useCallback(
    (el: string) => {
      navigate(`/bridge/explorer?t=0&p=1&addr=${el}`);
    },
    [_addr]
  );

  const handleOnDropdown = useCallback(
    (idx: number) => {
      if (_addr) {
        navigate(`/bridge/explorer?t=${idx}&p=1&addr=${_addr}`);
      } else {
        navigate(`/bridge/explorer?t=${idx}&p=1`);
      }
    },
    [_addr]
  );

  const reset = () => {
    navigate(`/bridge/explorer?t=0&p=1`);
  };

  useEffect(() => {
    getTransferList({
      optional: _addr,
      curPage: _p,
      type: _t,
    });
  }, [_p, _t, _addr]);

  const getScanInfo = async () => {
    try {
      const rsp = await requestScanInfo();
      setSummaryData({
        ...rsp,
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getScanInfo();
  }, []);

  return (
    <>
      <RankNTransferView data={summaryData} />
      <TableSearchInputView
        userInput={_addr}
        handleOnSearch={handleOnSearch}
        reset={reset}
        onEnter={handleOnSearch}
      />
      <TableView
        data={transferData}
        curType={_t}
        handleOnDropdown={handleOnDropdown}
        isLoading={isLoading}
      />
      <TablePaginationView
        curPage={_p}
        setCurPage={(e) => {
          if (isLoading) {
            return;
          }
          const _curPage = e as number;
          const qs = queryUpdater('p', _curPage);
          navigate({
            pathname: PATH.EXPLORER,
            search: qs,
          });
        }}
        totalCount={totalCount}
      />
    </>
  );
};

export { ExplorerViewController };

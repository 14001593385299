import React, { useContext } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { ModalContext } from 'src/contexts/ModalShadowContext';

const ModalShadow: React.FC = () => {
  const { modalContent, isModalOpened } = useContext(ModalContext);

  return ReactDOM.createPortal(
    <>
      {isModalOpened && (
        <StyledModalContainer>{modalContent}</StyledModalContainer>
      )}
    </>,
    document.querySelector('#modal-shadow')!
  );
};

const StyledModalContainer = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 21001;
  background-color: rgba(0, 0, 0, 0.6);
`;

export { ModalShadow };

import IconService from 'icon-sdk-js';
import { TransferType } from 'src/constants/types';
import { roundDownAt, stringToHexConverter } from 'src/utils/utils';

type CreateCallTxType = {
  contract: string;
  params: any;
  method: string;
};

const endpoint = process.env.REACT_APP_HAVAH_CTZ_ENDPOINT;
const rpc = endpoint!;
const provider = new IconService.HttpProvider(rpc);
const { IconConverter, IconBuilder } = IconService;

const iconService = new IconService(provider);
const { CallBuilder } = IconBuilder;

const getStepCosts = async () => {
  try {
    const txObj = createCallTransaction({
      contract: 'cx0000000000000000000000000000000000000000',
      params: {},
      method: 'getStepCosts',
    });
    const result = await executeMethod(txObj);
    return result;
  } catch (e) {
    throw e;
  }
};

const getStepPrice = async () => {
  try {
    const txObj = createCallTransaction({
      contract: 'cx0000000000000000000000000000000000000000',
      params: {},
      method: 'getStepPrice',
    });
    const result = await executeMethod(txObj);
    return result;
  } catch (e) {
    throw e;
  }
};

const getEstimatedTxFee = async () => {
  try {
    const stepCosts = await getStepCosts();
    const _contractCall = stepCosts.contractCall;
    const stepPrice = await getStepPrice();
    const txFee = IconConverter.toBigNumber(_contractCall)
      .times(stepPrice)
      .times(Math.pow(10, -18))
      .toNumber();
    return txFee;
  } catch (e) {
    throw e;
  }
};

const createCallTransaction = ({
  contract,
  params,
  method,
}: CreateCallTxType) => {
  try {
    const transactionObj = new CallBuilder()
      .to(contract)
      .method(method)
      .params(!!params ? params : {})
      .build();
    return transactionObj;
  } catch (e) {
    throw e;
  }
};

const executeMethod = async (transactionObj: any) => {
  try {
    const result = await iconService.call(transactionObj).execute();
    return result;
  } catch (e) {
    throw e;
  }
};

const getHavahBalance = async (addr: string, decimal: number) => {
  try {
    const result = await iconService.getBalance(addr).execute();
    const _num = result.times(Math.pow(10, -1 * decimal)).toNumber();
    const _result = roundDownAt(_num, 6);
    return _result;
  } catch (e) {
    throw e;
  }
};

const getHavahTokenBalance = async (
  tokenAddr: string,
  senderAddr: string,
  decimal: number
) => {
  try {
    const txObj = createCallTransaction({
      contract: tokenAddr,
      params: {
        _owner: senderAddr,
      },
      method: 'balanceOf',
    });
    const result = await executeMethod(txObj);
    const _num = IconConverter.toBigNumber(result)
      .times(Math.pow(10, -1 * decimal))
      .toNumber();
    const _result = roundDownAt(_num, 6);
    return _result;
  } catch (e) {
    throw e;
  }
};

const connectToHavah = async () => {
  try {
    const rsp = await window.havah.connect();
    return rsp;
  } catch (e) {
    // console.log(e);
    throw e;
  }
};

const getAddrfromHAVAH = async () => {
  try {
    const accounts = await window.havah.accounts();
    return accounts;
  } catch (e) {
    // console.log(e);
    return e;
  }
};

const havahCheckGetApproved = async (
  bridgeContract: string,
  tokenId: string,
  tokenAddr: string
) => {
  try {
    const _tokenId = stringToHexConverter(tokenId);
    const txObj = createCallTransaction({
      contract: tokenAddr,
      params: {
        _tokenId: _tokenId,
      },
      method: 'getApproved',
    });
    const result = await executeMethod(txObj);
    const approvedAddr = result;
    return bridgeContract === approvedAddr ? true : false;
  } catch (e) {
    throw e;
  }
};

const havahCheckIsApproved = async (
  senderAddress: string,
  bridgeContract: string,
  scAddr: string
) => {
  try {
    const txObj = createCallTransaction({
      contract: scAddr,
      params: {
        _owner: senderAddress,
        _operator: bridgeContract,
      },
      method: 'isApprovedForAll',
    });
    const result = await executeMethod(txObj);
    const approvedTx = result;
    return parseInt(approvedTx, 16) === 1 ? true : false;
  } catch (e) {
    throw e;
  }
};

const havahCheckAllowance = async (
  senderAddr: string,
  bridgeAddr: string,
  tokenAddr: string
) => {
  const txObj = createCallTransaction({
    contract: tokenAddr,
    params: {
      _owner: senderAddr,
      _spender: bridgeAddr,
    },
    method: 'allowance',
  });
  const result = await executeMethod(txObj);
  return result;
};

const havahApproveFT = async ({
  senderAddr,
  bridgeAddr,
  tokenAddr,
  amount = '0x0',
}: TransferType) => {
  const rawTx = {
    from: senderAddr,
    method: 'approve',
    params: {
      _spender: bridgeAddr,
      _value: amount,
    },
    to: tokenAddr,
  };
  return rawTx;
};

const havahApproveNFT = async ({
  senderAddr,
  bridgeAddr,
  tokenAddr,
  amount = '0x0',
}: TransferType) => {
  const _tokenId = stringToHexConverter(amount);

  const rawTx = {
    from: senderAddr,
    method: 'approve',
    params: {
      _approved: bridgeAddr,
      _tokenId,
    },
    to: tokenAddr,
  };
  return rawTx;
};

const havahApproveAllNFT = async ({
  senderAddr,
  tokenAddr,
  bridgeAddr,
}: TransferType) => {
  try {
    const rawTx = {
      from: senderAddr,
      method: 'setApprovalForAll',
      params: {
        _operator: bridgeAddr,
        _approved: '0x1',
      },
      to: tokenAddr,
    };

    return rawTx;
  } catch (e) {
    throw e;
  }
};

const havahTransferFT = async ({
  bridgeAddr,
  senderAddr,
  messageId,
  decimal,
  blockHeight,
  fromChain,
  fromToken,
  toChain,
  amount,
  commission,
  targetChainFee,
  recipient,
  signature,
  value,
}: any) => {
  try {
    const toHex = IconConverter.toHex;
    const _commission = IconConverter.toBigNumber(Math.pow(10, decimal)).times(
      commission
    );
    const _targetChainFee = IconConverter.toBigNumber(
      Math.pow(10, decimal)
    ).times(targetChainFee);
    const _amount = IconConverter.toBigNumber(Math.pow(10, decimal)).times(
      amount
    );
    // const _txFee = IconConverter.toHex(IconConverter.toBigNumber(Math.pow(10, decimal)).times((commission + targetChainFee)));
    const _hexAmount = toHex(_amount);
    const _hexCommission = IconConverter.toHex(_commission);
    const _hexTargetChainFee = toHex(_targetChainFee);

    const rawTx = {
      from: senderAddr,
      method: 'transferFT',
      params: {
        _messageId: messageId,
        _blockHeight: '0x' + blockHeight.toString(16),
        _fromChain: '0x' + fromChain.toString(16),
        _fromToken: fromToken,
        _toChain: '0x' + toChain.toString(16),
        _amount: _hexAmount,
        _commission: _hexCommission,
        _targetChainFee: _hexTargetChainFee,
        _recipient: recipient,
        _signature: signature,
      },
      to: bridgeAddr,
      value,
    };
    return rawTx;
  } catch (e) {
    throw new Error(JSON.stringify('P300'));
  }
};

const havahTransferNFT = async ({
  senderAddr,
  decimal,
  bridgeAddr,
  messageId,
  blockHeight,
  fromChain,
  fromToken,
  toChain,
  tokenId,
  tokenType,
  commission,
  targetChainFee,
  recipient,
  signature,
}: any) => {
  try {
    const toHex = IconConverter.toHex;
    const _commission = IconConverter.toBigNumber(Math.pow(10, decimal)).times(
      commission
    );
    const _targetChainFee = IconConverter.toBigNumber(
      Math.pow(10, decimal)
    ).times(targetChainFee);
    const _tokenId = IconConverter.toHex(IconConverter.toBigNumber(tokenId));
    const _hexCommission = toHex(_commission);
    const _hexTargetChainFee = toHex(_targetChainFee);

    const rawTx = {
      method: 'transferNFT',
      params: {
        _messageId: messageId,
        _blockHeight: '0x' + blockHeight.toString(16),
        _fromChain: '0x' + fromChain.toString(16),
        _fromToken: fromToken,
        _toChain: '0x' + toChain.toString(16),
        _tokenId: _tokenId,
        _type: '0x' + tokenType.toString(16),
        _commission: _hexCommission,
        _targetChainFee: _hexTargetChainFee,
        _recipient: recipient,
        _signature: '0x' + signature,
      },
    };

    return rawTx;
  } catch (e) {
    throw e;
  }
};

const havahTransferNFTBatch = async ({
  senderAddr,
  decimal,
  bridgeAddr,
  messageId,
  blockHeight,
  fromChain,
  fromToken,
  toChain,
  tokens,
  tokenType,
  commission,
  targetChainFee,
  recipient,
  signature,
}: any) => {
  try {
    const toHex = IconConverter.toHex;
    const _commission = IconConverter.toBigNumber(Math.pow(10, decimal)).times(
      commission
    );
    const _targetChainFee = IconConverter.toBigNumber(
      Math.pow(10, decimal)
    ).times(targetChainFee);
    // const _tokenId = IconConverter.toHex(IconConverter.toBigNumber(tokens));
    const _hexCommission = toHex(_commission);
    const _hexTargetChainFee = toHex(_targetChainFee);
    let tokenArray: any[] = [];
    let innerArray: any[] = [];
    for (let i = 0; i < tokens[0].length; i++) {
      innerArray.push(
        toHex(IconConverter.toBigNumber(tokens[0][i])).toString()
      );
    }
    tokenArray.push(innerArray);
    innerArray = [];
    for (let i = 0; i < tokens[1].length; i++)
      innerArray.push(
        toHex(IconConverter.toBigNumber(tokens[1][i])).toString()
      );
    tokenArray.push(innerArray);

    const rawTx = {
      method: 'transferNFTBatch',
      params: {
        _messageId: messageId,
        _blockHeight: '0x' + blockHeight.toString(16),
        _fromChain: '0x' + fromChain.toString(16),
        _fromToken: fromToken,
        _toChain: '0x' + toChain.toString(16),
        _tokenIds: tokenArray,
        _type: '0x' + tokenType.toString(16),
        _commission: _hexCommission,
        _targetChainFee: _hexTargetChainFee,
        _recipient: recipient,
        _signature: '0x' + signature,
      },
    };

    return rawTx;
  } catch (e) {
    throw e;
  }
};

const havahSendTransaction = async (txData: any) => {
  try {
    const rsp = await window.havah.sendTransaction(txData);
    return rsp;
  } catch (e) {
    throw e;
  }
};

const havahCheckTxConfirm = async (url: string, params: string) => {
  try {
    const data = {
      jsonrpc: '2.0',
      id: '1001',
      method: 'icx_getTransactionResult',
      params: {
        txHash: params,
      },
    };
    const headers: HeadersInit = {
      Accept: '*/*',
      'Content-Type': 'application/json',
    };

    const rsp = await fetch(`${url}`, {
      method: 'POST',
      headers: {
        ...headers,
      },
      body: JSON.stringify({
        ...data,
      }),
    });
    let response = await rsp.json();

    return response;
  } catch (e) {
    throw e;
  }
};

export {
  getEstimatedTxFee,
  getHavahBalance,
  getHavahTokenBalance,
  connectToHavah,
  getAddrfromHAVAH,
  havahCheckGetApproved,
  havahCheckIsApproved,
  havahCheckAllowance,
  havahSendTransaction,
  havahApproveFT,
  havahApproveNFT,
  havahApproveAllNFT,
  havahTransferFT,
  havahTransferNFT,
  havahTransferNFTBatch,
  havahCheckTxConfirm,
};

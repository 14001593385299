import React, { useEffect, useContext, useState, useCallback } from 'react';
import stepStore from 'src/stores/StepStore';
import bridgePageStore from 'src/stores/BridgePageStore';
import {
  Button,
  ToastModalContent,
  Warning3,
  Warning4,
} from 'src/components/index';
import {
  FromChainNFTSelectViewContainer,
  FromNFTSelectedViewContainer,
  FromChainConnectViewContainer,
  ToChainConnectViewContainer,
  SendViewContainer,
  TransactionCompleteViewContainer,
} from 'src/container/BridgeNFTContainer';
import { StepperContainer } from 'src/container/StepperContainer/StepperContainer';
import { ModalContext as ToastModalContext } from 'src/contexts/ToastModalContext';
import { bridgePageAction } from 'src/actions/bridgePageAction';
import { stepAction } from 'src/actions/stepAction';
import {
  StyledWrapper,
  StyledBtnWrapper,
  StyledArrow,
  StyledTransferWrapper,
} from 'src/pages/StyledPage';
import { ChainConfig, FromNFTConfig } from 'src/constants/types';
import { NETWORK_ID } from 'src/constants/enums';

const NFTBridgePage: React.FC = () => {
  const store = bridgePageStore;
  const [isShowWarning, setIsShowWarning] = useState<boolean>(false);
  const [isBtnAble, setIsAble] = useState<boolean>(false);
  const [stepIdx, setStepIdx] = useState<number>(0);
  const [toChainName, setToChainName] = useState<string>('');
  const toastModal = useContext(ToastModalContext);

  const _onChangeBridgeConfig = useCallback(() => {
    const { toWalletAddr, toChain, fromToken } = store.getBridgeConfig();
    const hasToWallet = toWalletAddr.length > 0;
    if (
      ((toChain as ChainConfig).chainNo == NETWORK_ID.APTOS ||
        (toChain as ChainConfig).chainNo == NETWORK_ID.MEVERSE) &&
      (fromToken as FromNFTConfig).transferTokenAmount > 1
    ) {
      setIsAble(false);
      setIsShowWarning(true);
      setToChainName((toChain as ChainConfig).chainName);
    } else {
      setIsAble(hasToWallet);
      setIsShowWarning(false);
      setToChainName('');
    }
  }, [store]);

  const _onChangeStep = () => {
    setStepIdx(stepStore.getStepNumber());
  };

  useEffect(() => {
    store.addChangeListener(_onChangeBridgeConfig);
    stepStore.addChangeListener(_onChangeStep);
    return () => {
      store.removeChangeListener(_onChangeBridgeConfig);
      stepStore.removeChangeListener(_onChangeStep);
    };
  }, [store, _onChangeBridgeConfig]);

  window.addEventListener('keplr_keystorechange', () => {
    bridgePageAction._removeFromWallet();
    bridgePageAction._removeFromToken();
    bridgePageAction._removeToChain();
    bridgePageAction._removeWeb3WalletID();
    bridgePageAction._removeToWallet();
    stepAction._changeStep(0);
  });

  useEffect(() => {
    const eventHandler = async (event: any) => {
      try {
        const { type, payload } = event.detail;
        if (type === 'RESPONSE_ADDRESS') {
          if (stepIdx >= 3) {
            bridgePageAction._changeToWallet(payload);
          } else {
            toastModal.handleModal(
              <ToastModalContent
                type='connect'
                txt={'Wallet connected successfully'}
              />
            );
            bridgePageAction._changeFromWallet(payload);
            stepAction._changeStep(1);
          }
        }
      } catch (e) {
        console.log(e);
      }
    };
    window.addEventListener('ICONEX_RELAY_RESPONSE', eventHandler);
    return () =>
      window.removeEventListener('ICONEX_RELAY_RESPONSE', eventHandler);
  }, [toastModal, stepIdx]);

  if (stepIdx === 6) {
    return (
      <>
        <TransactionCompleteViewContainer />
        <StepperContainer />
      </>
    );
  } else if (stepIdx === 5) {
    return (
      <>
        <SendViewContainer />
        <StepperContainer />
      </>
    );
  } else {
    return (
      <>
        <StyledWrapper step={stepIdx} className='container from'>
          {stepIdx <= 4 && <FromChainConnectViewContainer />}
          {stepIdx <= 1 && <FromChainNFTSelectViewContainer />}
          {stepIdx >= 2 && stepIdx <= 4 && <FromNFTSelectedViewContainer />}
        </StyledWrapper>
        <StyledArrow>
          <div />
        </StyledArrow>
        <StyledWrapper step={0} className='container to'>
          <ToChainConnectViewContainer />
        </StyledWrapper>
        {isShowWarning ? (
          <>
            <StyledTransferWrapper>
              <Warning4 txt={toChainName} />
              <StyledBtnWrapper width={580}>
                <Button
                  disabled={!isBtnAble}
                  type='cta'
                  name='Next'
                  handleOnClick={() => {
                    stepAction._changeStep(5);
                  }}
                />
              </StyledBtnWrapper>
            </StyledTransferWrapper>
          </>
        ) : (
          <>
            <StyledBtnWrapper width={628}>
              <Button
                disabled={!isBtnAble}
                type='cta'
                name='Next'
                handleOnClick={() => {
                  stepAction._changeStep(5);
                }}
              />
            </StyledBtnWrapper>
          </>
        )}

        <StepperContainer />
      </>
    );
  }
};

export { NFTBridgePage };

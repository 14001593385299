import React from 'react';
import styled from 'styled-components';
import { RankContentView, TransferSummaryView } from 'src/components/index';
import { dateAndTime } from 'src/utils/utils';
import { ExplorerSummaryDataType } from 'src/constants/types';
import { theme } from 'src/styles/theme';
import bg_rank_box from 'src/assets/background_rank_box.svg';
import img_l_wing from 'src/assets/img_wings_l.svg';
import img_r_wing from 'src/assets/img_wings_r.svg';

interface IProps {
  data: ExplorerSummaryDataType;
}

const RankNTransferView: React.FC<IProps> = (props) => {
  const { data } = props;

  const { time, timeZoneStr } = dateAndTime(new Date().toISOString());
  return (
    <StyleSummaryPage>
      <div className="rank-box">
        <div className="timer">
          Updated at {time} {timeZoneStr}
        </div>
        <div className="view-title">
          <span>Top Rank</span>
        </div>
        <div className="rank-content">
          <RankContentView
            txt={
              'The Network with the most Tokens and NFTs moved through the HAVAH Mitter.'
            }
            data={data}
            isNFT={true}
          />
          <RankContentView
            txt={
              'The total amount of tokens(FT) in the highest amount based on USDT among tokens bridged to the HAVAH Mitter.'
            }
            data={data}
            isNFT={false}
          />
        </div>
      </div>
      <TransferSummaryView data={data} />
    </StyleSummaryPage>
  );
};

const StyleSummaryPage = styled.div`
  width: 1000px;
  height: auto;
  margin: 0px auto;
  padding-bottom: 48px;
  & > div.rank-box {
    position: relative;
    width: 100%;
    height: 100%;
    background: url(${bg_rank_box}) center center no-repeat;
    background-size: 1000px 222px;
    padding: 16px 36px 36px 36px;
    margin-bottom: 26px;
    & > div.timer {
      ${theme.body1};
      color: ${theme.mono4};
      position: absolute;
      right: 0px;
      top: -26px;
    }
    & > div.view-title {
      ${theme.headline2};
      height: 40px;
      margin-bottom: 8px;
      font-family: LuckiestGuy;
      color: ${theme.primaryTxt};
      text-align: center;
      & > span {
        position: relative;
        vertical-align: middle;
        &:before {
          content: '';
          position: absolute;
          left: -90px;
          top: -38px;
          width: 82px;
          height: 62px;
          background: url(${img_l_wing}) center center no-repeat;
          background-size: 82px 62px;
        }
        &:after {
          content: '';
          position: absolute;
          right: -90px;
          top: -38px;
          width: 82px;
          height: 62px;
          background: url(${img_r_wing}) center center no-repeat;
          background-size: 82px 62px;
        }
      }
    }
    & > div.rank-content {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 122px;
      grid-column-gap: 24px;
    }
  }
`;

export { RankNTransferView };

import React from 'react';
import styled from 'styled-components';
import { useNavigate, Link } from 'react-router-dom';
import { ExplorerDetailViewController } from 'src/controller/ExplorerDetailViewController/index';
import { PATH } from 'src/constants/index';
import { Button } from 'src/components/index';

const BridgeExplorerDetailPage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <StyledPage>
      <ExplorerDetailViewController />
      <BtnWrapper>
        <Link
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            const isFromTxResult = window.history.state.usr?.fromTxResult;
            if (isFromTxResult) return navigate(PATH.EXPLORER);
            navigate(-1);
          }}
          to={PATH.EXPLORER}
        >
          <Button name="Back to List" handleOnClick={() => {}} />
        </Link>
      </BtnWrapper>
    </StyledPage>
  );
};

const StyledPage = styled.div`
  width: 1000px;
  height: auto;
  margin: 0px auto;
`;

const BtnWrapper = styled.div`
  margin-top: 50px;
  height: 48px;
`;

export { BridgeExplorerDetailPage };

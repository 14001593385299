import { classNameBinderType } from 'src/constants/types';
import BigNumber from 'bignumber.js';
import IconService from 'icon-sdk-js';
import { ERROR_MSG } from 'src/constants/codes';

const storage_prefix = process.env.REACT_APP_IPFS_GATEWAY_PREFIX;
const { IconConverter } = IconService;

const toHex = (value: number | string) => {
  const hex = IconConverter.toHex(value);
  return hex;
};

const bigNumberDivideDecimal = (value: string, decimal: number) => {
  const numStr = IconConverter.toBigNumber(value)
    .times(Math.pow(10, -1 * decimal))
    .toString();
  return numStr;
};

const numberSubstract = (value: string, sub: number) => {
  const numStr = IconConverter.toBigNumber(value).minus(sub).toString();
  return numStr;
};

const numberGreaterThan = (value: any, compare: any) => {
  return IconConverter.toBigNumber(value).gt(compare);
};

const numberEqualTo = (value: any, compare: any) => {
  return IconConverter.toBigNumber(value).eq(compare);
};

const numberZeroCompare = (value: any) => {
  if (numberGreaterThan(value, 0)) {
    return 'gt';
  } else if (IconConverter.toBigNumber(value).eq(0)) {
    return 'zero';
  } else {
    return 'lt';
  }
};

const stringToHexConverter = (str: string | number) => {
  return IconConverter.toHex(IconConverter.toBigNumber(str));
};

const classNameBinder = (props: classNameBinderType) => {
  const { prefix, suffix, cond } = props;
  const space = ' ';
  const result = cond ? (!!prefix ? prefix + space + suffix : suffix) : prefix;
  return result;
};

const createRndHexString = () => {
  const timestamp = Math.round(Math.random() * Math.pow(10, 10));
  return timestamp;
};

const protocolReplacer = (str: string) => {
  if (!str) {
    return '';
  }
  const prefix = !!storage_prefix
    ? storage_prefix
    : 'https://nftstorage.link/ipfs/';
  const _uri = str
    .replaceAll('ipfs://', prefix)
    .replaceAll('https://ipfs.io/ipfs/', prefix);
  return _uri;
};

const hexToAscii = (str: string) => {
  const hexString = str;
  let strOut = '';
  for (let x = 0; x < hexString.length; x += 2) {
    strOut += String.fromCharCode(parseInt(hexString.substr(x, 2), 16));
  }
  return strOut;
};

const errCodeParser = (
  e: any
): { retCode: string; retMsg: string; bCosmosChianError: boolean } => {
  const isCode = !!e.code;
  let _code;
  let _bCosmosChianError = false;
  let returnValue = {
    retCode: '',
    retMsg: '',
    bCosmosChianError: false,
  };
  if (isCode) {
    if (typeof e.code === 'number') {
      if (e.code > 1 && e.code < 43) _bCosmosChianError = true;
      _code = e.code.toString();
    } else {
      _code = e.code;
    }

    const _msg = !!ERROR_MSG[_code]
      ? ERROR_MSG[_code]
      : e.message
      ? e.message
      : ERROR_MSG['DEFAULT_1'];

    returnValue = {
      retCode: _code,
      retMsg: _msg,
      bCosmosChianError: _bCosmosChianError,
    };
  } else {
    try {
      const _parsed = JSON.parse(e.message);
      const hasCode = !!_parsed.code;
      if (hasCode) {
        returnValue = {
          retCode: _parsed.code.toString(),
          retMsg: !!ERROR_MSG[_parsed.code.toString()]
            ? ERROR_MSG[_parsed.code.toString()]
            : e.message
            ? e.message
            : ERROR_MSG['DEFAULT_1'],
          bCosmosChianError: _bCosmosChianError,
        };
      } else {
        const _msg = ERROR_MSG[_parsed.toString()];
        if (_msg) {
          returnValue = {
            retCode: _parsed.toString(),
            retMsg: _msg,
            bCosmosChianError: _bCosmosChianError,
          };
        } else {
          returnValue = {
            retCode: '',
            retMsg: _parsed.toString(),
            bCosmosChianError: _bCosmosChianError,
          };
        }
      }
    } catch {
      if (e.message === 'Request rejected') {
        returnValue = {
          retCode: 'P200',
          retMsg: ERROR_MSG['P200'],
          bCosmosChianError: _bCosmosChianError,
        };
      } else if (e.message) {
        returnValue = {
          retCode: '',
          retMsg: e.message,
          bCosmosChianError: _bCosmosChianError,
        };
      } else {
        returnValue = {
          retCode: '',
          retMsg: ERROR_MSG['DEFAULT_1'],
          bCosmosChianError: _bCosmosChianError,
        };
      }
    }
  }
  return returnValue;
};

const sumFloat = (num_1: number, num_2: number) => {
  const sum1 = BigNumber.sum(num_1, num_2).toNumber();
  return sum1;
  // const dec_1 = num_1.toString().split(".")[1];
  // const dec_2 = num_2.toString().split(".")[1];
  // const len_1 = !!dec_1 ? dec_1.length : 0;
  // const len_2 = !!dec_2 ? dec_2.length : 0;

  // const long = len_1 > len_2 ? len_1 : len_2;

  // const sum = num_1 + num_2;
  // return parseFloat(sum.toFixed(long));
};

const queryParser = (str: string): { [key: string]: string } => {
  let returnObj = {};
  str
    .replace('?', '')
    .split('&')
    .map((el: any) => {
      const rawStr = el.split('=');
      const key = rawStr[0];
      const value = rawStr[1];
      returnObj = {
        ...returnObj,
        [key]: value,
      };
    });
  return returnObj;
};

const noExponential = (num: number) => {
  var data = String(num).split(/[eE]/);
  if (data.length == 1) return data[0];

  var z = '',
    sign = num < 0 ? '-' : '',
    str = data[0].replace('.', ''),
    mag = Number(data[1]) + 1;

  if (mag < 0) {
    z = sign + '0.';
    while (mag++) z += '0';
    return z + str.replace(/^\-/, '');
  }
  mag -= str.length;
  while (mag--) z += '0';
  return str + z;
};

const roundDownAt = (number: number, at: number) => {
  const _at = Math.pow(10, at);
  const _times = number * _at;
  const _floor = Math.floor(_times) * Math.pow(10, -1 * at);
  return _floor.toFixed(at);
};

const localeTimeSetter = (dateStr: string) => {
  if (!dateStr) {
    return '';
  }
  const timeZone = new Date().getTimezoneOffset() / 60;
  const timestamp = new Date(dateStr).valueOf();
  const localTimestamp = timestamp + 3600000 * -1 * timeZone;
  const newDateStr = new Date(localTimestamp).toISOString();
  return newDateStr;
};

const dateAndTime = (dateStr: string) => {
  if (!dateStr) {
    return {
      date: null,
      time: null,
    };
  }

  const timeZone = new Date().getTimezoneOffset() / 60;
  const timeZoneStr = `UTC${timeZone > 0 ? '' : '+'}${-1 * timeZone}`;
  const newDate = new Date(dateStr).setHours(
    new Date(dateStr).getHours() + -1 * timeZone
  );
  const _str = new Date(newDate).toISOString();
  const date = _str.split('T')[0].substring(2).replaceAll('-', '.');
  const time = _str.split('T')[1].substring(0, 8);
  const hours = time.split(':')[0];
  const _parsedHour = parseInt(hours);
  let ampm = 'AM';
  if (_parsedHour > 12) {
    ampm = 'PM';
  }
  const _converted = _parsedHour === 12 ? 12 : _parsedHour % 12;
  let convertedHour = _converted.toString();
  const len = convertedHour.length;
  if (len === 1) {
    convertedHour = '0' + convertedHour;
  }

  const timeStr = dateStr.split('T')[1].substring(0, 8);
  const convertedTime = convertedHour + timeStr.substring(2);
  return {
    date,
    time,
    ampm,
    convertedTime,
    timeZoneStr,
  };
};

const queryUpdater = (key: any, value: number) => {
  var kvp = window.location.search.substr(1).split('&');
  let i = 0;

  for (; i < kvp.length; i++) {
    if (kvp[i].startsWith(key + '=')) {
      let pair: any[] = kvp[i].split('=');
      pair[1] = value;
      kvp[i] = pair.join('=');
      break;
    }
  }

  if (i >= kvp.length) {
    kvp[kvp.length] = [key, value].join('=');
  }

  if (!!kvp[0]) {
    const params = kvp.join('&');
    return params;
  } else {
    const _spliced = kvp.splice(1);
    return _spliced[0];
  }
  // can return this or...
};

const commify = (num: string) => {
  var parts = num.split('.');
  const numberPart = parts[0];
  const decimalPart = parts[1];
  const thousands = /\B(?=(\d{3})+(?!\d))/g;
  return (
    numberPart.replace(thousands, ',') + (decimalPart ? '.' + decimalPart : '')
  );
};

const uint8ArrayToString = (data: Uint8Array) => {
  let hexStr = '';
  for (let i = 0; i < data.length; i++) {
    hexStr += data[i].toString(16).padStart(2, '0');
  }
  return hexStr;
};

const copyToClipboard = (str: string) => {
  if (navigator.clipboard !== undefined) {
    navigator.clipboard.writeText(str);
  } else {
    // execCommand 사용
    const textArea = document.createElement('textarea');
    textArea.value = str;
    document.body.appendChild(textArea);
    textArea.select();
    textArea.setSelectionRange(0, 99999);
    try {
      document.execCommand('copy');
    } catch (err) {
      console.error('복사 실패', err);
    }
    textArea.setSelectionRange(0, 0);
    document.body.removeChild(textArea);
  }
};

export {
  toHex,
  bigNumberDivideDecimal,
  numberSubstract,
  numberEqualTo,
  numberZeroCompare,
  numberGreaterThan,
  stringToHexConverter,
  protocolReplacer,
  classNameBinder,
  createRndHexString,
  hexToAscii,
  errCodeParser,
  sumFloat,
  queryParser,
  noExponential,
  roundDownAt,
  localeTimeSetter,
  dateAndTime,
  queryUpdater,
  commify,
  uint8ArrayToString,
  copyToClipboard,
};

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { bridgePageAction } from 'src/actions/bridgePageAction';
import { stepAction } from 'src/actions/stepAction';
import { useLocation, Link } from 'react-router-dom';
import { theme } from 'src/styles/theme';
import { PATH } from 'src/constants/index';
import { classNameBinder } from 'src/utils/utils';
import ic_logo from 'src/assets/havah_mitter_logo.svg';

interface IProps {
  isScrolled: boolean;
  isBridge: boolean;
}

const Header: React.FC<IProps> = ({ isScrolled, isBridge }) => {
  const { pathname } = useLocation();
  const navigateNames = ['nft', 'ft', 'explorer'];
  const [curNavIdx, setCurNavIdx] = useState<number>(0);

  useEffect(() => {
    const num = navigateNames.findIndex((el) => pathname.includes(el));
    setCurNavIdx(num);
  }, [pathname, navigateNames]);

  return (
    <StyledHeader isScrolled={isScrolled} isBridge={isBridge}>
      <div className="logo-area">
        <Link
          onClick={(e) => {
            e.stopPropagation();
            bridgePageAction._reset();
            stepAction._changeStep(0);
          }}
          to={PATH.NFT}
        >
          <img src={ic_logo} alt="logo" />
        </Link>
      </div>
      <StyledNavs>
        {navigateNames.map((el, idx) => {
          const binderProps = {
            prefix: '',
            suffix: 'active',
            cond: idx === curNavIdx,
          };
          return (
            <StyledLi
              key={idx.toString()}
              className={classNameBinder(binderProps)}
            >
              <Link
                onClick={(e) => {
                  e.stopPropagation();
                  bridgePageAction._reset();
                  stepAction._changeStep(0);
                  setCurNavIdx(idx);
                }}
                to={'/bridge/' + el}
              >
                {idx === 0 && 'NFTs'}
                {idx === 1 && 'Tokens'}
                {idx === 2 && 'Explorer'}
              </Link>
            </StyledLi>
          );
        })}
      </StyledNavs>
    </StyledHeader>
  );
};

const StyledHeader = styled.header<{ isScrolled: boolean; isBridge: boolean }>`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  width: 100vw;
  height: 60px;
  padding: 10px 26px;
  z-index: 20000;
  & > div.logo-area {
    position: absolute;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    top: 0px;
    left: 0px;
    height: 60px;
    padding-left: 26px;
    cursor: pointer;
    & > img {
      width: 170px;
      height: 40px;
    }
  }
  ${(props) =>
    !props.isBridge &&
    `
    background-color : ${theme.mono6}
  `}
  ${(props) =>
    props.isBridge &&
    props.isScrolled &&
    `
    background-color : ${theme.mono6};
  `}
`;

const StyledNavs = styled.ul`
  list-style: none;
  display: flex;
`;

const StyledLi = styled.li`
  ${theme.tab2};
  color: ${theme.mono4};
  margin-right: 24px;
  line-height: 20px;
  cursor: pointer;
  &.active {
    border-bottom: 2px solid ${theme.mono1};
    & > a {
      color: ${theme.mono1};
    }
  }
  & > a {
    color: ${theme.mono4};
    text-decoration: none;
    cursor: pointer;
  }
  &:last-of-type {
    margin-right: 0px;
  }
`;

export { Header };

import React from 'react';
import styled from 'styled-components';
import { theme } from 'src/styles/theme';
import ic_dropdown_default from 'src/assets/down_default.svg';
import ic_dropdown_disabled from 'src/assets/down_disabled.svg';

interface IProps {
  initialValue?: string;
  name: string;
  handleOnClick: () => void;
  disabled?: boolean;
}

const LongModalButton: React.FC<IProps> = (props) => {
  const { initialValue = '', name, handleOnClick, disabled = false } = props;
  return (
    <StyledLongModalButton disabled={disabled} onClick={() => handleOnClick()}>
      {!!name ? name : initialValue}
    </StyledLongModalButton>
  );
};

const StyledLongModalButton = styled.button`
  width: 100%;
  height: 100%;
  padding: 14px 14px 14px 14px;
  color: ${theme.mono1};
  border: 1px solid ${theme.mono8};
  border-radius: 6px;
  text-align: start;
  cursor: pointer;
  background: url(${ic_dropdown_default}) center right 14px no-repeat;
  background-size: 20px 20px;
  background-color: ${theme.mono8};
  &:hover {
    border: 1px solid ${theme.primaryBG};
  }
  &:disabled {
    color: ${theme.mono5};
    background: url(${ic_dropdown_disabled}) center right 14px no-repeat;
    background-size: 20px 20px;
    background-color: ${theme.mono8};
    cursor: default;

    &:hover {
      border: 1px solid ${theme.mono8};
    }
  }
`;

export { LongModalButton };

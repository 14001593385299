import React, { useState, useCallback, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { ModalContext as ToastContext } from 'src/contexts/ToastModalContext';
import { ModalContext } from 'src/contexts/ModalContext';
import bridgePageStore from 'src/stores/BridgePageStore';
import {
  requestMetadata,
  requestNFTInfo,
  requestNFTInfoBalance,
} from 'src/apis/api';
import { bridgePageAction } from 'src/actions/bridgePageAction';
import { ethCheckOwnerOf, ethCheckBalanceOf } from 'src/sc/metamask';
import { stepAction } from 'src/actions/stepAction';
import { Input, Button, ImageLoader } from 'src/components/index';
import {
  protocolReplacer,
  errCodeParser,
  copyToClipboard,
} from 'src/utils/utils';
import { ERROR_MSG } from 'src/constants/codes';
import { ChainConfig, NFTMetadataType } from 'src/constants/types';
import { theme } from 'src/styles/theme';
import { ToastModalContent } from './ToastModalContent';
import { NETWORK_ID } from 'src/constants/enums';
import { getXplaLCDClient } from 'src/sc/keplr';

import ic_exclude from 'src/assets/ic_exclude.svg';
import ic_refresh from 'src/assets/ic_refresh.svg';
import ic_remove from 'src/assets/ic_remove.svg';
import img_empty_nft from 'src/assets/img_empty_nft.svg';
import img_tip from 'src/assets/address_bubble_tip.png';
import img_copy_btn from 'src/assets/copy_s.png';
import img_copy_focus from 'src/assets/copy_focus.png';

const initValue: NFTMetadataType = {
  additionalCounterpart: null,
  attributes: [{ key: '', value: '' }],
  description: '',
  external_url: '',
  image: '',
  name: '',
  preview: '',
};

const ModalNFTInput: React.FC = () => {
  const { handleModal, setTitle, setSubtitle } = useContext(ModalContext);
  const toastModal = useContext(ToastContext);

  const store = bridgePageStore;
  const config = store.getBridgeConfig();
  const { fromWalletAddr } = config;
  const fromChain = config.fromChain as ChainConfig;
  const { chainNo, endpoint } = fromChain;
  const [contractAddr, setContractAddr] = useState<string | number>('');
  const [nftId, setNftId] = useState<string | number>('');
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const [nftInfo, setNftInfo] = useState<any>(initValue);

  const [contractErrMsg, setContractErrMsg] = useState<string>('');
  const [tokenIdErrMsg, setTokenIdErrMsg] = useState<string>('');
  const [isReloaded, setIsReloaded] = useState<boolean>(false);
  const [selectedTokenCount, setSelectedTokenCount] = useState<number>(1);
  const [tokenAmount, setTokenAmount] = useState<number>(1);
  const [isShow, setIsShow] = useState<boolean>(false);

  const filter = useCallback(
    (contract: string, nftId: string) => {
      try {
        const reg = /^[0-9]+$/;
        if (chainNo !== NETWORK_ID.XPLA && contract.length !== 42) {
          setContractErrMsg(
            'Invalid NFT Contract Address. Please check the address and try again.'
          );
          return true;
        } else if (!reg.test(nftId)) {
          setTokenIdErrMsg(
            'Invalid NFT Token ID. Please check the ID and try again.'
          );
          return true;
        }
      } catch (e) {
        throw e;
      }
    },
    [chainNo, setTokenIdErrMsg]
  );

  const handleOnLoadNFT = useCallback(async () => {
    try {
      const _constractAddr = contractAddr.toString();
      const _nftId = nftId.toString();
      const isErr = filter(_constractAddr, _nftId);
      if (isErr) return;
      const _cotractStr = contractAddr.toString();
      const contractToLowerCase = _cotractStr.toLowerCase();
      const rsp = await requestNFTInfo({
        chainNo,
        address: contractToLowerCase,
        tokenId: nftId as string,
      });
      const { type, contractName, contractSymbol, metadataUri } = rsp;
      let isOwner;
      let nftAmount;

      if (type === '2') {
        nftAmount = await requestNFTInfoBalance(
          chainNo,
          contractToLowerCase,
          fromWalletAddr,
          nftId
        );
        if (
          nftAmount == null ||
          nftAmount?.retCode !== 0 ||
          nftAmount.result === 0
        )
          throw new Error(JSON.stringify({ code: 'E400' }));
        else setTokenAmount(nftAmount.result);
      }

      if (
        chainNo === NETWORK_ID.BASE ||
        chainNo === NETWORK_ID.FNCY ||
        chainNo === NETWORK_ID.MEVERSE ||
        chainNo === NETWORK_ID.WEMIX ||
        chainNo === NETWORK_ID.OKTC
      ) {
        if (type === '1') {
          isOwner = await ethCheckOwnerOf(
            endpoint,
            _nftId,
            _constractAddr,
            fromWalletAddr
          );
        } else if (type === '2') {
          isOwner = await ethCheckBalanceOf(
            endpoint,
            _nftId,
            _constractAddr,
            fromWalletAddr
          );
        }
        if (!isOwner) {
          throw new Error(JSON.stringify({ code: 'E400' }));
        }
      } else if (chainNo === NETWORK_ID.XPLA && type === '1') {
        try {
          const ret: any = await getXplaLCDClient().wasm.contractQuery(
            _constractAddr,
            {
              owner_of: { token_id: _nftId, owner: fromWalletAddr },
            }
          );
          if (ret.owner !== fromWalletAddr) {
            throw new Error(JSON.stringify({ code: 'E400' }));
          }
        } catch {
          throw new Error(JSON.stringify({ code: 'E400' }));
        }
      }
      const result = await requestMetadata(
        chainNo,
        contractToLowerCase,
        nftId as number
      );
      const metadata = result;
      const _type = parseInt(type);
      const _name = metadata?.name;
      const _imgUrl = metadata?.image ? metadata.image : metadataUri;
      const _symbol = metadata?.symbol ? metadata.symbol : contractSymbol;
      let _imgReplacedProtocol = protocolReplacer(_imgUrl);
      if (_imgReplacedProtocol.includes('{id}')) {
        _imgReplacedProtocol = _imgReplacedProtocol.replace('{id}', _nftId);
      }
      const props = {
        tokenAddr: contractToLowerCase,
        tokenName: !!_name ? _name : !!contractName ? contractName : '',
        tokenSymbol: !!_symbol ? _symbol : '',
        tokenImage: !!_imgUrl ? _imgReplacedProtocol : '',
        tokenType: _type,
        contractName: _type === 2 ? '' : !!contractName ? contractName : '',
      };
      setNftInfo({
        ...props,
      });
      setIsSelected(true);
    } catch (e: any) {
      const { retCode, retMsg } = errCodeParser(e);
      const msg =
        retCode === '4001'
          ? 'Metamask request error \n' + retMsg
          : ERROR_MSG[retCode]
          ? ERROR_MSG[retCode]
          : 'Temporary error\nTry again later';
      toastModal.handleModal(<ToastModalContent type='warning' txt={msg} />);
    }
  }, [
    chainNo,
    contractAddr,
    nftId,
    endpoint,
    fromWalletAddr,
    toastModal,
    filter,
  ]);

  const handleOnSelectNFT = useCallback(() => {
    let count = selectedTokenCount.toString();

    const props = {
      ...nftInfo,
      tokenId: nftId,
      transferTokenAmount: count === '' ? '1' : count,
      tokenScAddr: contractAddr,
    };
    bridgePageAction._changeFromToken(props);
    stepAction._changeStep(2);
    handleModal();
    setTitle('');
    setSubtitle('');
  }, [
    nftInfo,
    contractAddr,
    handleModal,
    nftId,
    setSubtitle,
    setTitle,
    selectedTokenCount,
  ]);

  const handleClear = () => {
    setContractAddr('');
    setNftId('');
    setIsSelected(false);
  };

  const resetErrMsg = () => {
    setContractErrMsg('');
    setTokenIdErrMsg('');
  };

  const nftCountInputChange = (e: any) => {
    let numbersOnly = e.target.value.replace(/[^0-9]/g, '');
    if (parseInt(numbersOnly) > tokenAmount) {
      numbersOnly = tokenAmount;
    } else if (parseInt(numbersOnly) < 1) {
      numbersOnly = 1;
    }
    e.target.value = numbersOnly;
    setSelectedTokenCount(numbersOnly);
  };

  const nftCountInputFocus = (e: any) => {
    setTimeout(() => {
      e.target.selectionStart = e.target.selectionEnd = e.target.value.length;
    });
  };

  const btnDisabled = !nftId || !contractAddr;
  const hasContractErr = contractErrMsg.length > 0;
  const hasTokenIdErr = tokenIdErrMsg.length > 0;

  return (
    <StyledModal>
      <div className='nft-view-wrapper'>
        <div className='nft-view'>
          <div>
            <span>NFT Contract Address</span>
          </div>
          <div
            className={
              !hasContractErr
                ? isSelected
                  ? 'nft-view-input no-pad'
                  : 'nft-view-input'
                : 'nft-view-input  error'
            }
          >
            <Input
              disabled={isSelected}
              value={contractAddr as string}
              setValue={setContractAddr}
              reset={resetErrMsg}
              hasIcon={false}
              placeholder={'Enter NFT Contract Address'}
            />
            {hasContractErr && (
              <img className='exclude' src={ic_exclude} alt='ic_exclude' />
            )}
            {!isSelected && contractAddr && (
              <img
                onClick={() => {
                  setContractErrMsg('');
                  setContractAddr('');
                }}
                className='remove-btn'
                src={ic_remove}
                alt='remove'
              />
            )}
          </div>
          {!!contractErrMsg && (
            <div className='error-txt'>{contractErrMsg}</div>
          )}
          <div className='mgb-20' />
          <div>
            <span>NFT Token ID</span>
          </div>
          <div
            className={
              !hasTokenIdErr
                ? isSelected
                  ? 'nft-view-input no-pad'
                  : 'nft-view-input'
                : 'nft-view-input error'
            }
          >
            <Input
              disabled={isSelected}
              value={nftId as string}
              setValue={setNftId}
              reset={resetErrMsg}
              hasIcon={false}
              placeholder={'Enter NFT Token ID'}
            />
            {hasTokenIdErr && (
              <img className='exclude' src={ic_exclude} alt='ic_exclude' />
            )}
            {!isSelected && nftId && (
              <img
                onClick={() => {
                  setTokenIdErrMsg('');
                  setNftId('');
                }}
                className='remove-btn'
                src={ic_remove}
                alt='remove'
              />
            )}
          </div>
          {!!tokenIdErrMsg && <div className='error-txt'>{tokenIdErrMsg}</div>}
          <div className='mgb-20' />
          <div className='nft-view-btn'>
            {isSelected ? (
              <div className='btn-align'>
                <Button
                  type='ghostWithIcon'
                  icon={ic_refresh}
                  name='Clear'
                  handleOnClick={handleClear}
                />
              </div>
            ) : (
              <Button
                disabled={btnDisabled}
                name='Load NFT'
                handleOnClick={handleOnLoadNFT}
              />
            )}
          </div>
        </div>
        {!isSelected && (
          <div className='preview-item'>
            <div className='empty-outline'>
              <img src={img_empty_nft} alt='empty' />
            </div>
            <div className='empty-txt'>
              When you input the required fields, it will bring up the details
              of the NFT you want to transfer.
            </div>
          </div>
        )}
        {isSelected && (
          <div className='preview-item selected'>
            <div className='img-area'>
              <div className='img-box'>
                <ImageLoader
                  url={!isReloaded ? nftInfo.tokenImage : ''}
                  img={img_empty_nft}
                  isNFT={true}
                  outline={true}
                />
                <div
                  className='reload-img-btn'
                  onClick={() => {
                    setIsReloaded(true);
                    setTimeout(() => {
                      setIsReloaded(false);
                    }, 1000);
                  }}
                />
              </div>
            </div>
            <div className='nft-info-area'>
              {nftInfo.tokenType !== 2 && (
                <div className='nft-collection'>{nftInfo.contractName}</div>
              )}
              {!!nftInfo.tokenName && (
                <div className='nft-info'>
                  <div>Name</div>
                  <div className='nft-name'>{nftInfo.tokenName}</div>
                </div>
              )}
              <div className='nft-info'>
                <div>Token ID</div>
                <div className='token-id'>{nftId}</div>
              </div>
              {!!nftInfo.tokenAddr && (
                <div className='nft-info'>
                  <div>Contract</div>
                  <div className='token-address'>
                    {nftInfo.tokenAddr.slice(0, 5) +
                      '...' +
                      nftInfo.tokenAddr.slice(nftInfo.tokenAddr.length - 5)}
                    <div className='hover-bubble'>{nftInfo.tokenAddr}</div>
                  </div>
                  <img
                    className='copy-btn'
                    src={img_copy_btn}
                    onMouseLeave={(e: any) => {
                      e.target.src = img_copy_btn;
                    }}
                    onMouseOver={(e: any) => {
                      e.target.src = img_copy_focus;
                    }}
                    onClick={(e: any) => {
                      setIsShow(true);
                      copyToClipboard(nftInfo.tokenAddr);
                      setTimeout(() => {
                        const text = document.querySelector('.copy-text');
                        text?.classList.add('fade-out');
                        setTimeout(() => {
                          setIsShow(false);
                        }, 200);
                      }, 600);
                    }}
                    alt='img'
                  ></img>
                  {isShow && <span className='copy-text'>Copied</span>}
                </div>
              )}
            </div>
            {nftInfo.tokenType === 2 &&
            fromChain.chainNo !== NETWORK_ID.MEVERSE ? (
              <>
                <div className='multi-btn-area'>
                  <div className='btn-line'>
                    <div className='nft-count'>
                      {tokenAmount <= 99999 ? (
                        <div className='count-text'>x&nbsp;</div>
                      ) : null}
                      {tokenAmount > 99999 ? '99999+' : tokenAmount}
                    </div>

                    <input
                      className='input-count'
                      type='text'
                      defaultValue={1}
                      maxLength={5}
                      value={selectedTokenCount}
                      onChange={nftCountInputChange}
                      onFocus={nftCountInputFocus}
                    />
                  </div>
                  <Button
                    type='nftSelect'
                    name='Select'
                    handleOnClick={handleOnSelectNFT}
                  />
                </div>
              </>
            ) : (
              <>
                <div className='btn-area'>
                  <Button name='Select' handleOnClick={handleOnSelectNFT} />
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </StyledModal>
  );
};

const StyledModal = styled.div`
  width: 628px;
  padding: 20px 24px 24px;
  margin-top: 20px;
  border-top: 1px solid rgba(79, 83, 85, 0.5);
  & > div.nft-view-wrapper {
    display: flex;
    width: 100%;
    height: auto;
    border-radius: 6px;
    overflow: hidden;
    & > div.nft-view {
      ${theme.body2};
      color: ${theme.mono2};
      width: 292px;
      margin-right: 26px;
      & > div.nft-view-input {
        position: relative;
        width: 100%;
        height: 48px;
        background-color: ${theme.mono7};
        border-radius: 6px;
        margin-top: 4px;
        padding-right: 32px;
        &.error {
          padding-right: 58px;
        }
        &.no-pad {
          padding-right: 0px;
        }
        & > input {
          padding: 14px;
        }
        & > img.exclude {
          position: absolute;
          right: 42px;
          top: 13px;
        }
        & > img.remove-btn {
          position: absolute;
          right: 16px;
          top: 13px;
          cursor: pointer;
        }
      }
      & > div.error-txt {
        color: ${theme.error};
        margin-top: 4px;
      }
      & > div.mgb-20 {
        margin-bottom: 20px;
      }

      & > div.nft-view-btn {
        height: 32px;
        & > div.btn-align {
          float: right;
          width: 100px;
          height: 100%;
        }
      }
    }
    & > div.preview-item {
      position: relative;
      padding-top: 77px;
      width: 258px;
      height: 388px;
      background-color: ${theme.mono7};
      border-radius: 6px;
      &.selected {
        padding: 24px 20px 55px;
        overflow: scroll;
        ::-webkit-scrollbar {
          -webkit-appearance: none;
          width: 0px;
        }
      }
      & > div.empty-outline {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 160px;
        height: 160px;
        margin: 0px auto 30px;
        border: 1px dashed ${theme.mono4};
        border-radius: 6px;
        & > img {
          width: 102px;
          height: 60px;
        }
      }
      & > div.empty-txt {
        width: 160px;
        margin: 0px auto;
        color: ${theme.mono4};
        text-align: center;
      }
      & > div.collection-name {
        ${theme.tab1};
        color: ${theme.mono1};
        margin-bottom: 10px;
        width: 100%;

        & > span.nft-name {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      & > div.img-area {
        ${theme.body2};
        color: ${theme.mono1};
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
        & > div.img-box {
          position: relative;
          width: 160px;
          height: 160px;
          & > div.reload-img-btn {
            width: 32px;
            height: 32px;
            position: absolute;
            bottom: 6px;
            right: 6px;
            background: url(${ic_refresh}) center center no-repeat;
            background-size: 24px 24px;
            background-color: rgba(79, 83, 85, 0.7);
            border-radius: 6px;
            cursor: pointer;
          }
        }
      }
      & > div.nft-info-area {
        ${theme.body2};
        color: ${theme.mono1};
        & > div.nft-collection {
          ${theme.tab1};
          width: 200px;
          margin-bottom: 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        & > div.nft-info {
          display: flex;
          & > div:first-of-type {
            width: 48px;
            margin-right: 10px;
            color: ${theme.mono4};
          }
          & > div.nft-name {
            width: 160px;
            text-overflow: ellipsis;
            overflow: hidden;
            word-break: break-word;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          & > div.token-id {
            width: 160px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          & > img.copy-btn {
            width: 16px;
            height: 18px;
            cursor: pointer;
          }
          & > span.copy-text {
            ${theme.body2}
            color: ${theme.primaryTxt};
            transition: opacity 200ms;
          }
          & > span.fade-out {
            opacity: 0;
          }
          & > div.token-address {
            width: auto;
            margin-right: 2px;
            ${theme.body2}
            word-wrap: break-word;
            position: relative;
            cursor: pointer;
            & > div.hover-bubble {
              display: none;
            }
            &:hover {
              & > div.hover-bubble {
                display: block;
                ${theme.caption};
                padding: 8px 8px 10px;
                position: absolute;
                bottom: -3px;
                left: 50%;
                top: 120%;
                transform: translateX(-50%);
                width: 218px;
                height: 44px;
                visibility: visible;
                background-color: ${theme.mono4};
                border-radius: 6px;
                z-index: 20000;
                &:after {
                  content: ' ';
                  position: absolute;
                  bottom: 44px;
                  left: 50%;
                  width: 6px;
                  height: 5px;
                  background: url(${img_tip}) center center no-repeat;
                  background-size: 6px 5px;
                  transform: translateX(-50%);
                }
              }
            }
          }
        }
      }
      & > div.multi-btn-area {
        display: flex;
        width: 218px;
        height: 32px;
        position: absolute;
        bottom: 23px;
        & > .btn-line {
          width: 150px;
          display: flex;
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
          border: 1px solid ${theme.primaryTxt};
          border-right: none;
          background-color: #000000;
          align-items: center;
          & > .input-count {
            padding: 0px;
            width: 40px;
            height: 20px;
            margin-left: auto;
            margin-right: 15px;
            border: 0px;
            background-color: #000000;
            color: ${theme.mono1};
            outline: none;
            text-align: end;
            caret-color: ${theme.primaryBG};
            ${theme.attention};
          }
          & > div.nft-count {
            ${theme.tokenAmount}
            padding: 1px 4px;
            display: inline-flex;
            justify-content: center;
            height: 18px;
            margin-left: 10px;
            min-width: 20px;
            width: auto;
            background-color: rgba(22, 217, 146, 0.14);
            border-radius: 6px;
            color: ${theme.primaryTxt};
          }
        }
      }
      & > div.btn-area {
        width: 218px;
        height: 32px;
        position: absolute;
        bottom: 23px;
      }
    }
  }
`;

export { ModalNFTInput };

import React from 'react';
import styled from 'styled-components';
import { LongModalButton } from 'src/components/index';
import { theme } from 'src/styles/theme';

interface IProps {
  title?: string;
  modalHandler: () => void;
  btnDisabled: boolean;
}

const FromChainTokenSelectView: React.FC<IProps> = (props) => {
  const { title = 'Tokens', modalHandler, btnDisabled } = props;

  const isToken = title === 'Tokens';
  return (
    <>
      <div className="mgb-16" />
      <StyledComp>
        <div className="sub-title">{title}</div>
        <div className="token-select-area">
          <div className="w-564">
            <LongModalButton
              disabled={btnDisabled}
              initialValue={`Select your ${isToken ? 'Tokens' : 'NFTs'}`}
              name=""
              handleOnClick={() => {
                modalHandler();
              }}
            />
          </div>
        </div>
      </StyledComp>
    </>
  );
};

const StyledComp = styled.div`
  width: 100%;
  height: auto;
  & > div {
    &.sub-title {
      color: ${theme.mono3};
      margin-bottom: 6px;
    }
    &.token-select-area {
      width: auto;
      height: 48px;
    }
  }
  .mgb-16 {
    margin-bottom: 16px;
  }
  .w-564 {
    width: 564px;
    height: 100%;
  }
`;

export { FromChainTokenSelectView };

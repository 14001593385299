import React, { useContext, useCallback } from 'react';
import bridgePageStore from 'src/stores/BridgePageStore';
import stepStore from 'src/stores/StepStore';
import {
  FromChainTokenSelectView,
  ModalTokenList,
  LoadingModal,
  ModalServiceUnavailable,
} from 'src/components/index';
import { ModalContext } from 'src/contexts/ModalContext';
import { ModalContext as ModalShadowContext } from 'src/contexts/ModalShadowContext';
import { requestFTList } from 'src/apis/api';
import {
  getHavahBalance as HAVAHCoinBalance,
  getHavahTokenBalance,
} from 'src/sc/havah';
import {
  getBalance as ethGetBalance,
  getBalanceOfToken,
} from 'src/sc/metamask';
import { errCodeParser, numberZeroCompare } from 'src/utils/utils';
import { ChainConfig } from 'src/constants/types';
import IconService from 'icon-sdk-js';

import { NETWORK_ID } from 'src/constants/enums';
import { getBalanceFromKeplr, getXplaLCDClient } from 'src/sc/keplr';
import { getTokenBalance } from 'src/sc/solana';
const { IconConverter } = IconService;

const FromChainTokenSelectViewContainer: React.FC = () => {
  const { handleModal, setList, setIsLoadingList, setTitle, setSubtitle } =
    useContext(ModalContext);
  const modalShadow = useContext(ModalShadowContext);
  const store = bridgePageStore;
  const config = store.getBridgeConfig();
  const stepNumber = stepStore.getStepNumber();

  const addr = config.fromWalletAddr;

  const { fromWalletAddr } = config;
  const fromChain = config.fromChain as ChainConfig;
  const { chainNo } = fromChain;
  const ep = fromChain.endpoint;
  // Fix ModalTokenList

  const getTokenList = useCallback(
    async (chainNo: number) => {
      try {
        const rsp = await requestFTList(chainNo);
        return rsp;
      } catch (e: any) {
        const { retCode } = errCodeParser(e);
        if (retCode === '1') {
          modalShadow.handleModal(
            <ModalServiceUnavailable close={() => modalShadow.handleModal()} />
          );
        }
        throw e;
      }
    },
    [modalShadow]
  );

  const getBalance = useCallback(
    async (data: any) => {
      try {
        const { tokenAddr, chainNo, tokenDecimal, tokenType } = data;
        let _tempBalance;
        if (tokenType === 'C') {
          if (chainNo === 4) {
            _tempBalance = await HAVAHCoinBalance(fromWalletAddr, tokenDecimal);
          } else if (chainNo === NETWORK_ID.XPLA) {
            const [balance] = await getXplaLCDClient().bank.balance(
              fromWalletAddr
            );
            const value = balance.get('axpla');

            _tempBalance = value
              ? IconConverter.toBigNumber(value.amount.toString())
                  .times(Math.pow(10, -1 * tokenDecimal))
                  .toNumber()
              : 0;
          } else {
            _tempBalance = await ethGetBalance(ep, addr, tokenDecimal);
          }
        } else {
          if (chainNo === NETWORK_ID.XPLA) {
            // const ret = await getCW20TokenBalance(
            //   BRIDGE_CHAIN_NAME[chainNo],
            //   tokenAddr,
            //   ep,
            //   fromWalletAddr
            // );
            // console.log(ret);
            // const gasPrices = await (
            //     await fetch('https://cube-fcd.xpla.dev/v1/txs/gas_prices', {
            //       redirect: 'follow',
            //     })
            //   ).json();
            const ret: any = await getXplaLCDClient().wasm.contractQuery(
              tokenAddr,
              {
                balance: { address: fromWalletAddr },
              }
            );
            // console.log(re);
            _tempBalance = IconConverter.toBigNumber(ret.balance)
              .times(Math.pow(10, -1 * tokenDecimal))
              .toNumber();
          } else if (chainNo === NETWORK_ID.HAVAH) {
            _tempBalance = await getHavahTokenBalance(
              tokenAddr,
              fromWalletAddr,
              tokenDecimal
            );
          } else if (chainNo === NETWORK_ID.SOLANA) {
            // getTokenBalance(fromChain.endpoint, tokenAddr, fromWalletAddr);
          } else {
            _tempBalance = await getBalanceOfToken(
              ep,
              addr,
              tokenAddr,
              tokenDecimal
            );
          }
        }
        return _tempBalance;
      } catch (e) {
        throw e;
      }
    },
    [addr, ep, fromWalletAddr]
  );

  const getBalanceOfTokens = useCallback(async () => {
    try {
      const rsp = await getTokenList(chainNo);
      const hasBalanceTokens = await Promise.all(
        rsp.map(async (data: any) => {
          const response = await getBalance(data);
          let _tempObj = {
            ...data,
          };
          _tempObj = {
            ..._tempObj,
            tokenBalance: response,
          };
          return _tempObj;
        })
      );
      const nonZeroList = hasBalanceTokens.filter(
        (el) => !!el && numberZeroCompare(el.tokenBalance) === 'gt'
      );
      const zeroList = hasBalanceTokens.filter(
        (el) => !!el && numberZeroCompare(el.tokenBalance) !== 'gt'
      );
      const result = nonZeroList.concat(zeroList);

      setList(result);
    } catch (e) {
      throw e;
    }
  }, [chainNo, getBalance, getTokenList, setList]);

  const refresh = useCallback(async () => {
    try {
      setIsLoadingList(true);
      await getBalanceOfTokens();
    } catch (e) {
      throw e;
    } finally {
      setIsLoadingList(false);
    }
  }, [getBalanceOfTokens, setIsLoadingList]);

  const modalHandler = useCallback(async () => {
    try {
      modalShadow.handleModal(<LoadingModal />);
      await getBalanceOfTokens();
    } catch (e) {
      throw e;
    } finally {
      modalShadow.handleModal();
      setTitle('My Tokens on Chain');
      setSubtitle('Only supported Tokens will appear in the list.');
      handleModal(<ModalTokenList refresh={refresh} />);
    }
  }, [
    getBalanceOfTokens,
    handleModal,
    modalShadow,
    refresh,
    setSubtitle,
    setTitle,
  ]);

  return (
    <FromChainTokenSelectView
      modalHandler={modalHandler}
      btnDisabled={stepNumber !== 1}
    />
  );
};

export { FromChainTokenSelectViewContainer };

import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  ChangeEvent,
} from 'react';
import { NETWORK_ID } from 'src/constants/enums';
import { requestFTToChainList } from 'src/apis/api';
import {
  ToChainConnectView,
  ModalWalletList,
  ModalServiceUnavailable,
} from 'src/components/index';
import stepStore from 'src/stores/StepStore';
import bridgePageStore from 'src/stores/BridgePageStore';
import { ModalContext } from 'src/contexts/ModalContext';
import { ModalContext as ModalShadowContext } from 'src/contexts/ModalShadowContext';
import { bridgePageAction } from 'src/actions/bridgePageAction';
import { WALLET_LIST } from 'src/constants/index';
import { ChainConfig, ChainListType, FromFTConfig } from 'src/constants/types';
import { errCodeParser } from 'src/utils/utils';

const ToChainConnectViewContainer: React.FC = () => {
  const { handleModal, setTitle } = useContext(ModalContext);
  const modalShadow = useContext(ModalShadowContext);
  const store = bridgePageStore;
  const config = store.getBridgeConfig();
  const stepNumber = stepStore.getStepNumber();

  const fromToken = config.fromToken as FromFTConfig;
  const fromChain = config.fromChain as ChainConfig;
  const toChain = config.toChain as ChainConfig;
  const toWallet = config.toWalletAddr;
  const tokenId = fromToken.tokenId;
  const fromChainId = fromChain.chainNo;
  const toChainId = toChain.chainNo;
  const toChainName = toChain.chainName;

  const [toChainList, setToChainList] = useState<ChainListType[]>([]);

  const [walletAddr, setWalletAddr] = useState<string>(toWallet);
  const [chainNo, setChainNo] = useState<number>(toChainId);
  const [chainName, setChainName] = useState<string>(toChainName);

  const isActiveToChain = stepNumber >= 4;
  const isActiveToChainWallet = isActiveToChain && !!toChainId;

  const _onChange = () => {
    const _config = store.getBridgeConfig();
    const toChain = _config.toChain as ChainConfig;
    const toChainNo = toChain.chainNo;
    const toChainName = toChain.chainName;
    const toWalletAddr = _config.toWalletAddr;
    setWalletAddr(toWalletAddr);
    setChainNo(toChainNo);
    setChainName(toChainName);
  };

  const getToChainList = useCallback(async () => {
    try {
      const _tokenId = tokenId as number;
      const rsp = await requestFTToChainList(fromChainId, _tokenId);
      setToChainList(rsp);
    } catch (e: any) {
      const { retCode } = errCodeParser(e);
      const _parsedCode = parseInt(retCode);
      if (_parsedCode === 1) {
        modalShadow.handleModal(
          <ModalServiceUnavailable close={() => modalShadow.handleModal()} />
        );
        return;
      }
      throw e;
    }
  }, [tokenId, fromChainId, modalShadow]);

  useEffect(() => {
    store.addChangeListener(_onChange);
    return () => store.removeChangeListener(_onChange);
  }, []);

  useEffect(() => {
    if (stepNumber >= 3) {
      getToChainList();
    }
  }, [stepNumber, getToChainList]);

  const toChainModalHandler = useCallback(() => {
    const chainName = NETWORK_ID[toChainId];
    const toLower = chainName.toLowerCase();
    const walletList = WALLET_LIST[toLower];
    const chainListIdx = toChainList.findIndex((el) => el.chainNo === chainNo);
    const chainInfo = toChainList[chainListIdx];
    setTitle('Connect to Wallet');
    handleModal(
      <ModalWalletList
        chainInfo={chainInfo}
        list={walletList}
        withInput={true}
        saveBtn={saveToChainWalletAddr}
      />
    );
  }, [toChainId, handleModal, chainNo, setTitle, toChainList]);

  const saveToChainWalletAddr = (walletAddr: string) => {
    bridgePageAction._changeToWallet(walletAddr);
  };

  const toChainIdHandler = useCallback(
    (chainId: number | string) => {
      const chainListIdx = toChainList.findIndex(
        (el) => el.chainNo === chainId
      );
      bridgePageAction._changeToChain(toChainList[chainListIdx]);
    },
    [toChainList]
  );

  const resetToChainView = () => {
    bridgePageAction._removeToWallet();
  };
  return (
    <ToChainConnectView
      chainList={toChainList}
      curStepIdx={stepNumber}
      chainNo={chainNo}
      chainName={chainName}
      isActiveToChain={isActiveToChain}
      isActiveToChainWallet={isActiveToChainWallet}
      toChainWalletAddr={walletAddr}
      modalHandler={toChainModalHandler}
      toChainIdHandler={toChainIdHandler}
      reset={resetToChainView}
      handleOnList={getToChainList}
    />
  );
};

export { ToChainConnectViewContainer };

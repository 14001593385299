import React from 'react';
import styled from 'styled-components';
import { theme } from 'src/styles/theme';
import ic_info from 'src/assets/ic_info.svg';
import { ReactComponent as BubbleTip } from 'src/assets/ic_bubble_tip.svg';
import { ReactComponent as InfoIcon } from 'src/assets/ic_info.svg';

interface IProps {
  color?: string;
  txt?: string;
  iconColor?: string;
  font_weight?: string;
}

const Tooltip: React.FC<IProps> = (props) => {
  const { color = '', txt = '', iconColor = '', font_weight = '' } = props;

  return (
    <StyleTooltip color={color} iconColor={iconColor} font_weight={font_weight}>
      <InfoIcon></InfoIcon>
      <div className="bubble">
        <span>{txt}</span>
        <div className="bubble-tip">
          <BubbleTip fill={color} />
        </div>
      </div>
    </StyleTooltip>
  );
};

const StyleTooltip = styled.div<{
  color: string;
  iconColor: string;
  font_weight: string;
}>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  & > svg {
    width: 20px;
    height: 20px;
    & > path {
      fill: ${(props) => props.iconColor};
    }
  }
  &:hover {
    & > div.bubble {
      display: block;
      position: absolute;
      bottom: 24px;
      padding: 8px 10px;
      background-color: ${(props) => props.color};
      border-radius: 6px;
      width: 200px;
      line-height: 14px;
      & > span {
        ${theme.caption};
        font-weight: ${(props) => props.font_weight};
        color: ${theme.mono1};
      }
      & > div.bubble-tip {
        position: absolute;
        left: 48%;
        bottom: -7.5px;
        & > svg {
          width: 6px;
          height: 5px;
          & > path {
            fill: ${(props) => props.color};
          }
        }
      }
    }
  }
  & > div.bubble {
    display: none;
  }
`;

export { Tooltip };

import React from 'react';
import { ExplorerViewController } from 'src/controller/ExplorerViewController/index';

const BridgeExplorerPage: React.FC = () => {
  return (
    <>
      <ExplorerViewController />
    </>
  );
};

export { BridgeExplorerPage };

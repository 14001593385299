import { useState, ReactNode } from 'react';

export const useShadowModal = () => {
  const [isModalOpened, toggleModal] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<ReactNode>();

  const handleModal = (props?: any) => {
    toggleModal(!!props ? true : false);
    if (!!props) {
      setModalContent(props);
    }
  };

  return {
    isModalOpened,
    modalContent,
    handleModal,
  };
};

import dispatcher from 'src/dispatcher/dispatcher';

const BridgeDispatcher = () => {
  const handleViewAction = (action: any) => {
    dispatcher.dispatch({
      source: 'BRIDGE_VIEW_ACTION',
      action,
    });
  };

  return {
    handleViewAction,
  };
};

export { BridgeDispatcher };

import React, { useCallback, useEffect, useState } from 'react';
import stepStore from 'src/stores/StepStore';
import bridgePageStore from 'src/stores/BridgePageStore';
import { Button } from 'src/components/index';
import // ReadyToTransferViewController,
// TransactionCompleteViewController
'src/controller/FTViewController/index';

import {
  FromChainConnectViewContainer,
  FromChainTokenSelectViewContainer,
  FromChainTokenSelectedViewContainer,
  ToChainConnectViewContainer,
  SendViewContainer,
  TransactionCompleteViewContainer,
} from 'src/container/BridgeFTContainer/index';
import { bridgePageAction } from 'src/actions/bridgePageAction';
import { StepperContainer } from 'src/container/StepperContainer/StepperContainer';
import { stepAction } from 'src/actions/stepAction';
import {
  StyledWrapper,
  StyledBtnWrapper,
  StyledArrow,
} from 'src/pages/StyledPage';

const FTBridgePage: React.FC = () => {
  const store = bridgePageStore;
  const [isBtnAble, setIsAble] = useState<boolean>(false);
  const [stepIdx, setStepIdx] = useState<number>(0);

  const _onChangeBridgeConfig = useCallback(() => {
    const { toWalletAddr } = store.getBridgeConfig();
    const hasToWallet = toWalletAddr.length > 0;
    setIsAble(hasToWallet);
  }, [store]);

  const _onChangeStep = () => {
    setStepIdx(stepStore.getStepNumber());
  };

  useEffect(() => {
    store.addChangeListener(_onChangeBridgeConfig);
    stepStore.addChangeListener(_onChangeStep);
    return () => {
      store.removeChangeListener(_onChangeBridgeConfig);
      stepStore.removeChangeListener(_onChangeStep);
    };
  }, [store, _onChangeBridgeConfig]);

  // useEffect(() => {
  //   if (window.ethereum) {
  //     const { ethereum } = window;
  //     ethereum.on('accountsChanged', () => {
  //       bridgePageAction._removeFromWallet();
  //       bridgePageAction._removeFromToken();
  //       bridgePageAction._removeToChain();
  //       bridgePageAction._removeWeb3WalletID();
  //       bridgePageAction._removeToWallet();
  //       stepAction._changeStep(0);
  //     });
  //   }
  // }, []);

  useEffect(() => {
    const eventHandler = async (event: any) => {
      const { type, payload } = event.detail;
      if (type === 'RESPONSE_ADDRESS') {
        bridgePageAction._changeFromWallet(payload);
        stepAction._changeStep(1);
        // HAVAH의 USDT
        // const tx = await getBalance('cx54b2f26668f8892db466f0905a6f2668eecd210d', payload);
        // console.log(tx);
      }
    };
    window.addEventListener('ICONEX_RELAY_RESPONSE', eventHandler);

    return () =>
      window.removeEventListener('ICONEX_RELAY_RESPONSE', eventHandler);
  }, []);

  if (stepIdx === 6) {
    return (
      <>
        <TransactionCompleteViewContainer />
        <StepperContainer />
      </>
    );
  } else if (stepIdx === 5) {
    return (
      <>
        <SendViewContainer />
        <StepperContainer />
      </>
    );
  } else {
    return (
      <>
        <StyledWrapper step={stepIdx} className='container from'>
          {stepIdx <= 4 && <FromChainConnectViewContainer />}
          {stepIdx <= 2 && <FromChainTokenSelectViewContainer />}
          {stepIdx >= 3 && <FromChainTokenSelectedViewContainer />}
        </StyledWrapper>
        <StyledArrow>
          <div />
        </StyledArrow>
        <StyledWrapper step={0} className='container to'>
          <ToChainConnectViewContainer />
        </StyledWrapper>
        <StyledBtnWrapper width={628}>
          <Button
            disabled={!isBtnAble}
            name='Next'
            handleOnClick={() => {
              // window.history.pushState({ forSend: true }, document.title, window.location.href);
              stepAction._changeStep(5);
            }}
          />
        </StyledBtnWrapper>
        <StepperContainer />
      </>
    );
  }
};

export { FTBridgePage };

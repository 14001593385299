import React from 'react';
import styled from 'styled-components';
import { theme } from 'src/styles/theme';
import ic_exclude from 'src/assets/ic_exclude.svg';

interface IProps {
  txt?: string;
}

const Warning1: React.FC<IProps> = ({
  txt = 'Amount exceeds the current balance.',
}) => {
  return (
    <StyledComp className="warning-1">
      <div>
        <img src={ic_exclude} alt="exclude" />
        {txt.split('\n').map((el: string) => {
          return (
            <>
              <span>{el}</span>
              <br />
            </>
          );
        })}
      </div>
    </StyledComp>
  );
};

const StyledComp = styled.div`
  display: block;
  margin-top: 9px;
  width: 100%;
  height: 20px;
  & > div {
    ${theme.body2};
    float: right;
    display: flex;
    align-items: center;
    color: ${theme.error};
    & > img {
      margin-right: 8px;
    }
  }
`;

export { Warning1 };

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { theme } from 'src/styles/theme';
import ic_finalizing_void from 'src/assets/img_finalizing_void.gif';

interface IProps {
  timer: number;
}

const Approximate: React.FC<IProps> = ({ timer }) => {
  const [count, setCount] = useState<number>(timer);

  useEffect(() => {
    const timerId = setInterval(() => {
      setCount((prev: number) => {
        if (prev === 1) {
          clearInterval(timerId);
          return 1;
        }
        return prev - 1;
      });
    }, 60000);
    return () => clearInterval(timerId);
  }, []);

  return (
    <StyledTxt>
      <img src={ic_finalizing_void} alt="load" />
      <span>{count}min</span>
    </StyledTxt>
  );
};

const StyledTxt = styled.span`
  display: inline-block;
  display: flex;
  align-items: center;
  padding: 4px 6px;
  background-color: ${theme.mono4};
  border-radius: 4px;
  width: 63px;
  height: 22px;
  margin-right: 6px;
  & > span {
    ${theme.body2};
    font-weight: 700;
    color: ${theme.mono7};
  }
  & > img {
    margin-right: 4px;
    width: 14px;
    height: 14px;
  }
`;

export { Approximate };

import { useEffect } from 'react';

const useAccessBlocker = () => {
  const getHistory = (e: any) => {
    return (e.returnValue = '');
  };

  useEffect(() => {
    window.addEventListener('beforeunload', getHistory);
    return () => {
      window.removeEventListener('beforeunload', getHistory);
    };
  }, []);
};

export { useAccessBlocker };

import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter } from 'react-router-dom';
import 'src/styles/fonts.css';
import 'src/global.css';
import App from 'src/App';
import { ModalProvider } from 'src/contexts/ModalContext';
import { ModalProvider as ToastProvider } from 'src/contexts/ToastModalContext';
import { ModalProvider as ModalShadowProvider } from 'src/contexts/ModalShadowContext';
import { theme } from 'src/styles/theme';
import { getChainOptions, WalletProvider } from '@xpla/wallet-provider';

declare global {
  interface Window {
    ethereum: any;
    klaytn: any;
    havah: any;
    aptos: any;
    keplr: any;
    okxwallet: any;
    phantom: any;
  }
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
getChainOptions().then((chainOptions) => {
  root.render(
    <ThemeProvider theme={theme}>
      <BrowserRouter basename={'/'}>
        <WalletProvider {...chainOptions}>
          <ModalShadowProvider>
            <ToastProvider>
              <ModalProvider>
                <App />
              </ModalProvider>
            </ToastProvider>
          </ModalShadowProvider>
        </WalletProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
});

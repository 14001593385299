import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Button, ModalStatusDescription } from 'src/components/index';
import { ModalContext } from 'src/contexts/ModalContext';
import { localeTimeSetter } from 'src/utils/utils';
import {
  NETWORK_LOGO,
  SCAN_ADDR_URL,
  SCAN_URL,
  PATH,
} from 'src/constants/index';
import { TransferDataType } from 'src/constants/types';
import { theme } from 'src/styles/theme';
import ic_info from 'src/assets/ic_info.svg';
import ic_t_arrow_r from 'src/assets/ic_t_arrow_r.svg';
import ic_dropdown_d_gray from 'src/assets/ic_dropdown_d_gray.svg';
import logo_default from 'src/assets/chain/chain_default.png';
import { NETWORK_ID } from 'src/constants/enums';

interface IProps {
  data: TransferDataType[];
  curType: number;
  handleOnDropdown: (idx: number) => void;
  isLoading: boolean;
}

const TableView: React.FC<IProps> = (props) => {
  const { data, curType, handleOnDropdown, isLoading } = props;

  const navigate = useNavigate();
  const [isToggle, setIsToggle] = useState<boolean>(false);
  const { handleModal, setTitle } = useContext(ModalContext);
  const hasNoList = data.length === 0;

  const types = ['All Types', 'Tokens', 'NFTs'];
  const isDev = process.env.REACT_APP_IS_DEV === 'dev';

  return (
    <StyledView>
      <div className='table-area'>
        <table>
          <thead>
            <tr>
              <th></th>
              <th>
                <div
                  onClick={() => setIsToggle(!isToggle)}
                  className='dropdown'
                >
                  <span>{types[curType]}</span>
                  <img src={ic_dropdown_d_gray} alt='dropdown' />
                  {isToggle && (
                    <ul className='dropdown-list'>
                      {types.map((el, idx) => {
                        return (
                          <li
                            key={el}
                            onClick={() => {
                              handleOnDropdown(idx);
                            }}
                          >
                            {el}
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </div>
              </th>
              <th>Timestamp</th>
              <th>Account</th>
              <th>
                <div className='from-to'>
                  <span>From</span>
                  <img className='t-arrow' src={ic_t_arrow_r} alt='arrow' />
                  <span>To</span>
                </div>
              </th>
              <th>
                <div
                  onClick={() => {
                    setTitle('Transaction Status');
                    handleModal(<ModalStatusDescription />);
                  }}
                  className='bubble-noti'
                >
                  <span className='underline'>Status</span>
                  <img src={ic_info} alt='bubble_noti' />
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {isLoading && (
              <tr className='no-list'>
                <td colSpan={6}>Loading...</td>
              </tr>
            )}
            {!isLoading && hasNoList && (
              <tr className='no-list'>
                <td colSpan={6}>No Results Found</td>
              </tr>
            )}
            {!isLoading && !hasNoList && (
              <>
                {data.map((el, idx) => {
                  const {
                    messageId,

                    tokenType,
                    fromTxHash,
                    fromTxDate = 0,
                    fromChainNo,
                    senderAddress,

                    toTxHash,
                    toChainNo,
                    transferStatus,
                  } = el;
                  const isNFT = tokenType === '2';
                  const dateStr = localeTimeSetter(
                    new Date(fromTxDate * 1000).toISOString()
                  );
                  const localDate = !!fromTxDate
                    ? dateStr.split('T')[0] +
                      ' ' +
                      dateStr.split('T')[1].substring(0, 8)
                    : '0000-00-00 00:00:00';
                  const fromAddUrl =
                    isDev && fromChainNo === NETWORK_ID.APTOS
                      ? '?network=testnet'
                      : isDev && fromChainNo === NETWORK_ID.SOLANA
                      ? '?cluster=devnet'
                      : '';
                  const toAddUrl =
                    isDev && toChainNo === NETWORK_ID.APTOS
                      ? '?network=testnet'
                      : isDev && toChainNo === NETWORK_ID.SOLANA
                      ? '?cluster=devnet'
                      : '';
                  return (
                    <tr
                      className={transferStatus >= 10 ? 'error' : ''}
                      key={messageId}
                      onClick={(e) => {
                        e.preventDefault();
                        navigate(`${PATH.EXPLORER_DETAIL}${messageId}`);
                      }}
                    >
                      <td className='table-idx table-txt-gray'>{idx + 1}</td>
                      <td>{isNFT ? 'NFTs' : 'Tokens'}</td>
                      <td className='table-txt-gray'>{localDate}</td>
                      <td>
                        <div className='w-106'>
                          <a
                            className='link'
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              if (!!senderAddress) {
                                window.open(
                                  `${SCAN_ADDR_URL[fromChainNo]}${senderAddress}${fromAddUrl}`
                                );
                              }
                            }}
                            href={`${SCAN_ADDR_URL[fromChainNo]}${senderAddress}${fromAddUrl}`}
                          >
                            <Button
                              type='ghost'
                              name={senderAddress}
                              handleOnClick={() => {}}
                            />
                          </a>
                        </div>
                      </td>
                      <td>
                        <div className='from-to'>
                          <img
                            className='logo mgr-8'
                            src={
                              !!NETWORK_LOGO[fromChainNo]
                                ? NETWORK_LOGO[fromChainNo]
                                : logo_default
                            }
                            alt='logo'
                          />
                          <div className='w-106'>
                            <a
                              className='link'
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                if (!!fromTxHash) {
                                  window.open(
                                    `${SCAN_URL[fromChainNo]}${fromTxHash}${fromAddUrl}`
                                  );
                                }
                              }}
                              href={`${SCAN_URL[fromChainNo]}${fromTxHash}${fromAddUrl}`}
                            >
                              <Button
                                type='ghost'
                                isError={!fromTxHash && transferStatus >= 10}
                                name={fromTxHash}
                                handleOnClick={() => {}}
                              />
                            </a>
                          </div>
                          <img
                            className='t-arrow'
                            src={ic_t_arrow_r}
                            alt='arrow'
                          />
                          <img
                            className='logo mgr-8'
                            src={
                              !!NETWORK_LOGO[toChainNo]
                                ? NETWORK_LOGO[toChainNo]
                                : logo_default
                            }
                            alt='logo'
                          />
                          <div className='w-106'>
                            <a
                              className='link'
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                if (!!toTxHash) {
                                  window.open(
                                    `${SCAN_URL[toChainNo]}${toTxHash}${toAddUrl}`
                                  );
                                }
                              }}
                              href={`${SCAN_URL[toChainNo]}${toTxHash}${toAddUrl}`}
                            >
                              <Button
                                type='ghost'
                                isError={!toTxHash && transferStatus >= 10}
                                name={toTxHash}
                                handleOnClick={() => {}}
                              />
                            </a>
                          </div>
                        </div>
                      </td>
                      <td>
                        {transferStatus < 10 && transferStatus !== 4 && (
                          <span>Processing ({transferStatus}/3)</span>
                        )}
                        {transferStatus === 4 && <span>Completed</span>}
                        {transferStatus >= 10 && (
                          <span className='txt-error'>Failed</span>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </>
            )}
          </tbody>
        </table>
      </div>
    </StyledView>
  );
};

const StyledView = styled.div`
  width: 100vw;
  height: auto;
  background-color: ${theme.mono1};
  & > div.table-area {
    width: 1208px;
    margin: 0px auto;
    & > table {
      width: 100%;
      border-top: 2px solid ${theme.mono8};
      border-bottom: 1px solid ${theme.mono2};
      border-collapse: collapse;
      & > thead {
        border-bottom: 1px solid ${theme.mono2}!important;
        & > tr {
          height: 40px;
          color: ${theme.mono4};
          & > th {
            ${theme.body1};
            text-align: left;
            &:nth-child(1) {
              width: 100px;
            }
            &:nth-child(2) {
              width: 144px;
            }
            &:nth-child(3) {
              width: 183px;
            }
            &:nth-child(4) {
              width: 153px;
            }
            &:nth-child(5) {
              width: 464px;
            }
            &:nth-child(6) {
              width: 164px;
            }
            & > span {
              vertical-align: middle;
            }
            & > div.bubble-noti {
              position: relative;
              cursor: pointer;
              &:hover {
                &:before {
                  position: absolute;
                  content: '';
                  top: -8px;
                  right: 104px;
                  border-right: 4px solid transparent;
                  border-left: 4px solid transparent;
                  border-top: 4px solid ${theme.subpink};
                  box-sizing: border-box;
                }
                &:after {
                  ${theme.caption};
                  position: absolute;
                  content: 'Press to view Transaction status';
                  top: -43px;
                  left: -30px;
                  background-color: ${theme.subpink};
                  padding: 8px 10px;
                  color: ${theme.mono1};
                  border-radius: 6px;
                  width: 173px;
                  box-sizing: border-box;
                }
              }
              & > img {
                margin-left: 2px;
                vertical-align: middle;
              }
            }

            & > div.dropdown {
              position: relative;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              width: 100%;
              cursor: pointer;
              & > ul.dropdown-list {
                position: absolute;
                top: 30px;
                left: 0px;
                list-style: none;
                width: 144px;
                border-radius: 6px;
                border: 1px solid ${theme.mono2};
                padding: 0px;
                margin: 0px;
                padding-inline-start: 0px;
                background-color: ${theme.mono1};
                & > li {
                  display: flex;
                  align-items: center;
                  padding: 8px 12px;
                  width: 144px;
                  height: 36px;
                  &:hover {
                    width: 144px;
                    height: 36px;
                    border-radius: 6px;
                    border: 1px solid ${theme.primaryBG};
                    background-color: rgba(22, 217, 146, 0.1);
                  }
                }
              }
            }
          }
        }
      }
      & > tbody {
        & > tr {
          &.no-list {
            &:hover {
              border: none;
              background-color: transparent;
            }
            & > td {
              color: ${theme.mono4};
              width: 100%;
              height: 100px;
              vertical-align: middle;
              text-align: center;
              background-color: rgba(227, 228, 228, 0.16);
            }
          }

          &.error {
            &:hover {
              border-radius: 6px;
              border: 1px solid ${theme.error};
              background-color: rgba(255, 89, 89, 0.1);
            }
          }
          &:hover {
            border-radius: 6px;
            border: 1px solid ${theme.primaryBG};
            background-color: rgba(22, 217, 146, 0.1);
            .normal {
              background-color: ${theme.mono1};
            }
            .error {
              background-color: ${theme.mono1};
            }
          }

          & > td {
            height: 50px;
            & > span {
              &.txt-error {
                ${theme.tab1};
                color: ${theme.error};
              }
            }
            &.table-idx {
              text-align: center;
            }
            &.table-txt-gray {
              color: ${theme.mono4};
            }
          }
        }
      }
    }
  }
  .underline {
    text-decoration: underline;
  }
  .w-106 {
    width: 106px;
    height: 30px;
  }
  .from-to {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    & > span {
      width: 194px;
      &:first-of-type {
        text-align: end;
      }
    }
    & > img {
      &.t-arrow {
        margin: 0px 8px;
      }
      &.mgr-8 {
        margin-right: 8px;
      }
      &.logo {
        border-radius: 4px;
        width: 30px;
        height: 30px;
      }
    }
  }
  .link {
    text-decoration: none;
  }
`;

export { TableView };

import React from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { stepAction } from 'src/actions/stepAction';
import { ReactComponent as BackIcon } from 'src/assets/ic_back_align.svg';
import { theme } from 'src/styles/theme';

const BridgeSummaryHeader: React.FC = () => {
  const { pathname } = useLocation();
  const isNFT = pathname.includes('/bridge/nft');

  return (
    <StyledView>
      <div
        className="back-btn-area"
        onClick={async () => {
          stepAction._changeStep(isNFT ? 2 : 4);
        }}
      >
        <BackIcon />
      </div>
      <span>Summary</span>
    </StyledView>
  );
};

const StyledView = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  margin-bottom: 24px;
  & > div.back-btn-area {
    position: absolute;
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: ${theme.mono6};
    cursor: pointer;
  }
  & > span {
    ${theme.title1};
    color: ${theme.mono1};
  }
`;

export { BridgeSummaryHeader };

import { createContext } from 'react';
import { ModalWrapper } from 'src/components/index';
import { useModal } from 'src/hooks/useModal';

const ModalContext = createContext<any>({});
const { Provider } = ModalContext;

const ModalProvider = ({ children }: { children: React.ReactNode }) => {
  const {
    isModalOpened,
    handleModal,
    modalContent,
    isLoadingList,
    setIsLoadingList,
    curPage,
    setCurPage,
    morailsCursor,
    setMorailsCursor,
    setTotal,
    total,
    setTitle,
    title,
    setSubtitle,
    subtitle,
    handleOnSelect,
    setList,
    list,
  } = useModal();

  return (
    <Provider
      value={{
        isModalOpened,
        modalContent,
        handleModal,
        isLoadingList,
        setIsLoadingList,
        curPage,
        setCurPage,
        morailsCursor,
        setMorailsCursor,
        setTotal,
        total,
        setTitle,
        title,
        setSubtitle,
        subtitle,
        handleOnSelect,
        setList,
        list,
      }}
    >
      <ModalWrapper />
      {children}
    </Provider>
  );
};

export { ModalContext, ModalProvider };

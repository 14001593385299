import React, { useState, useEffect, useCallback, useContext } from 'react';
import { NETWORK_ID } from 'src/constants/enums';
import { requestNFTToChainList } from 'src/apis/api';
import {
  ToChainConnectView,
  ModalWalletList,
  ModalServiceUnavailable,
} from 'src/components/index';
import stepStore from 'src/stores/StepStore';
import bridgePageStore from 'src/stores/BridgePageStore';
import { ModalContext } from 'src/contexts/ModalContext';
import { ModalContext as ModalShadowContext } from 'src/contexts/ModalShadowContext';
import { bridgePageAction } from 'src/actions/bridgePageAction';
import { WALLET_LIST } from 'src/constants/index';
import { errCodeParser } from 'src/utils/utils';
import { ChainConfig } from 'src/constants/types';

const ToChainConnectViewContainer: React.FC = () => {
  const store = bridgePageStore;
  const config = store.getBridgeConfig();
  const fromChain = config.fromChain as ChainConfig;
  const toChain = config.toChain as ChainConfig;
  const fromChainNo = fromChain.chainNo;
  const toChainNo = toChain.chainNo;
  const toChainName = toChain.chainName;
  const toWalletAddr = config.toWalletAddr;

  const stepNumber = stepStore.getStepNumber();
  const modal = useContext(ModalContext);
  const modalShadow = useContext(ModalShadowContext);
  const [toChainList, setToChainList] = useState<any[]>([]);
  const [walletAddr, setWalletAddr] = useState<string>(toWalletAddr);
  const [chainNo, setChainNo] = useState<number>(toChainNo);
  const [chainName, setChainName] = useState<string>(toChainName);

  const isActiveToChain = stepNumber >= 2;
  const isActiveToChainWallet = isActiveToChain && !!toChainNo;

  const _onChange = useCallback(() => {
    const _config = store.getBridgeConfig();
    const toChain = _config.toChain as ChainConfig;
    const toChainNo = toChain.chainNo;
    const toChainName = toChain.chainName;
    const toWalletAddr = _config.toWalletAddr;
    setWalletAddr(toWalletAddr);
    setChainNo(toChainNo);
    setChainName(toChainName);
  }, [store]);

  useEffect(() => {
    store.addChangeListener(_onChange);
    return () => store.removeChangeListener(_onChange);
  }, [_onChange, store]);

  const getToChainList = useCallback(async () => {
    try {
      const rsp = await requestNFTToChainList(fromChainNo);
      setToChainList(rsp);
    } catch (e: any) {
      const { retCode } = errCodeParser(e);
      const _parsedCode = parseInt(retCode);
      if (_parsedCode === 1) {
        modalShadow.handleModal(
          <ModalServiceUnavailable close={() => modalShadow.handleModal()} />
        );
        return;
      }
      throw e;
    }
  }, [fromChainNo, modalShadow]);

  useEffect(() => {
    if (stepNumber >= 2) {
      getToChainList();
    }
  }, [stepNumber, getToChainList]);

  const toChainModalHandler = useCallback(() => {
    const _chainId = toChainNo as number;
    const chainName = NETWORK_ID[_chainId];
    const toLower = chainName.toLowerCase();
    const walletList = WALLET_LIST[toLower];
    const chainListIdx = toChainList.findIndex((el) => el.chainNo === chainNo);
    const chainInfo = toChainList[chainListIdx];

    modal.setTitle('Connect Wallet');
    modal.handleModal(
      <ModalWalletList
        chainInfo={chainInfo}
        list={walletList}
        withInput={true}
        saveBtn={saveToChainWalletAddr}
      />
    );
  }, [toChainNo, modal, chainNo, toChainList]);

  const saveToChainWalletAddr = (walletAddr: string) => {
    bridgePageAction._changeToWallet(walletAddr);
  };

  const toChainIdHandler = useCallback(
    (chainId: number | string) => {
      const chainListIdx = toChainList.findIndex(
        (el) => el.chainNo === chainId
      );
      bridgePageAction._changeToChain(toChainList[chainListIdx]);
      bridgePageAction._removeToWallet();
    },
    [toChainList]
  );

  const resetToChainView = () => {
    bridgePageAction._removeToWallet();
  };

  return (
    <ToChainConnectView
      chainList={toChainList}
      curStepIdx={stepNumber}
      modalHandler={toChainModalHandler}
      isActiveToChain={isActiveToChain}
      isActiveToChainWallet={isActiveToChainWallet}
      chainNo={chainNo}
      chainName={chainName}
      toChainWalletAddr={walletAddr}
      toChainIdHandler={toChainIdHandler}
      reset={resetToChainView}
      handleOnList={getToChainList}
    />
  );
};

export { ToChainConnectViewContainer };

import * as borsh from 'borsh';
import { Assignable } from './assignable';
import { BRIDGE_TYPE, packMessage } from '../message';
import { Buffer } from 'buffer';
import { deserialize, field, fixedArray, serialize } from '@dao-xyz/borsh';

export enum FtInstruction {
  Initialize,
  SetAdmin,
  SetValidator,
  SetTreasury,
  SetLookupTable,
  SetActive,
  RegisterWrappedCollection,
  TransferNativeFT,
  TransferWrappedFT,
  ReceiveNativeFT,
  ReceiveWrappedFT,
}

export enum NftInstruction {
  Initialize,
  SetAdmin,
  SetValidator,
  SetTreasury,
  SetLookupTable,
  SetActive,
  RegisterWrappedCollection,
  TransferNativeNFT,
  TransferWrappedNFT,
  ReceiveNativeNFT,
  ReceiveWrappedNFT,
  TransferNativeNFTBatch,
  TransferWrappedNFTBatch,
  ReceiveNativeNFTBatch,
  ReceiveWrappedNFTBatch,
}

export class TransferNFTArgs extends Assignable {
  @field({ type: 'u8' })
  instruction: number;
  @field({ type: 'string' })
  messageId: string;
  @field({ type: 'u64' })
  expires: bigint;
  @field({ type: 'u16' })
  fromChain: number;
  @field({ type: 'string' })
  fromToken: string;
  @field({ type: 'u16' })
  toChain: number;
  @field({ type: 'u64' })
  commission: bigint;
  @field({ type: 'u64' })
  targetChainFee: bigint;
  @field({ type: 'string' })
  recipient: string;
  @field({ type: fixedArray('u8', 64) })
  signature: Uint8Array;
  toBuffer() {
    return serialize(this);
  }

  getMessage(): number[] {
    return packMessage(
      BRIDGE_TYPE.NFT_TRANSFER,
      this['messageId'],
      this['expires'],
      this['fromChain'],
      this['fromToken'],
      this['toChain'],
      this['commission'],
      this['targetChainFee'],
      this['recipient']
    );
  }
}

const TransferNFTArgsSchema = new Map([
  [
    TransferNFTArgs,
    {
      kind: 'struct',
      fields: [
        ['instruction', 'u8'],
        ['messageId', 'string'],
        ['expires', 'u64'],
        ['fromChain', 'u16'],
        ['fromToken', 'string'],
        ['toChain', 'u16'],
        ['commission', 'u64'],
        ['targetChainFee', 'u64'],
        ['recipient', 'string'],
        ['signature', ['u8', 64]],
      ],
    },
  ],
]);

export class TransferNFTBatchArgs extends Assignable {
  @field({ type: 'u8' })
  instruction: number;
  @field({ type: 'string' })
  messageId: string;
  @field({ type: 'u64' })
  expires: bigint;
  @field({ type: 'u16' })
  fromChain: number;
  @field({ type: 'string' })
  fromToken: string;
  @field({ type: 'u16' })
  toChain: number;
  @field({ type: 'u64' })
  amount: bigint;
  @field({ type: 'u64' })
  commission: bigint;
  @field({ type: 'u64' })
  targetChainFee: bigint;
  @field({ type: 'string' })
  recipient: string;
  @field({ type: fixedArray('u8', 64) })
  signature: Uint8Array;
  toBuffer() {
    return serialize(this);
  }

  getMessage(): number[] {
    return packMessage(
      BRIDGE_TYPE.NFT_TRANSFER_BATCH,
      this['messageId'],
      this['expires'],
      this['fromChain'],
      this['fromToken'],
      this['toChain'],
      this['amount'],
      this['commission'],
      this['targetChainFee'],
      this['recipient']
    );
  }
}

const TransferNFTBatchArgsSchema = new Map([
  [
    TransferNFTBatchArgs,
    {
      kind: 'struct',
      fields: [
        ['instruction', 'u8'],
        ['messageId', 'string'],
        ['expires', 'u64'],
        ['fromChain', 'u16'],
        ['fromToken', 'string'],
        ['toChain', 'u16'],
        ['amount', 'u64'],
        ['commission', 'u64'],
        ['targetChainFee', 'u64'],
        ['recipient', 'string'],
        ['signature', ['u8', 64]],
      ],
    },
  ],
]);

export class ReceiveNFTArgs extends Assignable {
  toBuffer() {
    return Buffer.from(borsh.serialize(ReceiveNativeArgsSchema, this));
  }

  getMessage(): number[] {
    return packMessage(
      BRIDGE_TYPE.NFT_RECEIVE,
      this['messageId'],
      this['expires'],
      this['originChain'],
      this['originToken'],
      this['fromChain'],
      this['fromToken'],
      this['toChain'],
      this['sender'],
      this['tokenId'],
      this['recipient']
    ) as number[];
  }
}

const ReceiveNativeArgsSchema = new Map([
  [
    ReceiveNFTArgs,
    {
      kind: 'struct',
      fields: [
        ['instruction', 'u8'],
        ['messageId', 'string'],
        ['expires', 'u64'],
        ['originChain', 'u16'],
        ['originToken', 'string'],
        ['fromChain', 'u16'],
        ['fromToken', 'string'],
        ['toChain', 'u16'],
        ['tokenId', 'string'],
        ['sender', 'string'],
        ['recipient', 'string'],
        ['metadataUri', 'string'],
        ['signature', ['u8', 64]],
      ],
    },
  ],
]);

export class ReceiveNFTBatchArgs extends Assignable {
  toBuffer() {
    return Buffer.from(borsh.serialize(ReceiveNativeBatchArgsSchema, this));
  }

  getMessage(): number[] {
    return packMessage(
      BRIDGE_TYPE.NFT_RECEIVE_BATCH,
      this['messageId'],
      this['expires'],
      this['originChain'],
      this['originToken'],
      this['fromChain'],
      this['fromToken'],
      this['toChain'],
      this['sender'],
      this['tokenId'],
      this['amount'],
      this['recipient']
    ) as number[];
  }
}

const ReceiveNativeBatchArgsSchema = new Map([
  [
    ReceiveNFTBatchArgs,
    {
      kind: 'struct',
      fields: [
        ['instruction', 'u8'],
        ['messageId', 'string'],
        ['expires', 'u64'],
        ['originChain', 'u16'],
        ['originToken', 'string'],
        ['fromChain', 'u16'],
        ['fromToken', 'string'],
        ['toChain', 'u16'],
        ['tokenId', 'string'],
        ['amount', 'u64'],
        ['sender', 'string'],
        ['recipient', 'string'],
        ['metadataUri', 'string'],
        ['signature', ['u8', 64]],
      ],
    },
  ],
]);

export class RegisterWrappedCollectionArgs extends Assignable {
  toBuffer() {
    return Buffer.from(
      borsh.serialize(RegisterWrappedCollectionArgsSchema, this)
    );
  }
}

const RegisterWrappedCollectionArgsSchema = new Map([
  [
    RegisterWrappedCollectionArgs,
    {
      kind: 'struct',
      fields: [
        ['instruction', 'u8'],
        ['originChain', 'u16'],
        ['originToken', 'string'],
        ['type', 'u8'],
      ],
    },
  ],
]);

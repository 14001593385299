import React, { useRef, RefObject, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { ImageLoader, LoadingModal } from 'src/components/index';
import { TransferDataType } from 'src/constants/types';
import {
  NETWORK_LOGO,
  SCAN_URL,
  PATH,
  TOKEN_LOGO,
  SCAN_ADDR_URL,
} from 'src/constants/index';
import {
  classNameBinder,
  commify,
  copyToClipboard,
  localeTimeSetter,
  noExponential,
} from 'src/utils/utils';
import { theme } from 'src/styles/theme';
import img_token_default from 'src/assets/tokens/img_token_default.png';
import img_nft_default from 'src/assets/tokens/img_NFT_default.png';
import ic_back from 'src/assets/ic_back.svg';
// import ic_exclude from "src/assets/ic_exclude.svg";
import img_complete from 'src/assets/img_complete.svg';
import img_failed from 'src/assets/img_failed.svg';
import img_pending from 'src/assets/img_pending.gif';
import ani_pending from 'src/assets/ani_pending.gif';
import logo_default from 'src/assets/chain/chain_default.png';
import img_copy_btn from 'src/assets/copy_s.png';
import { NETWORK_ID } from 'src/constants/enums';

interface IProps {
  isLoading: boolean;
  fromChainInfo: any;
  toChainInfo: any;
  tokenMetadata: any;
  transferData: TransferDataType;
}

const ExplorerCompleteView: React.FC<IProps> = ({
  isLoading,
  fromChainInfo,
  toChainInfo,
  transferData,
  tokenMetadata,
}) => {
  const {
    tokenType, // 1 : FT 2 : NFT
    fromChainNo,
    toTxDate,
    fromTxDate,
    nftTokenIdString,
    fromTxHash,
    senderAddress,
    toChainNo,
    toTxHash,
    recipientAddress,
    transferStatus,
    commission,
    // fromChainTxFee,  // from 체인에서 실제로 발생한 TX Fee
    claimToChainTxFee,
    amount,
    tokenName,
    fromTokenIdNo,
    tokenSymbol,
    deployFee,
  } = transferData;
  const [isSenderShow, setIsSenderShow] = useState<boolean>(false);
  const [isRecipientShow, setIsRecipientShow] = useState<boolean>(false);
  const { image, name, symbol } = tokenMetadata;

  const navigate = useNavigate();
  const divRef = useRef<HTMLDivElement>();

  const isNFT = tokenType === '2';

  const fromTx = fromTxHash;
  const toTx = toTxHash;

  const _status = !!transferStatus ? transferStatus : 0;

  const _fromChainLogo = !!NETWORK_LOGO[fromChainNo]
    ? NETWORK_LOGO[fromChainNo]
    : logo_default;
  const _toChainLogo = !!NETWORK_LOGO[toChainNo]
    ? NETWORK_LOGO[toChainNo]
    : logo_default;

  const _tokenName = isNFT ? name : tokenName;
  const _tokenSymbol = isNFT ? symbol : tokenSymbol;
  const _tokenIdNo = !!fromTokenIdNo ? fromTokenIdNo : 0;
  const unit = isNFT ? fromChainInfo.chainSymbol : tokenSymbol;
  const _fromTokenImg = TOKEN_LOGO[_tokenIdNo];

  // const _fromChainTxFee = !!fromChainTxFee ? fromChainTxFee : 0;
  const _commission = !!commission ? commission : 0;
  const _txFee = !!claimToChainTxFee ? claimToChainTxFee : 0;
  const _deployFee = !!deployFee ? deployFee : 0;
  const _amount = !!amount ? amount : 0;
  const _calculatedTxFee = !!deployFee
    ? parseFloat(
        (
          (_txFee * Math.pow(10, 7) - deployFee * Math.pow(10, 7)) *
          Math.pow(10, -7)
        ).toFixed(7)
      )
    : _txFee;

  const token_total = parseFloat(
    (
      (_amount * Math.pow(10, 7) -
        (_commission * Math.pow(10, 7) + _txFee * Math.pow(10, 7))) *
      Math.pow(10, -1 * 7)
    ).toFixed(7)
  );
  const nft_total = parseFloat(
    (
      (_commission * Math.pow(10, 7) + _txFee * Math.pow(10, 7)) *
      Math.pow(10, -1 * 7)
    ).toFixed(7)
  );

  const noCommission = _commission === 0;
  const noTxFee = _txFee === 0;
  const isDev = process.env.REACT_APP_IS_DEV === 'dev';
  const fromAddUrl =
    isDev && fromChainNo === NETWORK_ID.APTOS
      ? '?network=testnet'
      : isDev && fromChainNo === NETWORK_ID.SOLANA
      ? '?cluster=devnet'
      : '';
  const toAddUrl =
    isDev && toChainNo === NETWORK_ID.APTOS
      ? '?network=testnet'
      : isDev && toChainNo === NETWORK_ID.SOLANA
      ? '?cluster=devnet'
      : '';

  const className = (status: any) => {
    if (status === 0) {
      return classNameBinder({
        prefix: 'view-title',
        suffix: 'failure',
        cond: true,
      });
    } else if (status === 1 || status === 2 || status === 3) {
      return classNameBinder({
        prefix: 'view-title',
        suffix: 'pending',
        cond: true,
      });
    } else if (status === 4) {
      return classNameBinder({
        prefix: 'view-title',
        suffix: 'success',
        cond: true,
      });
    } else if (status >= 10) {
      return classNameBinder({
        prefix: 'view-title',
        suffix: 'failure',
        cond: true,
      });
    } else {
      return classNameBinder({
        prefix: 'view-title',
        suffix: 'pending',
        cond: true,
      });
    }
  };

  if (isLoading) {
    return (
      <StyledEmpty>
        <LoadingModal type='light' />
      </StyledEmpty>
    );
  }

  return (
    <StyledView className={'dark'} ref={divRef as RefObject<HTMLDivElement>}>
      <div className='header-back'>
        <div
          className='btn-area'
          onClick={(e) => {
            // e.stopPropagation();
            // e.preventDefault();
            const isFromTxResult = window.history.state.usr?.fromTxResult;
            if (isFromTxResult) return navigate(PATH.EXPLORER);
            navigate(-1);
          }}
        >
          <img src={ic_back} alt='back' />
          <span>Back</span>
        </div>
      </div>
      <div className='header-title'>
        <span className='title'>Transaction Details</span>
        <span className='flags'>{isNFT ? 'NFTs' : 'Tokens'}</span>
      </div>
      <div className={className(_status)}>
        {(_status === 0 || _status >= 10) && (
          <>
            <img src={img_failed} alt='failure' />
            <span>Transaction failed</span>
          </>
        )}
        {(_status === 1 || _status === 2 || _status === 3) && (
          <>
            <img src={img_pending} alt='complete' />
            <span>Transaction requested</span>
          </>
        )}
        {_status === 4 && (
          <>
            <img src={img_complete} alt='complete' />
            <span>Transaction completed</span>
          </>
        )}
      </div>
      <div className='transfer-info'>
        <div className='transfer-header'>
          {isNFT ? <span>NFT</span> : <span>Token</span>}
        </div>
        {isNFT ? (
          <div className='item-info'>
            <div className='img-box'>
              <ImageLoader url={image} img={img_nft_default} outline={true} />
            </div>
            <span className='txt-tokenName'>{_tokenName}</span>
            <div className='sh-divider'>|</div>
            <span className='txt-tokenId'>#{nftTokenIdString}</span>
            {amount != null && (
              <div className='nft-count'>
                {amount <= 99999 ? (
                  <div className='count-text'>x&nbsp;</div>
                ) : null}
                {amount}
              </div>
            )}
          </div>
        ) : (
          <div className='item-info'>
            <div className='img-box'>
              <ImageLoader
                url={_fromTokenImg}
                isNFT={false}
                img={img_token_default}
                outline={true}
              />
            </div>
            <span>{_tokenName}</span>
            <div className='sh-divider'>|</div>
            <span className='bold txt-ellipsis'>
              {commify(noExponential(_amount))}&nbsp;
            </span>
            <span>{_tokenSymbol}</span>
          </div>
        )}
        <div className='transfer-header'>From</div>
        <div className='transfer-content'>
          <img src={_fromChainLogo} alt='logo' />
          <span>{fromChainInfo.chainName}</span>
          <a
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              window.open(
                SCAN_ADDR_URL[fromChainNo] + senderAddress + fromAddUrl
              );
            }}
            href={SCAN_ADDR_URL[fromChainNo] + senderAddress + fromAddUrl}
          >
            {senderAddress}
          </a>
          <div className='copy-area'>
            {isSenderShow && <span className='copied-text'>Copied</span>}
            <div
              className='copy-btn'
              onClick={(e: any) => {
                const texted = document.querySelector('.copy-btn');
                texted?.classList.add('fade-in');
                setIsSenderShow(true);
                copyToClipboard(senderAddress);
                setTimeout(() => {
                  const text = document.querySelector('.copied-text');
                  text?.classList.add('fade-out');
                  texted?.classList.remove('fade-in');
                  setTimeout(() => {
                    setIsSenderShow(false);
                  }, 200);
                }, 800);
              }}
            >
              <img className='copy-img' src={img_copy_btn} alt='img'></img>
              <span className='copy-text'>Copy</span>
            </div>
          </div>
        </div>

        <div className='transfer-header'>To</div>
        <div className='transfer-content'>
          <img src={_toChainLogo} alt='logo' />
          <span>{toChainInfo.chainName}</span>
          <a
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              window.open(
                SCAN_ADDR_URL[toChainNo] + recipientAddress + toAddUrl
              );
            }}
            href={SCAN_ADDR_URL[toChainNo] + recipientAddress + toAddUrl}
          >
            {recipientAddress}
          </a>
          <div className='copy-area'>
            {isRecipientShow && <span className='copied-text'>Copied</span>}
            <div
              className='copy-btn'
              onClick={(e: any) => {
                setIsRecipientShow(true);
                copyToClipboard(recipientAddress);
                setTimeout(() => {
                  const text = document.querySelector('.copied-text');
                  text?.classList.add('fade-out');
                  setTimeout(() => {
                    setIsRecipientShow(false);
                  }, 200);
                }, 800);
              }}
            >
              <img className='copy-img' src={img_copy_btn} alt='img'></img>
              <span className='copy-text'>Copy</span>
            </div>
          </div>
        </div>
      </div>
      <div className='divider-g' />
      <div className='transaction-info'>
        <div className='tx-title'>Source chain</div>
        <div className='tx-content'>
          <div className='tx-details'>
            <div className='tx-header'>
              <span>TX</span>
            </div>
            <div className='tx-link'>
              <a
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  if (!!fromTx) {
                    window.open(SCAN_URL[fromChainNo] + fromTx + fromAddUrl);
                  }
                }}
                href={SCAN_URL[fromChainNo] + fromTx + fromAddUrl}
              >
                View TX
              </a>
            </div>
            <div className='tx-header'>
              <span>Status</span>
            </div>
            <div className='tx-data'>
              {_status === 0 && <span className='txt-r'>Fail</span>}
              {_status === 1 && (
                <>
                  <span className='txt-g'>Pending</span>
                  <img src={ani_pending} alt='dots' />
                </>
              )}
              {_status >= 2 && <span>Success</span>}
            </div>
            {!!fromTxDate && (
              <>
                <div className='tx-header'>
                  <span>Date</span>
                </div>
                <div className='tx-date'>
                  {
                    localeTimeSetter(
                      new Date(fromTxDate * 1000).toISOString()
                    ).split('T')[0]
                  }{' '}
                  {localeTimeSetter(new Date(fromTxDate * 1000).toISOString())
                    .split('T')[1]
                    .substring(0, 8)}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className='divider-g' />
      <div className='transaction-info'>
        <div className='tx-title'>Destination chain</div>
        <div className='tx-content'>
          <div className='tx-details'>
            <div className='tx-header'>
              <span>TX</span>
            </div>
            <div className='tx-link'>
              <a
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  if (!!toTx) {
                    window.open(SCAN_URL[toChainNo] + toTx + toAddUrl);
                  }
                }}
                href={SCAN_URL[toChainNo] + toTx + toAddUrl}
              >
                View TX
              </a>
            </div>
            <div className='tx-header'>
              <span>Status</span>
            </div>
            <div className='tx-data'>
              {(_status >= 10 || _status === 0) && (
                <span className='txt-r'>Fail</span>
              )}
              {(_status === 1 || _status === 2 || _status === 3) && (
                <>
                  <span className='txt-g'>Pending</span>
                  <img src={ani_pending} alt='dots' />
                </>
              )}
              {_status === 4 && <span>Success</span>}
            </div>
            {!!toTxDate && (
              <>
                <div className='tx-header'>
                  <span>Date</span>
                </div>
                <div className='tx-date'>
                  {
                    localeTimeSetter(
                      new Date(toTxDate * 1000).toISOString()
                    ).split('T')[0]
                  }{' '}
                  {localeTimeSetter(new Date(toTxDate * 1000).toISOString())
                    .split('T')[1]
                    .substring(0, 8)}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className='divider-g' />
      <div className='fee-info'>
        {isNFT ? (
          <>
            <div className='fee-title'>Fee</div>
            <div className='fee-total'>
              <div>Total fee</div>
              <div className='info'>
                <span className='amount'>
                  {commify(noExponential(nft_total))}
                </span>
                <span className='symbol'>{unit}</span>
              </div>
            </div>
            <div className='fee-content'>
              <div className='fee-details'>
                <div className='fee-type'>
                  <span>Destination chain TX fee</span>
                </div>
                <div className='fee-amount'>
                  <span>{commify(noExponential(_calculatedTxFee))}</span>
                </div>
                <div className='fee-unit'>
                  <span className='symbol'>{unit}</span>
                </div>
                {!!_deployFee && (
                  <>
                    <div className='fee-type'>
                      <span>Deploy NFT fee</span>
                    </div>
                    <div className='fee-amount'>
                      <span>{commify(noExponential(_deployFee))}</span>
                    </div>
                    <div className='fee-unit'>
                      <span className='symbol'>{unit}</span>
                    </div>
                  </>
                )}
                <div className='fee-type' style={{ marginBottom: '0px' }}>
                  <span>Bridge fee</span>
                </div>
                <div className='fee-amount'>
                  <span>{commify(noExponential(_commission))}</span>
                </div>
                <div className='fee-unit'>
                  <span className='symbol'>{unit}</span>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className='fee-title'>Amount{'&'}Fee</div>
            <div className='fee-total'>
              <div>Receivable Amount</div>
              <div className='info'>
                <span className='amount'>
                  {commify(noExponential(token_total))}
                </span>
                <span className='symbol'>{unit}</span>
              </div>
            </div>
            <div className='fee-content'>
              <div className='fee-details'>
                <div className='fee-type'>
                  <span>Transfer Amount</span>
                </div>
                <div className='fee-amount'>
                  <span>{commify(noExponential(_amount))}</span>
                </div>
                <div className='fee-unit'>
                  <span className='symbol'>{unit}</span>
                </div>
                <div className='fee-type'>
                  <span>Destination chain TX fee</span>
                </div>
                <div className='fee-amount'>
                  <span>
                    {!noTxFee && '(-)'} {commify(noExponential(_txFee))}
                  </span>
                </div>
                <div className='fee-unit'>
                  <span className='symbol'>{unit}</span>
                </div>
                <div className='fee-type' style={{ marginBottom: '0px' }}>
                  <span>Bridge fee</span>
                </div>
                <div className='fee-amount'>
                  <span>
                    {!noCommission && '(-)'}{' '}
                    {commify(noExponential(_commission))}
                  </span>
                </div>
                <div className='fee-unit'>
                  <span className='symbol'>{unit}</span>
                </div>
              </div>
            </div>
          </>
        )}
        <div className='divider-g' />
      </div>
    </StyledView>
  );
};

const StyledView = styled.div`
  color: ${theme.mono4};
  & > div.header-back {
    padding-top: 26px;
    padding-bottom: 40px;
    & > div.btn-area {
      display: flex;
      align-items: center;
      width: 80px;
      & > img {
        width: 20px;
        height: 20px;
        cursor: pointer;
        vertical-align: middle;
      }
      & > span {
        ${theme.body1};
        color: ${theme.mono6};
        margin-right: 8px;
        cursor: pointer;
        vertical-align: middle;
      }
    }
    /* & > span */
  }
  & > div.header-title {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    & > span.title {
      ${theme.headline2};
      color: ${theme.mono8};
      font-weight: 900;
      margin-right: 10px;
    }
    & > span.flags {
      ${theme.tab1};
      color: ${theme.mono4};
      font-weight: bold;
      background-color: ${theme.mono2};
      border-radius: 6px;
      padding: 5px 7px;
    }
  }
  & > div.view-title {
    ${theme.title1};
    padding-bottom: 16px;
    text-align: left;
    border-top: 2px solid ${theme.mono6};
    color: ${theme.mono8};
    padding: 25px 20px;
    & > img {
      width: 26px;
      height: 26px;
      vertical-align: middle;
      margin-right: 8px;
    }
    & > span {
      vertical-align: middle;
    }
    &.pending {
      background-color: rgba(131, 137, 140, 0.1);
    }
    &.success {
      background-color: rgba(22, 217, 146, 0.1);
    }
    &.failure {
      background-color: rgba(255, 89, 89, 0.1);
    }
  }

  & > div.transfer-info {
    ${theme.body1};
    display: grid;
    grid-template-columns: 210px 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    margin: 4px 0px;
    & > div.transfer-header {
      display: flex;
      align-items: center;
      height: 50px;
      color: ${theme.mono4};
    }
    & > div.transfer-content {
      display: flex;
      align-items: center;
      color: ${theme.mono8};
      & > span {
        vertical-align: middle;
        margin-right: 10px;
      }
      & > img {
        width: 30px;
        height: 30px;
        border-radius: 6px;
        margin-right: 10px;
        vertical-align: middle;
      }
      & > a {
        width: auto;
        height: 34px;
        padding: 7px 12px;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        color: ${theme.mono6};
        background-color: ${theme.mono1};
        border: 1px solid ${theme.mono2};
        text-align: center;
        color: ${theme.mono6};
        text-decoration: none;
        &:hover {
          border: 1px solid ${theme.primaryBG};
          color: ${theme.primaryTxt};
        }
      }
      & > div.copy-area {
        & > span.copied-text {
          cursor: pointer;
          position: absolute;
          display: flex;
          width: 69px;
          height: 34px;
          padding-left: 15px;
          align-items: center;
          ${theme.body2}
          color: ${theme.mono1};
          background-color: ${theme.primaryTxt};
          transition: opacity 200ms;
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
        }
        & > span.fade-out {
          opacity: 0;
        }
        & > div.copy-btn {
          display: flex;
          align-items: center;
          width: 69px;
          height: 34px;
          padding-left: 7px;
          border: 1px solid ${theme.mono2};
          border-left: 0px;
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
          cursor: pointer;
          &:hover {
            background-color: rgba(22, 217, 146, 0.1);
          }
          & > .copy-img {
            width: 20px;
            height: 20px;
          }
          & > .copy-text {
            width: 28px;
            height: 18px;
            ${theme.body2}
          }
        }
      }
    }
    & > div.item-info {
      ${theme.body1};
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: ${theme.mono8};
      & > div.img-box {
        width: 30px;
        height: 30px;
        margin-right: 14px;
      }
      & > div.sh-divider {
        width: 4px;
        height: 20px;
        color: ${theme.mono4};
        margin: 0px 10px;
      }
      & > span.txt-tokenName {
        max-width: 338px;
        margin-right: 4px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      & > span.txt-tokenId {
        max-width: 338px;
        margin-right: 4px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      & > div.nft-count {
        ${theme.tokenAmountEx}
        padding: 0px 4px;
        display: inline-flex;
        justify-content: center;
        height: 18px;
        margin-left: 10px;
        min-width: 20px;
        width: auto;
        background-color: ${theme.primaryTxt};
        border-radius: 6px;
        color: ${theme.mono1};
      }
    }
  }
  & > div.transaction-info {
    ${theme.body1};
    margin-top: 56px;
    & > div.tx-title {
      ${theme.tab1};
      color: ${theme.mono8};
      padding-bottom: 4px;
      border-bottom: 2px solid ${theme.mono8};
    }
    & > div.tx-header {
      color: ${theme.mono4};
      margin-bottom: 10px;
      & > span:last-of-type {
        ${theme.body2}
        color : ${theme.primaryTxt};
        font-weight: 900;
        text-decoration: underline;
        float: right;
        cursor: pointer;
      }
    }
    & > div.tx-content {
      ${theme.body1};
      color: ${theme.mono4};
      padding-top: 4px;
      padding-bottom: 4px;
      & > div.tx-details {
        display: grid;
        grid-template-columns: 210px 1fr;
        grid-template-rows: auto;
        margin-bottom: 10px;
        &:last-of-type {
          margin-bottom: 0px;
        }

        & > div {
          height: 40px;
          display: flex;
          align-items: center;
        }
        & > div.tx-link {
          ${theme.body2};
          text-decoration: underline;
          font-weight: 900;
          & > a {
            color: ${theme.mono8};
          }
          & > a:active {
            color: ${theme.mono8};
          }
        }
        & > div.tx-data {
          color: ${theme.mono8};
          & > img {
            width: 24px;
            height: 20px;
            margin-left: 8px;
          }
          & > span:last-of-type {
            &.txt-r {
              font-weight: 900;
              color: ${theme.error};
            }
            &.txt-g {
              color: ${theme.mono4};
            }
          }
        }
        & > div.tx-date {
          color: ${theme.mono8};
        }
      }
    }
  }
  & > div.fee-info {
    ${theme.body1};
    margin-top: 56px;
    & > div.fee-title {
      ${theme.tab1};
      color: ${theme.mono8};
      padding-bottom: 4px;
      border-bottom: 2px solid ${theme.mono8};
    }
    & > div.fee-total {
      ${theme.tab1};
      color: ${theme.mono5};
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: rgba(131, 137, 140, 0.1);
      padding: 15px 20px;
      & > div.info {
        display: flex;
        align-items: center;
        color: ${theme.primaryTxt};
        & > span.amount {
          ${theme.title1};
        }
        & > span.symbol {
          margin-left: 6px;
        }
      }
    }
    & > div.fee-content {
      display: flex;
      flex-direction: column;
      padding: 20px;
      padding-top: 14px;
      padding-bottom: 16px;
      & > div.fee-details {
        display: grid;
        grid-template-columns: 210px 1fr auto;
        grid-template-rows: auto;
        padding-left: 10px;
        border-left: 3px solid ${theme.mono2};
        & > div {
          display: flex;
        }
        & > div.fee-type {
          ${theme.body1};
          color: ${theme.mono4};
          margin-bottom: 20px;
        }
        & > div.fee-amount {
          color: ${theme.mono6};
          font-weight: 900;
          justify-content: flex-end;
        }
        & > div.fee-unit {
          color: ${theme.mono6};
          margin-left: 6px;
          justify-content: flex-start;
        }
      }
    }
  }
`;

const StyledEmpty = styled.div`
  width: 100%;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export { ExplorerCompleteView };

import styled from 'styled-components';
import { theme } from 'src/styles/theme';
import bg_summary_bg from 'src/assets/background_summary_bg.png';
import bg_complete_to from 'src/assets/background_complete_to.png';
import bg_complete_header_to from 'src/assets/background_complete_header_to.png';
import img_bg_252 from 'src/assets/img_bg_252.png';
import img_bg_332 from 'src/assets/img_bg_332.png';

import bg_to from 'src/assets/background_to.svg';
import ic_arrow_down from 'src/assets/arr_bottom.svg';

const StyledWrapper = styled.div<{ step: number }>`
  width: 628px;
  height: 100%;
  padding: 32px 32px 42px 32px;
  margin: 0 auto;
  &.summary {
    padding: 24px 24px 40px;
    background: url(${bg_summary_bg}) center no-repeat;
    background-size: 624px 100%;
  }
  &.complete {
    padding: 32px 32px;
    background: url(${bg_complete_header_to}) center top 0px no-repeat,
      url(${bg_complete_to}) center center no-repeat;
    background-size: 628px 162px, 628px 100%;
  }
  &.from {
    ${(props) =>
      props.step <= 2 &&
      `
      background : url(${img_bg_252}) center center no-repeat;
      background-size: 100% 252px;
    `}
    ${(props) =>
      (props.step === 3 || props.step === 4) &&
      `
    height : 332px;
      background : url(${img_bg_332}) center center no-repeat;
      background-size: 100% 332px;
    `}
  }
  &.to {
    height: 152px;
    background: url(${bg_to}) center center no-repeat;
    background-size: 628px 152px;
    padding: 32px 32px 32px 32px;
  }
  .divider {
    border-bottom: 1px solid ${theme.mono6};
  }
  .mgb-16 {
    margin-bottom: 16px;
  }
  .mgb-22 {
    margin-bottom: 22px;
  }
  .mgr-16 {
    margin-right: 16px;
  }
  .w-388 {
    width: 388px;
    height: 100%;
  }
  .w-160 {
    width: 160px;
    height: 100%;
  }
`;

const StyledTransferWrapper = styled.div`
  width: 628px;
  border-radius: 6px;
  background-color: ${theme.mono8};
  margin: 12px auto 0px;
  padding: 20px 24px;
`;

const StyledBtnWrapper = styled.div<{ width: number; marginTop?: number }>`
  width: ${(props) => props.width}px;
  height: 48px;
  margin: 0px auto;
  margin-top: ${(props) => (!!props.marginTop ? props.marginTop : 24)}px;
`;

const StyledArrow = styled.div`
  width: 628px;
  margin: 0px auto;
  position: relative;
  padding: 5px;
  & > div {
    position: absolute;
    z-index: 1001;
    left: 315px;
    top: -18.5px;
    width: 46px;
    height: 46px;
    transform: translate(-50%);
    background: url(${ic_arrow_down}) center center no-repeat;
    background-size: 30px 30px;
  }
`;

export { StyledWrapper, StyledTransferWrapper, StyledBtnWrapper, StyledArrow };

import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { getAddressFromAptos, connectPetra } from 'src/sc/aptos';
import { Buffer } from 'buffer';
import {
  ChainConfig,
  CreatedNFTMsgType,
  FromNFTConfig,
} from 'src/constants/types';
import {
  encodeTransferNFT,
  aptosSendTransaction,
  encodeReceiveNFT,
  aptosCheckTxConfirm,
  aptosGetTokenStore,
} from 'src/sc/aptos';
import { TxnBuilderTypes, BCS, AptosClient } from 'aptos';

const AptosConnectPage: React.FC = () => {
  const [senderAddress, setSenderAddress] = useState<string>('');
  const [msg, setMsg] = useState<CreatedNFTMsgType>();

  const connect = async () => {
    try {
      await connectPetra();
      const { address } = await getAddressFromAptos();
      setSenderAddress(address);
    } catch (e) {
      console.log(e);
      throw e;
    }
  };

  // Using Morails
  const getNft = async () => {
    const ep = 'https://fullnode.testnet.aptoslabs.com/v1';
    const owner =
      '0xc416e360fff9688dc270116bde4fe5a83404faf5d9944ef12bfc34881de05741';
    const tokenStore = await aptosGetTokenStore(ep, owner);
    if (tokenStore != null) {
      console.log((tokenStore.data as any).direct_transfer);
    } else {
      console.log('tokenStore is null');
    }
  };

  const createMsg = () => {
    const msg: CreatedNFTMsgType = {
      messageId: '98947e9a80a12d02',
      blockHeight: 99028135,
      decimal: 8,
      fromChain: 8,
      fromToken:
        '0x637833316264313334613033396366303030303030303030303030303030303030303030303030303030',
      toChain: 5,
      tokenId: '1201',
      tokenType: 0,
      commission: 1,
      targetChainFee: 1,
      recipient: '0x187969bd5a65ccbbd21224cac89c678a355b8dde',
      signature: '',
      senderAddress: '',
      deployWrapped: {
        deployFee: 0,
      },
      txFee: 0,
    };
    setMsg(msg);
  };

  // Or Opt in
  const approve = useCallback(async () => {
    const params = {
      expires: msg?.blockHeight,
      creator: '',
      collectionName: '',
      tokenName: '',
      ...msg,
    };
    const encodedTx = encodeTransferNFT(params);
    console.log(encodedTx);
    await aptosSendTransaction(msg);
  }, [msg]);

  const transferNFT = () => {};

  const checkTxConfirm = useCallback(async () => {
    const returnValue = await aptosCheckTxConfirm(
      'https://fullnode.testnet.aptoslabs.com/v1',
      '0x3808d1ffb0d5a86810687a97fb228ff2846ab9192f697dc86d972018cd269c21'
    );
    console.log(returnValue);
  }, []);

  const createReceiveMsg = () => {
    const msg: CreatedNFTMsgType = {
      messageId: '98947e9a80a12d02',
      blockHeight: 99028135,
      decimal: 8,
      fromChain: 5,
      fromToken: '0xd0fd4905378a67a541a3d1280efb1d607bf5ba99',
      toChain: 8,
      tokenId: '1115',
      tokenType: 0,
      commission: 0,
      targetChainFee: 1,
      recipient:
        '0xc416e360fff9688dc270116bde4fe5a83404faf5d9944ef12bfc34881de05741',
      signature: '',
      senderAddress: '0x187969bd5a65ccbbd21224cac89c678a355b8dde ',
      deployWrapped: {
        deployFee: 0,
      },
      txFee: 0,
    };
    setMsg(msg);
  };

  const ReceiveNFT = useCallback(async () => {
    const params = {
      expires: msg?.blockHeight,
      creator: '',
      collectionName: '',
      tokenName: '',
      ...msg,
    };
    const encodedTx = encodeReceiveNFT(params);
    console.log(encodedTx);
    await aptosSendTransaction(msg);
  }, [msg]);

  const handleOnTransferNFT = useCallback(async () => {
    // "messageId": "da080fd2cdbca84b",
    // "blockHeight": 98377102,
    // "fromChainNo": 8,
    // "toChainNo": 5,
    // "commission": 0,
    // "txFee": 0,
    // "senderAddress": "0xc416e360fff9688dc270116bde4fe5a83404faf5d9944ef12bfc34881de05741",
    // "recipientAddress": "0x187969bd5a65ccbbd21224cac89c678a355b8dde",
    // "aptosCreatorAddr": "0x0b21c3923e9ea5ed4c96185b60bbf27ad488c77f42b4e6f434c89d41196641b3",
    // "aptosCollectionName": "nft-test-2",
    // "aptosTokenName": "nft-test-2 #2",
    // "aptosTokenPropertyVersion": 0,
    // "sig": "030de2e924e0df7dd90f88711995b0ba89003ae813872921e8451b47c8387956688b394f9e4bfb21cadb639b16f04c16ddee72760c41608610da4209bf1c2006"
    // // call address
    // const txObj = new TxnBuilderTypes.TransactionPayloadEntryFunction(
    //   TxnBuilderTypes.EntryFunction.natural(
    //     `0x41119f9d4fed466647fc451a85a884020248d30a01df26c60bbcb7869caf1d24::mitter_debug`,
    //     "call_address",
    //     [],
    //     [
    //       BCS.bcsToBytes(TxnBuilderTypes.AccountAddress.fromHex("0x0b21c3923e9ea5ed4c96185b60bbf27ad488c77f42b4e6f434c89d41196641b3"))
    //     ]
    //   )
    // )
    // // call u16
    // const txObj = new TxnBuilderTypes.TransactionPayloadEntryFunction(
    //   TxnBuilderTypes.EntryFunction.natural(
    //     `0x41119f9d4fed466647fc451a85a884020248d30a01df26c60bbcb7869caf1d24::mitter_debug`,
    //     "call_u16",
    //     [],
    //     [
    //       BCS.bcsSerializeU16(8)
    //     ]
    //   )
    // )

    // // call u64
    // const txObj = new TxnBuilderTypes.TransactionPayloadEntryFunction(
    //   TxnBuilderTypes.EntryFunction.natural(
    //     `0x41119f9d4fed466647fc451a85a884020248d30a01df26c60bbcb7869caf1d24::mitter_debug`,
    //     "call_u64",
    //     [],
    //     [
    //       BCS.bcsSerializeUint64(98377102)
    //     ]
    //   )
    // )

    // call string
    const txObj = new TxnBuilderTypes.TransactionPayloadEntryFunction(
      TxnBuilderTypes.EntryFunction.natural(
        `0x41119f9d4fed466647fc451a85a884020248d30a01df26c60bbcb7869caf1d24::mitter_debug`,
        'call_string',
        [],
        [BCS.bcsSerializeStr('nft-test-2 #2')]
      )
    );
    console.log(txObj);

    // // call vector
    // const signature = Buffer.from('030de2e924e0df7dd90f88711995b0ba89003ae813872921e8451b47c8387956688b394f9e4bfb21cadb639b16f04c16ddee72760c41608610da4209bf1c2006', 'hex');
    // const txObj = new TxnBuilderTypes.TransactionPayloadEntryFunction(
    //   TxnBuilderTypes.EntryFunction.natural(
    //     `0x41119f9d4fed466647fc451a85a884020248d30a01df26c60bbcb7869caf1d24::mitter_debug`,
    //     "call_vector",
    //     [],
    //     [
    //       BCS.bcsToBytes(new TxnBuilderTypes.Ed25519Signature(signature)),
    //       // BCS.bcsSerializeBytes(signature),
    //     ]
    //   )
    // )
    try {
      const txResult = await aptosSendTransaction(txObj);
      console.log(txResult);
    } catch (e: any) {
      console.log(e);
      // throw new Error();
    }
  }, []);

  return (
    <StyledView>
      <div>
        <button onClick={() => connect()}>Connect</button>
        <span>{senderAddress}</span>
      </div>
      <div>
        <button onClick={() => getNft()}>Get My Token</button>
      </div>
      <div>
        <button onClick={() => createMsg()}>Create Msg</button>
      </div>
      <div>
        <button onClick={() => approve()}>Approve</button>
      </div>
      <div>
        <button onClick={() => transferNFT()}>Transfer NFT</button>
      </div>
      <div>
        <button onClick={() => checkTxConfirm()}>Check TX</button>
      </div>
      <div>
        <button onClick={() => createReceiveMsg()}>Create Msg</button>
      </div>
      <div>
        <button onClick={() => handleOnTransferNFT()}>Test Aptos Send</button>
      </div>
      <div>
        <button onClick={() => checkTxConfirm()}>Check TX</button>
      </div>
    </StyledView>
  );
};

const StyledView = styled.div``;

export { AptosConnectPage };

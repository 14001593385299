import React from 'react';
import styled from 'styled-components';
import { theme } from 'src/styles/theme';

import img_error from 'src/assets/img_toast_error.png';
import img_connect from 'src/assets/img_toast_connect.png';
import img_noti from 'src/assets/img_toast_noti.png';

interface IProps {
  type: 'connect' | 'notification' | 'warning';
  txt: string;
}

const ToastModalContent: React.FC<IProps> = (props) => {
  const { type = 'warning', txt = '' } = props;

  const isWarning = type === 'warning';
  const isNoticification = type === 'notification';
  const idx = txt.indexOf('\n');
  const splitedTxt = txt.split('\n');
  const hasTitle = splitedTxt.length >= 2;
  const title = hasTitle ? splitedTxt[0] : isWarning ? 'Fail to send. ' : '';
  const desc = hasTitle ? txt.substring(idx) : splitedTxt[0];

  if (isWarning) {
    return (
      <StyledWarningView>
        <div />
        {!!title && <span>{title}</span>}
        <span>{desc}</span>
      </StyledWarningView>
    );
  } else if (isNoticification) {
    return (
      <StyledNotificationView>
        <div />
        {!!title && <span>{title}</span>}
        <span>{desc}</span>
      </StyledNotificationView>
    );
  } else {
    return (
      <StyledConnectView>
        <div />
        {!!title && <span>{title}</span>}
        <span>{desc}</span>
      </StyledConnectView>
    );
  }
};

const StyledDefaultView = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: auto;
  height: auto;
  background-color: ${theme.mono7};
  border-radius: 6px;
  padding: 12px 18px 12px 70px;
  width: auto;
  max-width: 398px;
  & > div {
    width: 42px;
    height: 70px;
    position: absolute;
    left: 10px;
    bottom: 8px;
  }
  & > span:first-of-type {
    color: ${theme.error};
    font-weight: 900;
  }
  & > span:last-of-type {
    color: ${theme.mono1};
    font-weight: 400;
  }
`;

const StyledWarningView = styled(StyledDefaultView)`
  & > div {
    background: url(${img_error}) center bottom 0px no-repeat;
    background-size: 42px 49px;
  }
`;

const StyledNotificationView = styled(StyledDefaultView)`
  & > div {
    background: url(${img_noti}) center bottom 0px no-repeat;
    background-size: 42px 49px;
  }
`;

const StyledConnectView = styled(StyledDefaultView)`
  & > div {
    background: url(${img_connect}) center bottom 0px no-repeat;
    background-size: 42px 49px;
  }
`;

export { ToastModalContent };

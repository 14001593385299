import { AddressLookupTableAccount, PublicKey } from '@solana/web3.js';
import { Env } from './env';
import { Pda } from './pda';
import { Base } from './base';
import { SettingsState } from './state';
import { Buffer } from 'buffer';

export class Settings extends Base {
  private readonly pda: Pda;
  constructor(env: Env) {
    super(env);
    this.pda = new Pda(env.program);
  }

  public async getAdmin(): Promise<PublicKey> {
    return new PublicKey((await this.getSettings()).admin);
  }

  async getSettings(): Promise<SettingsState> {
    const settings_pda = this.pda.getSettingsAddress()[0];
    let accountInfo = await this.env.connection.getAccountInfo(settings_pda);
    return SettingsState.fromBuffer(accountInfo!!.data);
  }

  public async getActive(): Promise<boolean> {
    return (await this.getSettings()).active;
  }

  public async getValidator(): Promise<PublicKey> {
    return new PublicKey((await this.getSettings()).validator);
  }

  public async getTreasury(): Promise<PublicKey[]> {
    let settings = await this.getSettings();
    return [
      new PublicKey(settings.targetChainFeeTreasury),
      new PublicKey(settings.commissionTreasury),
    ];
  }

  public async getLookupTable(): Promise<PublicKey> {
    return new PublicKey((await this.getSettings()).lookupTable);
  }

  public async getAddressLookupTable(): Promise<AddressLookupTableAccount> {
    const lookupTableAddr = await this.getLookupTable();
    (window as any).Buffer = Buffer;
    return (
      await this.env.connection.getAddressLookupTable(lookupTableAddr, {
        commitment: 'finalized',
      })
    ).value!!;
  }
}

import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { BridgeCompleteView, Button } from 'src/components/index';
import { bridgePageAction } from 'src/actions/bridgePageAction';
import { stepAction } from 'src/actions/stepAction';
import { PATH } from 'src/constants/index';
import bridgePageStore from 'src/stores/BridgePageStore';
import { requestNFTCheckMessage } from 'src/apis/api';
import { StyledWrapper, StyledBtnWrapper } from 'src/pages/StyledPage';

type BridgeResultType = {
  toTxSender: string;
  messageId: string;
  blockHeight: number;
  originChainNo: number;
  originNftScAddr: string;
  fromChainNo: number;
  fromNftScAddr: string;
  senderAddress: string;
  toChainNo: number;
  tokenId: number;
  recipientAddress: string;
  commission: number;
  txFee: number;
  messageStatus: number;
  toChainTxHash: string;
  toChainBlockHeight: number;
  deployFee?: number;
};

const _initialState = {
  toTxSender: '',
  messageId: '',
  originChainNo: null,
  originNftScAddr: '',
  fromChainNo: null,
  fromNftScAddr: '',
  fromChainTxHash: '',
  fromTxDate: 0,
  fromTxFee: 0,
  senderAddress: '',
  toChainNo: null,
  tokenId: null,
  toNftScAddr: '',
  recipientAddress: '',
  commission: 0,
  toTxDate: 0,
  toTxFee: 0,
  txFee: 0,
  messageStatus: 0,
  toChainTxHash: '',
  deployFee: 0,
};

const TransactionCompleteViewContainer: React.FC = () => {
  const store = bridgePageStore;
  const config = store.getBridgeConfig();
  const _hash = store.getBridgeResult();
  const _msg = store.getTxConfig();
  const [txHash, setTxHash] = useState<string>('');
  const [result, setResult] = useState<BridgeResultType>({
    ..._initialState,
    ..._msg,
  });
  const _messageId = result.messageId;
  console.log(config.aptosDirectTransfer);

  const checkMsg = useCallback(
    async (hash: string) => {
      try {
        const rsp = await requestNFTCheckMessage(hash, {
          message_id: _messageId,
        });
        return rsp;
      } catch (e) {
        throw e;
      }
    },
    [_messageId]
  );

  useEffect(() => {
    if (_messageId) {
      let intervalId = setInterval(async () => {
        const rsp = await checkMsg(_hash);

        const _result = rsp.result;

        if (_result.messageStatus === 4 || _result.messageStatus === 10) {
          clearInterval(intervalId);
        }

        if (!!_result) {
          setResult((prev) => {
            return {
              ...prev,
              ..._result,
            };
          });
        }
      }, 10000);
      return () => clearInterval(intervalId);
    }
  }, [txHash, _hash, _messageId, checkMsg]);

  const _onChangeTxHash = useCallback(() => {
    const txHash: string = store.getBridgeResult();
    setTxHash(txHash);
  }, [store]);

  useEffect(() => {
    store.addChangeListener(_onChangeTxHash);
    return () => store.removeChangeListener(_onChangeTxHash);
  }, [_onChangeTxHash, store]);

  return (
    <>
      <StyledWrapper step={0} className="complete">
        <BridgeCompleteView config={config} result={result} />
      </StyledWrapper>
      <StyledBtnWrapper width={628}>
        <Link
          onClick={(e) => {
            e.stopPropagation();
            if (!!_messageId) {
              bridgePageAction._reset();
              stepAction._changeStep(0);
            }
          }}
          to={`${PATH.EXPLORER_DETAIL}${_messageId}`}
          state={{ fromTxResult: true }}
        >
          <Button name="View on Explorer" handleOnClick={() => {}} />
        </Link>
      </StyledBtnWrapper>
    </>
  );
};

export { TransactionCompleteViewContainer };

import React from 'react';
import styled from 'styled-components';
import { ImageLoader } from 'src/components/index';
import { theme } from 'src/styles/theme';
import img_havah_default from 'src/assets/tokens/img_NFT_default_dark.png';
import ic_close from 'src/assets/ic_close.svg';

interface IProps {
  nftInfo: any;
  tokenId: string;
  aptosCreatorAddr?: string;
  tokenCount: number;
  close: () => void;
}

const FromNFTSelectedView: React.FC<IProps> = (props) => {
  const { nftInfo, tokenId, aptosCreatorAddr, tokenCount, close } = props;
  const { name, image } = nftInfo;

  return (
    <>
      <div className='mgb-16' />
      <StyledComp>
        <div className='nft-title'>NFTs</div>
        <div className='nft-content'>
          <div className='img-area'>
            <ImageLoader url={image} img={img_havah_default} outline={true} />
          </div>
          <div className='nft-info'>
            <div className='nft-details'>
              <span className='token-name'>{name}</span>
              {!aptosCreatorAddr && (
                <>
                  <span className='divider'>|</span>
                  <span className='token-id'>#{tokenId}</span>
                </>
              )}
              {tokenCount != null && (
                <div className='nft-count'>
                  {tokenCount <= 99999 ? (
                    <div className='count-text'>x&nbsp;</div>
                  ) : null}
                  {tokenCount}
                </div>
              )}
            </div>
            <img
              onClick={() => close()}
              className='close-btn'
              src={ic_close}
              alt='close'
            />
          </div>
        </div>
      </StyledComp>
    </>
  );
};

const StyledComp = styled.div`
  & > div.nft-title {
    ${theme.body1};
    color: ${theme.mono3};
    margin-bottom: 8px;
    height: 20px;
  }
  & > div.nft-content {
    display: flex;
    align-items: center;
    justify-content: right;
    width: 100%;
    height: auto;
    background-color: ${theme.mono6};
    border-radius: 6px;
    overflow: hidden;
    & > div.img-area {
      border-radius: 6px;
      overflow: hidden;
      width: 48px;
      height: 48px;
      & > img {
        width: 100%;
        height: 100%;
      }
    }
    & > div.nft-info {
      display: flex;
      align-items: center;
      padding: 6px 19px 6px 8px;
      width: calc(100% - 48px);
      & > div.nft-details {
        display: flex;
        & > span.token-name {
          ${theme.body1};
          max-width: 186px;
          margin-left: 2px;
          color: ${theme.mono1};
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        & > span.divider {
          width: 4px;
          height: 20px;
          margin-left: 10px;
          margin-right: 10px;
          color: ${theme.mono4};
        }
        & > span.token-id {
          max-width: 186px;
          ${theme.body1};
          color: ${theme.mono1};
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        & > div.nft-count {
          ${theme.tokenAmount}
          padding: 1px 4px;
          display: inline-flex;
          justify-content: center;
          height: 18px;
          margin-left: 10px;
          min-width: 20px;
          width: auto;
          background-color: rgba(22, 217, 146, 0.14);
          border-radius: 6px;
          color: ${theme.primaryTxt};
        }
      }
      & > img.close-btn {
        margin-left: auto;
        cursor: pointer;
      }
    }
  }
`;

export { FromNFTSelectedView };

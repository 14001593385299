import React from 'react';
import styled from 'styled-components';
import { theme } from 'src/styles/theme';

interface IProps {
  isBridge?: boolean;
  bannerTitle?: string;
  bannerSubtitle?: string;
}

const ServiceTitle: React.FC<IProps> = (props) => {
  const { isBridge = false, bannerTitle, bannerSubtitle } = props;
  return (
    <StyledServiceTitle isBridge={isBridge}>
      <span>{bannerTitle && bannerTitle}</span>
      <span>{bannerSubtitle && bannerSubtitle}</span>
    </StyledServiceTitle>
  );
};

const StyledServiceTitle = styled.div<{ isBridge: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: ${(props) => (props.isBridge ? 640 : 1000)}px;
  height: 58px;
  margin: 0px auto ${(props) => (props.isBridge ? 24 : 26)}px;
  margin-top: 30px;
  & > span:first-of-type {
    ${theme.headline1};
    line-height: 36px;
    color: ${theme.mono1};
    font-family: 'LuckiestGuy';
  }
  & > span {
    ${theme.body1};
    color: ${theme.mono4};
  }
`;

export { ServiceTitle };

import { WALLET_ID } from 'src/constants/enums';

// Chain Image
import logo_havah from 'src/assets/chain/logo_havah@2x.png';
import logo_bsc from 'src/assets/chain/logo_bsc@2x.png';
import logo_klaytn from 'src/assets/chain/logo_klaytn@2x.png';
import logo_ethereum from 'src/assets/chain/logo_ethereum@2x.png';
import logo_polygon from 'src/assets/chain/logo_polygon.png';
import logo_iconex from 'src/assets/chain/logo_iconex.png';
import logo_meverse from 'src/assets/chain/logo_meverse@2x.png';
import logo_aptos from 'src/assets/chain/logo_aptos@2x.png';
import logo_wemix from 'src/assets/chain/logo_wemix.png';
import logo_fncy from 'src/assets/chain/logo_fncy.png';
import logo_base from 'src/assets/chain/logo_base.png';
import logo_oktc from 'src/assets/chain/logo_oktc.png';
import logo_xpla from 'src/assets/chain/logo_xpla.png';
import logo_solana from 'src/assets/chain/logo_solana.png';

// Wallet Image
import logo_wallet_metamask from 'src/assets/wallet/logo_metamask@2x.png';
import logo_wallet_petra from 'src/assets/wallet/logo_petra@2x.png';
import logo_wallet_xpla from 'src/assets/wallet/logo_keplr.png';
import logo_wallet_phantom from 'src/assets/wallet/logo_phantom@2x.png';

// Token Image
import img_havah from 'src/assets/tokens/img_token_havah.png';
import img_havah_tether from 'src/assets/tokens/img_havah_tether.png';
import img_tether from 'src/assets/tokens/img_tether.png';
import img_polygon_tether from 'src/assets/tokens/img_havah_ptether.png';
import img_token_tava from 'src/assets/tokens/img_token_tava.png';
import { cubeChainInfo, dimensionChainInfo } from './cosmosType';

const isDev = process.env.REACT_APP_IS_DEV === 'dev';

let NETWORK_LOGO: any = {
  1: logo_iconex,
  2: logo_bsc,
  3: logo_klaytn,
  4: logo_havah,
  5: logo_ethereum,
  6: logo_polygon,
  8: logo_aptos,
  9: logo_base,
  10: logo_solana,
  101: logo_meverse,
  102: logo_wemix,
  103: logo_fncy,
  104: logo_oktc,
  201: logo_xpla,
};

let TOKEN_LOGO_DEV = {
  1: img_havah,
  2: img_havah,
  7: img_tether,
  8: img_polygon_tether,
  9: img_havah,
  10: img_havah,
  11: img_token_tava,
  12: img_havah_tether,
  13: img_havah,
  14: img_tether,
  15: img_havah,
  16: img_tether,
  17: img_havah_tether,
};

let TOKEN_LOGO_PROD = {
  1: img_havah,
  2: img_havah,
  3: img_tether,
  4: img_havah_tether,
  5: img_tether,
  6: img_polygon_tether,
  7: img_havah,
  8: img_havah,
  9: img_token_tava,
  10: img_token_tava,
};

const WALLET_LIST: any = {
  ethereum: [
    {
      icon: logo_wallet_metamask,
      name: 'MetaMask',
      value: WALLET_ID.METAMASK,
    },
  ],
  bsc: [
    {
      icon: logo_wallet_metamask,
      name: 'MetaMask',
      value: WALLET_ID.METAMASK,
    },
  ],
  klaytn: [
    {
      icon: logo_wallet_metamask,
      name: 'MetaMask',
      value: WALLET_ID.METAMASK,
    },
  ],
  havah: [
    {
      icon: logo_havah,
      name: 'HAVAH',
      value: WALLET_ID.HAVAH,
    },
  ],
  polygon: [
    {
      icon: logo_wallet_metamask,
      name: 'MetaMask',
      value: WALLET_ID.METAMASK,
    },
  ],
  meverse: [
    {
      icon: logo_wallet_metamask,
      name: 'MetaMask',
      value: WALLET_ID.METAMASK,
    },
  ],
  aptos: [
    {
      icon: logo_wallet_petra,
      name: 'Petra',
      value: WALLET_ID.PETRA,
    },
  ],
  wemix: [
    {
      icon: logo_wallet_metamask,
      name: 'MetaMask',
      value: WALLET_ID.METAMASK,
    },
  ],
  fncy: [
    {
      icon: logo_wallet_metamask,
      name: 'MetaMask',
      value: WALLET_ID.METAMASK,
    },
  ],
  solana: [
    {
      icon: logo_wallet_phantom,
      name: 'Phantom',
      value: WALLET_ID.PHANTOM,
    },
  ],
  base: [
    {
      icon: logo_wallet_metamask,
      name: 'MetaMask',
      value: WALLET_ID.METAMASK,
    },
  ],
  oktc: [
    {
      icon: logo_wallet_metamask,
      name: 'MetaMask',
      value: WALLET_ID.METAMASK,
    },
  ],
  xpla: [
    {
      icon: logo_wallet_xpla,
      name: 'Keplr',
      value: WALLET_ID.XPLA,
    },
  ],
};

const SCAN_URL_PROD = {
  2: 'https://bscscan.com/tx/',
  3: 'https://scope.klaytn.com/tx/',
  4: 'https://scan.havah.io/txn/',
  5: 'https://etherscan.io/tx/',
  6: 'https://polygonscan.com/tx/',
  8: 'https://explorer.aptoslabs.com/txn/',
  9: 'https://basescan.org/tx/',
  10: 'https://explorer.solana.com/tx/',
  101: 'https://www.meversescan.io/tx/',
  102: 'https://explorer.wemix.com/tx/',
  103: 'https://fncyscan.fncy.world/tx/',
  104: 'https://www.oklink.com/oktc/tx/',
  201: 'https://explorer.xpla.io/mainnet/tx/',
};

const SCAN_URL_DEV = {
  2: 'https://testnet.bscscan.com/tx/',
  3: 'https://baobab.scope.klaytn.com/tx/',
  4: 'https://scan.vega.havah.io/txn/',
  5: 'https://sepolia.etherscan.io/tx/',
  6: 'https://amoy.polygonscan.com/tx/',
  8: 'https://explorer.aptoslabs.com/txn/',
  9: 'https://sepolia.basescan.org/tx/',
  10: 'https://explorer.solana.com/tx/',
  101: 'https://testnet.meversescan.io/tx/',
  102: 'https://explorer.test.wemix.com/tx/',
  103: 'https://fncyscan-testnet.fncy.world/tx/',
  104: 'https://www.oklink.com/oktc-test/tx/',
  201: 'https://explorer.xpla.io/testnet/tx/',
};

const SCAN_ADDR_URL_PROD: any = {
  2: 'https://bscscan.com/address/',
  3: 'https://scope.klaytn.com/account/',
  4: 'https://scan.havah.io/address/',
  5: 'https://etherscan.io/address/',
  6: 'https://polygonscan.com/address/',
  8: 'https://explorer.aptoslabs.com/account/',
  9: 'https://basescan.org/address/',
  10: 'https://explorer.solana.com/address/',
  101: 'https://www.meversescan.io/address/',
  102: 'https://explorer.wemix.com/address/',
  103: 'https://fncyscan.fncy.world/address/',
  104: 'https://www.oklink.com/oktc/address/',
  201: 'https://explorer.xpla.io/mainnet/address/',
};

const SCAN_ADDR_URL_DEV: any = {
  2: 'https://testnet.bscscan.com/address/',
  3: 'https://baobab.scope.klaytn.com/account/',
  4: 'https://scan.vega.havah.io/address/',
  5: 'https://sepolia.etherscan.io/address/',
  6: 'https://amoy.polygonscan.com/address/',
  8: 'https://explorer.aptoslabs.com/account/',
  9: 'https://sepolia.basescan.org/address/',
  10: 'https://explorer.solana.com/address/',
  101: 'https://testnet.meversescan.io/address/',
  102: 'https://explorer.test.wemix.com/address/',
  103: 'https://fncyscan-testnet.fncy.world/address/',
  104: 'https://www.oklink.com/oktc-test/address/',
  201: 'https://explorer.xpla.io/testnet/address/',
};

const CHAIN_ID_PROD = {
  2: 56,
  3: 8217,
  4: 256,
  5: 1,
  6: 137,
  9: 8453,
  10: 1399811149,
  101: 7518,
  102: 1111,
  103: 73,
  104: 66,
  201: 37,
};

const CHAIN_ID_DEV = {
  2: 97,
  3: 1001,
  4: 257,
  5: 11155111,
  6: 80002,
  9: 84532,
  101: 4759,
  102: 1112,
  103: 923018,
  104: 65,
  201: 3701,
};

const CHAIN_INFO_PROD = {
  'dimension_37-1': dimensionChainInfo,
};

const CHAIN_INFO_DEV = {
  'cube_47-5': cubeChainInfo,
};

const CHAIN_NAME_PROD = {
  2: 'bsc',
  5: 'eth',
  6: 'polygon',
  9: 'base',
  201: 'dimension_37-1',
};

const CHAIN_NAME_DEV = {
  2: 'bsc testnet',
  5: 'sepolia',
  6: 'amoy',
  9: 'base sepolia',
  201: 'cube_47-5',
  10: 69420,
  101: 4759,
  102: 1112,
  103: 923018,
  104: 195,
};
// 2 : BSC, 3 : BAOBAB, 5 : Ethereum, 6 : Polygon
const SCAN_URL: any = isDev ? SCAN_URL_DEV : SCAN_URL_PROD;
const SCAN_ADDR_URL: any = isDev ? SCAN_ADDR_URL_DEV : SCAN_ADDR_URL_PROD;
const METAMASK_CHAIN_ID: { [key: number]: number } = isDev
  ? CHAIN_ID_DEV
  : CHAIN_ID_PROD;
const BRIDGE_CHAIN_NAME: { [key: number]: string } = isDev
  ? CHAIN_NAME_DEV
  : CHAIN_NAME_PROD;
const KEPLR_CHAIN_INFO: { [key: string]: any } = isDev
  ? CHAIN_INFO_DEV
  : CHAIN_INFO_PROD;

const TOKEN_LOGO: any = isDev ? TOKEN_LOGO_DEV : TOKEN_LOGO_PROD;

const METAMASK_NATIVE_CURRENCY: any = {
  1: 'ETH',
  5: 'ETH',
  56: 'BNB',
  97: 'BNB',
  8217: 'KLAY',
  1001: 'KLAY',
  80001: 'MATIC',
  80002: 'MATIC',
  137: 'MATIC',
  7518: 'MEV',
  4759: 'MEV',
  1111: 'WEMIX',
  1112: 'TWEMIX',
  73: 'FNCY', // FNCY
  923018: 'FNCY', // FNCY TEST
  11155111: 'ETH', // SEPOLIA
  8453: 'ETH', // BASE NET
  84531: 'ETH', // BASE TEST
  84532: 'ETH', // BASE SEPOLIA
  66: 'OKT',
  65: 'OKB',
  37: 'XPLA',
  3701: 'XPLA',
  69420: 'SOL',
  1399811149: 'SOL',
};

const PATH = {
  FT: '/bridge/ft',
  NFT: '/bridge/nft',
  NFT_SEND: '/bridge/nft/send',
  NFT_SEND_COMPLETE: '/bridge/nft/complete',
  EXPLORER: '/bridge/explorer',
  EXPLORER_DETAIL: '/bridge/explorer/',
  MOBILE_BLOCK: '/bridge/block',
  SERVICE_UNAVAILABLE: '/bridge/service-block',
};

const CHROME_EXTENSION_LINK: { [key: string]: string } = {
  metamask:
    'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn',
  havah:
    'https://chrome.google.com/webstore/detail/havah-wallet/cnncmdhjacpkmjmkcafchppbnpnhdmon',
  petra:
    'https://chrome.google.com/webstore/detail/petra-aptos-wallet/ejjladinnckdgjemekebdpeokbikhfci',
  xpla: 'https://chromewebstore.google.com/detail/xpla-vault-wallet/ocjobpilfplciaddcbafabcegbilnbnb',
  keplr:
    'https://chromewebstore.google.com/detail/keplr/dmkamcknogkgcdfhhbddcghachkejeap',
  phantom:
    'https://chrome.google.com/webstore/detail/phantom/bfnaelmomeimhlpmgjnjophhpkkoljpa',
};

export {
  NETWORK_LOGO,
  TOKEN_LOGO,
  WALLET_LIST,
  METAMASK_CHAIN_ID,
  SCAN_URL,
  SCAN_ADDR_URL,
  PATH,
  CHROME_EXTENSION_LINK,
  METAMASK_NATIVE_CURRENCY,
  BRIDGE_CHAIN_NAME,
  KEPLR_CHAIN_INFO,
};

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { theme } from 'src/styles/theme';
import loading_gif from 'src/assets/img_loading.gif';
import loading_light_gif from 'src/assets/img_loading_light.gif';

interface IProps {
  type?: 'dark' | 'light';
}

const LoadingModal: React.FC<IProps> = ({ type = 'dark' }) => {
  const [dots, setDots] = useState<string>('...');

  useEffect(() => {
    let maxDots = 3;
    const timerId = setInterval(() => {
      setDots((prev: string) => {
        const len = prev.length;
        const curDots = len < maxDots ? prev + '.' : '';
        return curDots;
      });
    }, 300);
    return () => clearInterval(timerId);
  }, []);

  const isDark = type === 'dark';

  return (
    <StyledModal>
      <img src={isDark ? loading_gif : loading_light_gif} alt="loading" />
      <span>Loading{dots}</span>
    </StyledModal>
  );
};

const StyledModal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & > img {
    width: 62px;
    height: 62px;
    margin-bottom: 3px;
  }
  & > span {
    ${theme.tab1};
    color: ${theme.mono4};
  }
`;

export { LoadingModal };

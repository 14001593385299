import React, { useContext } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { ModalContext } from 'src/contexts/ToastModalContext';

const ToastModal: React.FC = () => {
  const { modalContent, isModalOpened } = useContext(ModalContext);

  return ReactDOM.createPortal(
    <>{isModalOpened && <StyledToastModal>{modalContent}</StyledToastModal>}</>,
    document.querySelector('#modal-toast')!
  );
};

const StyledToastModal = styled.div`
  position: fixed;
  z-index: 20001;
  top: 34px;
  right: -398px;
  animation: slide 5s ease-in-out;
  @keyframes slide {
    0% {
      right: -398px;
    }
    20% {
      right: 20px;
    }
    80% {
      right: 20px;
    }
    100% {
      right: -398px;
    }
  }
`;

export { ToastModal };

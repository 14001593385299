import React from 'react';
import styled from 'styled-components';
import { theme } from 'src/styles/theme';
import ic_unlink from 'src/assets/unlink.png';
import ic_exclude from 'src/assets/ic_exclude.svg';
import img_tip from 'src/assets/img_bubble_tip.png';

interface IProps {
  initialValue?: string;
  name?: string;
  timer?: string;
  fontWeight?: number;
  handleOnClick: () => void;
  type?:
    | 'cta'
    | 'ghostWithIcon'
    | 'ghost'
    | 'ghost2'
    | 'connected'
    | 'nftSelect';
  disabled?: boolean;
  isError?: boolean;
  icon?: string;
}

const Button: React.FC<IProps> = (props) => {
  const {
    initialValue = '',
    name,
    timer = '',
    fontWeight = 400,
    handleOnClick,
    type = 'cta',
    disabled = false,
    isError = false,
    icon,
  } = props;
  const ellipsisTxt = !!name
    ? name.substr(0, 5) + '...' + name.substr(name.length - 5)
    : '-';

  return (
    <StyledButtonWrapper onClick={() => handleOnClick()}>
      {type === 'cta' && (
        <StyledCTAButton
          disabled={disabled}
          style={{
            fontWeight,
          }}
        >
          <span>{!!name ? name : initialValue}</span>
          {timer && <span> {timer}</span>}
        </StyledCTAButton>
      )}
      {type === 'ghostWithIcon' && (
        <StyledGhostButtonWithIcon onClick={() => handleOnClick()}>
          <img src={icon} alt='icon' />
          <span>{name}</span>
        </StyledGhostButtonWithIcon>
      )}
      {type === 'ghost' && (
        <StyledGhostButton
          className={isError ? 'error' : 'normal'}
          disabled={disabled}
          isError={isError}
        >
          <span className='ellipsis'>{ellipsisTxt}</span>
        </StyledGhostButton>
      )}
      {type === 'ghost2' && (
        <StyledGhost2Button
          className={isError ? 'error' : 'normal'}
          disabled={disabled}
          isError={isError}
        >
          <span>{!!name ? name : initialValue}</span>
        </StyledGhost2Button>
      )}
      {type === 'connected' && (
        <StyledConnectedButton>
          {!!name
            ? name.substring(0, 5) + '...' + name.substring(name.length - 5)
            : initialValue}
          <div className='hover-bubble'>Disconnect</div>
        </StyledConnectedButton>
      )}
      {type === 'nftSelect' && (
        <StyledNFTButton
          disabled={disabled}
          style={{
            fontWeight,
          }}
        >
          <span>{!!name ? name : initialValue}</span>
          {timer && <span> {timer}</span>}
        </StyledNFTButton>
      )}
    </StyledButtonWrapper>
  );
};

const StyledButtonWrapper = styled.div`
  width: auto;
  height: 100%;
`;

const StyledCTAButton = styled.button`
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 6px;
  color: ${theme.mono1};
  background-color: ${theme.primaryTxt};
  cursor: pointer;
  & > span {
    font-family: 'BrutalType';
  }
  &:hover {
    color: ${theme.mono1};
    background-color: ${theme.primaryBG};
  }
  &:disabled {
    color: ${theme.mono4};
    background-color: ${theme.mono5};
    cursor: default;
  }
`;

const StyledGhostButtonWithIcon = styled.button`
  ${theme.body1};
  line-height: 20px;
  font-family: 'BrutalType';
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: ${theme.mono1};
  border-radius: 6px;
  border: 1px solid ${theme.mono2};
  background-color: transparent;
  padding: 5px 10px;
  cursor: pointer;
  & > img {
    width: 20px;
    height: 20px;
  }
`;

const StyledGhostButton = styled.button<{ isError: boolean }>`
  ${theme.body1};
  line-height: 20px;
  font-family: 'BrutalType';
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: ${theme.mono6};
  border-radius: 6px;
  border: 1px solid ${theme.mono2};
  background-color: transparent;
  padding: 5px 10px;
  overflow: hidden;
  ${(props) =>
    props.isError &&
    `
    background : url(${ic_exclude}) center left 10px no-repeat;
    background-size : 20px 20px;
  `};
  &:hover {
    background-color: ${theme.mono1};
    color: ${theme.primaryTxt};
    border: 1px solid ${theme.primaryBG};
    ${(props) =>
      props.isError &&
      `
      color : ${theme.mono6};
      border : 1px solid ${theme.error};
  `};
  }
  &:disabled {
    color: ${theme.mono4};
    border: 1px solid ${theme.mono5};
    cursor: default;
  }
`;

const StyledGhost2Button = styled.button<{ isError: boolean }>`
  ${theme.body2};
  line-height: 20px;
  font-family: 'BrutalType';
  text-align: center;
  vertical-align: middle;
  width: 100%;
  height: 100%;
  color: ${theme.mono1};
  border-radius: 6px;
  border: 1px solid ${theme.mono4};
  background-color: transparent;
  padding: 5px 10px;
  &:hover {
    background-color: rgba(0, 185, 132, 0.1);
    color: ${theme.primaryTxt};
    border: 1px solid ${theme.primaryBG};
    ${(props) =>
      props.isError &&
      `
      color : ${theme.mono6};
      border : 1px solid ${theme.error};
  `};
  }
  &:disabled {
    color: ${theme.mono4};
    border: 1px solid ${theme.mono5};
    cursor: default;
  }
`;

const StyledConnectedButton = styled.button`
  position: relative;
  width: 100%;
  height: 100%;
  color: ${theme.mono1};
  border-radius: 6px;
  padding: 14px 15px 14px 40px;
  border: 1px solid ${theme.mono4};
  background: url(${ic_unlink}) center left 15px no-repeat;
  background-size: 20px 20px;
  background-color: transparent;
  cursor: pointer;
  & > div.hover-bubble {
    display: none;
    /* visibility: hidden; */
  }
  &:hover {
    & > div.hover-bubble {
      display: block;
      ${theme.caption};
      padding: 5px 18px;
      position: absolute;
      top: -30px;
      left: 50%;
      visibility: visible;
      transform: translateX(-50%);
      background-color: ${theme.subpink};
      border-radius: 6px;
      z-index: 20000;
      &:after {
        content: ' ';
        position: absolute;
        bottom: -5px;
        left: 50%;
        width: 6px;
        height: 5px;
        background: url(${img_tip}) center center no-repeat;
        background-size: 6px 5px;
        transform: translate(-50%);
      }
    }
  }
`;

const StyledNFTButton = styled.button`
  width: 68px;
  height: 100%;
  border: none;
  margin-left: auto;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  color: ${theme.mono1};
  background-color: ${theme.primaryTxt};
  cursor: pointer;
  & > span {
    font-family: 'BrutalType';
  }
  &:hover {
    color: ${theme.mono1};
    background-color: ${theme.primaryBG};
  }
  &:disabled {
    color: ${theme.mono4};
    background-color: ${theme.mono5};
    cursor: default;
  }
`;

export { Button };

import React, { useState, useEffect, useCallback, useContext } from 'react';
import {
  FromChainConnectView,
  ModalWalletList,
  ModalServiceUnavailablePage,
} from 'src/components/index';
import { requestFTChainList } from 'src/apis/api';
import bridgePageStore from 'src/stores/BridgePageStore';
import { ModalContext } from 'src/contexts/ModalContext';
import { ModalContext as ModalShadowContext } from 'src/contexts/ModalShadowContext';
import { bridgePageAction } from 'src/actions/bridgePageAction';
import { stepAction } from 'src/actions/stepAction';
import { ChainConfig, ChainListType } from 'src/constants/types';
import { WALLET_LIST } from 'src/constants/index';
import { NETWORK_ID } from 'src/constants/enums';
import { errCodeParser } from 'src/utils/utils';

const FromChainConnectViewContainer: React.FC = () => {
  const modal = useContext(ModalContext);
  const shadowModal = useContext(ModalShadowContext);
  const store = bridgePageStore;
  const config = store.getBridgeConfig();
  const fromChainNo = (config.fromChain as ChainConfig).chainNo;
  const fromChainName = (config.fromChain as ChainConfig).chainName;
  const fromWalletAddr = config.fromWalletAddr;
  // const [chainNo, setChainNo] = useState<number>();
  const [chainList, setChainList] = useState<ChainListType[]>([]);
  const [walletAddr, setWalletAddr] = useState<string>(fromWalletAddr);
  const [chainNo, setChainNo] = useState<number>(fromChainNo);
  const [chainName, setChainName] = useState<string>(fromChainName);

  const _onChange = () => {
    const _config = bridgePageStore.getBridgeConfig();
    const fromChainNo = (_config.fromChain as ChainConfig).chainNo;
    const fromChainName = (_config.fromChain as ChainConfig).chainName;
    const fromWalletAddr = _config.fromWalletAddr;
    setWalletAddr(fromWalletAddr);
    setChainNo(fromChainNo);
    setChainName(fromChainName);
  };

  useEffect(() => {
    store.addChangeListener(_onChange);
    return () => store.addChangeListener(_onChange);
  }, []);

  useEffect(() => {
    getChainList();
  }, []);

  const fromChainModalHandler = useCallback(() => {
    const _chainNo = fromChainNo;
    const chainName = NETWORK_ID[_chainNo];
    const toLower = chainName.toLowerCase();
    const walletList = WALLET_LIST[toLower];
    const chainListIdx = chainList.findIndex(
      (el) => el.chainNo === fromChainNo
    );
    const chainInfo = chainList[chainListIdx];
    modal.setTitle('Connect Wallet');
    modal.handleModal(
      <ModalWalletList list={walletList} chainInfo={chainInfo} />
    );
  }, [fromChainNo, chainList]);

  const fromChainBtnDisabled = !fromChainNo;

  const chainIdHandler = useCallback(
    (chainId: number | string) => {
      const chainListIdx = chainList.findIndex((el) => el.chainNo === chainId);
      bridgePageAction._changeFromChain(chainList[chainListIdx]);
      bridgePageAction._removeFromWallet();
      bridgePageAction._removeFromToken();
      bridgePageAction._removeToChain();
      bridgePageAction._inputBalance('0');
      bridgePageAction._removeToWallet();
      bridgePageAction._removeWeb3WalletID();
      stepAction._changeStep(0);
    },
    [chainList]
  );

  const resetFromChainView = () => {
    bridgePageAction._removeFromWallet();
    bridgePageAction._removeFromToken();
    bridgePageAction._removeToChain();
    bridgePageAction._inputBalance('0');
    bridgePageAction._removeToWallet();
    bridgePageAction._removeWeb3WalletID();
    stepAction._changeStep(0);
  };

  const getChainList = async () => {
    try {
      const rsp = await requestFTChainList();
      setChainList(rsp);
    } catch (e: any) {
      fullyReset();
      const { retCode } = errCodeParser(e);
      if (retCode === '1') {
        shadowModal.handleModal(<ModalServiceUnavailablePage />);
      }
      console.log(e);
    }
  };

  const fullyReset = () => {
    bridgePageAction._removeFromChain();
    bridgePageAction._removeFromWallet();
    bridgePageAction._removeFromToken();
    bridgePageAction._removeToChain();
    bridgePageAction._inputBalance('0');
    bridgePageAction._removeToWallet();
    bridgePageAction._removeWeb3WalletID();
    stepAction._changeStep(0);
  };

  return (
    <FromChainConnectView
      modalHandler={fromChainModalHandler}
      handleOnList={getChainList}
      btnDisabled={fromChainBtnDisabled}
      chainIdHandler={chainIdHandler}
      reset={resetFromChainView}
      chainList={chainList}
      walletAddr={walletAddr}
      chainName={chainName}
      chainNo={chainNo}
    />
  );
};

export { FromChainConnectViewContainer };

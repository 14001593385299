import { TxnBuilderTypes, BCS, AptosClient } from 'aptos';
import { Buffer } from 'buffer';
import { BroadcastMode, ChainInfo, Keplr, StdFee } from '@keplr-wallet/types';
import { BRIDGE_CHAIN_NAME, KEPLR_CHAIN_INFO } from 'src/constants';
import { SecretNetworkClient } from 'secretjs';
import { AuthInfo, Fee, TxBody, TxRaw } from 'src/cosmos/tx/v1beta1/tx';
import { SignMode } from 'src/cosmos/tx/signing/v1beta1/signing';
import { PubKey } from 'src/cosmos/crypto/secp256k1/keys';
import { Any } from 'src/cosmos/google/protobuf/any';
import Long from 'long';
import { fromUtf8, toUtf8 } from '@cosmjs/encoding';
import { LCDClient } from '@xpla/xpla.js';
import { toHex, uint8ArrayToString } from 'src/utils/utils';
const isDev = process.env.REACT_APP_IS_DEV === 'dev';
const controller = new AbortController();

const getXplaLCDClient = () => {
  const xpla = isDev
    ? new LCDClient({
        URL: 'https://cube-lcd.xpla.dev',
        chainID: 'cube_47-5',
      })
    : new LCDClient({
        URL: 'https://dimension-lcd.xpla.dev',
        chainID: 'Dimension_37-1',
      });
  return xpla;
};

const getKeplrFromWindow: () => Promise<Keplr | undefined> = async () => {
  if (typeof window === 'undefined') {
    return undefined;
  }

  if (window.keplr) {
    return window.keplr;
  }

  if (document.readyState === 'complete') {
    return window.keplr;
  }

  return new Promise((resolve) => {
    const documentStateChange = (event: Event) => {
      if (
        event.target &&
        (event.target as Document).readyState === 'complete'
      ) {
        resolve(window.keplr);
        document.removeEventListener('readystatechange', documentStateChange);
      }
    };

    document.addEventListener('readystatechange', documentStateChange);
  });
};

const getKeplr = async (chainId: string) => {
  try {
    const keplr = await getKeplrFromWindow();

    if (!keplr) {
      return undefined;
    } else {
      await keplr.experimentalSuggestChain(KEPLR_CHAIN_INFO[chainId]);
      await keplr.enable(chainId);
    }
    return keplr;
  } catch (e) {
    throw e;
  }
};

const getAddressFromKeplr = async (chainId: string) => {
  try {
    const keplr = await getKeplrFromWindow();
    if (!keplr) {
      return '';
    }
    const offlineSigner = keplr.getOfflineSigner(chainId);
    const accounts = await offlineSigner.getAccounts();
    console.log(accounts);

    return accounts[0].address;
  } catch (e) {
    throw e;
  }
};

const getBalanceFromKeplr = async (
  chainId: string,
  address: string,
  endPoint: string
) => {
  const secretjs = new SecretNetworkClient({
    url: endPoint,
    chainId: chainId,
  });

  const result = await secretjs.query.bank.balance({
    address: address,
    denom: 'axpla',
  });
  console.log(result);
  // const uri = `${endPoint}/cosmos/bank/b1beta1/balances/${address}?pagination.limit=1000`;
  // const rsp = await fetch(uri, {
  //   method: 'GET',
  //   headers: {
  //     'Content-Type': 'application/json',
  //   },
  //   redirect: 'follow',
  //   signal: controller.signal,
  // });
  // console.log(rsp);
};

const sendTxFromKeplr = async (
  chainId: string,
  tx: Uint8Array
): Promise<Uint8Array> => {
  const keplr = await getKeplrFromWindow();

  return keplr!.sendTx(chainId, tx, 'sync' as BroadcastMode);
};

const sendMsgsFromKeplr = async (
  chainInfo: ChainInfo,
  sender: string,
  proto: Any[],
  fee: StdFee,
  memo: string = ''
) => {
  const keplr = await getKeplrFromWindow();

  const account = await fetchAccountInfo(chainInfo, sender);
  const { pubKey } = await keplr!.getKey(chainInfo.chainId);

  if (account) {
    const signDoc = {
      bodyBytes: TxBody.encode(
        TxBody.fromPartial({
          messages: proto,
          memo,
        })
      ).finish(),

      authInfoBytes: AuthInfo.encode({
        signerInfos: [
          {
            publicKey: {
              typeUrl: '/ethermint.crypto.v1.ethsecp256k1.PubKey',
              value: PubKey.encode({
                key: pubKey,
              }).finish(),
            },
            modeInfo: {
              single: {
                mode: SignMode.SIGN_MODE_DIRECT,
              },
              multi: undefined,
            },
            sequence: account.base_account.sequence,
          },
        ],
        fee: Fee.fromPartial({
          amount: fee.amount.map((coin) => {
            return {
              denom: coin.denom,
              amount: coin.amount.toString(),
            };
          }),
          gasLimit: fee.gas,
        }),
      }).finish(),
      chainId: chainInfo.chainId,
      accountNumber: Long.fromString(account.base_account.account_number),
    };

    const signed = await keplr!.signDirect(chainInfo.chainId, sender, signDoc);

    const signedTx = {
      tx: TxRaw.encode({
        bodyBytes: signed.signed.bodyBytes,
        authInfoBytes: signed.signed.authInfoBytes,
        signatures: [Buffer.from(signed.signature.signature, 'base64')],
      }).finish(),
      signDoc: signed.signed,
    };

    const txHash = await sendTxFromKeplr(chainInfo.chainId, signedTx.tx);

    return txHash !== undefined ? uint8ArrayToString(txHash) : '';
    // const txTracer = new TendermintTxTracer(chainInfo.rpc, '/websocket');
    // txTracer.traceTx(txHash).then((tx) => {
    //   alert('Transaction commit successfully');
    // });
  } else {
    throw new Error(JSON.stringify({ code: 'E500' }));
  }
};

export const fetchAccountInfo = async (
  chainInfo: ChainInfo,
  address: string
) => {
  try {
    const uri = `${chainInfo.rest}/cosmos/auth/v1beta1/accounts/${address}`;

    const rsp = await fetch(uri, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      signal: controller.signal,
    });

    const result = await rsp.json();
    if (result.code) throw result;
    // const response = await api<AccountResponse>(uri);
    console.log(result.account);
    return result.account;
  } catch (e) {
    // console.error(
    //   'This may be a new account. Please send some tokens to this account first.'
    // );
    throw e;
  }
};

export {
  getXplaLCDClient,
  getKeplr,
  getAddressFromKeplr,
  getBalanceFromKeplr,
  sendTxFromKeplr,
  sendMsgsFromKeplr,
};
